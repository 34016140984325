//BEGIN_IMPORT_SECTION
import React, {Component} from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import getToken,{setToken} from '../../GetSetToken';
import getApiUrl, {getPassthroughUrl} from '../../GetApiUrl';
import DataRetrieval, {retrieveApiData, retrieveRowCount,retrieveData2} from '../../data_retrieval';
import '../../styles.css';


const rowClassRules = {
	'row-fail': function(params) { return params.data.role_id === 0; },
	'row-pass': function(params) { return params.data.role_id >0; },
};	

var the_this
export default class AccessRoleAppGuiGrid extends React.Component  {
	state = {
		loaded:false,
		gridOptions:{},
		role_id:0,
		app_id:0,
		gui_id:0,
		data:[],
		dd_access_mode:{'N':'No Access','R':'Read Only','W':'Read and Write','F':'Full Access'},
	}
//	data:[{checked:0,role_id:0,app_id:0,gui_id:0,description:"",access_mode:"",note:"",create_by:"",create_dt:"",update_by:"",update_dt:"",}]
	// {"checked": 0, "role_id": 0, "app_id": 1, "gui_id": 10000, "description": "Account and System Security", "access_mode": "N", "note": null, "create_by": 0, "create_dt": "", "update_by": 0, "update_dt": ""}
	gridApi: GridApi
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: true })
		this.retrieveData()
	}
	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		console.log('AccessRoleAppGuiGrid-componentDidUpdate this.props',this.props);
		if(this.props.role_id!==prevProps.role_id||this.props.app_id!==prevProps.app_id) {
			if(this.props.role_id>0&&this.props.app_id>0)
				this.retrieveData();
		}
	}

	onGridReady = params => {
    	this.gridApi = params.api;
 	 };

	retrieveData() {
		console.log('AccessRoleAppGuiGrid retrieveData 0');

		let input_parm = [
			{"request_row_count":1},
			{"offset_row":0},
			{"request_rows":999},
			{"role_id":this.props.role_id},
			{"app_id":this.props.app_id},
		]
		let sql_id = 35;
		let app_id = 1;
		console.log('AccessRoleAppGuiGrid retrieveData input_parm',input_parm);
		let tmp_data = retrieveApiData(1,10200,sql_id,"usp_react_sql_retrieve2", JSON.stringify(input_parm));
        let where_clause = input_parm.slice(3,input_parm.length);
		Promise.resolve(tmp_data).then((value)=>{
			console.log('AccessRoleAppGuiGrid retrieveData value',value);
			let total_row_count = parseInt(value);
			retrieveData2(1,10200,sql_id,0,total_row_count,where_clause)
			.then((retrieved_data) => {
				console.log('AccessRoleAppGuiGrid retrieved_data',retrieved_data);
				let statusCopy = Object.assign({}, this.state)
				statusCopy.data = retrieved_data;
				this.setState(statusCopy);
				this.gridApi.setRowData(retrieved_data)
			})
		});

	}
	onSelectionChanged = (event)=>{
		const selectedNodes =  event.api.getSelectedNodes();		
		console.log('AccessRoleGuiGrid- selectedNodes:', selectedNodes);
		if(selectedNodes.length>0)
			the_this.props.getRoleAppGuiId(selectedNodes[0].data.gui_id)
	}
	onCellValueChanged = (obj) => {
		console.log('AccessRoleGuiGrid onCellValueChanged obj XX', obj);
		let field_name = obj.column.colDef.field 
		let the_role_id = obj.data.role_id
		let the_app_id = obj.data.app_id
		let the_gui_id = obj.data.gui_id
		let the_data = obj.value
		let token = getToken();
		if (typeof the_data==='string')
			the_data = '"' + the_data + '"'
		let the_input_data = '{"' + field_name + '":' + the_data + ',"role_id":' + the_role_id.toString() + ',"app_id":' + the_app_id.toString() + ',"gui_id":' + the_gui_id.toString() + '}'
		let the_input = '{"usp_name":"usp_react_sql_onchange_update","sql_id":35,"input_data"' + ':' + the_input_data + '}'
		console.log('AccessRoleGuiGrid-onCellValueChanged the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {
			method: 'POST',headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
         })
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			console.log('AccessRoleGuiGrid-onCellValueChanged 2 no error,responseJson',responseJson);
		})	
		.catch((error) => {
			console.error(error-'Error in update!');
			//this.insertOrder(1);
		});
	};
	onPasteStart(params) {
	  console.log('AccessRoleGuiGrid onPasteStart:', params);
	}
	onPasteEnd(params) {
	  console.log('AccessRoleGuiGrid Callback onPasteEnd:', params);
	}

	onCopySelectedRowsToClipboard(params) {
	  console.log('send to clipboard called with data:');
	  console.log(params.data);
	}
	onCellClicked(event){
//		console.log('AccessRoleGrid event', event.columnApi.columnController.columnDefs[0]);
		console.log("AccessRoleGuiGrid onCellClicked event", event);

	}
	onCellDoubleClicked(obj){
		console.log('AccessRoleGuiGrid onCellDoubleClicked(obj)', obj);
	}	
	onCellValueChanged(event) {
		console.log('AccessRoleGuiGrid onCellValueChanged(event)', event);
	}
	cellClicked(params) {
	console.log('AccessRoleGuiGrid cellClicked(params)', params);
	  params.node.setSelected(true)
	}
	onCellValueChanged = (obj) => {
		console.log('AccessRoleGuiGrid onCellValueChanged obj xx', obj);
		let field_name = obj.column.colDef.field 
		let the_role_id = obj.data.role_id
		let the_app_id = obj.data.app_id
		let the_gui_id = obj.data.gui_id
		let the_data = obj.value
		let token = getToken();
		if (typeof the_data==='string')
			the_data = '"' + the_data + '"'
			let the_input_data = '{"' + field_name + '":' + the_data + ',"role_id":' + the_role_id.toString() + ',"app_id":' + the_app_id.toString() + ',"gui_id":' + the_gui_id.toString() + '}'
			let the_input = '{"usp_name":"usp_react_sql_onchange_update","sql_id":3,"input_data"' + ':' + the_input_data + '}'
			console.log('AccessRoleGuiGrid-onCellValueChanged the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {
			method: 'POST',headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
         })
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			console.log('AccessRoleGuiGrid-onCellValueChanged 2 no error');
		})	
		.catch((error) => {
			console.error(error-'Error in update!');
			//this.insertOrder(1);
		});
	}
	onAddRow = () => {
		let there_is_update = false;
		the_this.gridApi.forEachNode(function(node, i){
			if(node.selected===true) {// selected the data
				console.log('AccessRoleGuiGrid-onAddRow selected node.data', node.data);
				if(node.data.role_id===0) {
					there_is_update = true;
					let the_input = [{"role_id":the_this.props.role_id},{"app_id":the_this.props.app_id},{"gui_id":node.data.gui_id},{"access_mode":"R"},{"note":"Online Created"}]
					let tmp_data = retrieveApiData(1,10200,35,"usp_react_sql_insert2", JSON.stringify(the_input));
					Promise.resolve(tmp_data).then((value)=>{
						console.log('AccessRoleGuiGrid-onAddRow value', value);
					})
				}
				console.log('AccessRoleGuiGrid-onAddRow selected===true node', node);
			}
		})
		if(there_is_update) {
			this.retrieveData();
		}
	}
	onAddAllGui = () => {
		console.log("onAddAllGui")
	}

	onDeleteRow = () => {
		let there_is_update = false;
		the_this.gridApi.forEachNode(function(node, i){
			if(node.selected===true) {// selected the data
				console.log('AccessRoleGuiGrid-onDeleteRow selected node.data.checked', node.data);
				if(node.data.role_id>0) {
					there_is_update = true;
					let the_input = [{"role_id":the_this.props.role_id},{"app_id":the_this.props.app_id},{"gui_id":node.data.gui_id}]
					console.log('AccessRoleGuiGrid-onDeleteRow selected the_input', the_input);
					let tmp_data = retrieveApiData(1,10200,35,"usp_react_sql_delete2", JSON.stringify(the_input));
					Promise.resolve(tmp_data).then((value)=>{
						console.log('AccessRoleGuiGrid-onDeleteRow value', value);
					})
				}
				console.log('AccessRoleGuiGrid-onDeleteRow selected===true node', node);
			}
		})
		
		if(there_is_update) {
			this.retrieveData();
		}
		
	}
/*
const model = gridApi.getFilterModel(); 
gridApi.setFilterModel(model);
{
    athlete: {
        filterType: 'text',
        type: 'startsWith',
        filter: 'mich'
    },
    age: {
        filterType: 'number',
        type: 'lessThan',
        filter: 30
    }
}
*/
	
	createGridOptions() {
		const options: GridOptions = {
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true,
				rowMultiSelectWithClick: true,
       			headerCheckboxSelection: true,
				rowDeselection: true,
			},
			columnDefs: [
				{headerName:"",headerClass:"header-checked",field:"checked",hide:false,width:30,sortable:true,headerCheckboxSelection:true,checkboxSelection:true,editable:true,valueSetter:function (params) {params.data.checked= params.newValue;return true;},suppressNavigable:true,
				headerCheckboxSelection: true,
				headerCheckboxSelectionFilteredOnly: true,
				checkboxSelection: true,
				},
				{headerName:"RoleID",width: 80, headerClass:"header-role_id",field:"role_id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.role_id= params.newValue;return true;},suppressNavigable:true,filter: 'NumberFilter',},
				{headerName:"AppID",width: 60, headerClass:"header-app_id",field:"app_id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.app_id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"GuiID",width: 60, headerClass:"header-gui_id",field:"gui_id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.gui_id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Description",width: 150, headerClass:"header-description",field:"description",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.description= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"AccessMode",width: 100, headerClass:"header-access_mode",field:"access_mode",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.note= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Note",width: 200,headerClass:"header-note",field:"note",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.note= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Create_by",width: 80,headerClass:"header-create_by",field:"create_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Create_dt",width: 80,headerClass:"header-create_dt",field:"create_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_dt= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_by",width: 100,headerClass:"header-update_by",field:"update_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_dt",width: 100,headerClass:"header-update_dt",field:"update_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_dt= params.newValue;return true;},suppressNavigable:true,},
			],
		}
		return options;
	}

	/*
	{headerName:"column_id",headerClass:"header-column_id",field:"column_id",hide:false,sortable:true,checkboxSelection:false,
	editable:true,
	refData: this.dd_selection,
	cellEditor: 'agSelectCellEditor',
	cellEditorParams: {  keys: Object.keys(this.dd_selection) , 
	values: Object.values(this.dd_selection).sort()},
	valueFormatter: function (params) {return sslc_this.dd_selection[params.value]; },
	cellEditorParams: {  keys: Object.keys(this.dd_access_mode) , values: Object.values(this.dd_access_mode).sort()},
	valueParser:function (params)  {return the_this.lookupKey(the_this.dd_access_mode, params.newValue);},

	valueParser:function (params)  {return sslc_this.lookupKey(csslc_this.dd_selection, params.newValue);},
	valueSetter:function (params) {if(params.data.column_id>0) {return false} else {params.data.column_id=params.newValue;return true};},
	suppressNavigable:true,},

					{headerName:"AccessMode",width: 80, headerClass:"header-access_mode",field:"access_mode",hide:false,resizable:true,sortable:false,checkboxSelection:false,
				editable:true,
				suppressNavigable:false,
				refData:the_this.dd_access_mode,
				cellEditor: 'agSelectCellEditor',
				valueFormatter: function (params) {return the_this.dd_access_mode[params.value]; },
				valueSetter:function (params) {{params.data.access_mode=params.newValue;return true};},
				suppressNavigable:true,},

	*/


	render() {
		return  (
			<div style={{position:"absolute",top:0,width:530,height:420,backgroundColor:"#FFFFFF"}}>
				<div style={{position:"absolute",left:20,top:0,width:510,height:25,backgroundColor:"#FFFFFF",textAlign:"left"}}>
					Role GUI
				</div>
				<div className="ag-theme-balham" 
                    style={{position:"absolute",top:25,left:0,width:530,height:400,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
						rowClassRules={rowClassRules}
	 				    rowSelection={"multiple"} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
				<div id="pid_0630_cell_001_002"
                    style={{position:"absolute",top:0,left:100,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onAddRow}>Add
					</button>
				</div>
				<div id="pid_0630_cell_001_004"
                    style={{position:"absolute",top:0,left:300,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onDeleteRow}>
						Remove
					</button>
				</div>
			</div>
		)
	}
}

//[{"login_user_id": 1, "access_failed_count": 0, "display_name": "ZoftTest01@gmail.com", "email": "ZoftTest01@gmail.com", "PasswordHash": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "PhoneNumber": null, "UserName": "ZoftTest01@gmail.com", "last_name": null, "first_name": null, "address": null, "city": null, "state_province": null, "country": null, "TextMsg": null, "phone": null, "title": null, "profession": null, "profession_id": null, "pin": 0, "active_ind": null, "mid_name": null, "zip_code": null, "sec_verify_method": null, "reset_password_ind": null, "last_access": null, "note": null, "create_by": 0, "create_dt": "2022-05-18 01:43:40", "update_by": 0, "update_dt": "2022-05-18 01:43:40"},{"login_user_id": 2, "access_failed_count": 0, "display_name": "SuperUser@quintarabio.com", "email": "SuperUser@quintarabio.com", "PasswordHash": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "PhoneNumber": null, "UserName": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "last_name": null, "first_name": null, "address": null, "city": null, "state_province": null, "country": null, "TextMsg": null, "phone": null, "title": null, "profession": null, "profession_id": null, "pin": 0, "active_ind": null, "mid_name": null, "zip_code": null, "sec_verify_method": null, "reset_password_ind": null, "last_access": null, "note": null, "create_by": 0, "create_dt": "2022-05-18 01:45:40", "update_by": 0, "update_dt": "2022-05-18 01:45:40"}]