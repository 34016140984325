import React, {Component, useState, useEffect, useContext} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './v2/css/bootstrap.min.css';
import './v2/css/qbio_style.css';
import {ResponseWindowOk, ResponseWindowYesNo} from './Modals';
import GetInternalPagePath from './GetInternalPagePath';
import {useNavigate} from "react-router-dom";
import "./styles.css";
import logotransparent from "./v2/img/logotransparent.png"

const Registration = ()=> {
    const [modalTitle, setModalTitle] = useState("Warning Message");
    const [showModalOk, SetShowModalOk] = useState(false);

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [first_name, setFirst_name] = useState('');
    const [last_name, setLast_name] = useState('');
    const [counter, SetCounter] = useState(0);
    useEffect(() => {
    },[]);

    /*
    useEffect(() => {
        if(counter ===1&&showModalOk===false)
            navigate('/sign-in');
    },[showModalOk]);
    */

    var timer_get_menu_data;
    const onClicked = ()=> {
        const api_url = process.env.NODE_ENV==='development'?process.env.REACT_APP_DEV_PROXY_URL:process.env.REACT_APP_QA_PROXY_URL;
        const url = api_url + '/api2/React2/Usp_react_signup';
        let the_pin = 0;
        console.log("url",url);
        if(password!=password2) {
            setModalTitle("Passwords are not matched, please re-enter!");
            SetShowModalOk(true);

        } else {
            fetch(url, {
                headers: {'Content-Type': 'application/json',},
                method: 'POST',
                body: JSON.stringify({
                    'email': email,
                    'first_name': first_name,
                    'last_name': last_name,
                    'password': password,
                })
            })
            .then(response => response.json())
            .then(data => {
                if(data.success) {
 //                   alert('success')
                    setModalTitle("You have successfully signed up to Quintarabio, please check your email for login pin, then proceed to sign-in");
                    SetShowModalOk(true);
                    SetCounter(1);
            } else {
                    console.log("signup data",data)
                    setModalTitle("Fail To Sign Up - " + data.error.message);
                    SetShowModalOk(true);
            }
                console.log('data', data);
            })
            .catch(err => {
                alert('fetch err:' + err); 
            });
        }
    }
  return (
      <>
        <div className="outer">
            <div className="inner">
                <form>
                    <img class="pb-5 img-fluid" src={logotransparent} alt="Quintara Biosciences" />
                    <h3>Register</h3>
                    <div className="form-group">
                        <label>First name</label>
                        <input type="first_name" className="form-control" placeholder="Enter First Name" autoComplete="username"
                            value = {first_name}
                            onChange={event => {setFirst_name(event.target.value)}}
                        />
                    </div>
                    <div className="form-group">
                        <label>Last name</label>
                        <input type="text" className="form-control" placeholder="Enter Last name" 
                            value = {last_name}
                            onChange={event => {setLast_name(event.target.value)}}
                        />
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" className="form-control" placeholder="Enter email" 
                            value = {email}
                            onChange={event => {setEmail(event.target.value)}}
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" placeholder="Enter password" 
                            value = {password}
                            onChange={event => {setPassword(event.target.value)}}
                        />
                    </div>
                    <div className="form-group">
                        <label>Re-enter Password</label>
                        <input type="password" className="form-control" placeholder="Re-enter password" 
                            value = {password2}
                            onChange={event => {setPassword2(event.target.value)}}
                        />
                    </div>
                    <button type="submit" className="btn btn-secondary btn-lg btn-block"
                            onClick={()=>onClicked()}
                    >Register</button>
                    <p className="forgot-password text-right">
                        Already registered <a href="/sign-in#">Login Here</a>
                    </p>
                    {showModalOk ? <ResponseWindowOk title={modalTitle} setShowModal={SetShowModalOk} /> : null}
                </form>
            </div>
        </div>
    </>
    )
}
export default Registration;
