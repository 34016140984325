//Design Tool--
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import DropBoxGrid from './dropboxgrid';

export default class DropboxDataPage extends React.Component {  
	state = {
		dropbox_id:0,
		campus_id:0,
		route_id:0,
	}
	componentDidMount() {
	}
//-----Render Script-----
render(){
    return  (
        <>
        <div style={{width:1300,height:700,backgroundColor:"#FFFFFF"}}>
			<div  id = "01010101DropBoxGrid" 
                style={{left:0,top:0, width:650,height:500,backgroundColor:"#FFFFFF"}}>
            	<DropBoxGrid 
                    id = "01010101DropBoxGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"				
				/>
            </div>			
        </div>
        </>
		);
	}
}
