//BEGIN_IMPORT_SECTIONs
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import getToken, {setToken} from '../../GetSetToken.js';
import getApiUrl, {getPassthroughUrl} from '../../GetApiUrl';
import AccessUserGuiGrid from './accessuserguigrid';
import DataRetrieval, {retrieveApiData,retrieveData2} from '../../data_retrieval';

var the_this
export default class AccessUserRoleGrid extends React.Component {
	state = {
		loaded:false,
		gridOptions:{},
		selected_role_id:0,
		user_id:0,
		data:[]
//		data:[{user_id:this.props.user_id,role_id:0,access_mode:"",note:"",create_by:0,create_dt:"",update_by:0,update_dt:"",}]
	}
	gridApi: GridApi
		constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, user_id:this.props.user_id, Loaded: true })
	}
	componentDidUpdate(prevProps) {
		if(this.props.user_id!==prevProps.user_id&&this.props.user_id>0) {
			this.retrieveData();
		}
	}

	onGridReady = params => {
    	this.gridApi = params.api;
 	 };

	retrieveData() {
		console.log('AccessUserRoleGrid retrieveData 0');
		let sql_id = 6;
		let app_id = 1;
		let input_parm = [{"request_row_count":1},{"offset_row":0},{"request_rows":999},{"user_id":this.props.user_id}];
		console.log('AccessUserRoleGrid retrieveData input_parm',input_parm);
		let tmp_data = retrieveApiData(1,10200,sql_id,"usp_react_sql_retrieve2", JSON.stringify(input_parm));
        let where_clause = input_parm.slice(3,input_parm.length);

		Promise.resolve(tmp_data).then((value)=>{
			console.log('AccessUserRoleGrid retrieveData value',value);
			let total_row_count = parseInt(value);
			retrieveData2(1,10200,sql_id,0,total_row_count,where_clause)
			.then((retrieved_data) => {
				console.log('AccessUserRoleGrid retrieveData retrieved_data',retrieved_data);
				let statusCopy = Object.assign({}, this.state)
				statusCopy.data = retrieved_data;
				this.setState(statusCopy);
				this.gridApi.setRowData(this.state.data)
//				this.props.retrieved();
			})
		});
	}
	onSelectionChanged = (event)=>{
		const selectedRows =  event.api.getSelectedRows();
		const selectedNodes =  event.api.getSelectedNodes();
		if(selectedNodes.length>1)
			the_this.props.getUserRoleId(selectedNodes[0].data.user_id,selectedNodes[0].data.role_id,selectedNodes[0].data.app_id)
/*
		console.log('AccessUserRoleGrid selectedNodes:', selectedNodes);
		console.log('AccessUserRoleGrid event:', event);
		this.gridApi.forEachNode(function(node, i){
			console.log('AccessUserRoleGrid-onSelectionChanged node', node);
			if(node.selected===true) {// selected the data
				console.log('AccessUserRoleGrid-onSelectionChanged selected===true node', node);
				//the_this.props.getRoleId(node.data.role_id)
			}
		})		
		*/
	}
	onCellValueChanged = ({ data }) => {
  		console.log("AccessUserRoleGrid onCellClicked data", data);
	};
	onPasteStart(params) {
	  console.log('AccessUserRoleGrid onPasteStart:', params);
	}
	onPasteEnd(params) {
	  console.log('AccessUserRoleGrid Callback onPasteEnd:', params);
	}

	onCopySelectedRowsToClipboard(params) {
	  console.log('send to clipboard called with data:');
	  console.log(params.data);
	}
	onCellClicked(event){
//		console.log('AccessRoleGrid event', event.columnApi.columnController.columnDefs[0]);
		console.log("AccessUserRoleGrid onCellClicked event", event);

	}
	onCellDoubleClicked(obj){
		console.log('AccessUserRoleGrid onCellDoubleClicked(obj)', obj);
	}	
	onCellValueChanged(event) {
		console.log('AccessUserRoleGrid onCellValueChanged(event)', event);
	}
	cellClicked(params) {
	console.log('AccessUserRoleGrid cellClicked(params)', params);
	  params.node.setSelected(true)
	}
	onCellValueChanged = (obj) => {
		console.log('AccessUserRoleGrid onCellValueChanged obj', obj);		
		let field_name = obj.column.colDef.field 
		let the_role_id = obj.data.role_id
		let the_user_id = obj.data.user_id
		let the_data = obj.value
		let token = getToken();
		if (typeof the_data==='string')
			the_data = '"' + the_data + '"'
		//let the_input_data = '{"' + field_name + '":' + the_data + ',"id":' + the_id.toString() + '}'
		let the_input_data = '{"' + field_name + '":' + the_data + ',"user_id":' + the_user_id.toString() + ',"role_id":' + the_role_id.toString() + '}'
		let the_input = '{"usp_name":"usp_react_sql_onchange_update","sql_id":6,"input_data"' + ':' + the_input_data + '}'
		console.log('AccessUserRoleGrid-onCellValueChanged the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {
			method: 'POST',headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
         })
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			console.log('AccessUserRoleGrid-onCellValueChanged 2 no error');
		})	
		.catch((error) => {
			console.error(error-'Error in update!');
			//this.insertOrder(1);
		});
	}
	onAddRow = () => {
		let first_row = this.state.data === null ? 1 : 0 
		let role_id = first_row > 0 ? 1 : this.state.data[this.state.data.length - 1].role_id + 1 
		let user_id = this.props.user_id > 0 ? this.props.user_id : 1
		let newData = [{user_id:user_id,role_id:role_id,access_mode:"F",note:"",create_by:"",create_dt:"",update_by:"",update_dt:"",}]
		this.selected_role_id = role_id
		console.log('AccessRoleGuiGrid-onAddRow user_id', this.props.user_id);
		console.log('AccessRoleGuiGrid-onAddRow role_id', role_id);		
		//let the_input_data = '{"role_id":"' + role_id.toString() + '",gui_id":"' + gui_id.toString() + '"}'
		let the_input_data = '{"user_id":' + user_id + ',"role_id":' + role_id + '}'

		let the_input = '{"usp_name":"usp_react_sql_insert","sql_id":6,"input_data"' + ':' + the_input_data + '}'
		let token = getToken();
		console.log('AccessUserRoleGrid-onAddRow token', token);
		console.log('AccessUserRoleGrid-onAddRow the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {method: 'POST', headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})})
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			if(responseJson.output==='Insert OK')
				this.retrieveData()
		})	
		.catch((error) => {
			console.error(error-'Error in AddRow!');
			//this.insertOrder(1);
		});
	}
	onDeleteRow = () => {
		console.log("this.gridApi.rowModel",this.gridApi.rowModel)
		let selectedRow = []
		let max_sample_id = 0
		let token = getToken();
		this.gridApi.forEachNode(function(node, i){
//			console.log('AccessRoleGrid-onDeleteRow node', node);
			if(node.selected===true) {// delete the data
				let role_id = node.data.role_id
				let user_id = node.data.user_id
				//let the_input_data = '{"id":' + role_id.toString() + '}'
				let the_input_data = '{"user_id":' + user_id.toString() + ',"role_id":' + role_id.toString() + '}'
				let the_input = '{"usp_name":"usp_react_sql_delete","sql_id":6,"input_data"' + ':' + the_input_data + '}'
//				console.log('AccessRoleGrid-DeleteRow the_input', the_input);
				const api_url = getPassthroughUrl();
				return fetch(api_url, {method: 'POST', headers: { 'Content-Type': 'application/json', },
					body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
				})
			 	.then((response) => response.json())
			 	.then((responseJson) => {
					console.log('AccessUserRoleGrid-responseJson', responseJson);
					console.log('AccessUserRoleGrid-responseJson.success', responseJson.success);
					if(responseJson.success===true) {
					let statusCopy = Object.assign({}, the_this.state)
					statusCopy.data.splice(i, 1);
					the_this.gridApi.setRowData(statusCopy.data)
					}
				})	
				.catch((error) => {
					console.log('SangerSeqOrderDetail2-error', error);
				});
			}
		})
	}
	createGridOptions() {
		const options: GridOptions = {
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true
			},
			columnDefs: [
				{headerName:"UserID",width: 80, headerClass:"header-id",field:"user_id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.user_id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"RoleID",width: 80, headerClass:"header-name",field:"role_id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.role_id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"AccessMode",width: 100,headerClass:"header-access_mode",field:"access_mode",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.access_mode= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Note",width: 200,headerClass:"header-note",field:"note",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.note= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Create_by",width: 80,headerClass:"header-create_by",field:"create_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Create_dt",width: 80,headerClass:"header-create_dt",field:"create_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_dt= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_by",width: 100,headerClass:"header-update_by",field:"update_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_dt",width: 100,headerClass:"header-update_dt",field:"update_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_dt= params.newValue;return true;},suppressNavigable:true,},
			],
		}
		return options;
	}
	render() {
		/*
		if(this.state.user_id!==this.props.user_id) {
			this.retrieveData()
		}
		*/
		return  (
			<div style={{position:"absolute",top:0,width:650,height:200,backgroundColor:"#FFFFFF"}}>
				<div style={{position:"absolute",left:20,top:0,width:580,height:25,backgroundColor:"#FFFFFF",textAlign:"left"}}>
					User Role
				</div>
				<div className="ag-theme-balham" 
                    style={{position:"absolute",top:25,left:0,width:530,height:175,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={"single"} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
				<div id="pid_0630_cell_001_002"
                    style={{position:"absolute",top:0,left:100,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onAddRow}>Add
					</button>
				</div>
				<div id="pid_0630_cell_001_003"
                    style={{position:"absolute",top:0,left:200,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onDeleteRow}>
						Delete
					</button>
				</div>
			</div>
		)
	}
}

//[{"login_user_id": 1, "access_failed_count": 0, "display_name": "ZoftTest01@gmail.com", "email": "ZoftTest01@gmail.com", "PasswordHash": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "PhoneNumber": null, "UserName": "ZoftTest01@gmail.com", "last_name": null, "first_name": null, "address": null, "city": null, "state_province": null, "country": null, "TextMsg": null, "phone": null, "title": null, "profession": null, "profession_id": null, "pin": 0, "active_ind": null, "mid_name": null, "zip_code": null, "sec_verify_method": null, "reset_password_ind": null, "last_access": null, "note": null, "create_by": 0, "create_dt": "2022-05-18 01:43:40", "update_by": 0, "update_dt": "2022-05-18 01:43:40"},{"login_user_id": 2, "access_failed_count": 0, "display_name": "SuperUser@quintarabio.com", "email": "SuperUser@quintarabio.com", "PasswordHash": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "PhoneNumber": null, "UserName": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "last_name": null, "first_name": null, "address": null, "city": null, "state_province": null, "country": null, "TextMsg": null, "phone": null, "title": null, "profession": null, "profession_id": null, "pin": 0, "active_ind": null, "mid_name": null, "zip_code": null, "sec_verify_method": null, "reset_password_ind": null, "last_access": null, "note": null, "create_by": 0, "create_dt": "2022-05-18 01:45:40", "update_by": 0, "update_dt": "2022-05-18 01:45:40"}]