//BEGIN_IMPORT_SECTIONs
import React, {Component} from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import getToken, {setToken} from '../../GetSetToken.js';
import getApiUrl, {getPassthroughUrl} from '../../GetApiUrl';
import DataRetrieval, {retrieveApiData, zoftApiForUsp,retrieveData2} from '../../data_retrieval';
import {ResponseWindowOk2} from '../../Modals'

const rowClassRules = {
	'row-fail': function(params) { return params.data.user_id === 0; },
	'row-pass': function(params) { return params.data.user_id >0; },
};
var the_this
export default class AccessUserGuiFieldGrid extends React.Component {
	state = {
		loaded:false,
		gridOptions:{},
		showModalOk:false,
		user_id:0,
		app_id:0,
		//data:[{"user_id": 3, "app_id": 1, "gui_id": 30100, "access_mode": "F", "note": null, "Create_by": 0, "Create_dt": "2022-06-02 15:03:57", "Update_by": 0, "Update_dt": "2022-06-02 15:03:57"},]
		data:[]
//		data:[{checked:0,user_id:0,app_id:0,gui_id:0,field_id:0,description:"",access_mode:"",note:"",create_by:"",create_dt:"",update_by:"",update_dt:"",}]
	}

	gridApi: GridApi
		constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options,user_id:this.props.user_id,app_id:this.props.app_id, dataLoaded: true })
	}
	onGridReady = params => {
    	this.gridApi = params.api;
 	 };
	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if(this.props.user_id!==prevProps.user_id||this.props.app_id!==prevProps.app_id||this.props.gui_id!==prevProps.gui_id) {
			if(this.props.user_id>0&&this.props.app_id>0&&this.props.gui_id>0)
				this.retrieveData();
		}
	}

	retrieveData() {
		console.log('AccessUserGuiFieldGrid retrieveData 0');
		let input_parm = [
			{"request_row_count":1},
			{"offset_row":0},
			{"request_rows":999},
			{"user_id":this.props.user_id},
			{"app_id":this.props.app_id},
			{"gui_id":this.props.gui_id},
		]
		let sql_id = 59;
		let app_id = 1;

		console.log('AccessUserGuiFieldGrid retrieveData input_parm',input_parm);
		let tmp_data = retrieveApiData(1,10200,sql_id,"usp_react_sql_retrieve2", JSON.stringify(input_parm));
        let where_clause = input_parm.slice(3,input_parm.length);
		Promise.resolve(tmp_data).then((value)=>{
			console.log('AccessAppGuiGrid retrieveData value',value);
			let total_row_count = parseInt(value);
			retrieveData2(1,10200,sql_id,0,total_row_count,where_clause)
			.then((retrieved_data) => {
				console.log('AccessUserGuiFieldGrid retrieveData retrieved_data',retrieved_data);
				let statusCopy = Object.assign({}, this.state)
				statusCopy.data = retrieved_data;
				this.setState(statusCopy);
				this.gridApi.setRowData(retrieved_data)
			})
		});
	}
	onSelectionChanged = (event)=>{
		const selectedRows =  event.api.getSelectedRows();
		const selectedNodes =  event.api.getSelectedNodes();
		console.log('AccessUserGuiGrid selectedNodes:', selectedNodes);
		console.log('AccessUserGuiGrid event:', event);
		/*
		this.gridApi.forEachNode(function(node, i){
			console.log('AccessUserGuiGrid-onSelectionChanged node', node);
			if(node.selected===true) {// selected the data
				console.log('AccessUserGuiGrid-onSelectionChanged selected===true node', node);
				the_this.props.getGuiId(node.data.id)
			}
		})
		*/
	}
	onCellValueChanged = ({ data }) => {
  		console.log("AccessUserGuiGrid onCellClicked data", data);
	};
	onPasteStart(params) {
	  console.log('AccessUserGuiGrid onPasteStart:', params);
	}
	onPasteEnd(params) {
	  console.log('AccessUserGuiGrid Callback onPasteEnd:', params);
	}

	onCopySelectedRowsToClipboard(params) {
	  console.log('send to clipboard called with data:');
	  console.log(params.data);
	}
	onCellClicked(event){
//		console.log('AccessRoleGrid event', event.columnApi.columnController.columnDefs[0]);
		console.log("AccessUserGuiGrid onCellClicked event", event);

	}
	onCellDoubleClicked(obj){
		console.log('AccessUserGuiGrid onCellDoubleClicked(obj)', obj);
	}	
	onCellValueChanged(event) {
		console.log('AccessUserGuiGrid onCellValueChanged(event)', event);
	}
	cellClicked(params) {
	console.log('AccessUserGuiGrid cellClicked(params)', params);
	  params.node.setSelected(true)
	}
	onCellValueChanged = (obj) => {
		console.log('AccessUserGuiGrid onCellValueChanged obj', obj);		
		let field_name = obj.column.colDef.field 
		let the_gui_id = obj.data.gui_id
		let the_app_id = obj.data.app_id
		let the_user_id = obj.data.user_id
		let the_data = obj.value
		let token = getToken();
		if (typeof the_data==='string')
			the_data = '"' + the_data + '"'
		let the_input_data = '{"' + field_name + '":' + the_data + ',"user_id":' + the_user_id.toString() + ',"app_id":' + the_app_id.toString() + ',"gui_id":' + the_gui_id.toString() + '}'
		let the_input = '{"usp_name":"usp_react_sql_onchange_update","sql_id":7,"input_data"' + ':' + the_input_data + '}'
		console.log('AccessUserGuiGrid-onCellValueChanged the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {
			method: 'POST',headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
         })
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			console.log('AccessUserGuiGrid-onCellValueChanged 2 no error');
		})	
		.catch((error) => {
			console.error(error-'Error in update!');
			//this.insertOrder(1);
		});
	}
	/*onCellValueChanged
	 user_id
  ,app_id
  ,gui_id
  ,inclusive_ind
  ,access_mode
  ,note
  */
	onAddRow = () => {
		console.log('AccessUserGuiFieldGrid-onAddRow the_this.gridApi', the_this.gridApi);
		let there_is_update = false;
		let the_i = 0
		let the_count = the_this.gridApi.getSelectedRows()
		the_this.gridApi.forEachNode(function(node, i){
			if(node.selected===true&&node.data.user_id>0) {// selected the 
				let the_title = "Only rows with user_id = 0 can be added!"
				the_this.setState({showModalOk: true, modalTitle:the_title})
				return;
			}
		})
		the_this.gridApi.forEachNode(function(node, i){
			if(node.selected===true) {// selected the data
				console.log('AccessUserGuiFieldGrid-onAddRow selected node.data', node.data);
				the_i++;
				if(node.data.user_id===0) {
					let the_input = [{"user_id":the_this.props.user_id},{"app_id":node.data.app_id},{"gui_id":node.data.gui_id},{"field_id":node.data.field_id},{"access_mode":"W"},{"note":"Online Created"}]
					let api_return_data = zoftApiForUsp(1,10200,59,0,0,"usp_react_sql_insert2", JSON.stringify(the_input));
					Promise.resolve(api_return_data).then((value)=>{
						console.log('AccessUserGuiFieldGrid-onSaveRow value', value);
						if(value.return_status===0) { //normal return
							there_is_update = true;
							console.log('AccessUserGuiFieldGrid-onSaveRow value.return_data', value.return_data);
							if(value.return_data.success) {
								console.log('AccessUserGuiFieldGrid-onSaveRow value.return_data.output.data',value.return_data.output);
							} else {
								let error = "Insert Error:" + value.return_data.message + "!"
								the_this.setState({showModalOk: true, modalTitle:error})
								console.log('AccessUserGuiFieldGrid-onSaveRow error',error);
//								this.setState({ ...this.state, showModalOk: true, modalTitle:the_title})
							};
						} else {
							let error = "Unspecified error, please report it to tech support!"
							the_this.setState({showModalOk: true, modalTitle:error})
							console.log('AccessUserGuiFieldGrid-onAddRow value.return_data', value.return_data);
						}
						console.log('AccessUserGuiFieldGrid-onAddRow the_count.length,i,there_is_update', the_count.length,i,there_is_update);
						if(the_i === the_count.length)
							if(there_is_update) {
								console.log('AccessUserGuiFieldGrid-onAddRow there_is_update', there_is_update);
								the_this.retrieveData();
							}
					}) 
				}
				console.log('AccessUserGuiGrid-onAddRow selected===true node', node);
			}
		})


/*
		the_this.gridApi.forEachNode(function(node, i){
			if(node.selected===true) {// selected the data
				console.log('AccessUserGuiFieldGrid-onAddRow selected node.data', node.data);
				if(node.data.user_id===0) {
					there_is_update = true;     
					let the_input = [{"user_id":the_this.props.user_id},{"app_id":node.data.app_id},{"gui_id":node.data.gui_id},{"field_id":node.data.field_id},{"access_mode":"W"},{"note":"Online Created"}]
					let tmp_data = retrieveApiData(1,10200,59,"usp_react_sql_insert2", JSON.stringify(the_input));
					Promise.resolve(tmp_data).then((value)=>{
						console.log('AccessUserGuiFieldGrid-onAddRow value', value);
					})
				}
				console.log('AccessUserGuiFieldGrid-onAddRow selected===true node', node);
			}
		})
		if(there_is_update) {
			this.retrieveData();
		}
*/
	}
	onDeleteRow = () => {
		let there_is_update = false;
		let the_count = the_this.gridApi.getSelectedRows()
		let the_i = 0
		the_this.gridApi.forEachNode(function(node, i){
			if(node.selected===true&&node.data.user_id===0) {// selected the 
				let the_title = "Only rows with user_id > 0 can be deleted!"
				the_this.setState({showModalOk: true, modalTitle:the_title})
				return;
			}
		})
		the_this.gridApi.forEachNode(function(node, i){
			if(node.selected===true) {// selected the data
				console.log('AccessUserGuiFieldGrid-onDeleteRow selected node.data', node.data);
				the_i++;
				if(node.data.user_id>0) {
					let the_input = [{"user_id":node.data.user_id},{"app_id":node.data.app_id},{"gui_id":node.data.gui_id},{"field_id":node.data.field_id}]
					let api_return_data = zoftApiForUsp(1,10200,59,0,0,"usp_react_sql_delete2", JSON.stringify(the_input));
					Promise.resolve(api_return_data).then((value)=>{
						console.log('AccessUserGuiFieldGrid-onDeleteRow value', value);
						if(value.return_status===0) { //normal return
							there_is_update = true;
							console.log('AccessUserGuiFieldGrid-onDeleteRow value.return_data', value.return_data);
							if(value.return_data.success) {
								console.log('AccessUserGuiFieldGrid-onDeleteRow value.return_data.output.data',value.return_data.output);
							} else {
								let error = "Delete Error:" + value.return_data.message + "!"
								console.log('AccessUserGuiFieldGrid-onDeleteRow error',error);
//								this.setState({ ...this.state, showModalOk: true, modalTitle:the_title})
							};
						} else {
							let the_title = "Unspecified error, please report it to tech support!"
//							this.setState({ ...this.state, showModalOk: true, modalTitle:the_title})
							console.log('AccessUserGuiFieldGrid-onAddRow value.return_data', value.return_data);
						}
						console.log('AccessUserGuiFieldGrid-onDeleteRow the_count.length,i,there_is_update', the_count.length,i,there_is_update);
						if(the_i === the_count.length)
							if(there_is_update) {
								console.log('AccessUserGuiFieldGrid-onDeleteRow there_is_update', there_is_update);
								the_this.retrieveData();
							}
					}) 
				}
				console.log('AccessUserGuiFieldGrid-onDeleteRow selected===true node', node);
			}
		})



/*
		let there_is_update = false;
		console.log('AccessUserGuiFieldGrid-onDeleteRow 1');
		the_this.gridApi.forEachNode(function(node, i){
			if(node.selected===true&&node.data.user_id>0) {// selected the data
				console.log('AccessUserGuiFieldGrid-onDeleteRow selected node.data.checked', node.data);
				if(node.data.user_id>0) {
					there_is_update = true;
					let the_input = [{"user_id":node.data.user_id},{"app_id":the_this.props.app_id},{"gui_id":node.data.gui_id},{"field_id":node.data.field_id}]
					console.log('AccessUserGuiFieldGrid-onDeleteRow selected the_input', the_input);
					let tmp_data = retrieveApiData(1,10200,38,"usp_react_sql_delete2", JSON.stringify(the_input));
					Promise.resolve(tmp_data).then((value)=>{
						console.log('AccessUserGuiFieldGrid-onDeleteRow value', value);
					})
				}
				console.log('AccessUserGuiFieldGrid-onDeleteRow selected===true node', node);
			}
		})
		
		if(there_is_update) {
			this.retrieveData();
		}
*/
		/*
		console.log("this.gridApi.rowModel",this.gridApi.rowModel)
		let selectedRow = []
		let max_sample_id = 0
		let token = getToken();
		this.gridApi.forEachNode(function(node, i){
//			console.log('AccessRoleGrid-onDeleteRow node', node);
			if(node.selected===true) {// delete the data
				let user_id = node.data.user_id
				let app_id = node.data.app_id
				let gui_id = node.data.gui_id				
				let the_input_data = '{"user_id":' + user_id.toString() + ',"app_id":' + app_id.toString() + ',"gui_id":' + gui_id.toString() + '}'
				let the_input = '{"usp_name":"usp_react_sql_delete","sql_id":7,"input_data"' + ':' + the_input_data + '}'
//				console.log('AccessRoleGrid-DeleteRow the_input', the_input);
				const api_url = getPassthroughUrl();
				return fetch(api_url, {method: 'POST', headers: { 'Content-Type': 'application/json', },
					body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
				})
			 	.then((response) => response.json())
			 	.then((responseJson) => {
					console.log('AccessUserGuiGrid-responseJson', responseJson);
					console.log('AccessUserGuiGrid-responseJson.success', responseJson.success);
					if(responseJson.success===true) {
					let statusCopy = Object.assign({}, the_this.state)
					statusCopy.data.splice(i, 1);
					the_this.gridApi.setRowData(statusCopy.data)
					}
				})	
				.catch((error) => {
					console.log('SangerSeqOrderDetail2-error', error);
				});
			}
		})
		*/
	}

	createGridOptions() {
		const options: GridOptions = {
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true,
				rowMultiSelectWithClick: true,
       			headerCheckboxSelection: true,
				rowDeselection: true,
			},
			columnDefs: [
				{headerName:"",headerClass:"header-checked",field:"checked",hide:false,width:30,sortable:true,headerCheckboxSelection:true,checkboxSelection:true,editable:true,valueSetter:function (params) {params.data.checked= params.newValue;return true;},suppressNavigable:true,
				headerCheckboxSelection: true,
				headerCheckboxSelectionFilteredOnly: true,
				checkboxSelection: true,
				},
				{headerName:"UserID",width: 80, headerClass:"header-user_id",field:"user_id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.user_id= params.newValue;return true;},suppressNavigable:true,filter: 'NumberFilter',},
				{headerName:"AppID",width: 80, headerClass:"header-app_id",field:"app_id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.app_id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"GuiID",width: 80, headerClass:"header-gui_id",field:"gui_id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.gui_id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"FieldID",width: 80, headerClass:"header-field_id",field:"field_id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.field_id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Description",width: 180, headerClass:"header-description",field:"description",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.description= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"AccessMode",width: 120, headerClass:"header-access_mode",field:"access_mode",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.access_mode= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Note",width: 120,headerClass:"header-note",field:"note",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.note= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Create_by",width: 80,headerClass:"header-create_by",field:"create_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Create_dt",width: 80,headerClass:"header-create_dt",field:"create_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_dt= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_by",width: 100,headerClass:"header-update_by",field:"update_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_dt",width: 100,headerClass:"header-update_dt",field:"update_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_dt= params.newValue;return true;},suppressNavigable:true,},
			],
		}
		return options;
	}

	render() {
		return  (
			<div style={{position:"absolute",top:0,width:650,height:220,backgroundColor:"#FFFFFF"}}>
				<div style={{position:"absolute",left:20,top:0,width:530,height:25,backgroundColor:"#FFFFFF",textAlign:"left"}}>
					User GUI Field
				</div>
				<div id="pid_0630_cell_001_002"
                    style={{position:"absolute",top:0,left:200,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onAddRow}>Add
					</button>
				</div>
				<div id="pid_0630_cell_001_003"
                    style={{position:"absolute",top:0,left:300,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onDeleteRow}>
						Delete
					</button>
				</div>
				<div className="ag-theme-balham" 
                    style={{position:"absolute",top:25,left:0,width:530,height:200,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
						rowClassRules={rowClassRules}
	 				    rowSelection={"multiple"} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
				{this.state.showModalOk ? 
			 	<ResponseWindowOk2 
			 		title={this.state.modalTitle} 
					setShowModal={(showModel)=>this.setState({...this.state, showModalOk:showModel})}
				/> : 
				null}
			</div>
		)
	}
}

//[{"login_user_id": 1, "access_failed_count": 0, "display_name": "ZoftTest01@gmail.com", "email": "ZoftTest01@gmail.com", "PasswordHash": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "PhoneNumber": null, "UserName": "ZoftTest01@gmail.com", "last_name": null, "first_name": null, "address": null, "city": null, "state_province": null, "country": null, "TextMsg": null, "phone": null, "title": null, "profession": null, "profession_id": null, "pin": 0, "active_ind": null, "mid_name": null, "zip_code": null, "sec_verify_method": null, "reset_password_ind": null, "last_access": null, "note": null, "create_by": 0, "create_dt": "2022-05-18 01:43:40", "update_by": 0, "update_dt": "2022-05-18 01:43:40"},{"login_user_id": 2, "access_failed_count": 0, "display_name": "SuperUser@quintarabio.com", "email": "SuperUser@quintarabio.com", "PasswordHash": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "PhoneNumber": null, "UserName": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "last_name": null, "first_name": null, "address": null, "city": null, "state_province": null, "country": null, "TextMsg": null, "phone": null, "title": null, "profession": null, "profession_id": null, "pin": 0, "active_ind": null, "mid_name": null, "zip_code": null, "sec_verify_method": null, "reset_password_ind": null, "last_access": null, "note": null, "create_by": 0, "create_dt": "2022-05-18 01:45:40", "update_by": 0, "update_dt": "2022-05-18 01:45:40"}]