//Design Tool--
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
//import SangerSeqAccessCtrlUserGrid from 'api/layout/547.js'
import BussLookupTableGrid from './busslookuptablegrid';
import BussLookupColumnGrid from './busslookupcolumngrid';
import BussLookupDescriptionGrid from './busslookupdescriptiongrid';
import SysLookupGrid from './syslookupgrid';
import SysLookupNumberGrid from './syslookupnumbergrid';
import SysLookupStringGrid from './syslookupstringgrid';


export default class LookuptableDataPage extends React.Component {  
	state = {
		table_id:0,
		col_id:0,
        lkup_id:0,
        lkup_num:0,
		}
	componentDidMount() {
	}
    getTableId = (table_id) => 
	{
		console.log('LookuptableDataPage getTableId', table_id)
		this.setState({ ...this.state,table_id:table_id}) ;		
	}
    getLkupId = (lkup_id) => 
	{
		console.log('LookuptableDataPage getLkupId', lkup_id)
		this.setState({ ...this.state,lkup_id:lkup_id}) ;		
	}
    getColId = (col_id) => 
	{
		console.log('LookuptableDataPage getColId', col_id)
		this.setState({ ...this.state,col_id:col_id}) ;		
	}
    getLkupNum = (lkup_num) => 
	{
		console.log('LookuptableDataPage getLkupNum', lkup_num)
		this.setState({ ...this.state,lkup_num:lkup_num}) ;		
	}
//-----Render Script-----
    render(){
    return  (
        <>
        <div style={{width:1300,height:700,backgroundColor:"#FFFFFF"}}>
            <div  id = "01010101TableGrid" 
                style={{left:0,top:0, width:650,height:200,backgroundColor:"#FFFFFF"}}>
                <BussLookupTableGrid
                    id = "01010101TableGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getTableId = {(table_id) => { this.AppId(table_id)}}
                />
            </div>
            <div  id = "01010101ColumnGrid" 
                style={{position:"absolute",left:0, top: 200, width:650,height:200,backgroundColor:"#FFFFFF"}}>
                <BussLookupColumnGrid
                    id = "01010101ColumnGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getTableId = {(table_id) => { this.AppId(table_id)}}
                    getColId = {(col_id) => { this.AppId(col_id)}}                
                />
            </div>
            <div  id = "01010101DescriptionGrid" 
                style={{position:"absolute",left:0, top: 400, width:650,height:200,backgroundColor:"#FFFFFF"}}>
                <BussLookupDescriptionGrid
                    id = "01010101DescriptionGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    table_id = {this.state.table_id}
                    col_id = {this.state.col_id}
                    getTableId = {(table_id) => { this.AppId(table_id)}}
                    getColId = {(col_id) => { this.AppId(col_id)}} 
                />
            </div>
            <div  id = "01010101LookupGrid" 
                style={{position:"absolute",left:650, top: 0, width:650,height:200,backgroundColor:"#FFFFFF"}}>
                <SysLookupGrid
                    id = "01010101LookupGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getLkupId = {(lkup_id) => { this.AppId(lkup_id)}}
                />
            </div>
            <div  id = "01010101LookupNumberGrid" 
                style={{position:"absolute",left:650, top: 200, width:650,height:200,backgroundColor:"#FFFFFF"}}>
                <SysLookupNumberGrid
                    id = "01010101LookupNumberGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    lkup_id = {this.state.lookup_id}
                    getLkupId = {(lkup_id) => { this.getLkupId(lkup_id)}}
                    getLkupNum = {(lkup_num) => { this.getLkupNum(lkup_num)}}
                />
            </div>
            <div  id = "01010101LookupStringGrid" 
                style={{position:"absolute",left:650, top: 400, width:650,height:200,backgroundColor:"#FFFFFF"}}>
                <SysLookupStringGrid
                    id = "01010101LookupStringGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    lkup_id = {this.state.lookup_id}
                    getLkupId = {(lkup_id) => { this.getLkupId(lkup_id)}}
                    getLkupNum = {(lkup_num) => { this.getLkupNum(lkup_num)}}
                />
            </div>
        </div>
        </>
		);
	}
}

/*
            <div  id = "01010101LookupGrid" 
                style={{position:"absolute",left:650, top: 0, width:650,height:200,backgroundColor:"#FFFFFF"}}>
                <SysLookupGrid
                    id = "01010101LookupGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getLkupId = {(lkup_id) => { this.AppId(lkup_id)}}
                />
            </div>
            <div  id = "01010101LookupNumberGrid" 
                style={{position:"absolute",left:650, top: 200, width:650,height:200,backgroundColor:"#FFFFFF"}}>
                <SysLookupNumberGrid
                    id = "01010101LookupNumberGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getLkupId = {(lkup_id) => { this.getLkupId(lkup_id)}}
                    getLkupNum = {(lkup_num) => { this.getLkupNum(lkup_num)}}
                    />
            </div>
            <div  id = "01010101LookupStringGrid" 
                style={{position:"absolute",left:650, top: 400, width:650,height:200,backgroundColor:"#FFFFFF"}}>
                <SysLookupStringGrid
                    id = "01010101LookupStringGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getLkupId = {(lkup_id) => { this.getLkupId(lkup_id)}}
                    getLkupNum = {(lkup_num) => { this.getLkupNum(lkup_num)}}
                    />
            </div>
*/