//BEGIN_IMPORT_SECTION
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import DataRetrieval, {retrieveApiData,retrieveData2} from '../../data_retrieval';

var the_this_user_list
export default class AccessUserListGrid extends React.Component {
	state = {
		loaded:false,
		gridOptions:{},
		user_id:0,
		data:[]
//		data:[{"user_id": 1, "display_name": "", "email": ""},]
	}
	gridApi: GridApi
		constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this_user_list = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: true })
		this.retrieveData()
	}
	onGridReady = params => {
    	this.gridApi = params.api;
 	 };

	retrieveData() {
		this.setState({loaded:true});

		let input_parm = [
			{"request_row_count":1},
			{"offset_row":0},
			{"request_rows":999},
			{"user_id":this.props.user_id},
		]
		let sql_id = 8;
		let app_id = 1;
		console.log('AccessUserListGrid retrieveData input_parm',input_parm);
		let tmp_data = retrieveApiData(1,10200,sql_id,"usp_react_sql_retrieve2", JSON.stringify(input_parm));
        let where_clause = input_parm.slice(3,input_parm.length);
		Promise.resolve(tmp_data).then((value)=>{
			console.log('AccessUserListGrid retrieveData value',value);
			let total_row_count = parseInt(value);
			retrieveData2(1,10200,sql_id,0,total_row_count,where_clause)
			.then((retrieved_data) => {
				console.log('AccessUserListGrid retrieveData retrieved_data',retrieved_data);
				let statusCopy = Object.assign({}, this.state)
				statusCopy.data = retrieved_data;
				this.setState(statusCopy);
				this.gridApi.setRowData(retrieved_data)
			})
		});

	}
	onRefresh = ()=>{
		this.setState({loaded:false})
		console.log('onRefresh');
	}

	onSelectionChanged = (event)=>{
		const selectedNodes =  event.api.getSelectedNodes();
		console.log('AccessUserListGrid selectedNodes:', selectedNodes);
		console.log('AccessUserListGrid event:', event);
		if(selectedNodes.length>0)
			the_this_user_list.props.getUserId(selectedNodes[0].data.user_id)
			/*
		this.gridApi.forEachNode(function(node, i){
			console.log('AccessUserGrid-onSelectionChanged node', node);
			if(node.selected===true) {// selected the data
				console.log('AccessUserGrid-onSelectionChanged selected===true node', node);
				the_this_user_list.props.getUserId(node.data.user_id)	
			}
		})
		*/
	}
	createGridOptions() {
		const options: GridOptions = {
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true
			},
			columnDefs: [
				{headerName:"UserID",width:80,headerClass:"header-user_id",field:"user_id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.login_user_id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"DisplayName",width:200,headerClass:"header-display_name",field:"display_name",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.display_name= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Email",width:200,headerClass:"header-email",field:"email",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.email= params.newValue;return true;},suppressNavigable:true,},
			],
		}
		return options;
	}
	render() {
		/*
		if(this.state.loaded===false) {
			this.retrieveData()
		}
		*/
		return  (
			<div style={{position:"absolute",top:0,width:530,height:200,backgroundColor:"#FFFFFF"}}>
				<div style={{position:"absolute",left:20,top:0,width:80,height:25,backgroundColor:"#FFFFFF",textAlign:"left"}}>
					User List
				</div>
				<div className="ag-theme-balham" 
                    style={{position:"absolute",top:25,left:0,width:530,height:175,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={"single"} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
				<div id="pid_0630_cell_001_002"
                    style={{position:"absolute",top:0,left:100,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onRefresh}>Refresh
					</button>
				</div>
			</div>
		)
	}
}
