//BEGIN_IMPORT_SECTIONs
import React, {Component, useState, useEffect, useContext} from 'react';
import ReactDOM from 'react-dom';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import getToken, {setToken} from '../../GetSetToken.js';
import getApiUrl, {getPassthroughUrl} from '../../GetApiUrl';
import {retrieveData,retrieveRowCount} from '../../data_retrieval';

var the_this_app
export default class AccessAppGrid extends React.Component {
	state = {
		loaded:false,
		gridOptions:{},
		selected_role_id:0,
		data:[{"id": 0, "name": "Website", "description": "Website", "note": "public access", "version": "1", "version_num": 1, "dev_qa_prod": "d", "expire_dt": null, "create_by": 0, "create_dt": "2022-06-18 19:39:03", "update_by": 0, "update_dt": "2022-06-18 19:39:03"}]
	}
	gridApi: GridApi
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this_app = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: true })
		this.retrievingData()
	}
	onGridReady = params => {
    	this.gridApi = params.api;
 	 };

//export const retrieveRowCount = async(app_id,gui_id,sql_id,where_clause)=> {
	retrievingData() {
		this.setState({loaded:true});
		let sql_id = 5;
		let app_id = 1;
		let where_clause = ''
		let data_count = retrieveRowCount(app_id,10200,sql_id,where_clause);
		let total_row_count = 0;
		Promise.resolve(data_count).then((value)=>{
			console.log("AccessAppGrid-retrievingData value",value)
			total_row_count = parseInt(value);
			retrieveData(app_id,10200,sql_id,0,total_row_count,where_clause)
			.then((retrieved_data) => {
				console.log("AccessAppGrid-retrieve_data",retrieved_data)
				let statusCopy = Object.assign({}, this.state)
				statusCopy.data = retrieved_data;
				statusCopy.loaded = true;
				this.setState(statusCopy);
				console.log('AccessRoleGrid-this.state.data', this.state.data);
				this.gridApi.setRowData(retrieved_data)
			});
		})
	}

	onSelectionChanged = (event)=>{
		const selectedNodes =  event.api.getSelectedNodes();
		the_this_app.props.getAppId(selectedNodes[0].data.id)
	}

	onCellValueChanged = (obj) => {
 		  console.log('AccessAppGrid onCellValueChanged obj', obj);		
		  let field_name = obj.column.colDef.field 
		  let the_id = obj.data.id
		  let the_data = obj.value
		  let token = getToken();
		  if (typeof the_data==='string')
			  the_data = '"' + the_data + '"'
		  let the_input_data = '{"' + field_name + '":' + the_data + ',"id":' + the_id.toString() + '}'
		  let the_input = '{"usp_name":"usp_react_sql_onchange_update","sql_id":5,"input_data"' + ':' + the_input_data + '}'
		  console.log('AccessAppGrid-onCellValueChanged the_input', the_input);
		  const api_url = getPassthroughUrl();
		  fetch(api_url, {
			  method: 'POST',headers: { 'Content-Type': 'application/json', },
			  body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
		   })
		   .then((response) => response.json())
		   .then((responseJson) => {
			  console.log('AccessAppGrid-onCellValueChanged 2 no error');
		  })	
		  .catch((error) => {
			  console.error(error-'Error in update!');
			  //this.insertOrder(1);
		  });
	}

	onCellClicked(event){
//		console.log('AccessRoleGrid event', event.columnApi.columnController.columnDefs[0]);
		console.log("AccessAppGrid onCellClicked event", event);

	}
	onAddRow =() => {
		let new_row = this.state.data === null ? 1 : this.state.data[this.state.data.length -1].id + 1
		console.log('AccessAppGrid-onAddRow new_row', new_row  );	
		let newData = [{id:0,name:"",description:"",note:"",version:"",version_num:1,Dev_Qa_Prod:"",expire_dt:"",create_by:1,create_dt:"",update_by:1,update_dt:"",}]
		//let the_input_data = '{"name":"New App"}'
		let the_input_data = '{"id":' + new_row + ',"name":"New App","description":""}'
		let the_input = '{"usp_name":"usp_react_sql_insert","sql_id":5,"input_data"' + ':' + the_input_data + '}'
		let token = getToken();
		console.log('AccessAppGrid-onAddRow token', token);
		console.log('AccessAppGrid-onAddRow the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {method: 'POST', headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})})
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			if(responseJson.output==='Insert OK')
				this.retrieveData()
				window.selected_app_id = new_row
		})
		.catch((error) => {
			console.error(error-'Error in AddRow!');
			//this.insertOrder(1);
		});
	}
	onDeleteRow = () => {
		console.log("this.gridApi.rowModel",this.gridApi.rowModel)
		if (window.confirm("Confirm Delete?")){

				let selectedRow = []
				let max_sample_id = 0
				let token = getToken();
				this.gridApi.forEachNode(function(node, i){
					if(node.selected===true) {// delete the data
						let app_id = node.data.id
						let the_input_data = '{"id":' + app_id.toString() + '}'
						let the_input = '{"usp_name":"usp_react_sql_delete","sql_id":5,"input_data"' + ':' + the_input_data + '}'
						const api_url = getPassthroughUrl();
						return fetch(api_url, {method: 'POST', headers: { 'Content-Type': 'application/json', },
							body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
						})
						.then((response) => response.json())
						.then((responseJson) => {
							console.log('AccessAppGrid-responseJson', responseJson);
							console.log('AccessAppGrid-responseJson.success', responseJson.success);
							if(responseJson.success===true) {
							let statusCopy = Object.assign({}, the_this_app.state)
							statusCopy.data.splice(i, 1);
							the_this_app.gridApi.setRowData(statusCopy.data)

							//--------Cascading Deletes of children Roles and Role-Screens
							the_input_data = '{"app_id":' + app_id.toString() +'}'
							the_input = '{"usp_name":"usp_react_sql_subsql","sql_id":5,"input_data"' + ':' + the_input_data + '}'
							const api_url = getPassthroughUrl();
							return fetch(api_url, {
								method: 'POST',
								headers: { 'Content-Type': 'application/json', },
								body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
							})
							.then((response) => response.json())
							.then((responseJson) => {
								console.log('AccessRoleGrid-responseJson using usp_react_sql_subsql', responseJson);
								console.log('AccessRoleGrid-responseJson.success using usp_react_sql_subsql', responseJson.success);
								the_this_app.onAppSelection_DW1(app_id);
								the_this_app.onAppSelection_DW2(0);	
								the_this_app.onAppSelection_DW3(window.user_id,app_id)
							})	
							.catch((error) => {
								console.log('SangerSeqOrderDetail2-error', error);
							});
						//--------End of Cascading Delete Logic

						}
						})	
						.catch((error) => {
							console.log('SangerSeqOrderDetail2-error', error);
						});
					}
				})
		}	//--If Confirmed Deletion	
	}
	onRefresh = ()=>{
		this.retrievingData() 
//		this.setState({ ...this.state, dataLoaded: false })
		console.log('onRefresh');
//		the_this.onAppRetrieve_DW1(true);
	}

	createGridOptions() {
		const options: GridOptions = {
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true
			},
			columnDefs: [
				{headerName:"ID",width:40,headerClass:"header-id",field:"id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Name",width:120,headerClass:"header-name",field:"name",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Description",width:120,headerClass:"header-description",field:"description",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.description= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Note",width:80,headerClass:"header-note",field:"note",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.note= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Version",width:70,headerClass:"header-version",field:"version",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.version= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Version_num",width:100,headerClass:"header-version_num",field:"version_num",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.version_num= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Dev_Qa_Prod",width:110,headerClass:"header-dev_qa_prod",field:"dev_qa_prod",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.dev_qa_prod= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Expire_dt",width:80,headerClass:"header-expire_dt",field:"expire_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.expire_dt= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Create_by",width:80,headerClass:"header-create_by",field:"create_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Create_dt",width:80,headerClass:"header-create_dt",field:"create_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_dt= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_by",width:100,headerClass:"header-update_by",field:"update_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_dt",width:100,headerClass:"header-update_dt",field:"update_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_dt= params.newValue;return true;},suppressNavigable:true,},
			],
		}
		return options;
	}
	render() {

		/*
		if(this.state.loaded===false) {
			this.retrieveData()
		}
		*/
		
		return  (
			<div style={{position:"absolute",left:0,top:0,width:510,height:175,backgroundColor:"#FFFFFF"}}>
				<div style={{position:"absolute",left:20,top:0,width:510,height:25,backgroundColor:"#FFFFFF",textAlign:"left"}}>
					App
				</div>
				<div className="ag-theme-balham" 
                    style={{position:"absolute",top:25,left:0,width:510,height:150,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={"single"} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
				<div 
                    style={{position:"absolute",top:0,left:100,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"left",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onRefresh}>Refresh
					</button>
				</div>
				<div 
                    style={{position:"absolute",top:0,left:200,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"left",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onAddRow}>Add
					</button>
				</div>
				<div 
                    style={{position:"absolute",top:0,left:300,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"left",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onDeleteRow}>
						Delete
					</button>
				</div>				
			</div>
		)
	}
}

//[{"login_user_id": 1, "access_failed_count": 0, "display_name": "ZoftTest01@gmail.com", "email": "ZoftTest01@gmail.com", "PasswordHash": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "PhoneNumber": null, "UserName": "ZoftTest01@gmail.com", "last_name": null, "first_name": null, "address": null, "city": null, "state_province": null, "country": null, "TextMsg": null, "phone": null, "title": null, "profession": null, "profession_id": null, "pin": 0, "active_ind": null, "mid_name": null, "zip_code": null, "sec_verify_method": null, "reset_password_ind": null, "last_access": null, "note": null, "create_by": 0, "create_dt": "2022-05-18 01:43:40", "update_by": 0, "update_dt": "2022-05-18 01:43:40"},{"login_user_id": 2, "access_failed_count": 0, "display_name": "SuperUser@quintarabio.com", "email": "SuperUser@quintarabio.com", "PasswordHash": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "PhoneNumber": null, "UserName": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "last_name": null, "first_name": null, "address": null, "city": null, "state_province": null, "country": null, "TextMsg": null, "phone": null, "title": null, "profession": null, "profession_id": null, "pin": 0, "active_ind": null, "mid_name": null, "zip_code": null, "sec_verify_method": null, "reset_password_ind": null, "last_access": null, "note": null, "create_by": 0, "create_dt": "2022-05-18 01:45:40", "update_by": 0, "update_dt": "2022-05-18 01:45:40"}]