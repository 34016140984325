//BEGIN_IMPORT_SECTIONs
import React, {Component, useState} from 'react';
import ReactDOM from 'react-dom';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
//import { AgGridReact,AgGridColumn } from '@ag-grid-community/react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import {retrieveApiData,retrieveData2,zoftApiForUsp} from '../../../data_retrieval';
import {ResponseWindowYesNo2,ResponseWindowSelf} from '../../../Modals';
import ZTextButton from '../../../zcomponents/ZTextButton';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
//import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

var the_this;
var is_retrieving = false;
var retrieve_counting = 0;
export default class ApplyPaymentInvoiceListDataGrid extends React.Component {
	dd_select_status={}
	dd_select_costcenter={}
	dd_select_institution={}
	state = {
		loaded:false,
		modules:[ClientSideRowModelModule],
		gridOptions:{},
		rowSelection: 'multiple',
		selected_building_id:0,
		data:[
			{"checked":0,"id": 0,"name":"","pi_id":0,"amount":0,"fee":0,"tax":0,"credits":0,"total_due":0,"est_total_due":0,"due_date":"","invoice_date":"",
			"cur_status_id":0,"billgroup_id":0,"user_id":0,"category_id":0,"product_id":0,"po":"","notes":"","verified":0,"shipping":0,"time_created":"",
			}
		],
		offset_row : 0,
		request_rows:50,
		updated_from_detail_allowed:true,
		showModalOk:false,
		showModalYesNo:false,
		showModalSelf:false,
		response:0,
		current_time:(new Date()),
		counter:1,
		editable:false,
	}

	gridApi: GridApi
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: true })
		/*
		for(let i = 0; i < this.props.status_list.length;i++)
			this.dd_select_status[this.props.status_list[i].id] = this.props.status_list[i].name;	
		for(let i = 0; i < this.props.costcenter_list.length;i++)
			this.dd_select_costcenter[this.props.costcenter_list[i].id] = this.props.costcenter_list[i].name;
		for(let i = 0; i < this.props.institution_list.length;i++)
			this.dd_select_institution[this.props.institution_list[i].id] = this.props.institution_list[i].name;
		*/
	}
	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		console.log('ApplyPaymentInvoiceListDataGrid componentDidUpdate this.props',this.props);
		if(this.props.payment_id!==prevProps.payment_id&&this.props.payment_id>0) {
			this.retrieveData();
		}
	}

	onGridReady = params => {
    	this.gridApi = params.api;
 	};

	retrieveData = () => {
		console.log('ApplyPaymentInvoiceListDataGrid retrieveData 1');
		let statusCopy0 = Object.assign({}, this.state)
//		statusCopy0.showModalSelf = true;
		statusCopy0.data = [];
		this.setState(statusCopy0);
		this.gridApi.setRowData(this.state.data)

		let sql_id = 48;
		let app_id = 1;
	//	let input_parm0 = this.props.input_parm;

		let input_parm0 = [
			{"request_row_count":1},
			{"offset_row":0},
			{"request_rows":9999},
			{"payment_id":0},
		]
		input_parm0[0].request_row_count = 1
		input_parm0[1].offset_row = 0
		input_parm0[2].request_rows = 9999
		input_parm0[3].payment_id = this.props.payment_id
//		console.log('ApplyPaymentInvoiceListDataGrid retrieveData input_parm0',input_parm0);

		let tmp_data = retrieveApiData(1,30101,sql_id,"usp_react_sql_retrieve2", JSON.stringify(input_parm0));
//		let where_clause = input_parm0.splice(0,3);
        let where_clause = input_parm0.slice(3,input_parm0.length);
		console.log('ApplyPaymentInvoiceListDataGrid retrieveData where_clause',where_clause);

		Promise.resolve(tmp_data).then((value)=>{
			console.log('ApplyPaymentInvoiceListDataGrid retrieveData value',value);
			let total_row_count = parseInt(value);
			retrieveData2(1,30101,sql_id,0,total_row_count,where_clause)
			.then((retrieved_data) => {
//				alert(JSON.stringify(retrieved_data));
				console.log('ApplyPaymentInvoiceListDataGrid retrieveData retrieved_data',retrieved_data);
				let statusCopy = Object.assign({}, this.state)
//				statusCopy.showModalSelf = false;
				statusCopy.data = retrieved_data;
				this.setState(statusCopy);
				this.gridApi.setRowData(this.state.data)
//				this.props.retrieved();
			})
		});
	}

	onSelectionChanged = (event)=>{
		let the_list = [];
		const selectedNodes =  event.api.getSelectedNodes();
		if(selectedNodes.length>0) {
			console.log('ApplyPaymentInvoiceListDataGrid-onSelectionChanged selectedNodes',selectedNodes);
			selectedNodes.map((item, i)=>{the_list.push(item.data.id)});
			console.log('ApplyPaymentInvoiceListDataGrid-onSelectionChanged the_list',the_list);
			this.props.getDeleteInvoiceList(the_list);
			this.props.getInvoiceId(selectedNodes[0].data.id);
		}
		console.log('ApplyPaymentInvoiceListDataGrid-onSelectionChanged the_list', the_list);
	}
	onCellClicked(event){
		console.log("ApplyPaymentInvoiceListDataGrid onCellClicked event", event);
		the_this.setState({showModalYesNo:true});
	}
	onCellDoubleClicked(obj){
		console.log('ApplyPaymentInvoiceListDataGrid onCellDoubleClicked(obj)', obj);
	}

	onCellEditingStopped = (obj) => {
		console.log('ApplyPaymentInvoiceListDataGrid onCellEditingStopped obj', obj);
	}

	onCellValueChanged = (obj) => {
		console.log('ApplyPaymentInvoiceListDataGrid onCellValueChanged obj', obj);
	   let field_name = obj.column.colId
	   let the_id = obj.data.pi_id
	   let the_data = obj.value
//	   let the_type = typeof the_this.state.data[0][field_name];
		let the_type = obj.value
	   console.log('ApplyPaymentInvoiceListDataGrid onCellValueChanged the_this.state.data[0][field_name]', the_this.state.data[0][field_name]);
	   if (typeof the_this.state.data[0][field_name]==='string')
		   the_data = '"' + the_data + '"'
	   let the_input_data = '{"' + field_name + '":' + the_data + ',"id":' + the_id.toString() + '}'
//		the_input_data = '{"' + field_name + '":' + tmp_data.toString() + ',"id":' + the_id.toString() + '}'
		console.log('ApplyPaymentInvoiceListDataGrid onCellValueChanged the_input_data', the_input_data);
		let api_return_data = zoftApiForUsp(1,30103,55,0,0,"usp_react_sql_onchange_update", the_input_data);
		Promise.resolve(api_return_data).then((value)=>{
			console.log('EditInvoiceDetailDataGrid-onCellValueChanged value', value);
			if(value.return_status===0) { //normal return
				console.log('EditInvoiceDetailDataGrid-onCellValueChanged value.return_data', value.return_data);
				if(value.return_data.success) {
					console.log('EditInvoiceDetailDataGrid-onButtonClickNew value.return_data.output.data',value.return_data.output);
//                        props.getCurrentDataFromDetail({insert_or_update:true,i:props.current_data_from_list.i, data:detail_data.data});
				} else {
					console.log('EditInvoiceDetailDataGrid-onBlurTextHandler Update Error:value.return_data.message', value.return_data.message);
//					SetModalTitle("Update Error:" + value.return_data.message + "!");
//					SetShowModalEdit(false);
				};
			} else {
//				SetModalTitle("Unspecified error, please report it to tech support!");
//				SetShowModalOk(true);
				console.log('EditInvoiceDetailDataGrid-onBlurTextHandler value.return_data', value.return_data);
			}
		}) 
	}
	setShowModalOk(a_showModal) {
		this.setState({showModalOk:a_showModal});
	}
	setShowModalYesNo(a_showModal) {
		this.setState({showModalYesNo:a_showModal});
	}
	setResponse(a_response) {
		this.setState({response:a_response});
	}

	onButtonClick(a_text) {
		this.retrieveData()
	}
	/*
	lookupKey(mappings, name){
		//		console.log('ApplyPaymentOrderListDataGrid-lookupKey mappings,name', mappings,name);
		for (var key in mappings) {
			if (mappings.hasOwnProperty(key)) {
//			console.log(key + " -> " + mappings[key] + '|' + name);
			if(mappings[key]===name)
				return key
			}
		}
		return 0
	}
	*/
	/*
	lookupValue(mappings, name){
		return mappings[name]
	}	// convert code to value

	makeValueFormatter = params => { 
		return lookupValue(dd_selection, params.value);
	};
	*/
//rowSelection:'multiple',
//rowMultiSelectWithClick:true
//rowSelection='multiple'
	createGridOptions() {
		const options: GridOptions = {
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true,
				rowMultiSelectWithClick: true,
       			headerCheckboxSelection: true,
				rowDeselection: true,
			},
			columnDefs: [
				{headerName:"",headerClass:"header-checked",field:"checked",hide:false,width:30,sortable:true,headerCheckboxSelection:true,checkboxSelection:true,editable:true,valueSetter:function (params) {params.data.checked= params.newValue;return true;},suppressNavigable:true,
				headerCheckboxSelection: true,
				headerCheckboxSelectionFilteredOnly: true,
				checkboxSelection: true,
				},
				{headerName:"Invoice ID",headerClass:"header-id",field:"id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.id= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Invoice Name",headerClass:"header-name",field:"name",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Paid Invoice ID",headerClass:"header-pi_id",field:"pi_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.pi_id= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Amount",headerClass:"header-amount",field:"amount",hide:false,width:160,sortable:false,checkboxSelection:false,
				editable: function(params) {
						return the_this.props.update_mode_invoice
				},
				valueSetter:function (params) {params.data.amount= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Fee",headerClass:"header-fee",field:"fee",hide:false,width:160,sortable:false,checkboxSelection:false,
				editable: function(params) {
					return the_this.props.update_mode_invoice
				},
				valueSetter:function (params) {params.data.fee= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Tax",headerClass:"header-tax",field:"tax",hide:false,width:160,sortable:false,checkboxSelection:false,
				editable: function(params) {
					return the_this.props.update_mode_invoice
				},
				valueSetter:function (params) {params.data.tax= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Credits",headerClass:"header-credits",field:"credits",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.credits= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Total Due",headerClass:"header-total_due",field:"total_due",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.total_due= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Est Total Due",headerClass:"header-est_total_due",field:"est_total_due",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.est_total_due= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Due Date",headerClass:"header-due_date",field:"due_date",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.due_date= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Invoice Date",headerClass:"header-invoice_date",field:"invoice_date",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.invoice_date= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Status",headerClass:"header-cur_status_id",field:"cur_status_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.cur_status_id= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Billgroup",headerClass:"header-billgroup_id",field:"billgroup_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.billgroup_id= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"User",headerClass:"header-user_id",field:"user_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.user_id= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Category",headerClass:"header-category_id",field:"category_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.category_id= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Product",headerClass:"header-prod_id",field:"prod_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.prod_id= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"PO",headerClass:"header-po",field:"po",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.po= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Notes",headerClass:"header-notes",field:"notes",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.notes= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Verified",headerClass:"header-verified",field:"verified",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.verified= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Shipping",headerClass:"header-shipping",field:"shipping",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.shipping= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Time Created",headerClass:"header-time_created",field:"time_created",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.time_created= params.newValue;return true;},suppressNavigable:false,},
			],
		}
		return options;
	}
	render() {
		return  (
			<div id="pid_0630_cell_001" style={{position:"absolute",top:0,width:1350,height:200,backgroundColor:"#FFFFFF"}}>
				<div id="pid_0630_cell_001_001" className="ag-theme-balham" 
                    style={{position:"absolute",top:0,left:0,width:1350,height:200,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={this.state.rowSelection} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
				{this.state.showModalSelf?
					<ResponseWindowSelf
						title={"Data Is Loading . . ."} 
					/> :null}
				{this.state.showModalYesNo?
					<ResponseWindowYesNo2
						title={"Current updated record will be overridden, continue?"} 
						setShowModal={(showModel)=>this.setShowModal(showModel)} 
						setResponse={(response)=>this.setResponse(response)}
					/> :null}
			</div>
		)
	}
}
