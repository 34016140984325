//BEGIN_IMPORT_SECTION
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import getToken, {setToken} from '../../GetSetToken.js';
import getApiUrl, {getPassthroughUrl} from '../../GetApiUrl';
import DataRetrieval, {retrieveApiData,retrieveData2} from '../../data_retrieval';

var the_this_role
export default class AccessRoleGrid extends React.Component {
	state = {
		loaded:false,
		gridOptions:{},
		role_id:0,
		app_id:0,
		data:[]
	}
//		data:[{id:0,name:"",description:"",app_id:0, inclusive_ind:"",note:"",create_by:"",create_dt:"",update_by:"",update_dt:"",}]
	gridApi: GridApi
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this_role = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: true })
		this.retrieveData()
	}
	onGridReady = params => {
    	this.gridApi = params.api;
 	 };

	retrieveData() {
//		this.setState({loaded:true});
		let input_parm = [
			{"request_row_count":1},
			{"offset_row":0},
			{"request_rows":999},
			{"role_id":this.props.role_id},
		]
		let sql_id = 1;
		let app_id = 1;
		console.log('AccessAppGuiFieldGrid retrieveData this.props.input_parm',this.props.app_id);
		let tmp_data = retrieveApiData(1,10200,sql_id,"usp_react_sql_retrieve2", JSON.stringify(input_parm));
		let where_clause = input_parm.slice(3,input_parm.length);
		Promise.resolve(tmp_data).then((value)=>{
			console.log('AccessAppGuiGrid retrieveData value',value);
			let total_row_count = parseInt(value);
			retrieveData2(1,10200,sql_id,0,total_row_count,where_clause)
			.then((retrieved_data) => {
				console.log('AccessAppGuiGrid retrieveData retrieved_data',retrieved_data);
				let statusCopy = Object.assign({}, this.state)
				statusCopy.data = retrieved_data;
				this.setState(statusCopy);
				this.gridApi.setRowData(this.state.data)
			})
		});
	}
	onSelectionChanged = (event)=>{
		const selectedNodes =  event.api.getSelectedNodes();
		console.log('AccessAppGuiGrid selectedNodes', selectedNodes);
		if(selectedNodes.length>0)
			the_this_role.props.getRoleAppId(selectedNodes[0].data.id,selectedNodes[0].data.app_id)
	}
	onCellValueChanged = ({ data }) => {
  		console.log("AccessRoleGrid onCellClicked data", data);
	};
	onPasteStart(params) {
	  console.log('AccessRoleGrid onPasteStart:', params);
	}
	onPasteEnd(params) {
	  console.log('AccessRoleGrid Callback onPasteEnd:', params);
	}

	onCopySelectedRowsToClipboard(params) {
	  console.log('send to clipboard called with data:');
	  console.log(params.data);
	}
	onCellClicked(event){
//		console.log('AccessRoleGrid event', event.columnApi.columnController.columnDefs[0]);
		console.log("AccessRoleGrid onCellClicked event", event);

	}
	onCellDoubleClicked(obj){
		console.log('AccessRoleGrid onCellDoubleClicked(obj)', obj);
	}	
	onCellValueChanged(event) {
		console.log('AccessRoleGrid onCellValueChanged(event)', event);
	}
	cellClicked(params) {
	console.log('AccessRoleGrid cellClicked(params)', params);
	  params.node.setSelected(true)
	}
	onCellValueChanged = (obj) => {
		console.log('AccessRoleGrid onCellValueChanged obj', obj);		
		let field_name = obj.column.colDef.field 
		let the_id = obj.data.id
		let the_app_id = obj.data.app_id
		let the_data = obj.value
		let token = getToken();
		if (typeof the_data==='string')
			the_data = '"' + the_data + '"'
		let the_input_data = '{"' + field_name + '":' + the_data + ',"id":' + the_id.toString() + ',"app_id":' + the_app_id.toString() + '}'
		let the_input = '{"usp_name":"usp_react_sql_onchange_update","sql_id":1,"input_data"' + ':' + the_input_data + '}'
		console.log('AccessRoleGrid-onCellValueChanged the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {
			method: 'POST',headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
         })
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			console.log('AccessRoleGrid-onCellValueChanged 2 no error');
		})	
		.catch((error) => {
			console.error(error-'Error in update!');
			//this.insertOrder(1);
		});
	}
	onAddRow = () => {
		let app_id = this.props.app_id > 0 ? this.props.app_id : 0
//		let newData = [{id:role_id,name:"",description:"",app_id:app_id,inclusive_ind:"",note:"",create_by:"",create_dt:"",update_by:"",update_dt:"",}]
		
		console.log('AccessRoleGrid-onAddRow app_id', this.props.app_id);
		//let the_input_data = '{"id":"' + role_id.toString() + '",app_id":"' + app_id.toString() + '"}'
		let the_input_data = '{"name":"New Role","app_id":' + app_id + '}'
		let the_input = '{"usp_name":"usp_react_sql_insert","sql_id":1,"input_data"' + ':' + the_input_data + '}'
		let token = getToken();
		console.log('AccessRoleGrid-onAddRow token', token);
		console.log('AccessRoleGrid-onAddRow the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {method: 'POST', headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})})
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			if(responseJson.output==='Insert OK')
				this.retrieveData()
		})	
		.catch((error) => {
			console.error(error-'Error in AddRow!');
			//this.insertOrder(1);
		});
	}
	onDeleteRow = () => {
		console.log("this.gridApi.rowModel",this.gridApi.rowModel)
		let selectedRow = []
		let max_sample_id = 0
		let token = getToken();
		this.gridApi.forEachNode(function(node, i){
//			console.log('AccessRoleGrid-onDeleteRow node', node);
			if(node.selected===true) {// delete the data
				let role_id = node.data.id
				let app_id = node.data.app_id				
				let the_input_data = '{"id":' + role_id.toString() + ',"app_id":' + app_id.toString() +'}'
				let the_input = '{"usp_name":"usp_react_sql_delete","sql_id":1,"input_data"' + ':' + the_input_data + '}'
//				console.log('AccessRoleGrid-DeleteRow the_input', the_input);
				const api_url = getPassthroughUrl();
                return fetch(api_url, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json', },
					body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
		        })
			 	.then((response) => response.json())
			 	.then((responseJson) => {
					console.log('AccessRoleGrid-responseJson', responseJson);
					console.log('AccessRoleGrid-responseJson.success', responseJson.success);
					if(responseJson.success===true) {
					let statusCopy = Object.assign({}, the_this_role.state)
					statusCopy.data.splice(i, 1);
					the_this_role.gridApi.setRowData(statusCopy.data)

					//--------Cascading Deletes of children GUIs
						the_input_data = '{"role_id":' + role_id.toString() +'}'
						//the_input = '{"usp_name":"usp_react_sql_general","sql_id":1,"input_data"' + ':' + the_input_data + '}'
						the_input = '{"usp_name":"usp_react_sql_subsql","sql_id":1,"input_data"' + ':' + the_input_data + '}'
						const api_url = getPassthroughUrl();
						return fetch(api_url, {
							method: 'POST',
							headers: { 'Content-Type': 'application/json', },
							body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
						})
						.then((response) => response.json())
						.then((responseJson) => {
							console.log('AccessRoleGrid-responseJson', responseJson);
							console.log('AccessRoleGrid-responseJson.success', responseJson.success);
							/*if(responseJson.success===true) {
							let statusCopy = Object.assign({}, the_this.state)
							statusCopy.data.splice(i, 1);
							the_this.gridApi.setRowData(statusCopy.data)
							}*/
							the_this_role.onRoleSelection_DW1(role_id);
							the_this_role.onRoleSelection_DW2(window.selected_user_id);
						})	
						.catch((error) => {
							console.log('SangerSeqOrderDetail2-error', error);
						});
					//--------End of Cascading Delete Logic
					}
				})	
				.catch((error) => {
					console.log('SangerSeqOrderDetail2-error', error);
				});
			}
		})
	}
	createGridOptions() {
		const options: GridOptions = {
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true
			},
			columnDefs: [
				{headerName:"RoleID",width: 60, headerClass:"header-id",field:"id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Name",width: 120, headerClass:"header-name",field:"name",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Description",width: 200,headerClass:"header-description",field:"description",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.description= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"AppID",width: 60, headerClass:"header-app_id",field:"app_id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.app_id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Inclusive_ind",width: 120,headerClass:"header-description",field:"inclusive_ind",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.inclusive_ind= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Note",width: 200,headerClass:"header-note",field:"note",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.note= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Create_by",width: 80,headerClass:"header-create_by",field:"create_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Create_dt",width: 80,headerClass:"header-create_dt",field:"create_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_dt= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_by",width: 100,headerClass:"header-update_by",field:"update_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_dt",width: 100,headerClass:"header-update_dt",field:"update_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_dt= params.newValue;return true;},suppressNavigable:true,},
			],
		}
		return options;
	}
	render() {
/*
		if(this.state.loaded===false) {
			this.retrieveData()
		}
*/
		return  (
			<div style={{position:"absolute",top:0,width:530,height:200,backgroundColor:"#FFFFFF"}}>
				<div style={{position:"absolute",left:20,top:0,width:510,height:25,backgroundColor:"#FFFFFF",textAlign:"left"}}>
					Role
				</div>
				<div className="ag-theme-balham" 
                    style={{position:"absolute",top:25,left:0,width:530,height:175,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={"single"} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
				<div id="pid_0630_cell_001_002"
                    style={{position:"absolute",top:0,left:100,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.retrieveData}>Refresh
					</button>
				</div>
				<div id="pid_0630_cell_001_003"
                    style={{position:"absolute",top:0,left:200,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onAddRow}>Add
					</button>
				</div>
				<div id="pid_0630_cell_001_004"
                    style={{position:"absolute",top:0,left:300,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onDeleteRow}>
						Delete
					</button>
				</div>
			</div>
		)
	}
}

//[{"login_user_id": 1, "access_failed_count": 0, "display_name": "ZoftTest01@gmail.com", "email": "ZoftTest01@gmail.com", "PasswordHash": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "PhoneNumber": null, "UserName": "ZoftTest01@gmail.com", "last_name": null, "first_name": null, "address": null, "city": null, "state_province": null, "country": null, "TextMsg": null, "phone": null, "title": null, "profession": null, "profession_id": null, "pin": 0, "active_ind": null, "mid_name": null, "zip_code": null, "sec_verify_method": null, "reset_password_ind": null, "last_access": null, "note": null, "create_by": 0, "create_dt": "2022-05-18 01:43:40", "update_by": 0, "update_dt": "2022-05-18 01:43:40"},{"login_user_id": 2, "access_failed_count": 0, "display_name": "SuperUser@quintarabio.com", "email": "SuperUser@quintarabio.com", "PasswordHash": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "PhoneNumber": null, "UserName": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "last_name": null, "first_name": null, "address": null, "city": null, "state_province": null, "country": null, "TextMsg": null, "phone": null, "title": null, "profession": null, "profession_id": null, "pin": 0, "active_ind": null, "mid_name": null, "zip_code": null, "sec_verify_method": null, "reset_password_ind": null, "last_access": null, "note": null, "create_by": 0, "create_dt": "2022-05-18 01:45:40", "update_by": 0, "update_dt": "2022-05-18 01:45:40"}]