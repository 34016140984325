import getToken, {setToken} from './GetSetToken.js';
import getApiUrl, {getPassthroughUrl} from './GetApiUrl';

export const ValidatePageWithTokenParm = async(id,token)=> {
    const api_url = getApiUrl();
    const url = api_url + '/api2/React2/usp_react_token_based_pass_through';
    let the_pin = 0;

    const settings = {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
        "auth_token": token,"app_id":1,
        "gui_id":id,
        "input": '{"usp_name":"Validate_Screen_Access","input_data":"dummy"}', 
        })
    };
    try {
        const fetchResponse = await fetch(url, settings);
        const data = await fetchResponse.json();
        console.log("ValidatePage",data)
        if(data.success&&data.dberror===0) { // the access is validated
 //           alert('validatePage');
            return 1;
        } else {
            return 0;
        }        

    } catch (e) {
        return 0;
    } 


}

const ValidatePage = async(id)=> {
    let token = getToken();
    const api_url = getApiUrl();
    const url = api_url + '/api2/React2/usp_react_token_based_pass_through';
    let the_pin = 0;
    console.log("url",url);
    console.log("token",token);

    const settings = {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
        "auth_token": token,"app_id":1,
        "gui_id":id,
        "input": '{"usp_name":"Validate_Screen_Access","input_data":"dummy"}', 
        })
    };
    try {
        const fetchResponse = await fetch(url, settings);
        const data = await fetchResponse.json();
        console.log("ValidatePage",data)
        if(data.success&&data.dberror===0) { // the access is validated
 //           alert('validatePage');
            return 1;
        } else {
            return 0;
        }        

    } catch (e) {
        return 0;
    } 
}

export default ValidatePage;