//BEGIN_IMPORT_SECTIONs
import React, {Component, useState} from 'react';
import ReactDOM from 'react-dom';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
//import { AgGridReact,AgGridColumn } from '@ag-grid-community/react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import {retrieveApiData,retrieveData2,zoftApiForUsp,zoftApiForUspField} from '../../../data_retrieval';
import {ResponseWindowYesNo2,ResponseWindowSelf,ResponseWindowOk2} from '../../../Modals';
import ZTextButton from '../../../zcomponents/ZTextButton';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
//import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

var the_this;
var is_retrieving = false;
var retrieve_counting = 0;
export default class EditInvoiceStatusOrderListDataGrid extends React.Component {
	dd_select_status={}
	dd_select_costcenter={}
	dd_select_institution={}
	state = {
		loaded:false,
		modules:[ClientSideRowModelModule],
		gridOptions:{},
		rowSelection: 'multiple',
		selected_building_id:0,
		data:[
//			{"id": 0,"name":"","type":"","po":"","b_checked":0, "checked_by":0,"quote":0,"price":0,"created":"","updated":"","cat_id":0,"prod_id":0,
//			"urgency":0,"ready_by":"","need_pcr":0,"seq_work_id":0,"p_location":0,"cust_id":0,"phone":0,"rating":0,"newMsg":"","troublesome":0,
//			"detail":0,"ar_report":0
//			}
		],
		offset_row : 0,
		request_rows:50,
		updated_from_detail_allowed:true,
		showModalOk:false,
		modalTitle:"",
		showModalYesNo:false,
		showModalSelf:false,
		response:0,
		current_time:(new Date()),
		counter:1,
	}
//					this.setState({ ...this.state, showModalOk: true, modalTitle:the_title})

	gridApi: GridApi
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: true })
		/*
		for(let i = 0; i < this.props.status_list.length;i++)
			this.dd_select_status[this.props.status_list[i].id] = this.props.status_list[i].name;	
		for(let i = 0; i < this.props.costcenter_list.length;i++)
			this.dd_select_costcenter[this.props.costcenter_list[i].id] = this.props.costcenter_list[i].name;
		for(let i = 0; i < this.props.institution_list.length;i++)
			this.dd_select_institution[this.props.institution_list[i].id] = this.props.institution_list[i].name;
		*/
	}
	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if(this.props.invoice_id!==prevProps.invoice_id&&this.props.invoice_id>0) {
			this.retrieveData();
		}
	}

	onGridReady = params => {
    	this.gridApi = params.api;
 	};

	retrieveData = () => {
		let statusCopy0 = Object.assign({}, this.state)
//		statusCopy0.showModalSelf = true;
		statusCopy0.data = [];
		this.setState(statusCopy0);
		this.gridApi.setRowData(this.state.data)

		let sql_id = 43;
		let app_id = 1;
	//	let input_parm0 = this.props.input_parm;

		let input_parm0 = [
			{"request_row_count":1},
			{"offset_row":0},
			{"request_rows":9999},
			{"invoice_id":0},
		]
		input_parm0[0].request_row_count = 1
		input_parm0[1].offset_row = 0
		input_parm0[2].request_rows = 99999
		input_parm0[3].invoice_id = this.props.invoice_id
		sql_id = 43;
		let tmp_data = retrieveApiData(1,30101,sql_id,"usp_react_sql_retrieve2", JSON.stringify(input_parm0));
//		let where_clause = input_parm0.splice(0,3);
        let where_clause = input_parm0.slice(3,input_parm0.length);

		Promise.resolve(tmp_data).then((value)=>{
			console.log('EditInvoiceStatusOrderListDataGrid retrieveData value',value);
			let total_row_count = parseInt(value);
			retrieveData2(1,30101,sql_id,0,total_row_count,where_clause)
			.then((retrieved_data) => {
//				alert(JSON.stringify(retrieved_data));
				console.log('EditInvoiceStatusOrderListDataGrid retrieveData retrieved_data',retrieved_data);
				let statusCopy = Object.assign({}, this.state)
//				statusCopy.showModalSelf = false;
				statusCopy.data = retrieved_data;
				this.setState(statusCopy);
				this.gridApi.setRowData(this.state.data)
//				this.props.retrieved();
			})
		});
	}

	onSelectionChanged = (event)=>{
		let the_list = [];
		const selectedNodes =  event.api.getSelectedNodes();
		if(selectedNodes.length>0) {
			console.log('EditInvoiceStatusOrderListDataGrid-onSelectionChanged selectedNodes',selectedNodes);
			selectedNodes.map((item, i)=>{the_list.push(item.data.id)});
			console.log('EditInvoiceStatusOrderListDataGrid-onSelectionChanged the_list',the_list);
			this.props.getDeleteOrderList(the_list);
			this.props.getOrderId(selectedNodes[0].data.id);
		}
	}
	onCellClicked(event){
		console.log("InstitutionGrid onCellClicked event", event);
		the_this.setState({showModalYesNo:true});
	}
	onCellDoubleClicked(obj){
		console.log('InstitutionGrid onCellDoubleClicked(obj)', obj);
	}

	//let api_return_data = zoftApiForUspField(1,30102,field_id, 31,0,"usp_react_sql_onchange_update", the_input_data);
	/*
	let the_id = detail_data.data.id;
	let the_input_data = "";
	if(the_id===undefined)
		return;
	console.log('onBlurTextHandler field_name, data_type',field_name, data_type);
	if(update_mode===1) {
		if(data_type===type_string) { //string
			the_input_data = '{"' + field_name + '":"' + detail_data.data[field_name] + '","id":' + the_id.toString() + '}'
		} else {
			let tmp_data = detail_data.data[field_name]===undefined ? 0:detail_data.data[field_name];
			the_input_data = '{"' + field_name + '":' + tmp_data.toString() + ',"id":' + the_id.toString() + '}'
		}
		console.log('EditInvoiceDetailDataGrid-onBlurTextHandler the_input_data', the_input_data);
		let api_return_data = zoftApiForUspField(1,30102,field_id, 31,0,"usp_react_sql_onchange_update", the_input_data);
	*/

	onCellValueChanged(obj) {
		let field_lookup = {"cust_id":28,"prod_id":29,"price":30,"po":31,"type":32,"comment":33,"p_location":34,"ready_by":35,"newMsg":36,"name":37,"troublesome":38,"seq_work_id":39,"rating":40,"urgency":41,"billing_checked":42,"checked_by":43,"phone":44,"need_pcr":45,"quote":46,"cat_id":47}
		console.log('EditInvoiceStatusOrderListDataGrid onCellValueChanged obj', obj);
		let field_name = obj.column.colDef.field 
		let field_id = field_lookup[field_name]
		let the_id = obj.data.id
		let the_data = ""
		if (typeof obj.value==='string')
			the_data = '"' + obj.value + '"'
		else
			the_data = obj.value.toString()
		let the_input_data = '{"' + field_name + '":' + the_data + ',"id":' + the_id.toString() + '}'
		console.log('EditInvoiceStatusOrderListDataGrid-onCellValueChanged the_input_data,field_id', the_input_data,field_id);
		let api_return_data = zoftApiForUspField(1,30101,field_id,43,0,"usp_react_sql_onchange_update", the_input_data);
		Promise.resolve(api_return_data).then((value)=>{
			console.log('EditInvoiceStatusOrderListDataGrid-onCellValueChanged value', value);
			if(value.return_status===0) { //normal return
				console.log('EditInvoiceStatusOrderListDataGrid-onCellValueChanged value.return_data', value.return_data);
				if(value.return_data.success) {
//					SetEditData("");
					console.log('EditInvoiceStatusOrderListDataGrid-onCellValueChanged value.return_data.output.data',value.return_data.output);
				} else {
					let the_title = "Update Error:" + value.return_data.message + "!"
					the_this.setState({ ...this.state, showModalOk: true, modalTitle:the_title})
				};
			} else {
				let the_title = "Unspecified error, please report it to tech support!"
				the_this.setState({ ...this.state, showModalOk: true, modalTitle:the_title})
				console.log('EditInvoiceStatusOrderListDataGrid-onCellValueChanged value.return_data', value.return_data);
			}
		}) 
	}
	setShowModalOk(a_showModal) {
		this.setState({showModalOk:a_showModal});
	}
	setShowModalYesNo(a_showModal) {
		this.setState({showModalYesNo:a_showModal});
	}
	setResponse(a_response) {
		this.setState({response:a_response});
	}

	onButtonClick(a_text) {
		this.retrieveData()
	}

//rowSelection:'multiple',
//rowMultiSelectWithClick:true

//rowSelection='multiple'
	createGridOptions() {
		const options: GridOptions = {      
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true,
				rowMultiSelectWithClick: true,
       			headerCheckboxSelection: true,
				rowDeselection: true,
			},		
			columnDefs: [
				{headerName:"",headerClass:"header-checked",field:"checked",hide:false,width:30,sortable:true,headerCheckboxSelection:true,checkboxSelection:true,editable:true,valueSetter:function (params) {params.data.checked= params.newValue;return true;},suppressNavigable:true,
				headerCheckboxSelection: true,
				headerCheckboxSelectionFilteredOnly: true,
				checkboxSelection: true,
				},
				{headerName:"Order ID",headerClass:"header-id",field:"id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.id= params.newValue;return true;},suppressNavigable:false,
				editable:false,},
				{headerName:"Order Name",headerClass:"header-name",field:"name",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["37"]===undefined ||the_this.props.dd_access["37"]===0?false:true;},
				},
				{headerName:"Type",headerClass:"header-type",field:"type",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.type= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["32"]===undefined ||the_this.props.dd_access["32"]===0?false:true;},
				},
				{headerName:"PO",headerClass:"header-po",field:"po",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.po= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["31"]===undefined ||the_this.props.dd_access["31"]===0?false:true;},
				},
				{headerName:"Billing Checked",headerClass:"header-b_checked",field:"b_checked",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.b_checked= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["42"]===undefined ||the_this.props.dd_access["42"]===0?false:true;},
				},
				{headerName:"Checked By",headerClass:"header-checked_by",field:"checked_by",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.checked_by= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["43"]===undefined ||the_this.props.dd_access["43"]===0?false:true;},
				},
				{headerName:"Quote",headerClass:"header-quote",field:"quote",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.quote= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["46"]===undefined ||the_this.props.dd_access["46"]===0?false:true;},
				},
				{headerName:"Price",headerClass:"header-price",field:"price",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.price= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["30"]===undefined ||the_this.props.dd_access["30"]===0?false:true;},
				},
				{headerName:"Create Time",headerClass:"header-created",field:"created",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.created= params.newValue;return true;},suppressNavigable:false,
				editable:false,
				},
				{headerName:"Update Time",headerClass:"header-updated",field:"updated",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.updated= params.newValue;return true;},suppressNavigable:false,
				editable:false,
				},
				{headerName:"Category",headerClass:"header-cat_id",field:"cat_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.cat_id= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["47"]===undefined ||the_this.props.dd_access["47"]===0?false:true;},
				},
				{headerName:"Product",headerClass:"header-prod_id",field:"prod_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.prod_id= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["29"]===undefined ||the_this.props.dd_access["29"]===0?false:true;},
				},
				{headerName:"Urgency",headerClass:"header-urgency",field:"urgency",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.urgency= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["41"]===undefined ||the_this.props.dd_access["41"]===0?false:true;},
				},
				{headerName:"Ready By",headerClass:"header-ready_by",field:"ready_by",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.ready_by= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["35"]===undefined ||the_this.props.dd_access["35"]===0?false:true;},
				},
				{headerName:"Need PCR",headerClass:"header-need_pcr",field:"need_pcr",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.need_pcr= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["45"]===undefined ||the_this.props.dd_access["45"]===0?false:true;},
				},
				{headerName:"Seq Work ID",headerClass:"header-seq_work_id",field:"seq_work_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.seq_work_id= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["39"]===undefined ||the_this.props.dd_access["39"]===0?false:true;},
				},
				{headerName:"Sample Pickup Location",headerClass:"header-p_location",field:"p_location",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.p_location= params.newValue;return true;},suppressNavigable:false,
					editable:function (params) {return the_this.props.dd_access["34"]===undefined ||the_this.props.dd_access["34"]===0?false:true;},
				},
				{headerName:"Customer",headerClass:"header-cust_id",field:"cust_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.cust_id= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["28"]===undefined ||the_this.props.dd_access["28"]===0?false:true;},
				},
				{headerName:"Phone",headerClass:"header-phone",field:"phone",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.phone= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["44"]===undefined ||the_this.props.dd_access["44"]===0?false:true;},
				},
				{headerName:"Rating",headerClass:"header-rating",field:"rating",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.rating= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["40"]===undefined ||the_this.props.dd_access["40"]===0?false:true;},
				},
				{headerName:"New Message",headerClass:"header-newMsg",field:"newMsg",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.newMsg= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["36"]===undefined ||the_this.props.dd_access["36"]===0?false:true;},
				},
				{headerName:"Troublesome",headerClass:"header-troublesome",field:"troublesome",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.troublesome= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["38"]===undefined ||the_this.props.dd_access["38"]===0?false:true;},
				},
			],
		}
		return options;
	}
	render() {
		return  (
			<div id="pid_0630_cell_001" style={{position:"absolute",top:0,width:1350,height:200,backgroundColor:"#FFFFFF"}}>
				<div id="pid_0630_cell_001_001" className="ag-theme-balham" 
                    style={{position:"absolute",top:0,left:0,width:1350,height:200,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={this.state.rowSelection} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
				{this.state.showModalSelf?
					<ResponseWindowSelf
						title={"Data Is Loading . . ."} 
					/> :null}
				{this.state.showModalYesNo?
					<ResponseWindowYesNo2
						title={"Current updated record will be overridden, continue?"} 
						setShowModal={(showModel)=>this.setShowModal(showModel)} 
						setResponse={(response)=>this.setResponse(response)}
					/> :null}
				{this.state.showModalOk ? 
					<ResponseWindowOk2 
						title={this.state.modalTitle} 
						setShowModal={(showModel)=>this.setState({...this.state, showModalOk:showModel})}
					/> : 
					null}
			</div>
		)
	}
}

/*
				{this.showModal ? 
					<ResponseWindowYesNo2
						title={"Current updated record will be overridden, continue?"} 
						setShowModal={(showModel)=>this.setShowModal(showModel)} 
						setResponse={(response)=>this.setResponse(response)}
					/> : null}

*/