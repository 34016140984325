//BEGIN_IMPORT_SECTIONs
import React, {Component, useState} from 'react';
import ReactDom from "react-dom";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
//import { AgGridReact,AgGridColumn } from '@ag-grid-community/react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import {retrieveApiData,retrieveData2,zoftApiForUsp} from '../../../data_retrieval';
import {ResponseWindowYesNo2,ResponseWindowSelf} from '../../../Modals';
import { ModalRetrieve } from '../../../ModalRetrieval';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
//import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

var the_this;
export default class EditInvoiceStatusOrderListDataGridForModal extends React.Component {
	dd_select_status={}
	dd_select_costcenter={}
	dd_select_institution={}
	input_parm0=[
		{"request_row_count":1},
		{"offset_row":0},
		{"request_rows":999999},
		{"category_id":0},
		{"product_id":0},
		{"customer_id":0},
		{"start_date":""},
		{"end_date":""},
	]
	state = {
		loaded:false,
		modules:[ClientSideRowModelModule],
		gridOptions:{},
		rowSelection: 'multiple',
		selected_building_id:0,
		data:[
			{"checked":0,"id": 0,"name":"","type":"","po":"","b_checked":0, "checked_by":0,"quote":0,"price":0,"created":"","updated":"","cat_id":0,"prod_id":0,
			"urgency":0,"ready_by":"","need_pcr":0,"seq_work_id":0,"p_location":0,"cust_id":0,"phone":0,"rating":0,"newMsg":"","troublesome":0,
			"detail":0,"ar_report":0
			}
		],
		showModalSelf:false,
		showModalRetrieve:false,
		offset_row : 0,
		request_rows:50,
		updated_from_detail_allowed:true,
		response:0,
		current_time:(new Date()),
		counter:1,
		retrieve_source:1, // 1=search, 2=prev, 3=next
		total_rows:0, // 1=search, 2=prev, 3=next
		from_row:1,
		rowCountInd:1,
	}

	gridApi: GridApi
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: true })
	}
	componentDidUpdate(prevProps) {

		this.input_parm0[0].request_row_count = this.props.input_parm.request_row_count
		this.input_parm0[1].offset_row = this.props.input_parm.offset_row
		this.input_parm0[2].request_rows = this.props.input_parm.request_rows
		this.input_parm0[3].category_id = this.props.input_parm.category_id
		this.input_parm0[4].product_id = this.props.input_parm.product_id
		this.input_parm0[5].customer_id = this.props.input_parm.customer_id
		this.input_parm0[6].start_date = this.props.input_parm.start_date
		this.input_parm0[7].end_date = this.props.input_parm.end_date

		if(this.props.search_clicked===true&&this.props.search_clicked!==prevProps.search_clicked) {
			console.log('ApplyPaymentInvoiceDataGridForModal componentDidUpdate this.props.input_parm', this.props.input_parm);
			let sql_id = 45;
			let app_id = 1;
			let gui_id = 30101;
			this.input_parm0[2].request_rows = 999999;
			let tmp_data = retrieveApiData(app_id,gui_id,sql_id,"usp_react_sql_retrieve2", JSON.stringify(this.input_parm0));
			Promise.resolve(tmp_data).then((value)=>{
				console.log('ApplyPaymentInvoiceDataGridForModal componentDidUpdate retrieveData value',value);
				let total_row_count = parseInt(value);
				if(total_row_count>0) {
					this.input_parm0[2].request_rows = this.props.input_parm.request_rows;
					this.SearchRetrieveData(total_row_count);
				}
			})
		}
	}
	setTitle = (completion) => {
		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.progressTitle = "Data Is Loading . . . " + completion.toString() + "%";
		this.setState(statusCopy0);
	}
	setResponse = (data) => {
		console.log('v setResponse data',data);
		let statusCopy = Object.assign({}, this.state)
		statusCopy.data = data;
		this.gridApi.setRowData(this.state.data)
		statusCopy.showModalRetrieve = false;
		if(this.state.retrieve_source===1) {
			this.props.getRetrievedSearch(data.length,statusCopy.total_rows);
		} else if(this.state.retrieve_source===2) {
			this.props.getRetrievedPrev(data.length);
		} else {
			this.props.getRetrievedNext(data.length);
		}
		this.setState(statusCopy);
	}	

	SearchRetrieveData = (total_rows) => {
		console.log('EditInvoiceStatusOrderListDataGridForModal SearchRetrieveData 0 this.props.input_parm',this.props.input_parm);
		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.rowCountInd = 1;
		let sql_id = 45;
		let app_id = 1;
		console.log('EditInvoiceStatusOrderListDataGridForModal SearchRetrieveData this.props.input_parm',this.props.input_parm);
		statusCopy0.sql_id = sql_id;
		statusCopy0.showModalRetrieve = true;
		statusCopy0.data = [];
		statusCopy0.input_parm = this.input_parm0;
		if(total_rows<1000)
			statusCopy0.input_parm[2].request_rows = total_rows;
		statusCopy0.retrieve_source = 1
		statusCopy0.total_rows = total_rows;
		statusCopy0.from_row = 1;
		this.setState(statusCopy0);
	}
	PrevRetrieveData = () => {
		let input_parm2 = [
			{"request_row_count":0},
			{"offset_row":this.props.invoice_retrieved.from_row - 1000},
			{"request_rows":1000},
			{"category_id":this.props.input_parm.category_id},
			{"product_id":this.props.input_parm.product_id},
			{"customer_id":this.props.input_parm.customer_id},
			{"start_date":this.props.input_parm.start_date},
			{"end_date":this.props.input_parm.end_date},
		]
		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.rowCountInd = 0;
		statusCopy0.input_parm = input_parm2;
		console.log('EditInvoiceStatusOrderListDataGridForModal PrevRetrieveData 0 statusCopy0.input_parm',statusCopy0.input_parm);
		statusCopy0.sql_id = 45;;
		statusCopy0.showModalRetrieve = true;
		statusCopy0.from_row = this.props.invoice_retrieved.from_row - 1000;
		statusCopy0.retrieve_source = 2
		this.setState(statusCopy0);
	}
	NextRetrieveData = () => {
		let input_parm3 = [
			{"request_row_count":0},
			{"offset_row":this.props.invoice_retrieved.to_row + 1},
			{"request_rows":1000},
			{"category_id":this.props.input_parm.category_id},
			{"product_id":this.props.input_parm.product_id},
			{"customer_id":this.props.input_parm.customer_id},
			{"start_date":this.props.input_parm.start_date},
			{"end_date":this.props.input_parm.end_date},
		]
		console.log('EditInvoiceStatusOrderListDataGridForModal NextRetrieveData 0 this.props.input_parm',this.props.input_parm);
		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.rowCountInd = 0;
		statusCopy0.input_parm = input_parm3;
		console.log('EditInvoiceStatusOrderListDataGridForModal PrevRetrieveData 0 statusCopy0.input_parm',statusCopy0.input_parm);
		statusCopy0.sql_id = 45;
		statusCopy0.showModalRetrieve = true;
		statusCopy0.from_row = this.props.invoice_retrieved.to_row + 1;
		statusCopy0.retrieve_source = 3
		this.setState(statusCopy0);
	}

	onGridReady = params => {
    	this.gridApi = params.api;
 	};

	 onSelectionChanged = (event)=>{
		const selectedNodes =  event.api.getSelectedNodes();
		console.log('EditInvoiceStatusOrderListDataGridForModal the_this.gridApi', the_this.gridApi);
		let order_list = [];
		for(let i=0;i<selectedNodes.length;i++)
			order_list.push(selectedNodes[i].data.id);
		this.props.getResponse(order_list);
	}

	/*
	retrieveData = () => {
		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.showModalSelf = true;
		statusCopy0.data = [];
		this.setState(statusCopy0);
		this.gridApi.setRowData(this.state.data)

		let sql_id = 45;
		let app_id = 1;
	//	let input_parm0 = this.props.input_parm;

		let input_parm0 = [
			{"request_row_count":1},
			{"offset_row":0},
			{"request_rows":9999},
			{"category_id":0},
			{"product_id":0},
			{"customer_id":0},
			{"start_date":""},
			{"end_date":""},
		]
		input_parm0[0].request_row_count = 1
		input_parm0[1].offset_row = 0
		input_parm0[2].request_rows = 99999
		input_parm0[3].category_id = this.props.input_parm.category_id
		input_parm0[4].product_id = this.props.input_parm.product_id
		input_parm0[5].customer_id = this.props.input_parm.customer_id
		input_parm0[6].start_date = this.props.input_parm.start_date
		input_parm0[7].end_date = this.props.input_parm.end_date
		console.log('EditInvoiceStatusOrderListDataGridForModal retrieveData 1');

		let tmp_data = retrieveApiData(1,30101,sql_id,"usp_react_sql_retrieve2", JSON.stringify(input_parm0));
//		let where_clause = input_parm0.splice(0,3);
        let where_clause = input_parm0.slice(3,input_parm0.length);
		console.log('EditInvoiceStatusOrderListDataGridForModal retrieveData where_clause',where_clause);

		Promise.resolve(tmp_data).then((value)=>{
			console.log('EditInvoiceStatusOrderListDataGridForModal retrieveData value',value);
			let total_row_count = parseInt(value);
			retrieveData2(1,30101,sql_id,0,total_row_count,where_clause)
			.then((retrieved_data) => {
//				alert(JSON.stringify(retrieved_data));
				console.log('EditInvoiceStatusOrderListDataGridForModal retrieveData retrieved_data',retrieved_data);
				let statusCopy = Object.assign({}, this.state)
				statusCopy.showModalSelf = false;
				statusCopy.data = retrieved_data;
				this.setState(statusCopy);
				this.gridApi.setRowData(this.state.data)
				this.props.retrieved(retrieved_data.length);
			})
		});
	}
	*/

	onButtonClick(a_text) {
		this.retrieveData()
	}
	createGridOptions() {
		const options: GridOptions = {      
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true,
				rowMultiSelectWithClick: true,
       			headerCheckboxSelection: true,
				rowDeselection: true,
			},		
			columnDefs: [
				{headerName:"",headerClass:"header-checked",field:"checked",hide:false,width:30,sortable:true,headerCheckboxSelection:true,checkboxSelection:true,editable:true,valueSetter:function (params) {params.data.checked= params.newValue;return true;},suppressNavigable:true,
				headerCheckboxSelection: true,
				headerCheckboxSelectionFilteredOnly: true,
				checkboxSelection: true,
				},
				{headerName:"Order ID",headerClass:"header-id",field:"id",hide:false,width:100,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.id= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Order Name",headerClass:"header-name",field:"name",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Type",headerClass:"header-type",field:"type",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"PO",headerClass:"header-po",field:"po",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Billing Checked",headerClass:"header-b_checked",field:"b_checked",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Checked By",headerClass:"header-checked_by",field:"checked_by",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Quote",headerClass:"header-quote",field:"quote",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Price",headerClass:"header-price",field:"price",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Create Time",headerClass:"header-created",field:"created",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Update Time",headerClass:"header-updated",field:"updated",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Category",headerClass:"header-cat_id",field:"cat_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Product",headerClass:"header-prod_id",field:"prod_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Urgency",headerClass:"header-urgency",field:"urgency",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Ready By",headerClass:"header-ready_by",field:"ready_by",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Need PCR",headerClass:"header-need_pcr",field:"need_pcr",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Seq Work ID",headerClass:"header-seq_work_id",field:"seq_work_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Sample Pickup Location",headerClass:"header-p_location",field:"p_location",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Customer",headerClass:"header-cust_id",field:"cust_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Phone",headerClass:"header-phone",field:"phone",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Rating",headerClass:"header-rating",field:"rating",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"New Message",headerClass:"header-newMsg",field:"newMsg",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Troublesome",headerClass:"header-troublesome",field:"troublesome",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
			],
		}
		return options;
	}
	render() {
		return (
			<div id="pid_0630_cell_001" style={{position:"absolute",top:0,width:1070,height:550,backgroundColor:"#FFFFFF"}}>
				<div id="pid_0630_cell_001_001" className="ag-theme-balham" 
                    style={{position:"absolute",top:0,left:0,width:1070,height:550,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={this.state.rowSelection} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
				{this.state.showModalSelf?
					<ResponseWindowSelf
						title={"Data Is Loading . . ."} 
					/> :null}
				{this.state.showModalRetrieve?
					<ModalRetrieve
						rowCountInd={this.state.rowCountInd}
						appId={1}	
						guiId={30101}
						sqlId={45}
						inputParm={this.state.input_parm}
						setResponse={(response)=>this.setResponse(response)}
					/> :null}			
			</div>
		)
	}
}
