import React, {Component} from 'react';
//import '@progress/kendo-theme-default/dist/all.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Navbar from 'react-bootstrap/Navbar';
//import './v2/css/bootstrap.min.css';
//import './v2/css/qbio_style.css';
//import BusinessHome from './BusinessHome'
//import OldHome from './OldHome'

import PublicPageAbout from './public_pages/about';
import WebSiteHome from './WebSiteHome_home';


import PublicPageServiceDnaSequencing from './public_pages/service/dna_sequencing';
/*
import WebSiteServices from './WebSiteServices_services';
import WebSiteService from './WebSite_service';
*/
import Signin from './Signin';
import Registration from './Signup';
import ResetPassword from './ResetPassword';

//import WebSiteHomeDefaultNavigator from "./WebSiteHome_default_navigator2";
import menuItems from './InternaPageTreeItems';

import PublicPageServiceNgsServices from './public_pages/service/ngs_services';
import PublicPageServiceDnaAssembly from './public_pages/service/dna_assembly';

import PublicPageServiceNgsCellSeq from './public_pages/service/ngs_cellseq';

import InternalPageAccountAndSecurity from './internal_pages/01accountandsecurity/accountandsecurity';
import InternalPageAccount from './internal_pages/01accountandsecurity/account';
import InternalPageAccessControl from './internal_pages/01accountandsecurity/accesscontrol';
import InternalPageGroupManager from './internal_pages/01accountandsecurity/groupmanager';
import InternalPageSysconfigAndMaintenance from './internal_pages/02sysconfigandmaintenance/sysconfigandmaintenance';
import InternalPageLookuptablesetup from './internal_pages/02sysconfigandmaintenance/lookuptablesetup';
import InternalPageDropbox from './internal_pages/02sysconfigandmaintenance/dropbox';
import InternalPageFacilityAndGpsTables from './internal_pages/02sysconfigandmaintenance/facilityandgpstables';
import InternalPageSaleAndMarketingTables from './internal_pages/02sysconfigandmaintenance/saleandmarketingtables';
import InternalPageSysConfigBillingAndAccounting from './internal_pages/02sysconfigandmaintenance/billingandaccounting';
import InternalPageSysConfigCustomerInfo from './internal_pages/02sysconfigandmaintenance/customerinformation';

import InternalPageBillingAndAccounting from './internal_pages/03billingandaccounting/billingandaccounting';
import InternalPageBilling from './internal_pages/03billingandaccounting/01billing/billing';
import InternalPageEditInvoiceStatus from './internal_pages/03billingandaccounting/01billing/editinvoicestatus';
import InternalPageBillGroupManage from './internal_pages/03billingandaccounting/01billing/billgroupmanage';
import InternalPageInvoicePdfUpload from './internal_pages/03billingandaccounting/01billing/invoicepdfupload';
import InternalPageChargeCreditCardOnHand from './internal_pages/03billingandaccounting/01billing/chargecreditcard';
import InternalPageBlanketPOSummary from './internal_pages/03billingandaccounting/01billing/blanketposum';
import InternalPageRecordNewPO from './internal_pages/03billingandaccounting/01billing/newpo';
import InternalPageInvoicesWithoutPO from './internal_pages/03billingandaccounting/01billing/invoicewithoutpo';
import InternalPageInvoiceGeneratingTool from './internal_pages/03billingandaccounting/01billing/invoicegeneratingtool';
import InternalPageBillingStat from './internal_pages/03billingandaccounting/02billingstat/billingstat';
import InternalPageCustomerActivityReport from './internal_pages/03billingandaccounting/02billingstat/customeractivityreport';
import InternalPageCustomerWithoutOrder from './internal_pages/03billingandaccounting/02billingstat/customerwithoutorder';
import InternalPageNotInvoicedOligoOrders from './internal_pages/03billingandaccounting/02billingstat/notinvoiceoligoorders';
import InternalPageNotInvoicedCloningOrderss from './internal_pages/03billingandaccounting/02billingstat/notinvoicecloningorers';
import InternalPageNotInvoicedSequencingOrderss from './internal_pages/03billingandaccounting/02billingstat/notinvoicesequencingorders';
import InternalPagePayment from './internal_pages/03billingandaccounting/03payment/payment';
import InternalPageApplyPayment from './internal_pages/03billingandaccounting/03payment/applypayment';
import InternalPagePaymentSummary from './internal_pages/03billingandaccounting/03payment/paymentsummary';
import InternalPagePrepaidAccounts from './internal_pages/03billingandaccounting/03payment/prepaidaccounts';
import InternalPageRevenue from './internal_pages/03billingandaccounting/04revenue/revenue';
import InternalPageDailyCalculateRevenue from './internal_pages/03billingandaccounting/04revenue/dailycalculaterevenue';
import InternalPageNotPerformingRevenueReport from './internal_pages/03billingandaccounting/04revenue/notperformingrevenuereport';
import InternalPageSangerSequencing from './internal_pages/04sangersequencing/sangersequencing';
import InternalPageTodaysPickup from './internal_pages/04sangersequencing/todaypickup';
import InternalPageTodaysOrder from './internal_pages/04sangersequencing/todayorder';
import InternalPageOrderSequencing from './internal_pages/04sangersequencing/ordersequencing';
import InternalPageMyQB from './internal_pages/04sangersequencing/myqb';
import InternalPageDashboard from './internal_pages/04sangersequencing/05dashboard/dashboard';
import InternalPageOrderSubmission from './internal_pages/04sangersequencing/05dashboard/ordersubmission';
import InternalPageProductinoBSTQC from './internal_pages/04sangersequencing/05dashboard/productionbstqc';
import InternalPageProductinoSFQC from './internal_pages/04sangersequencing/05dashboard/productionsfqc';
import InternalPageOrderTransform from './internal_pages/04sangersequencing/05dashboard/ordertransform';
import InternalPageSequencingOrderSummary from './internal_pages/04sangersequencing/05dashboard/seqordersummary';
import InternalPageReviewPlate from './internal_pages/04sangersequencing/05dashboard/reviewplate';
import InternalPageManageDropbox from './internal_pages/04sangersequencing/dropboxmanage';
import InternalPageManagePickupRoute from './internal_pages/04sangersequencing/managepickuproute';
import InternalPageThisMonthNotDoneQC from './internal_pages/04sangersequencing/thismonthnotdoneqc';
import InternalPageLastMonthNotDoneQC from './internal_pages/04sangersequencing/lastmonthnotdoneqc';
import InternalPageToolsSampleSheetsTemplates from './internal_pages/04sangersequencing/toolssamplesheetstemplates';

import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  Redirect,
}
from "react-router-dom";

import "./styles.css";
import "./treeview.css";
import "./qbio_style.css";

import logotransparent from "./v2/img/logotransparent.png"

//const components = PanelBarUtils.mapItemsToComponents(items);
const WebSite = (props)=> {

//  console.log('submenu_list_tmp',submenu_list_tmp)
    return (
        <>
          <Router>   
            <Routes>
              <Route path="/" element={<WebSiteHome />} />
              <Route path="/accountandsecurity" element={<InternalPageAccountAndSecurity />} />
              <Route path="/accountandsecurity/account" element={<InternalPageAccount />} />
              <Route path="/accountandsecurity/accesscontrol" element={<InternalPageAccessControl />} />
              <Route path="/accountandsecurity/groupmanager" element={<InternalPageGroupManager />} />
              <Route path="/sysconfigandmaintenance" element={<InternalPageSysconfigAndMaintenance />} />
              <Route path="/sysconfigandmaintenance/lookuptablesetup" element={<InternalPageLookuptablesetup />} />
              <Route path="/sysconfigandmaintenance/dropbox" element={<InternalPageDropbox />} />
              <Route path="/sysconfigandmaintenance/facilityandgpstables" element={<InternalPageFacilityAndGpsTables />} />
              <Route path="/sysconfigandmaintenance/saleandmarketingtables" element={<InternalPageSaleAndMarketingTables />} />
              <Route path="/sysconfigandmaintenance/billingandaccounting" element={<InternalPageSysConfigBillingAndAccounting />} />
              <Route path="/sysconfigandmaintenance/customerinformation" element={<InternalPageSysConfigCustomerInfo />} />

              <Route path="/billingandaccouting" element={<InternalPageBillingAndAccounting />} />
              <Route path="/billingandaccouting/billing" element={<InternalPageBilling />} />
              <Route path="/billingandaccouting/billing/editinvoicestatus" element={<InternalPageEditInvoiceStatus />} />

              <Route path="/billingandaccouting/billing/billgroupmanage" element={<InternalPageBillGroupManage />} />
              <Route path="/billingandaccouting/billing/invoicepdfupload" element={<InternalPageInvoicePdfUpload />} />
              <Route path="/billingandaccouting/billing/chargecreditcard" element={<InternalPageChargeCreditCardOnHand />} />
              <Route path="/billingandaccouting/billing/blanketposum" element={<InternalPageBlanketPOSummary />} />
              <Route path="/billingandaccouting/billing/newpo" element={<InternalPageRecordNewPO />} />
              <Route path="/billingandaccouting/billing/invoicewithoutpo" element={<InternalPageInvoicesWithoutPO />} />
              <Route path="/billingandaccouting/billing/invoicegeneratingtool" element={<InternalPageInvoiceGeneratingTool />} />
              <Route path="/billingandaccouting/billingstat" element={<InternalPageBillingStat />} />             
              <Route path="/billingandaccouting/billingstat/customeractivityreport" element={<InternalPageCustomerActivityReport />} />                          
              <Route path="/billingandaccouting/billingstat/customerwithoutorder" element={<InternalPageCustomerWithoutOrder />} />             
              <Route path="/billingandaccouting/billingstat/notinvoiceoligoorders" element={<InternalPageNotInvoicedOligoOrders />} />       
              <Route path="/billingandaccouting/billingstat/notinvoicecloningorers" element={<InternalPageNotInvoicedCloningOrderss />} />             
              <Route path="/billingandaccouting/billingstat/notinvoicesequencingorders" element={<InternalPageNotInvoicedSequencingOrderss />} />             
              <Route path="/billingandaccouting/payment" element={<InternalPagePayment />} />
              <Route path="/billingandaccouting/payment/applypayment" element={<InternalPageApplyPayment />} />
              <Route path="/billingandaccouting/payment/paymentsummary" element={<InternalPagePaymentSummary />} />
              <Route path="/billingandaccouting/payment/prepaidaccounts" element={<InternalPagePrepaidAccounts />} />
              <Route path="/billingandaccouting/revenue" element={<InternalPageRevenue />} />
              <Route path="/billingandaccouting/revenue/dailycalculaterevenue" element={<InternalPageDailyCalculateRevenue />} />              
              <Route path="/billingandaccouting/revenue/notperformingrevenuereport" element={<InternalPageNotPerformingRevenueReport />} />              
              <Route path="/sangersequencing" element={<InternalPageSangerSequencing />} />
              <Route path="/sangersequencing/todaypickup" element={<InternalPageTodaysPickup />} />
              <Route path="/sangersequencing/todayorder" element={<InternalPageTodaysOrder />} />
              <Route path="/sangersequencing/ordersequencing" element={<InternalPageOrderSequencing />} />
              <Route path="/sangersequencing/myqb" element={<InternalPageMyQB />} />
              <Route path="/sangersequencing/dashboard" element={<InternalPageDashboard />} />
              <Route path="/sangersequencing/dashboard/ordersubmission" element={<InternalPageOrderSubmission />} />
              <Route path="/sangersequencing/dashboard/productionbstqc" element={<InternalPageProductinoBSTQC />} />
              <Route path="/sangersequencing/dashboard/productionsfqc" element={<InternalPageProductinoSFQC />} />
              <Route path="/sangersequencing/dashboard/ordertransform" element={<InternalPageOrderTransform />} />
              <Route path="/sangersequencing/dashboard/seqordersummary" element={<InternalPageSequencingOrderSummary />} />
              <Route path="/sangersequencing/dashboard/reviewplate" element={<InternalPageReviewPlate />} />
              <Route path="/sangersequencing/dropboxmanage" element={<InternalPageManageDropbox />} />
              <Route path="/sangersequencing/managepickuproute" element={<InternalPageManagePickupRoute />} />
              <Route path="/sangersequencing/thismonthnotdoneqc" element={<InternalPageThisMonthNotDoneQC />} />
              <Route path="/sangersequencing/lastmonthnotdoneqc" element={<InternalPageLastMonthNotDoneQC />} />
              <Route path="/sangersequencing/toolssamplesheetstemplates" element={<InternalPageToolsSampleSheetsTemplates />} />

              <Route path="/service/dna_sequencing" element={<PublicPageServiceDnaSequencing  />} />
              <Route path="/service/ngs_services" element={<PublicPageServiceNgsServices />} />
              <Route path="/service/dna_assembly" element={<PublicPageServiceDnaAssembly />} />

              <Route path="/service/ngs_cellseq" element={<PublicPageServiceNgsCellSeq />} />

              <Route path="/sign-in" element={<Signin  />} />
              <Route path="/sign-up" element={<Registration />} />``
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/about" element={<PublicPageAbout />} />
            </Routes>
          </Router>
        </>
    )
}
export default WebSite;