//BEGIN_IMPORT_SECTIONs
import React, {Component, useState} from 'react';
import ReactDom from "react-dom";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
//import { AgGridReact,AgGridColumn } from '@ag-grid-community/react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import {retrieveApiData,retrieveData2,zoftApiForUsp} from '../../../data_retrieval';
import { ModalRetrieve } from '../../../ModalRetrieval';

import {ResponseWindowYesNo2,ResponseWindowSelf} from '../../../Modals';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
//import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

var the_this;
export default class ApplyPaymentInvoiceDataGridForModal extends React.Component {
	dd_select_status={}
	dd_select_costcenter={}
	dd_select_institution={}
	dd_select_quote={}
	input_parm0=
	[
		{"request_row_count":1},
		{"offset_row":0},
		{"request_rows":999999},
		{"invoice_id":0},
		{"status_id":0},
		{"billgroup_id":0},
		{"parm4":0},
		{"parm5":""},
		{"start_date":""},
		{"end_date":""},
		{"parm8":""},
		{"parm9":0},
		{"product_id":0},
		{"user_id":0},
		{"parm12":0},
		{"parm13":0},
	]

	state = {
		loaded:false,
		modules:[ClientSideRowModelModule],
		gridOptions:{},
		rowSelection: 'multiple',
		selected_building_id:0,
		data:[
			{"checked": 0, "inv_name":"","inv_num":0,"cat":0, "institute":0,"billgroup":0,"total_due":0,"quote_id":0,"prepaid":0,"invoice_date":"",
			"past_due":0,"status_id":0,"po":"","ref":"","user_name":"","status_date":"","pdf_inv":0,"xls_inv":0,"notes":"Testing","detail":0,"ar_report":0}
		],


		showModalSelf:false,
		showModalRetrieve:false,
		offset_row : 0,
		request_rows:50,
		updated_from_detail_allowed:true,
		response:0,
		current_time:(new Date()),
		counter:1,
		retrieve_source:1, // 1=search, 2=prev, 3=next
		total_rows:0, // 1=search, 2=prev, 3=next
		from_row:1,
		rowCountInd:1,
	}

//	[{"checked": 0, "inv_name": "1", "inv_num": 6000034, "cat": 1, "institute": "", "billgroup": "", "total_due": 1985.00, "quote_id": 0, "prepaid": 0, "invoice_date": "2015-05-30",
//	"past_due": 49676, "status_id": 55, "po": "", "ref": "", "user_name":"","status_date":"","pdf_inv":0,"xls_inv":0,"notes":"","detail":0,"ar_report":0}

	gridApi: GridApi
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: true })
	}
	componentDidUpdate(prevProps) {

		this.input_parm0[0].request_row_count = this.props.input_parm.request_row_count
		this.input_parm0[1].offset_row = this.props.input_parm.offset_row
		this.input_parm0[2].request_rows = this.props.input_parm.request_rows
		this.input_parm0[3].invoice_id = 0
		this.input_parm0[4].status_id = 0
		this.input_parm0[5].billgroup_id = this.props.billgroup_id===undefined ? 0 : this.props.billgroup_id
		this.input_parm0[6].parm4 = 0
		this.input_parm0[7].parm5 = ""
		this.input_parm0[8].start_date = this.props.input_parm.start_date===undefined ?'':this.props.input_parm.start_date
		this.input_parm0[9].end_date = this.props.input_parm.end_date===undefined ?'':this.props.input_parm.end_date
		this.input_parm0[10].parm8 = ""
		this.input_parm0[11].parm9 = 0
		this.input_parm0[12].product_id = this.props.input_parm.product_id
		this.input_parm0[13].user_id = this.props.input_parm.customer_id
		this.input_parm0[14].parm12 = 0
		this.input_parm0[15].parm13 = 0

		if(this.props.search_clicked===true&&this.props.search_clicked!==prevProps.search_clicked) {
			console.log('ApplyPaymentInvoiceDataGridForModal componentDidUpdate this.props.input_parm', this.props.input_parm);
			let sql_id = 55;
			let app_id = 1;
			let gui_id = 30301;
			this.input_parm0[2].request_rows = 999999;
			let tmp_data = retrieveApiData(app_id,gui_id,sql_id,"usp_react_sql_retrieve2", JSON.stringify(this.input_parm0));
			Promise.resolve(tmp_data).then((value)=>{
				console.log('componentDidUpdate retrieveData value',value);
				let total_row_count = parseInt(value);
				if(total_row_count>0) {
					this.input_parm0[2].request_rows = this.props.input_parm.request_rows;
					this.SearchRetrieveData(total_row_count);
				}
			})
			//			this.retrieveData();
		}
		if(this.props.prev_clicked===true&&this.props.prev_clicked!==prevProps.prev_clicked) {
			this.PrevRetrieveData();
		}
		if(this.props.next_clicked===true&&this.props.next_clicked!==prevProps.next_clicked) {
			console.log('ApplyPaymentListDataGrid componentDidUpdate next_clicked this.props.invoice_retrieved',this.props.invoice_retrieved);
			this.NextRetrieveData();
		}
	}	
	setTitle = (completion) => {
		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.progressTitle = "Data Is Loading . . . " + completion.toString() + "%";
		this.setState(statusCopy0);
	}
	setResponse = (data) => {
		console.log('ApplyPaymentInvoiceDataGridForModal setResponse data',data);
		let statusCopy = Object.assign({}, this.state)
		statusCopy.data = data;
		this.gridApi.setRowData(this.state.data)
		statusCopy.showModalRetrieve = false;
		if(this.state.retrieve_source===1) {
			this.props.getRetrievedSearch(data.length,statusCopy.total_rows);
		} else if(this.state.retrieve_source===2) {
			this.props.getRetrievedPrev(data.length);
		} else {
			this.props.getRetrievedNext(data.length);
		}
		this.setState(statusCopy);
	}	

	SearchRetrieveData = (total_rows) => {
		console.log('ApplyPaymentInvoiceDataGridForModal SearchRetrieveData 0 this.props.input_parm',this.props.input_parm);
		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.rowCountInd = 1;
		let sql_id = 55;
		let app_id = 1;
		console.log('ApplyPaymentInvoiceDataGridForModal SearchRetrieveData this.props.input_parm',this.props.input_parm);
		statusCopy0.sql_id = sql_id;
		statusCopy0.showModalRetrieve = true;
		statusCopy0.data = [];
		statusCopy0.input_parm = this.input_parm0;
		if(total_rows<1000)
			statusCopy0.input_parm[2].request_rows = total_rows;
		statusCopy0.retrieve_source = 1
		statusCopy0.total_rows = total_rows;
		statusCopy0.from_row = 1;
		this.setState(statusCopy0);
	}
	PrevRetrieveData = () => {
		let input_parm2 = [
			{"request_row_count":0},
			{"offset_row":this.props.invoice_retrieved.from_row - 1000},
			{"request_rows":1000},
			{"invoice_id":0},
			{"status_id":0},
			{"billgroup_id":this.props.billgroup_id===undefined ? 0 : this.props.billgroup_id},
			{"parm4":0},
			{"parm5":""},
			{"start_date":this.props.input_parm.start_date===undefined ?'':this.props.input_parm.start_date},
			{"end_date":this.props.input_parm.end_date===undefined ?'':this.props.input_parm.end_date},
			{"parm8":""},
			{"parm9":0},
			{"product_id":this.props.input_parm.product_id},
			{"user_id":this.props.input_parm.customer_id},
			{"parm12":0},
			{"parm13":0},
		]



		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.rowCountInd = 0;
		statusCopy0.input_parm = input_parm2;
		console.log('ApplyPaymentInvoiceDataGridForModal PrevRetrieveData 0 statusCopy0.input_parm',statusCopy0.input_parm);
		statusCopy0.sql_id = 55;;
		statusCopy0.showModalRetrieve = true;
		statusCopy0.from_row = this.props.invoice_retrieved.from_row - 1000;
		statusCopy0.retrieve_source = 2
		this.setState(statusCopy0);
	}
	NextRetrieveData = () => {
		let input_parm3 = [
			{"request_row_count":0},
			{"offset_row":this.props.invoice_retrieved.to_row + 1},
			{"request_rows":1000},
			{"invoice_id":0},
			{"status_id":0},
			{"billgroup_id":this.props.billgroup_id===undefined ? 0 : this.props.billgroup_id},
			{"parm4":0},
			{"parm5":""},
			{"start_date":this.props.input_parm.start_date===undefined ?'':this.props.input_parm.start_date},
			{"end_date":this.props.input_parm.end_date===undefined ?'':this.props.input_parm.end_date},
			{"parm8":""},
			{"parm9":0},
			{"product_id":this.props.input_parm.product_id},
			{"user_id":this.props.input_parm.customer_id},
			{"parm12":0},
			{"parm13":0},
		]
		console.log('ApplyPaymentInvoiceDataGridForModal NextRetrieveData 0 this.props.input_parm',this.props.input_parm);
		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.rowCountInd = 0;
		statusCopy0.input_parm = input_parm3;
		console.log('ApplyPaymentInvoiceDataGridForModal PrevRetrieveData 0 statusCopy0.input_parm',statusCopy0.input_parm);
		statusCopy0.sql_id = 55;
		statusCopy0.showModalRetrieve = true;
		statusCopy0.from_row = this.props.invoice_retrieved.to_row + 1;
		statusCopy0.retrieve_source = 3
		this.setState(statusCopy0);
	}


	onGridReady = params => {
    	this.gridApi = params.api;
 	};

	 onSelectionChanged = (event)=>{
		const selectedNodes =  event.api.getSelectedNodes();
		console.log('ApplyPaymentInvoiceDataGridForModal the_this.gridApi', the_this.gridApi);
		let invoice_list = [];
		for(let i=0;i<selectedNodes.length;i++)
			invoice_list.push(selectedNodes[i].data.inv_num);
		this.props.getResponse(invoice_list);
	}

	/*
	SearchRetrieveData = (total_rows) => {
		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.showModalSelf = true;
		statusCopy0.data = [];
		this.setState(statusCopy0);
		this.gridApi.setRowData(this.state.data)

		let sql_id = 55;
		let app_id = 1;

		let input_parm0 = [
			{"request_row_count":1},
			{"offset_row":0},
			{"request_rows":9999},
			{"invoice_id":0},
			{"status_id":0},
			{"parm3":0},
			{"parm4":0},
			{"parm5":""},
			{"start_date":""},
			{"end_date":""},
			{"parm8":""},
			{"parm9":0},
			{"product_id":0},
			{"user_id":0},
			{"parm12":0},
			{"parm13":0},
		]
		input_parm0[0].request_row_count = 1
		input_parm0[1].offset_row = 0
		input_parm0[2].request_rows = 9999
		input_parm0[3].invoice_id = 0
		input_parm0[4].status_id = 0
		input_parm0[5].billgroup_id = this.props.billgroup_id===undefined ? 0 : this.props.billgroup_id
		input_parm0[6].parm4 = 0
		input_parm0[7].parm5 = ""
		input_parm0[8].start_date = this.props.input_parm.start_date===undefined ?'':this.props.input_parm.start_date
		input_parm0[9].end_date = this.props.input_parm.end_date===undefined ?'':this.props.input_parm.end_date
		input_parm0[10].parm8 = ""
		input_parm0[11].parm9 = 0
		input_parm0[12].product_id = this.props.input_parm.product_id
		input_parm0[13].user_id = this.props.input_parm.customer_id
		input_parm0[14].parm12 = 0
		input_parm0[15].parm13 = 0
		console.log('ApplyPaymentInvoiceDataGridForModal retrieveData input_parm0',input_parm0);
        let where_clause = input_parm0.slice(3,input_parm0.length);

		Promise.resolve(tmp_data).then((value)=>{
			console.log('ApplyPaymentInvoiceDataGridForModal retrieveData value',value);
			let total_row_count = parseInt(value);
			retrieveData2(1,30101,sql_id,0,total_row_count,where_clause)
			.then((retrieved_data) => {
//				alert(JSON.stringify(retrieved_data));
				console.log('ApplyPaymentInvoiceDataGridForModal retrieveData retrieved_data',retrieved_data);
				let statusCopy = Object.assign({}, this.state)
				statusCopy.showModalSelf = false;
				statusCopy.data = retrieved_data;
				this.setState(statusCopy);
				this.gridApi.setRowData(this.state.data)
				this.props.retrieved(retrieved_data.length);
			})
		});
	}
*/
	onButtonClick(a_text) {
		this.retrieveData()
	}
	createGridOptions() {
		const options: GridOptions = {      
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true,
				rowMultiSelectWithClick: true,
       			headerCheckboxSelection: true,
				rowDeselection: true,
			},		
			columnDefs: [
				{headerName:"",headerClass:"header-checked",field:"checked",hide:false,width:30,sortable:true,headerCheckboxSelection:true,checkboxSelection:true,editable:true,valueSetter:function (params) {params.data.checked= params.newValue;return true;},suppressNavigable:true,
				headerCheckboxSelection: true,
				headerCheckboxSelectionFilteredOnly: true,
				checkboxSelection: true,
				},
				{
					headerName: "Row",
					valueGetter: function (params) { return params.node.rowIndex + the_this.state.from_row;},
					width:50,
				},
				{headerName:"Invoice Name",headerClass:"header-inv_name",field:"inv_name",hide:false,width:160,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.inv_name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Invoice #",headerClass:"header-inv_num",field:"inv_num",hide:false,width:100,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.inv_num= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Category",headerClass:"header-cat",field:"cat",hide:false,sortable:false,width:160,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.cat= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Institution",headerClass:"header-institute",field:"institute",hide:false,sortable:false,width:160,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.institute= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"BillGroup",headerClass:"header-billgroup",field:"billgroup",hide:false,sortable:false,width:160,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.billgroup= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Total Due",headerClass:"header-total_due",field:"total_due",hide:false,sortable:false,width:100,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.total_due= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Quote",headerClass:"header-quote_id",field:"quote_id",hide:false,sortable:false,width:160,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.quote_id= params.newValue;return true;},suppressNavigable:true,
				valueFormatter: function (params) {return the_this.dd_select_quote[params.value]},},
				{headerName:"Prepaid",headerClass:"header-prepaid",field:"prepaid",hide:false,sortable:false,width:100,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.prepaid= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Invoice Date",headerClass:"header-invoice_date",field:"invoice_date",hide:false,sortable:false,width:150,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.invoice_date= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Paste Due (days)",headerClass:"header-past_due",field:"past_due",hide:false,sortable:false,width:160,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.past_due= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Current Status",headerClass:"header-status_id",field:"status_id",hide:false,sortable:false,width:100,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.status_id= params.newValue;return true;},suppressNavigable:true,
				valueFormatter: function (params) {return the_this.dd_select_status[params.value]},},
				{headerName:"PO",headerClass:"header-po",field:"po",hide:false,sortable:false,width:100,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.po= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"REF",headerClass:"header-ref",field:"ref",hide:false,sortable:false,width:50,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.ref= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"User",headerClass:"header-user_name",field:"user_name",hide:false,sortable:false,width:100,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.user_name= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Status Date",headerClass:"header-status_date",field:"status_date",hide:false,sortable:false,width:100,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.status_date= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Pdf",headerClass:"header-pdf_inv",field:"pdf_inv",hide:false,sortable:false,width:100,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.pdf_inv= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Xls",headerClass:"header-xls_inv",field:"xls_inv",hide:false,sortable:false,width:100,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.xls_inv= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Notes",headerClass:"header-notes",field:"notes",hide:false,sortable:false,width:160,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.notes= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Detail",headerClass:"header-detail",field:"detail",hide:false,sortable:false,width:160,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.detail= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Collection",headerClass:"header-ar_report",field:"ar_report",hide:false,sortable:false,width:160,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.ar_report= params.newValue;return true;},suppressNavigable:true,},
			],
		}
		return options;
	}
	render() {
		return (
			<div id="pid_0630_cell_001" style={{position:"absolute",top:0,width:1070,height:550,backgroundColor:"#FFFFFF"}}>
				<div id="pid_0630_cell_001_001" className="ag-theme-balham" 
                    style={{position:"absolute",top:0,left:0,width:1070,height:550,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={this.state.rowSelection} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
					> 
					</AgGridReact>
				</div>
				{this.state.showModalSelf?
					<ResponseWindowSelf
						title={"Data Is Loading . . ."} 
					/> :null}
				{this.state.showModalRetrieve?
					<ModalRetrieve
						rowCountInd={this.state.rowCountInd}
						appId={1}	
						guiId={30301}
						sqlId={55}
						inputParm={this.state.input_parm}
						setResponse={(response)=>this.setResponse(response)}
					/> :null}			
			</div>
		)
	}
}
