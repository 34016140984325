import {retrieveApiData} from './data_retrieval';

var currentInternalId = 10100;


export const menuItems2={title:"System Menu", path:"/", id:0,enabled:false, expanded:false,
    nested:[
        {title:"Account and System Security", path:"/accountandsecurity", id:10000,enabled:true, expanded:false,
            nested:[
                {title:"User Account", path:"/accountandsecurity/account", id:10100,enabled:true, expanded:false,
                nested:[]},
                {title:"Access Control", path:"/accountandsecurity/accesscontrol", id:10200,enabled:true, expanded:false,
                nested:[]},
                {title:"Group Management", path:"/accountandsecurity/groupmanager", id:10300,enabled:true, expanded:false,
                nested:[]},
            ]
        },
        {title:"System Config and Maintenance", path:"/sysconfigandmaintenance",id:20000,enabled:true, expanded:false,
            nested:[
                {title:"Lookup Table Setup", path:"/sysconfigandmaintenance/lookuptablesetup",id:20100,enabled:true, expanded:false,
                nested:[]},
                {title:"Dropbox", path:"/sysconfigandmaintenance/dropbox",id:20200,enabled:true, expanded:false,
                nested:[]},
                {title:"Facility And GPS Tables", path:"/sysconfigandmaintenance/facilityandgpstables",id:20300,enabled:true, expanded:false,
                nested:[]},
                {title:"Sale And Marketing", path:"/sysconfigandmaintenance/saleandmarketingtables",id:20400,enabled:true, expanded:false,
                nested:[]},
                {title:"Billing And Accounting", path:"/sysconfigandmaintenance/billingandaccounting",id:20500,enabled:true, expanded:false,
                nested:[]},
                {title:"Customer Information", path:"/sysconfigandmaintenance/customerinformation",id:20600,enabled:true, expanded:false,
                nested:[]},
            ]
        },
        {title:"Billing and Accounting", path:"/billingandaccouting",id:30000,enabled:true, expanded:false,
            nested:[
                {title:"Billing", path:"/billingandaccouting/billing",id:30100,enabled:true, expanded:false,
                nested:[
                    {title:"Edit Invoice Status", path:"/billingandaccouting/billing/editinvoicestatus",id:30101,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Manage Billgroup", path:"/billingandaccouting/billing/billgroupmanage",id:30102,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Invoice PDF Upload", path:"/billingandaccouting/billing/invoicepdfupload",id:30103,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Charge Credit Card On-Hand", path:"/billingandaccouting/billing/chargecreditcard",id:30104,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Blanket PO Summary", path:"/billingandaccouting/billing/blanketposum",id:30105,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Record New PO", path:"/billingandaccouting/billing/newpo",id:30106,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Invoices Without PO", path:"/billingandaccouting/billing/invoicewithoutpo",id:30107,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Invoice Generating Tool", path:"/billingandaccouting/billing/invoicegeneratingtool",id:30108,enabled:true, expanded:false,
                    nested:[]},
                ]},
                {title:"Orders and Billing Stat", path:"/billingandaccouting/billingstat",id:30200,enabled:true, expanded:false,
                nested:[
                    {title:"Customer Activity Report", path:"/billingandaccouting/billingstat/customeractivityreport",id:30201,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Customer Without Order", path:"/billingandaccouting/billingstat/customerwithoutorder",id:30202,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Not Invoiced Oligo Orders", path:"/billingandaccouting/billingstat/notinvoiceoligoorders",id:30203,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Not Invoiced Cloning Orders", path:"/billingandaccouting/billingstat/notinvoicecloningorers",id:30204,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Not Invoiced Sequencing Orders", path:"/billingandaccouting/billingstat/notinvoicesequencingorders",id:30205,enabled:true, expanded:false,
                    nested:[]},
                ]},
                {title:"Payment", path:"/billingandaccouting/payment",id:30300,enabled:true, expanded:false,
                nested:[
                    {title:"Apply Payment", path:"/billingandaccouting/payment/applypayment",id:30301,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Payment Summary", path:"/billingandaccouting/payment/paymentSummary",id:30302,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Prepaid Accounts", path:"/billingandaccouting/payment/prepaidaccounts",id:30303,enabled:true, expanded:false,
                    nested:[]},
                ]},
                {title:"Revenue", path:"/billingandaccouting/revenue",id:30400,enabled:true, expanded:false,
                nested:[
                    {title:"Daily Calculate Revenue", path:"/billingandaccouting/revenue/dailycalculaterevenue",id:30401,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Payment Summary", path:"/billingandaccouting/revenue/notperformingrevenuereport",id:30402,enabled:true, expanded:false,
                    nested:[]},
                ]},
            ],
        },
        {title:"Sanger Sequencing", path:"/sangersequencing",id:40000,enabled:true, expanded:false,
            nested:[
                {title:"Todays Pickup", path:"/sangersequencing/todaypickup",id:40100,enabled:true, expanded:false,
                nested:[]},
                {title:"Todays Order", path:"/sangersequencing/todayorder",id:40200,enabled:true, expanded:false,
                nested:[]},
                {title:"Order Sequencing", path:"/sangersequencing/ordersequencing",id:40300,enabled:true, expanded:false,
                nested:[]},
                {title:"MyQB", path:"/sangersequencing/myqb",id:40400,enabled:true, expanded:false,
                nested:[]},
                {title:"Dashboard ", path:"/sangersequencing/dashboard",id:40500,enabled:true, expanded:false,
                nested:[
                    {title:"Order Submission", path:"/sangersequencing/dashboard/ordersubmission",id:40501,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Productino BST QC", path:"/sangersequencing/dashboard/productionbstqc",id:40502,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Production SF QC", path:"/sangersequencing/dashboard/productionsfqc",id:40503,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Order Transform", path:"/sangersequencing/dashboard/ordertransform",id:40504,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Sequencing Order Summary", path:"/sangersequencing/dashboard/seqordersummary",id:40505,enabled:true, expanded:false,
                    nested:[]},
                    {title:"Review Plate", path:"/sangersequencing/dashboard/reviewplate",id:40506,enabled:true, expanded:false,
                    nested:[]},
                ]},
                {title:"Manage Dropbox", path:"/sangersequencing/dropboxmanage",id:40600,enabled:true, expanded:false,
                nested:[]},
                {title:"Manage Pickup Route", path:"/sangersequencing/managepickuproute",id:40700,enabled:true, expanded:false,
                nested:[]},
                {title:"This Month Not Done QC", path:"/sangersequencing/thismonthnotdoneqc",id:40800,enabled:true, expanded:false,
                nested:[]},
                {title:"Last Month Not Done QC", path:"/sangersequencing/lastmonthnotdoneqc",id:40900,enabled:true, expanded:false,
                nested:[]},
                {title:"Tools, Sample Sheets, and Templates", path:"/sangersequencing/toolssamplesheetstemplates",id:41000,enabled:true, expanded:false,
                nested:[
                    {title:"SeqA5.1", path:"/sangersequencing/toolssamplesheetstemplates/seqa51",id:41001,enabled:true, expanded:false,
                    nested:[]},
                    {title:"SeqScanner", path:"/sangersequencing/toolssamplesheetstemplates/seqscanner",id:41002,enabled:true, expanded:false,
                    nested:[]},
                    {title:"PeakScanner", path:"/sangersequencing/toolssamplesheetstemplates/peakscanner",id:41003,enabled:true, expanded:false,
                    nested:[]},
                ]},
            ]
        },
        {title:"Oligo", path:"/oligo",id:50000,enabled:true, expanded:false,
            nested:[
                {title:"Search Oligo Order", path:"/oligo/search",id:50100,enabled:true, expanded:false,
                nested:[]},            
                {title:"Oligo Packing Slips", path:"/oligo/oligopackingslips",id:50200,enabled:true, expanded:false,
                nested:[]},            
                {title:"Record Oligo Order", path:"/oligo/recordoligoorder",id:50300,enabled:true, expanded:false,
                nested:[]},            
            ]    
        },  
        {title:"Cloning", path:"/cloning",id:60000,enabled:true, expanded:false,
            nested:[
                {title:"Cloning Dashboard", path:"/cloning/cloningdashboard",id:60100,enabled:true, expanded:false,
                nested:[]},
                {title:"Search Cloning Order", path:"/cloning/searchcloningorder",id:60200,enabled:true, expanded:false,
                nested:[]},            
                {title:"Record Cloning Order", path:"/cloning/recordcloningorder",id:60300,enabled:true, expanded:false,
                nested:[]},            
                {title:"Qblock", path:"/cloning/genefragment",id:60400,enabled:true, expanded:false,
                nested:[]},            
            ]    
        },      
        {title:"DNAprep", path:"/dnaprep",id:70000,enabled:true, expanded:false,
            nested:[
            ]    
        },      
        {title:"Other Services", path:"/otherservices",id:80000,enabled:true, expanded:false,
            nested:[
            ]    
        },      
        {title:"Tools and Resources", path:"/toolsandresources",id:90000,enabled:true, expanded:false,
            nested:[
                {title:"General Forms", path:"/toolsandresources/generalforms",id:90100,enabled:true, expanded:false,
                    nested:[
                    ]    
                },      
                {title:"Payroll Forms", path:"/toolsandresources/payrollforms",id:90200,enabled:true, expanded:false,
                    nested:[
                    ]
                },
                {title:"SampleSheet Template", path:"/toolsandresources/samplesheettemplates",id:90300,enabled:true, expanded:false,
                    nested:[
                    ]
                }
            ]
        },
    ]
    };

var menuItems = {};

export const getTreeMenu = ()=>{
    let menu = {title:"Menu", path:"/", id:0,enabled:false, expanded:false,nested:[]}
    let itemTemplate = {title:"", path:"/", id:0,enabled:true, expanded:false,nested:[]}
    let menuDataTmp = getMenuData();
    console.log("getTreeMenu-menuDataTmp",menuDataTmp);
    let menuItem0 = itemTemplate;
    let md = menuDataTmp[0];
    let curId = menuDataTmp[0].id;
    console.log("getTreeMenu-menuDataTmp",menuDataTmp);
    let curLevel1MenuId = Math.floor(curId/10000)
    let curLevel2MenuId = Math.floor((curId - curLevel1MenuId*10000)/100)
    let curLevel3MenuId = (curId - curLevel1MenuId*10000) - curLevel2MenuId*100
    
    menuItem0.title = menuDataTmp[0].title;
    menuItem0.path = menuDataTmp[0].path;
    menuItem0.id = menuDataTmp[0].id;
    menuItem0.enabled = md.access_mode==="N"?false:(md.user_id===1&&md.incl_ind==="E"?false:(md.role_id===0?false:true))
    menu.nested.push(menuItem0);
    console.log("getTreeMenu-menu",menu);
    //  return menu;
    
    //  for(let i=1; i<menuDataTmp.length;i++) {
    for(let i=1; i<menuDataTmp.length;i++) {
        let menuItem = {title:"Menu", path:"/", id:0,enabled:false, expanded:false,nested:[]};
        let prevLevel1MenuId = curLevel1MenuId;
        let prevLevel2MenuId = curLevel2MenuId;
        let prevLevel3MenuId = curLevel3MenuId;
        let prvId = curId;
        md = menuDataTmp[i];
        curId = menuDataTmp[i].id;
        curLevel1MenuId = Math.floor(curId/10000)
        curLevel2MenuId = Math.floor((curId - curLevel1MenuId*10000)/100)
        curLevel3MenuId = (curId - curLevel1MenuId*10000) - curLevel2MenuId*100
        console.log("getTreeMenu-prevLevel1MenuId,prevLevel2MenuId,prevLevel3MenuId",prevLevel1MenuId,prevLevel2MenuId,prevLevel3MenuId);
        console.log("getTreeMenu-curLevel1MenuId,curLevel2MenuId,curLevel3MenuId",curLevel1MenuId,curLevel2MenuId,curLevel3MenuId);
    
        menuItem.title = menuDataTmp[i].title;
        menuItem.path = menuDataTmp[i].path;
        menuItem.id = menuDataTmp[i].id;
        menuItem.enabled = (md.access_mode==="N"||md.enabled===0)?false:(md.user_id===1&&md.incl_ind==="E"?false:(md.role_id===0?false:true))
//        if(menuItem.enabled) {
            if(curLevel1MenuId>prevLevel1MenuId) { // insert into Level1
                let tmpItem = menuItem;
                menu.nested.push(tmpItem);
                console.log("getTreeMenu-menuItem-level1",tmpItem);
                console.log("getTreeMenu-menu-level1",menu);
            } else if(curLevel2MenuId>prevLevel2MenuId) { // insert into Level2
                let tmpItem = menuItem;
                menu.nested[curLevel1MenuId-1].nested.push(tmpItem)
                console.log("getTreeMenu-menuItem-level2",tmpItem);
                console.log("getTreeMenu-menu-level2",menu);
            } else if(curLevel1MenuId===prevLevel1MenuId&&curLevel2MenuId===prevLevel2MenuId&&(curLevel3MenuId-1)===prevLevel3MenuId) {  // sibling of level 3
                let tmpItem = menuItem;
                console.log("getTreeMenu-menuItem-level3,menu,prvId,curId",menu,prvId,curId);
                menu.nested[curLevel1MenuId-1].nested[curLevel2MenuId-1].nested.push(tmpItem);
                console.log("getTreeMenu-menuItem-level3",tmpItem);
                console.log("getTreeMenu-menu-level3",menu);
            }
//        }
    }
    return menu;
}
      

var menuData = [];
/*
[
    {"role_id": 0, "user_id": 0, "incl_ind": "E", "title": "Account and System Security", "path": "/accountandsecurity", "id": 10000, "enabled": 1, "access_mode": "N"},
    {"role_id": 1, "user_id": 1, "incl_ind": "I", "title": "User Account", "path": "/accountandsecurity/account", "id": 10100, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 1, "incl_ind": "I", "title": "Access Control", "path": "/accountandsecurity/accesscontrol", "id": 10200, "enabled": 1, "access_mode": "F"},
    {"role_id": 1, "user_id": 1, "incl_ind": "I", "title": "Access Control Manage", "path": "/accountandsecurity/accesscontrol/manage", "id": 10201, "enabled": 1, "access_mode": "F"},
    {"role_id": 1, "user_id": 1, "incl_ind": "I", "title": "Access Control Menu", "path": "/accountandsecurity/accesscontrol/menu", "id": 10202, "enabled": 1, "access_mode": "F"},
    {"role_id": 0, "user_id": 1, "incl_ind": "I", "title": "Group Management", "path": "/accountandsecurity/group_manager", "id": 10300, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 1, "incl_ind": "I", "title": "System Config and Maintenance", "path": "/sysconfigandmaintenance", "id": 20000, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 1, "incl_ind": "I", "title": "Lookup Table Setup", "path": "/sysconfigandmaintenance/lookuptablesetup", "id": 20100, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Billing and Accounting", "path": "/billingandaccouting", "id": 30000, "enabled": 0, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Billing", "path": "/billingandaccouting/billing", "id": 30100, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 1, "incl_ind": "I", "title": "Edit Invoice Status", "path": "/billingandaccouting/billing/editinvoicestatus", "id": 30101, "enabled": 1, "access_mode": "F"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Manage Billgroup", "path": "/billingandaccouting/billing/billgroupmanage", "id": 30102, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Invoice PDF Upload", "path": "/billingandaccouting/billing/invoicepdfupload", "id": 30103, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Charge Credit Card On-Hand", "path": "/billingandaccouting/billing/chargecreditcard", "id": 30104, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Blanket PO Summary", "path": "/billingandaccouting/billing/blanketposum", "id": 30105, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Record New PO", "path": "/billingandaccouting/billing/newpo", "id": 30106, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Invoices Without PO", "path": "/billingandaccouting/billing/invoicewithoutpo", "id": 30107, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Invoice Generating Tool", "path": "/billingandaccouting/billing/invoicegeneratingtool", "id": 30108, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Orders and Billing Stat", "path": "/billingandaccouting/billingstat", "id": 30200, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Customer Activity Report", "path": "/billingandaccouting/billingstat/customeractivityreport", "id": 30201, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Customer Without Order", "path": "/billingandaccouting/billingstat/customerwithoutorder", "id": 30202, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Not Invoiced Oligo Orders", "path": "/billingandaccouting/billingstat/notinvoiceoligoorders", "id": 30203, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Not Invoiced Cloning Orders", "path": "/billingandaccouting/billingstat/notinvoicecloningorers", "id": 30204, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Not Invoiced Sequencing Orders", "path": "/billingandaccouting/billingstat/notinvoicesequencingorders", "id": 30205, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Payment", "path": "/billingandaccouting/payment", "id": 30300, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Apply Payment", "path": "/billingandaccouting/payment/applypayment", "id": 30301, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Payment Summary", "path": "/billingandaccouting/payment/paymentSummary", "id": 30302, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Prepaid Accounts", "path": "/billingandaccouting/payment/prepaidaccounts", "id": 30303, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Revenue", "path": "/billingandaccouting/revenue", "id": 30400, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Daily Calculate Revenue", "path": "/billingandaccouting/revenue/dailycalculaterevenue", "id": 30401, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Payment Summary", "path": "/billingandaccouting/revenue/notperformingrevenuereport", "id": 30402, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Sanger Sequencing", "path": "/sangersequencing", "id": 40000, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Todays Pickup", "path": "/sangersequencing/todaypickup", "id": 40100, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Todays Order", "path": "/sangersequencing/todayorder", "id": 40200, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Order Sequencing", "path": "/sangersequencing/ordersequencing", "id": 40300, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "MyQB", "path": "/sangersequencing/myqb", "id": 40400, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Dashboard", "path": "/sangersequencing/dashboard", "id": 40500, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Order Submission", "path": "/sangersequencing/dashboard/ordersubmission", "id": 40501, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Productino BST QC", "path": "/sangersequencing/dashboard/productionbstqc", "id": 40502, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Production SF QC", "path": "/sangersequencing/dashboard/productionsfqc", "id": 40503, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Order Transform", "path": "/sangersequencing/dashboard/ordertransform", "id": 40504, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Sequencing Order Summary", "path": "/sangersequencing/dashboard/seqordersummary", "id": 40505, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Review Plate", "path": "/sangersequencing/dashboard/reviewplate", "id": 40506, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Manage Dropbox", "path": "/sangersequencing/dropboxmanage", "id": 40600, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Manage Pickup Route", "path": "/sangersequencing/managepickuproute", "id": 40700, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "This Month Not Done QC", "path": "/sangersequencing/thismonthnotdoneqc", "id": 40800, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Last Month Not Done QC", "path": "/sangersequencing/lastmonthnotdoneqc", "id": 40900, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Tools, Sample Sheets, and Templates", "path": "/sangersequencing/toolssamplesheetstemplates/seqa51", "id": 41000, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "SeqA5.1", "path": "/sangersequencing/toolssamplesheetstemplates/seqa51", "id": 41001, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "SeqScanner", "path": "/sangersequencing/toolssamplesheetstemplates/seqscanner", "id": 41002, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "PeakScanner", "path": "/sangersequencing/toolssamplesheetstemplates/peakscanner", "id": 41003, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Oligo", "path": "/oligo", "id": 50000, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Search Oligo Order", "path": "/oligo/search", "id": 50100, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Oligo Packing Slips", "path": "/oligo/oligopackingslips", "id": 50200, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Record Oligo Order", "path": "/oligo/recordoligoorder", "id": 50300, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Cloning", "path": "/oligo/recordoligoorder", "id": 60000, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Cloning Dashboard", "path": "/cloning/cloningdashboard", "id": 60100, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Search Cloning Order", "path": "/cloning/searchcloningorder", "id": 60200, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Record Cloning Order", "path": "/cloning/recordcloningorder", "id": 60300, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Qblock", "path": "/cloning/genefragment", "id": 60400, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "DNAprep", "path": "/dnaprep", "id": 70000, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Other Services", "path": "/otherservices", "id": 80000, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Tools and Resources", "path": "/toolsandresources", "id": 90000, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "General Forms", "path": "/toolsandresources/generalforms", "id": 90100, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Performance Review Form", "path": "/toolsandresources/generalforms/performancereviewform", "id": 90101, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Project Form and Process", "path": "/toolsandresources/generalforms/projectformandprocess", "id": 90102, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Purchasing Record Form", "path": "/toolsandresources/generalforms/purchaserecordform", "id": 90103, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Purchase Disburesement Policy", "path": "/toolsandresources/generalforms/purchasedisbursementpolicy", "id": 90104, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Site Daily P/L Form", "path": "/toolsandresources/generalforms/sitedailyplform", "id": 90105, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Site Monthly P/L Form", "path": "/toolsandresources/generalforms/sitemonthlyplform", "id": 90106, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "TimeSheet and Expense Form", "path": "/toolsandresources/generalforms/timesheetandexpenseform", "id": 90107, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Vacation Form", "path": "/toolsandresources/generalforms/vacationform ", "id": 90108, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Payroll Forms", "path": "/toolsandresources/payrollforms", "id": 90200, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "W-4", "path": "/toolsandresources/payrollforms/payrollformsw4", "id": 90201, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "W-9", "path": "/toolsandresources/payrollforms/payrollformsw9", "id": 90202, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "I-9", "path": "/toolsandresources/payrollforms/payrollformsi9", "id": 90203, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "SampleSheet Template", "path": "/toolsandresources/samplesheettemplates", "id": 90300, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "BD1.1 Template, by column", "path": "/toolsandresources/samplesheettemplates/bd11templatebycol", "id": 90301, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "BD1.1 Template, by row", "path": "/toolsandresources/samplesheettemplates/bd11templatebyrow", "id": 90302, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "BD3.1 Template, by column", "path": "/toolsandresources/samplesheettemplates/bd31templatebycol", "id": 90303, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "BD3.1 Template, by row", "path": "/toolsandresources/samplesheettemplates/bd31templatebyrow", "id": 90304, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Invoice Template", "path": "/toolsandresources/samplesheettemplates/invoicetemplate", "id": 90305, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Quotation Template", "path": "/toolsandresources/samplesheettemplates/quotationtemplate", "id": 90306, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "PO Template", "path": "/toolsandresources/samplesheettemplates/packingsliptemplate", "id": 90307, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Packing Slip Template", "path": "/toolsandresources/samplesheettemplates/packingsliptemplate", "id": 90308, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Payment Receipt Template", "path": "/toolsandresources/samplesheettemplates/paymentreceipttemplate", "id": 90309, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "CA Letter Head", "path": "/toolsandresources/samplesheettemplates/caletterhead", "id": 90310, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "MA Letter Head", "path": "/toolsandresources/samplesheettemplates/maletterhead", "id": 90311, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Plate Layout", "path": "/toolsandresources/samplesheettemplates/platelayout", "id": 90312, "enabled": 1, "access_mode": "R"},
    {"role_id": 1, "user_id": 0, "incl_ind": "E", "title": "Power Point Template", "path": "/toolsandresources/samplesheettemplates/powerpointtemplate", "id": 90313, "enabled": 1, "access_mode": "R"}
];
*/

export const getMenuData = ()=> {
    /*
    if(menuData===[]) {
        let input_parm = [{"request_row_count":0},{"offset_row":0},{"request_rows":999},{"app_id":1}];
        console.log('InternalPageBase retrieveData input_parm',input_parm);
        let tmp_data = retrieveApiData(1,10200,37,"usp_react_sql_retrieve2", JSON.stringify(input_parm));
        Promise.resolve(tmp_data).then((value)=>{
            setMenuData(value);
            return value;
        })
    } else 
    */
    return menuData;
}

export const setMenuData = (data)=> {
    menuData = data;
    if(data.length>0)
        menuItems = getTreeMenu();
}

export const getBuiltMenuItem = ()=> {
    return menuItems;
}

export default menuItems;


