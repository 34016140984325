
import React, {Component} from 'react';
//import '@progress/kendo-theme-default/dist/all.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './v2/css/bootstrap.min.css';
import "./qbio_style.css";

import {
    BrowserRouter as Router,
    useNavigate
  }


from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import "./styles.css";
import covid4a from "./v2/img/covid4a.png"
import cssDNA3 from "./v2/img/cssDNA3.png"
import ngs1 from "./v2/img/ngs1.png"
 
import WebSiteServiceIconLinks from "./WebSite_service_icon_links"
import WebSiteHomeDefaultNavigator from "./WebSiteHome_default_navigator"
import WebSiteHomeDefaultFooter from "./WebSiteHome_default_footer"
const WebSiteHome = ()=> {
  const navigate = useNavigate();
  const handleClick = (path) => {
      navigate(path);
  }  
     return (
        <>
			<WebSiteHomeDefaultNavigator />
            <hr />
        <header class="page-header header container-fluid">
            <div className="carousel slides ing-fluid">
                <Carousel>
                    <Carousel.Item interval={50000}>
                        <img class="d-block img-fluid w-100" src={ngs1} alt="active cases, from JHU" />
                        <Carousel.Caption>
                            <div class="container">
                                <div class="row justify-content-center text-center">
                                    <div class="col-8 bg-tagline d-none d-md-block py-3 mx-0">
                                        <h1>Next Generation Sequencing</h1>
                                        <h3 class="pb-3">Single Cell or Whole Genome </h3>
                                        <button class="bnt btn-lg btn-success"><a class="text-white" href="/service/ngs_services">Get Details</a></button>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={50000}>
                        <img class="d-block img-fluid w-100" src={cssDNA3} alt="right" />
                        <Carousel.Caption>
                            <div class="container">
                                <div class="row justify-content-center text-center">
                                    <div class="col-8 bg-tagline d-none d-md-block py-3 mx-0">
                                        <h1>Circular Single-Stranded DNA</h1>
                                        <h3 class="pb-3">CRISPR Donor Template</h3>
                                        <button class="bnt btn-lg btn-outline-info"><a class="text-info" href="/service/cssdna">Read More </a></button>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={50000}>
                        <img class="d-block img-fluid w-100" src={covid4a} alt="take control of COVID" />
                        <Carousel.Caption>
                            <div class="container">
                                <div class="row">
                                    <div class="col-8 bg-tagline d-none d-md-block py-3 mx-0">
                                        <h1>SARS-CoV-2 Genome Sequencing</h1>
                                        <h3 class="pb-3">Mutation Detection &amp; Lineage Report</h3>
                                        <button class="bnt btn-lg btn-danger"><a href="/service/covid">Get Started</a></button>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={50000}>
                        <img class="d-block img-fluid w-100" src={covid4a} alt="take control of COVID" />
                        <Carousel.Caption>
                            <div class="container">
                                <div class="row">
                                    <div class="col-8 bg-tagline d-none d-md-block py-3 mx-0">
                                        <h1>SARS-CoV-2 Genome Sequencing</h1>
                                        <h3 class="pb-3">Mutation Detection &amp; Lineage Report</h3>
                                        <button class="bnt btn-lg btn-danger"><a href="/service/covid">Get Started</a></button>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        </header>
            <div id="why-rbd" class="container why-rbd padding">
                <div class="row">
                    <div class="col-md-4 d-flex align-items-stretch">
                        <div class="content bg-dark">
                            <h3>Linear/Circular ssDNA?</h3>
                            <p>QuintaraBio provides high quality, sequence verified, endotoxin-free circular single stranded DNA (cssDNA), at µg scale, for length ranging from 200 nt to 10 kb.</p>
                                <button class="btn btn-light"><a class="text-dark" href="/service/cssdna">Quote</a></button>
                        </div>
                    </div>
                    <div class="col-md-8 d-flex align-items-stretch">
                        <div class="icon-boxes d-flex flex-column justify-content-center">
                            <div class="row">
                                <div class="col-lg-4 d-flex align-items-stretch">
                                    <div class="icon-box mt-4 mt-xl-0">
                                    <i class="bx bx-receipt"></i>
                                    <h4 class="text-success">ssDNA</h4>
                                    <p>donor templates for homology-directed repair in genome editing using CRISPR/Cas9 technology</p>
                                    </div>
                                </div>
                                <div class="col-lg-4 d-flex align-items-stretch">
                                    <div class="icon-box mt-4 mt-xl-0">
                                    <i class="bx bx-cube-alt"></i>
                                    <h4 class="text-success">cssDNA</h4>
                                    <p>More stable and less toxicity</p>
                                    <p>higher efficiency of integration </p>
                                    </div>
                                </div>
                                <div class="col-lg-4 d-flex align-items-stretch">
                                    <div class="icon-box mt-4 mt-xl-0">
                                    <i class="bx bx-images"></i>
                                    <h4 class="text-success">Applications</h4>
                                    <p>CAR-T Engineering</p>
                                    <p>Cell line Engineering</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            <hr />
            <>
                <WebSiteServiceIconLinks />
                <hr/>
                <WebSiteHomeDefaultFooter />
            </>

        </>
    )
}

export default WebSiteHome;

/*----------------------------------------------------------------
           <nav class="navbar navbar-expand-md navbar-light bg-light fixed-top padding">
                <div class="container-fluid d-flex align-items-center pt-3 mx-3">
                <a a onClick={()=>handleClick("/")} class="logo mr-auto">
                    <img src={logotransparent} alt="Quintara Biosciences" class="img-fluid" />
                </a>
                <nav class="nav-menu d-none d-lg-block">
                    <ul>
                        <li>
                            <a onClick={()=>handleClick("/")}>Home</a>                            
                        </li>
                        <li>
                            <a onClick={()=>handleClick("/about")}>About</a>
                        </li>
                        <li class="drop-down"><a onClick={()=>handleClick("/services")}>Services</a>
                            <ul>
                                <li><a onClick={()=>handleClick("/service/dna_sequencing")}>Sanger Sequencing</a></li>
                                <li><a onClick={()=>handleClick("/service/ngs_services")}>NGS Services</a></li>
                                <li><a onClick={()=>handleClick("/service/dna_maxiprep")}>DNAprep Services</a></li>
                                <li><a onClick={()=>handleClick("/service/oligo")}>Oligo Synthesis</a></li>
                                <li><a onClick={()=>handleClick("/service/covid")}>COVID-19 Sequencing</a></li>
                            </ul>
                        </li>
                        <li class="drop-down"><a onClick={()=>handleClick("/support")}>Tools</a>
                            <ul>
                                <li><a onClick={()=>handleClick("/support_faq")}>FAQ</a></li>
                                <li><a onClick={()=>handleClick("/qbtools/findPrimer")}>Search for QB Primers</a></li>
                            </ul>
                        </li>
                        <li><a onClick={()=>handleClick("/user/myqb")}>Order</a></li>
                        <li><a onClick={()=>handleClick("/contact")}>Contact</a></li>
                    </ul>
                </nav>
                <span><a onClick={()=>handleClick("/login")} class="login-btn">Login</a></span>
                </div>
            </nav>




           <div id="services" class="container">
                <div class="row text-center justify-content-between">
                    <div class="card">
                        <div class="card-head">
                            <a href="/service/ngs_services"> <img class="img-fluid" src={NGSred} /></a>
                        </div>
                        <div class="card-body">
                            <h4 class="d-none d-md-block"><a href="/service/ngs_services">NGS <br />Sequencing</a></h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-head">
                            <a href="/service/dna_sequencing"><img class="img-fluid" src={Sangerred} /></a>
                        </div>
                        <div class="card-body">
                            <h4 class="d-none d-md-block"><a href="/service/dna_sequencing">Sanger <br />Sequencing</a></h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-head">
                            <a href="/service/molecular_cloning"><img class="img-fluid" src={Cloningred} /></a>
                        </div>
                        <div class="card-body">
                            <h4 class="d-none d-md-block"><a href="/service/molecular_cloning">Cloning <br />Services</a></h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-head">
                            <a href="/service/oligo"><img class="img-fluid" src={Oligored} /></a>
                        </div>
                        <div class="card-body">
                            <h4 class="d-none d-md-block"><a href="/service/oligo"> Oligo <br />Synthesis</a></h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-head">
                            <a href="/service/dna_maxiprep"><img class="img-fluid" src={DNAprepred} /></a>
                        </div>
                        <div class="card-body">
                            <h4 class="d-none d-md-block"><a href="/service/dnamaxi_prep">DNAprep <br />Services</a></h4>
                        </div>
                    </div>
                </div>
            </div>
            */