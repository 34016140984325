import React, {useState, useEffect} from 'react';
import {ResponseWindow, ResponseWindowOk,ResponseWindowYesNo} from '../../../Modals';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//import zeditbox1 from 'zeditbox1';
import ZDropdownList from '../../../zcomponents/ZDropdownList';
import ZEditText from '../../../zcomponents/ZEditText';
import ZEditNumber from '../../../zcomponents/ZEditNumber';
import ZTextButton from '../../../zcomponents/ZTextButton';
import ZCheckbox from '../../../zcomponents/ZCheckbox';
import ZAutoCompleteEditText from '../../../zcomponents/ZAutoCompleteEditText';
import {zoftApiForUsp,retrieveApiData} from '../../../data_retrieval';

import ApplyPaymentListDataGrid from './applypaymentlistdatagrid';
import ApplyPaymentDetailDataGrid from './applypaymentdetaildatagrid';//set @input='[{"request_row_count":1},{"offset_row":0},{"request_rows":99999},{"invoice_id":0},{"invoice_status_id":0},{"billgroup":""},{"category_id":0},{"institution":""},{"dateStart":"2021-01-01"},{"dateEnd":"2022-10-31"},{"po":""},{"recalculate_totaldue":0},{"q_maxonly":0},{"exclude_paid":0},{"exclude_cancelled":0},{"exclude_nih":0}]';
import ApplyPaymentInvoiceListDataGrid from './applypaymentinvoicelistdatagrid';

const ApplyPaymentDataPage  = ()=> {
    const type_string = 1;
    const type_number = 0;
    
    const [modalTitle, setModalTitle] = useState("Warning Message");
    const [showModal, setShowModal] = useState(false);
    const [showModalOk, setShowModalOk] = useState(false);
    const [showModalYesNo, setShowModalYesNo] = useState(false);
    const [response, setResponse] = useState(-1);
    const [update_invoicestatusid, setUpdate_invoicestatusid] = useState(0);
    const [cost_center_list, setCost_center_list] = useState([]);
    const [institution_list, setInstitution_list] = useState([]);
    const [payment_type_list, setPayment_type_list] = useState([]);
    const [invoice_status_list, setInvoice_status_list] = useState([]);
    const [billgroup_list, setBillgroup_list] = useState([]);
    const [category_list, setCategory_list] = useState([]);
    const [action_list, setAction_list] = useState([]);
    const [delete_invoice_list,setDelete_invoice_list] = useState([]);
    const [total_payments_retrieved, setTotal_payments_retrieved] = useState(0);
    const [search_clicked, setSearch_clicked] = useState(false);
    const [prev_clicked, setPrev_clicked] = useState(false);
    const [next_clicked, setNext_clicked] = useState(false);
   const [retrieve_clicked, setRetrieve_clicked] = useState(false);
    const [new_record_in_detail, setNew_record_in_detail] = useState(false);
    const [update_mode,setUpdate_mode] = useState(0);
    const [update_mode_invoice,setUpdate_mode_invoice] = useState(false);
    const [new_record_inserted_or_updated,setNew_record_inserted_or_updated] = useState(false);
    const [deleting, setDeleting] = useState({"request_deleting":false,"deleted":false,"post_processed":false,"i":-1});
    const [dataCount, setDataCount] = useState(0);
    const [current_invoice_id, setCurrent_invoice_id] = useState(0);
    const [current_payment_id, setCurrent_payment_id] = useState(0);
    const [current_payment_id_from_detail, setCurrent_payment_id_from_detail] = useState(0);
    const [current_payment_id_from_list, setCurrent_payment_id_from_list] = useState(0);
    const [dd_access, setDd_access] = useState({});

    let yesNoSelection = [{id:-1,name:""},{id:0,name:"No"},{id:1,name:"Yes"}];    

    const [current_data_from_list,setCurrent_data_from_list] = useState({
        i:0,
        data:
        {
			"id": 0,"name":"","pi_id":0,"amount":0,"fee":0,"tax":0,"credits":0,"total_due":0,"est_total_due":0,"due_date":"","invoice_date":"",
			"cur_status_id":0,"billgroup_id":0,"user_id":0,"category_id":0,"product_id":0,"po":"","notes":"","verified":0,"shipping":0,"time_created":"",
        }
    });
    const [current_data_from_detail,setCurrent_data_from_detail] = useState({
        new_record_inserted_or_updated:false,
        i:-1,
        data:
        {
			"id": 0,"name":"","pi_id":0,"amount":0,"fee":0,"tax":0,"credits":0,"total_due":0,"est_total_due":0,"due_date":"","invoice_date":"",
			"cur_status_id":0,"billgroup_id":0,"user_id":0,"category_id":0,"product_id":0,"po":"","notes":"","verified":0,"shipping":0,"time_created":"",
        }
    });
    let input_parm01 = {
        "request_row_count":1,
        "offset_row":0,
        "request_rows":1000,
        "payment_id":0,
        "cost_center_id":0,
        "payment_type_id":0,
        "greater_amount":0,
        "less_amount":0,
        "dateStart":"",
        "dateEnd":"",
    }
    let input_parm02 = {
        "request_row_count":1,
        "offset_row":0,
        "request_rows":9999,
        "unpaid_invoices_only":0,
        "paid_invoices_without_payment":0,
        "invoices_ids":"",
        "payment_type_id":0,
    }
    const [input_parm1, setInput_parm1] = useState({
        "request_row_count":1,
        "offset_row":0,
        "request_rows":1000,
        "payment_id":0,
        "cost_center_id":0,
        "payment_type_id":0,
        "greater_amount":0,
        "less_amount":0,
        "dateStart":"",
        "dateEnd":"",
        "start_date":"",
        "end_date":"",
    })
    const [input_parm2, setInput_parm2] = useState({
        "request_row_count":1,
        "offset_row":0,
        "request_rows":9999,
        "unpaid_invoices_only":0,
        "paid_invoices_without_payment":0,
        "invoices_ids":"",
        "payment_type_id":0,
    })

    const [payment_retrieved, setPayment_retrieved] = useState({
        "from_row":0,
        "to_row":0,
        "total_rows":0,
    }
    );
    const setPayment_prev_list=(rows)=>{
        if(rows>0) {
            let new_from_row = payment_retrieved.from_row - rows
            if(new_from_row<1)
                new_from_row = 1
            let new_to_row = new_from_row + rows - 1
            let retrieved_tmp = payment_retrieved
            retrieved_tmp.from_row = new_from_row
            retrieved_tmp.to_row = new_to_row
            setPayment_retrieved({...retrieved_tmp})
            console.log("BillgroupManageDataPage setPayment_prev_list rows, retrieved_tmp",rows, retrieved_tmp)
        }
    }
    const setPayment_next_list=(rows)=>{
        if(rows>0) {
            let new_from_row = payment_retrieved.to_row + 1
            let new_to_row = new_from_row + rows - 1
            let retrieved_tmp = payment_retrieved
            retrieved_tmp.from_row = new_from_row
            retrieved_tmp.to_row = new_to_row
            setPayment_retrieved({...retrieved_tmp})
            console.log("BillgroupManageDataPage-setPayment_next_list rows retrieved_tmp",rows,retrieved_tmp)
        }
    }
    const setPayment_search_list=(rows,total_rows)=>{
        if(rows>0) {
            let new_from_row = 1
            let new_to_row = rows
            let retrieved_tmp = payment_retrieved
            retrieved_tmp.from_row = new_from_row
            retrieved_tmp.to_row = new_to_row
            retrieved_tmp.total_rows =total_rows
            setPayment_retrieved({...retrieved_tmp})
    //            setInvoice_retrieved({"invoice_from_row":new_from_row,"invoice_to_row":new_to_row,"total_invoice_rows":total_rows})
            console.log("BillgroupManageDataPage-setPayment_search_list rows,total_rows",retrieved_tmp)
        }
    }


    const getDataCount=(count)=>{
        setDataCount(count);
    }
    const getCurrentDataFromList = (a_data)=>{
        console.log("ApplyPaymentDataPage-getCurrentDataFromList-a_data", a_data);
        setCurrent_data_from_list(a_data)
        console.log("ApplyPaymentDataPage-getCurrentDataFromList-current_data", current_data_from_list);
    }
    const getCurrentDataFromDetail = (a_data)=>{
        console.log("ApplyPaymentDataPage-getCurrentDataFromDetail-a_data", a_data);
        setCurrent_data_from_detail({new_record_inserted_or_updated:a_data.new_record_inserted_or_updated,
            i:a_data.i,data:{...a_data.data}})
        console.log("ApplyPaymentDataPage-getCurrentDataFromDetail-current_data", current_data_from_detail);
    }

    const onChangeTextHandler1 =(text,field_name,parm_type) => {
        console.log("ApplyPaymentDataPage-onChangeTextHandler 1,field_name,text",field_name, text)
        let input_parm_tmp = input_parm1;
        if(parm_type===type_number)
            input_parm_tmp[field_name] = parseInt(text);
        else
            input_parm_tmp[field_name] = text;
        if(field_name==="billgroup") {
            console.log("ApplyPaymentDataPage-onChangeTextHandler,text",text)
            let input_data = '{"id":16,"search":"' + text + '"}'
            let tmp_data = retrieveApiData(1,30101,0,"usp_react_autocomplete_retrieve", input_data);
            Promise.resolve(tmp_data).then((value)=>{
                try {
                    console.log("ApplyPaymentDataPage-onChangeTextHandler,value",value)
                    setBillgroup_list(JSON.parse(value));
                } catch (e) {
                    console.log('Not JSON');
                }
            })
		} else if(field_name==="institution") {
//            setInstitution(text)
            let input_data = '{"id":15,"search":"' + text + '"}'
            console.log("ApplyPaymentDataPage-onChangeTextHandler,text",text)
            let tmp_data = retrieveApiData(1,30101,0,"usp_react_autocomplete_retrieve", input_data);
            Promise.resolve(tmp_data).then((value)=>{
                try {
                    console.log("ApplyPaymentDataPage-onChangeTextHandler,value",value)
                    setInstitution_list(JSON.parse(value))
                } catch (e) {
                    console.log('Not JSON');
                }
            })
		}
        setInput_parm2({...input_parm_tmp});
        console.log("ApplyPaymentDataPage-onChangeTextHandler,field_name,text",field_name, text)
	}
    const onChangeTextHandler2 =(text,field_name,parm_type) => {
        console.log("ApplyPaymentDataPage-onChangeTextHandler 1,field_name,text",field_name, text)
        let input_parm_tmp = input_parm2;
        if(parm_type===type_number)
            input_parm_tmp[field_name] = parseInt(text);
        else
            input_parm_tmp[field_name] = text;
        if(field_name==="billgroup") {
//            setBillgroup(text)
            console.log("ApplyPaymentDataPage-onChangeTextHandler,text",text)
            let input_data = '{"id":16,"search":"' + text + '"}'
            let tmp_data = retrieveApiData(1,30101,0,"usp_react_autocomplete_retrieve", input_data);
            Promise.resolve(tmp_data).then((value)=>{
                try {
                    console.log("ApplyPaymentDataPage-onChangeTextHandler,value",value)
                    setBillgroup_list(JSON.parse(value));
                } catch (e) {
                    console.log('Not JSON');
                }
            })
		} else if(field_name==="institution") {
//            setInstitution(text)
            let input_data = '{"id":15,"search":"' + text + '"}'
            console.log("ApplyPaymentDataPage-onChangeTextHandler,text",text)
            let tmp_data = retrieveApiData(1,30101,0,"usp_react_autocomplete_retrieve", input_data);
            Promise.resolve(tmp_data).then((value)=>{
                try {
                    console.log("ApplyPaymentDataPage-onChangeTextHandler,value",value)
                    setInstitution_list(JSON.parse(value))
                } catch (e) {
                    console.log('Not JSON');
                }
            })
		}
        setInput_parm2({...input_parm_tmp});
        console.log("ApplyPaymentDataPage-onChangeTextHandler,field_name,text",field_name, text)
	}
    const onClickCheckbox1 =(field_name)=> {
        let input_parm_tmp = input_parm1;
        let the_value = input_parm1[field_name];
        the_value = the_value===0?1:0;
        input_parm_tmp[field_name] = the_value;
        setInput_parm1({...input_parm_tmp});
    }
    const onClickCheckbox2 =(field_name)=> {
        let input_parm_tmp = input_parm2;
        let the_value = input_parm2[field_name];
        the_value = the_value===0?1:0;
        input_parm_tmp[field_name] = the_value;
        setInput_parm2({...input_parm_tmp});
    }

    const onButtonClick = (button_name)=> {
        if(button_name==="Search") {
            let filter_selected = true;
            if(search_clicked===true) {
                setModalTitle("Wait! Data are being retrieved!");
                setShowModalOk(true);
            } else {
                setSearch_clicked(true);
                // default the data.i to -1 before retrieving
                setCurrent_data_from_list({i:-1,data:current_data_from_list.data})
            }
        } else if(button_name==="Prev") {
            if(payment_retrieved.total_rows<=1000||payment_retrieved.from_row<1000) { 
                // ignore it
            } else {
                setPrev_clicked(true)
            }
        } else if(button_name==="Next") {
            if(payment_retrieved.total_rows<=1000||payment_retrieved.to_row===payment_retrieved.total_rows) { 
                // ignore it
            } else {
                setNext_clicked(true)
            }
        } 
    }

    const onButtonClick1 = (button_name)=> {
        if(button_name==="Clear Form") {
            setInput_parm1({...input_parm01});
        } else if(button_name==="Search Payments") {
            if(input_parm1===input_parm01) {
                setModalTitle("No Search Parameters Specified!");
                setShowModal(true);
            } else if(search_clicked===true) {
                setModalTitle("Wait! Data are being retrieved!");
                setShowModal(true);
            } else {
                console.log("ApplyPaymentDataPage-onChangeTextHandle - Search_clicked input_parm",input_parm1)
                setSearch_clicked(true);
            }
        } 
    }
    const onButtonClick2 = (button_name)=> {
        if(button_name==="Clear Form") {
            setInput_parm2({...input_parm02});
        } else if(button_name==="Search Invoices") {
            if(input_parm2===input_parm02) {
                setModalTitle("No Search Parameters Specified!");
                setShowModal(true);
            } else if(search_clicked===true) {
                setModalTitle("Wait! Data are being retrieved!");
                setShowModal(true);
            } else {
                console.log("ApplyPaymentDataPage-onChangeTextHandle - Search_clicked input_parm",input_parm2)
                setSearch_clicked(true);
            }
        } 
    }

    const handleStartDateChange1 =(date)=> {
        let input_parm_tmp = input_parm1;
        input_parm_tmp.dateStart = date;
        input_parm_tmp.start_date = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate()
        setInput_parm1({...input_parm_tmp});
    }
   
    const handleEndDateChange1 =(date)=> { 
        let input_parm_tmp = input_parm1;
        input_parm_tmp.dateEnd = date;
        input_parm_tmp.end_date = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate()

        setInput_parm1({...input_parm_tmp});
    }
    const handleStartDateChange2 =(date)=> {
        let input_parm_tmp = input_parm2;
        input_parm_tmp.dateStart = date;
        setInput_parm2({...input_parm_tmp});
    }
   
    const handleEndDateChange2 =(date)=> { 
        let input_parm_tmp = input_parm2;
        input_parm_tmp.dateEnd = date;
        setInput_parm2({...input_parm_tmp});
    }	
    useEffect(() => {
        let input_data1 = '[{"request_row_count":0},{"offset_row":0},{"request_rows":99999}]';
        let tmp_data1 = retrieveApiData(1,30301,40,"usp_react_sql_retrieve2", input_data1);
        Promise.resolve(tmp_data1).then((value)=>{
            try {
                console.log("BillGroupManageDataPage-useEffect,40-value",value)
                setCost_center_list(JSON.parse(value))
            } catch (e) {
                console.log('Not JSON');
            }
        })
        let input_data2 = '[{"request_row_count":0},{"offset_row":0},{"request_rows":99999}]';
        let tmp_data2 = retrieveApiData(1,30301,41,"usp_react_sql_retrieve2", input_data2);
        Promise.resolve(tmp_data2).then((value)=>{
            try {
                console.log("BillGroupManageDataPage-componentDidMount,value",value)
                setInstitution_list(JSON.parse(value))
            } catch (e) {
                console.log('Not JSON');
            }
        })
        console.log("ApplyPaymentDataPage-componentDidMount")
        let input_data = '[{"request_row_count":0},{"offset_row":0},{"request_rows":9999},{"type":"Billing"}]'
        let tmp_data3 = retrieveApiData(1,30101,49,"usp_react_sql_retrieve2", input_data);
        Promise.resolve(tmp_data3).then((value)=>{
            try {
                console.log("ApplyPaymentDataPage-componentDidMount,value",value)
                setPayment_type_list(JSON.parse(value))
            } catch (e) {
                console.log('Not JSON');
            }
        })
        let input_data4 = '[{"request_row_count":0},{"offset_row":0},{"request_rows":9999},{"gui_id":30101}]'
        let tmp_data4 = retrieveApiData(1,30101,57,"usp_react_sql_retrieve2", input_data4);
        Promise.resolve(tmp_data4).then((value)=>{
            try {
                console.log("ApplyPaymentDataPage-useEffect[],tmp_data4-value",value)
                let return_data = JSON.parse(value)
                let tmp_data = {}
                for(let i = 0; i < return_data.length;i++)
                    tmp_data[return_data[i].field_id] = return_data[i].access_mode
                console.log("ApplyPaymentDataPage-useEffect[],tmp_data",tmp_data)
                setDd_access(tmp_data)
            } catch (e) {
                console.log('Not JSON');
            }
        })
    },[]);
	useEffect(() => {
        if(response===1) {
            setResponse(-1)
            if(deleting.request_deleting===true) {
                setDeleting({"request_deleting":false,"deleted":deleting.deleted,"post_processed":deleting.post_processed,i:deleting.i})
                if(current_data_from_list.i>-1) {
                    let the_input = [{"id":current_data_from_list.data.id}];
                    let api_return_data = zoftApiForUsp(1,30102,39,0,0,"usp_react_sql_delete2", JSON.stringify(the_input));
                    Promise.resolve(api_return_data).then((value)=>{
                        console.log('BillGroupManageDataPage-useEffect(() [response], value', value);
                        if(value.return_status===0) { //normal return
                            console.log('BillGroupManageDetailDataGrid-useEffect value.return_data', value.return_data);
                            if(value.return_data.success) {
                                // successfully delete a record, delete the current row from the list grid, and re-select i-1 row as current row
                                console.log('BillGroupManageDetailDataGrid-useEffect value.return_data.output.data',value.return_data.output);
    //                            props.getCurrentDataFromDetail({new_record_inserted_or_updated:true,i:props.current_data_from_list.i, data:detail_data.data});
                                setDeleting({"request_deleting":false,"deleted":true,"post_processed":deleting.post_processed,i:deleting.i})
                            } else {
                                setModalTitle("Delete Error:" + value.return_data.message + "!");
                                setShowModalOk(true);
                            };
                        } else {
                            setModalTitle("Unspecified error, please report it to tech support!");
                            setShowModalOk(true);
                            console.log('BillGroupManageDetailDataGrid-onBlurTextHandler value.return_data', value.return_data);
                        }
                    }) 
                }
            }
        }
    },[response]);
    return  (
        <div style={{width:1500,height:1050,backgroundColor:"#ffffff"}}>
            <ZEditText id="03010101"
                x={20} y={0} width={100} height={70} whole_background_color="#ffffff"
                label="Payment ID" label_x={0} label_y={0} label_width={100} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                button_x={0} button_y={20} button_width={100} button_height={24} button_color="#000000" button_background_color="ffffff"
                value={input_parm1.payment_id}
                onchange_func = {(text) => { onChangeTextHandler1(text,"payment_id",type_number)}}
                onBlur= {() => {}}
            />
            <ZDropdownList id="03010102"
                x={130} y={0} width={125} height={70} whole_background_color="#ffffff"
                label="Cost Center" label_x={0} label_y={0} label_width={125} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                button_x={0} button_y={20} button_width={150} button_height={24} button_color="#000000" button_background_color="ffffff"
                value={input_parm1.cost_center_id}
                onchange_func = {(text) => { onChangeTextHandler1(text,"cost_center_id",type_number)}}
                onBlur= {() => {}}
                dataList={cost_center_list}
            />
            <ZDropdownList id="03010103"
                x={290} y={0} width={125} height={70} whole_background_color="#ffffff"
                label="Payment Method" label_x={0} label_y={0} label_color="#000000" label_background_color="#ffffff"
                button_x={0} button_y={20} button_width={125} button_height={24} button_color="#000000" button_background_color="ffffff"
                value={input_parm1.payment_type_id}
                onchange_func = {(text) => { onChangeTextHandler1(text,"payment_type_id",type_number)}}
                onBlur= {() => {}}
                dataList={payment_type_list}
            />
            <ZEditNumber id="03010104"
                x={425} y={0} width={100} height={70} whole_background_color="#ffffff"
                label=">= Amount" label_x={0} label_y={0} label_color="#000000" label_background_color="#ffffff"
                button_x={0} button_y={20} button_width={100} button_height={24} button_color="#000000" button_background_color="ffffff"
                value={input_parm1.greater_amount}
                onchange_func = {(text) => { onChangeTextHandler1(text,"greater_amount",type_number)}}
                onBlur= {() => {}}
            />
            <ZEditNumber id="03010105"
                x={535} y={0} width={80} height={70} whole_background_color="#ffffff"
                label="<= Amount" label_x={0} label_y={0} label_color="#000000" label_background_color="#ffffff"
                button_x={0} button_y={20} button_width={100} button_height={24} button_color="#000000" button_background_color="ffffff"
                value={input_parm1.less_amount}
                onchange_func = {(text) => { onChangeTextHandler1(text,"less_amount",type_number)}}
                onBlur= {() => {}}
            />
            <div id="03010106" style={{position:"absolute",left:645,top:0,width:150,height:70,backgroundColor:"#ffffff"}}>
                <scan style={{position:"absolute",left:0,top:0}}>
                    Start Date
                </scan>
                <div  style={{position:"absolute",left:0,top:20,width:100,height:30}}>
                    <DatePicker
                            selected={input_parm1.dateStart}
                            onChange={handleStartDateChange1}
                            dateFormat="MM/dd/yyyy"
                    />
                </div>
            </div>
            <div id="03010107" style={{position:"absolute",left:825,top:0,width:150,height:70,backgroundColor:"#ffffff"}}>
                <scan style={{position:"absolute",left:0,top:0}}>
                    End Date
                </scan>
                <div style={{position:"absolute",left:0,top:20,width:100,height:30}}>
                    <DatePicker
                        selected={input_parm1.dateEnd}
                        onChange={handleEndDateChange1}
                        dateFormat="MM/dd/yyyy"
                    />
                </div>
            </div>
            <ZTextButton id="03010109"
                x={1050} y={15} width={125} height={30} whole_background_color="#ffffff" 
                border_style="1px solid #000000"
                button_text="Search Payments" label_background_color="#ffffff"
                button_font_color="#000000" button_background_color="#ffffff"
                onButtonClick = {() => { onButtonClick1("Search Payments")}}
                onBlur= {() => {}}
            />
            <ZTextButton id="03010117"
                x={1190} y={15} width={125} height={30} whole_background_color="#ffffff" 
                border_style="1px solid #000000"
                button_text="Clear Form" label_background_color="#ffffff"
                button_font_color="#000000" button_background_color="#ffffff"
                onButtonClick = {() => { onButtonClick1("Clear Form")}}
            />
            <div id="03010118" style={{position:"absolute",left:20,top:48,width:290,height:40, borderBottom:"0px solid black"}}>
                <scan style={{position:"absolute",left:5,top:2,width:290,height:15}} >
                    {payment_retrieved.total_rows} total records  {payment_retrieved.from_row} - {payment_retrieved.to_row}
                </scan>
                <ZTextButton id="03010117"
                    x={250} y={2} width={60} height={30} whole_background_color="#ffffff" 
                    border_style="0px solid #000000"
                    button_text="Prev" label_background_color="#ffffff"
                    button_font_color="#000000" button_background_color="#ffffff"
                    onButtonClick = {() => { onButtonClick("Prev")}}
                />
                <ZTextButton id="03010117"
                    x={300} y={2} width={60} height={30} whole_background_color="#ffffff" 
                    border_style="0px solid #000000"
                    button_text="Next" label_background_color="#ffffff"
                    button_font_color="#000000" button_background_color="#ffffff"
                    onButtonClick = {() => { onButtonClick("Next")}}
                />
            </div>

            <div style={{position:"absolute",left:20,top:75,width:1350,height:300}}>
                <ApplyPaymentListDataGrid
                    cost_center_list={cost_center_list}
                    institution_list={institution_list}
                    payment_type_list={payment_type_list}
                    current_data_from_detail={current_data_from_detail}
                    new_record_inserted_or_updated={new_record_inserted_or_updated}
                    search_clicked={search_clicked}
                    prev_clicked={prev_clicked}
                    next_clicked={next_clicked}
                    retrieve_clicked={retrieve_clicked}
                    input_parm={input_parm1}
                    new_record_in_detail={new_record_in_detail}
                    update_mode={update_mode}
                    deleting={deleting}
                    update_invoicestatusid={input_parm1.update_invoicestatusid}
                    invoice_status_list={invoice_status_list}
                    payment_retrieved={payment_retrieved}

                    getCurrentDataFromList={(data)=>{getCurrentDataFromList(data)}}
                    getCurrentPaymentIdFromList={(id)=>{console.log("ApplyPaymentDataPage id",id);setCurrent_payment_id(id);setCurrent_payment_id_from_list(id)}}
                    getDataCount={(count)=>{getDataCount(count)}}
                    newRecordInserted={(a_inserting)=>{setNew_record_inserted_or_updated(a_inserting)}}
                    resetDeleting={()=>{setDeleting({"request_deleting":deleting.request_deleting,"deleted":deleting.deleted,"post_processed":true,"i":deleting.i})}}
                    getRetrievedSearch={(retrieved_rows,total_rows) =>{setSearch_clicked(false);setPayment_search_list(retrieved_rows,total_rows);console.log("EditInvoiceStatusDataPage-retrieved, search_clicked",search_clicked)}}
                    getRetrievedPrev={(retrieved_rows) =>{setPrev_clicked(false);setPayment_prev_list(retrieved_rows);}}
                    getRetrievedNext={(retrieved_rows) =>{setNext_clicked(false);setPayment_next_list(retrieved_rows);}}
                />
            </div>
            <div style={{position:"absolute",left:20,top:370,width:1350,height:155}}>
                <ApplyPaymentDetailDataGrid
                    status_list={invoice_status_list}
                    institution_list={institution_list}
                    billgroup_list={billgroup_list}
                    category_list={category_list}
                    current_payment_id_from_list={current_payment_id_from_list}
                    current_data_from_list={current_data_from_list}
                    delete_invoice_list={delete_invoice_list}
                    dd_access={dd_access}
                    resetDeleteInvoiceList={()=>setDelete_invoice_list([])}
                    resetCurrentPaymentId={(a_payment_id)=>{setCurrent_payment_id(0);setCurrent_payment_id(a_payment_id)}}
                    getCurrentDataFromDetail={(data)=>{getCurrentDataFromDetail(data)}}
                    newRecordInserted={(a_inserting)=>{setNew_record_in_detail(a_inserting)}}
                    set_update_mode={(a_mode)=>{setUpdate_mode(a_mode)}}
                    deleting={deleting}
                    resetDeleting={()=>{setDeleting({"request_deleting":deleting.request_deleting,"deleted":deleting.deleted,"post_processed":true,"i":deleting.i})}}
                />
            </div>
            <ZCheckbox id="03010112"
                x={50} y={550} width={200} height={50} whole_background_color="#ffffff"
                label="Unpaid Invoices Only" label_x={10} label_y={0} label_width={200} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                button_x={0} button_y={0} button_width="18px" button_height="18px" button_color="#000000" button_background_color="ffffff"
                value={input_parm2.unpaid_invoices_only}
                onClickCheckbox = {() => { onClickCheckbox2("unpaid_invoices_only")}}
                font_size={14} font_weight="normal"
            />
            <ZCheckbox id="03010113"
                x={230} y={550} width={250} height={50} whole_background_color="#ffffff"
                label="Paid Invoices Without Payment" label_x={10} label_y={0} label_width={250} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                value={input_parm2.paid_invoices_without_payment}
                button_x={0} button_y={0} button_width="18px" button_height="18px" button_color="#000000" button_background_color="ffffff"
                onClickCheckbox = {() => { onClickCheckbox2("paid_invoices_without_payment")}}
                font_size={14} font_weight="normal"
            />

            <ZEditText id="03010101"
                x={480} y={530} width={200} height={75} whole_background_color="#ffffff"
                label="Invoice Ids (,separated)" label_x={0} label_y={0} label_width={200} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                button_x={0} button_y={20} button_width={200} button_height={24} button_color="#000000" button_background_color="ffffff"
                value={input_parm2.invoices_ids}
                onchange_func = {(text) => { onChangeTextHandler2(text,"invoices_ids",type_string)}}
                onBlur= {() => {}}
            />
            <ZAutoCompleteEditText id="03010103"
                x={710} y={530} width={150} height={75} whole_background_color="#ffffff"
                label="Billgroup Name" label_x={0} label_y={0} label_color="#000000" label_background_color="#ffffff"
                button_x={0} button_y={20} button_width={150} button_height={24} button_color="#000000" button_background_color="ffffff"
                value={input_parm2.payment_type_id}
                onchange_func = {(text) => { onChangeTextHandler2(text,"billgroup",type_string)}}
                onBlur= {() => {}}
                dataList={billgroup_list}
            />

            <ZTextButton id="03010117"
                x={900} y={545} width={125} height={30} whole_background_color="#ffffff" 
                border_style="1px solid #000000"
                button_text="Clear Form" label_background_color="#ffffff"
                button_font_color="#000000" button_background_color="#ffffff"
                onButtonClick = {() => { onButtonClick2("Clear Form")}}
            />
            <ZTextButton id="03010109"
                x={1050} y={545} width={125} height={30} whole_background_color="#ffffff" 
                border_style="1px solid #000000"
                button_text="Search Invoices" label_background_color="#ffffff"
                button_font_color="#000000" button_background_color="#ffffff"
                onButtonClick = {() => { onButtonClick2("Search Invoices")}}
                onBlur= {() => {}}
            />
            <ZCheckbox id="030102detail34"
                x={1200} y={545} width={150} height={30} whole_background_color="#ffffff" 
                label="Update Mode" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#ff0000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={25} button_height={25} button_color="#000000" button_background_color="ffffff"
                value={update_mode_invoice===true?1:0}
                onClickCheckbox = {() => {setUpdate_mode_invoice(update_mode_invoice===true?false:true)}}
                onBlur= {() => {}}
            />
            <div style={{position:"absolute",left:20,top:580,width:1350,height:200}}>
                <ApplyPaymentInvoiceListDataGrid
                    payment_id={current_payment_id}
                    update_mode_invoice={update_mode_invoice}
                    dd_access={dd_access}
                    getInvoiceId={(invoice_id)=>{setCurrent_invoice_id(invoice_id);console.log("ApplyPaymentDataPage-retrieved, invoice_id",invoice_id)}}
                    getDeleteInvoiceList={(a_invoice_list)=>{setDelete_invoice_list(a_invoice_list)}}
               />
            </div>
        </div>

    );
}

export default ApplyPaymentDataPage;

