import React, {Component,useEffect,useState} from 'react';
import InternalPageBase from "../../../InternalPageBase"
import BillGroupManageDataPage from "./billgroupmanagedatapage"
import getToken,{setToken} from '../../../GetSetToken';
import {setMenuData} from '../../../InternaPageTreeItems';
import {useLocation,useNavigate} from "react-router-dom";

var tokenAndmenuIsNotReady = true;
const InternalPageBillGroupManage = (props)=> {
    const location = useLocation();
    const navigate = useNavigate();
    console.log("InternalPageBilling location.state",location.state)
//    const [tokenAndmenuIsNotReady, setTokenAndmenuIsNotReady] = useState(true);

    if(location!=null) {
        if(location.state!=null) {
            setToken(location.state.token);
            setMenuData(location.state.menuData);
            tokenAndmenuIsNotReady = false;
        } 
    } 
    if(tokenAndmenuIsNotReady===true) {
        if(getToken()!="0") {
            tokenAndmenuIsNotReady = false;
        }
    }
    useEffect(() => {
        if(tokenAndmenuIsNotReady===true) {
            navigate("/sign-in");
            let url = window.location.href
            if(url.indexOf("#") ===-1)
                window.location.href = url + '?#'
        }
    },[]);  
    return (
        <>
            {tokenAndmenuIsNotReady===true?
            <div>dummy</div>
            :
            <InternalPageBase menu_id={3} sub_menu_id={30102} data_component={<BillGroupManageDataPage />} paneHeight={800} />
            }
            </>
    )
}

export default InternalPageBillGroupManage;

