
const getApiUrl =() => {
    let the_url = "http://localhost:8082";
    
//    let the_url = process.env.REACT_APP_DEV_PROXY_URL;

//    let test_url = window.location.href;
    if(process.env.NODE_ENV!=='development') {
        the_url = "http://turbo.quintarabio.com:8082";
        /*
        if(test_url.includes("52.71.73.198")) {
            the_url = process.env.REACT_APP_QA_PROXY_URL;
        } else if(test_url.includes("turbo.")) {
            the_url = process.env.REACT_APP_STAG_PROXY_URL;
        } else if(test_url.includes("spa.")) {
            the_url = process.env.REACT_APP_PROD_PROXY_URL;
        }
        */
    }
//    return process.env.NODE_ENV==='development'?process.env.REACT_APP_DEV_PROXY_URL:process.env.REACT_APP_QA_PROXY_URL;
//    the_url = "http://localhost:8081";
    
//    alert("getApiUrl:" + the_url);
    return the_url;
}
export const getPassthroughUrl = ()=> {return getApiUrl() + '/api2/React2/Usp_react_token_based_pass_through'}
export const getPassthroughUrl2 = ()=> {return getApiUrl() + '/api2/React2/Usp_react_token_based_pass_through2'}
export default getApiUrl;

/*
REACT_APP_DEV_PROXY_URL=http://localhost:8081
REACT_APP_DEV_API_URL=https://52.71.73.198:5001
REACT_APP_DEV_WEB_URL=https://localhost:3000

REACT_APP_QA_PROXY_URL=http://52.71.73.198:8081
REACT_APP_QA_API_URL=https://52.71.73.198:5001
REACT_APP_QA_WEB_URL=http://52.71.73.198:5000

REACT_APP_STAG_PROXY_URL=http://turbo.quintarabio.com:8081
REACT_APP_STAG_API_URL=https://turbo.quintarabio.com:5001
REACT_APP_STAG_WEB_URL=http://turbo.quintarabio.com:5000

REACT_APP_PROD_PROXY_URL=http://spa.quintarabio.com:8081
REACT_APP_PROD_API_URL=https://spa.quintarabio.com:5001
REACT_APP_PROD_WEB_URL=http://spa.quintarabio:5000
*/