import React, {useEffect} from 'react';
import InternalPageBase from "../../InternalPageBase"
//import UserAccountDataPage from "./useraccountdatapage"
import getToken,{setToken} from '../../GetSetToken';
import {setMenuData} from '../../InternaPageTreeItems';
import {useLocation,useNavigate} from "react-router-dom";

var tokenAndmenuIsNotReady = true;
const InternalPageManageDropbox = (props)=> {
    const location = useLocation();
    const navigate = useNavigate();
    console.log("InternalPageBilling location.state",location.state)
    if(location!=null) {
        if(location.state!=null) {
            setToken(location.state.token);
            setMenuData(location.state.menuData);
            tokenAndmenuIsNotReady = false;
        } 
    } 
    if(tokenAndmenuIsNotReady===true) {
        if(getToken()!="0") {
            tokenAndmenuIsNotReady = false;
        }
    }
    useEffect(() => {
        if(tokenAndmenuIsNotReady===true) {
            navigate("/sign-in");
            let url = window.location.href
            if(url.indexOf("#") ===-1)
                window.location.href = url + '?#'
        }
    },[]);
    
    return (
        <>
            {tokenAndmenuIsNotReady===true?
            <div>dummy</div>
            :
            <InternalPageBase menu_id={4} sub_menu_id={40600} data_component={<div>Manage Dropbox To Be Constructed</div>} />
            }
            </>
    )
}

export default InternalPageManageDropbox;
