//Design Tool--
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import CategoryGrid from './categorygrid'

export default class SaleAndMarketingDataPage extends React.Component {  
	state = {
	}
	componentDidMount() {
	}
//-----Render Script-----
    render(){
    return  (
		<div style={{width:700,height:500,backgroundColor:"#FFFFFF"}}>
			<div  id = "01010101TableGrid" 
				style={{left:0,top:0, width:650,height:200,backgroundColor:"#FFFFFF"}}>
				<CategoryGrid
					id = "01010101TableGrid_instance"
					value = ""
					font_color = "#FFFFFF"
					background_color = "#ffffff"
					font_family = "Arial"
					font_size = "10"
					getTableId = {(table_id) => { this.AppId(table_id)}}
				/>
			</div>

		</div>        
		);
	}
}
