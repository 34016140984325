//BEGIN_IMPORT_SECTIONs
import React, {Component, useState} from 'react';
import ReactDOM from 'react-dom';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
//import { AgGridReact,AgGridColumn } from '@ag-grid-community/react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import {retrieveApiData,retrieveData2,zoftApiForUsp,zoftApiForUspField} from '../../../data_retrieval';
import {ResponseWindowYesNo2,ResponseWindowSelf,ResponseWindowOk2} from '../../../Modals';
import ZTextButton from '../../../zcomponents/ZTextButton';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
//import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

var the_this;
var is_retrieving = false;
var retrieve_counting = 0;
export default class EditInvoiceStatusOrderItemListDataGrid extends React.Component {
	dd_select_status={}
	dd_select_costcenter={}
	dd_select_institution={}
	state = {
		loaded:false,
		modules:[ClientSideRowModelModule],
		gridOptions:{},
		rowSelection: 'single',
		selected_building_id:0,
		data:[
//			{"id": 0,"name":"","order_id":0,"po":"","product_id":0, "count":0,"discount":0,"price":0,"amount":0,
//			}
		],		
		offset_row : 0,
		request_rows:50,
		updated_from_detail_allowed:true,
		showModalOk:false,
		modalTitle:"",
		showModalYesNo:false,
		showModalSelf:false,
		response:0,
		current_time:(new Date()),
		counter:1,
	}

	gridApi: GridApi
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: true })
		/*
		for(let i = 0; i < this.props.status_list.length;i++)
			this.dd_select_status[this.props.status_list[i].id] = this.props.status_list[i].name;	
		for(let i = 0; i < this.props.costcenter_list.length;i++)
			this.dd_select_costcenter[this.props.costcenter_list[i].id] = this.props.costcenter_list[i].name;
		for(let i = 0; i < this.props.institution_list.length;i++)
			this.dd_select_institution[this.props.institution_list[i].id] = this.props.institution_list[i].name;
		*/
	}
	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if(this.props.order_id!==prevProps.order_id&&this.props.order_id>0) {
			this.retrieveData();
		} else if(this.props.invoice_id!==prevProps.invoice_id&&this.props.order_id===prevProps.order_id) {
			let statusCopy = Object.assign({}, this.state)
			statusCopy.data = [];
			this.setState(statusCopy);
			this.gridApi.setRowData(this.state.data)
		}
	}	

	onGridReady = params => {
    	this.gridApi = params.api;
 	};

	retrieveData = () => {
		let statusCopy0 = Object.assign({}, this.state)
//		statusCopy0.showModalSelf = true;
		statusCopy0.data = [];
		this.setState(statusCopy0);
		this.gridApi.setRowData(this.state.data)

		let sql_id = 44;
		let app_id = 1;
	//	let input_parm0 = this.props.input_parm;

		let input_parm0 = [
			{"request_row_count":1},
			{"offset_row":0},
			{"request_rows":9999},
			{"order_id":0},
		]
		input_parm0[0].request_row_count = 1
		input_parm0[1].offset_row = 0
		input_parm0[2].request_rows = 9999
		input_parm0[3].order_id = this.props.order_id
		console.log('EditInvoiceStatusOrderItemListDataGrid this.props.order_id',this.props.order_id);
		let tmp_data = retrieveApiData(1,30101,sql_id,"usp_react_sql_retrieve2", JSON.stringify(input_parm0));
        let where_clause = input_parm0.slice(3,input_parm0.length);

		Promise.resolve(tmp_data).then((value)=>{
			console.log('EditInvoiceStatusOrderListDataGrid retrieveData value',value);
			let total_row_count = parseInt(value);
			retrieveData2(1,30101,sql_id,0,total_row_count,where_clause)
			.then((retrieved_data) => {
				console.log('EditInvoiceStatusOrderListDataGrid retrieveData retrieved_data',retrieved_data);
				let statusCopy = Object.assign({}, this.state)
				statusCopy.data = retrieved_data;
				this.setState(statusCopy);
				this.gridApi.setRowData(this.state.data)
			})
		});
	}
	onCellClicked(event){
		console.log("InstitutionGrid onCellClicked event", event);
		the_this.setState({showModalYesNo:true});
	}
	onCellDoubleClicked(obj){
		console.log('InstitutionGrid onCellDoubleClicked(obj)', obj);
	}
	onCellValueChanged(obj) {		
		let field_lookup = {"product_id":48,"count":49,"discount":50,"price":51,"amount":52,"name":53}
		console.log('EditInvoiceStatusOrderItemListDataGrid onCellValueChanged obj', obj);
		let field_name = obj.column.colDef.field 
		let field_id = field_lookup[field_name]
		let the_id = obj.data.id
		let the_data = ""
		if (typeof obj.value==='string')
			the_data = '"' + obj.value + '"'
		else
			the_data = obj.value.toString()
		let the_input_data = '{"' + field_name + '":' + the_data + ',"id":' + the_id.toString() + '}'
		console.log('EditInvoiceStatusOrderItemListDataGrid-onCellValueChanged the_input_data,field_id', the_input_data,field_id);
		let api_return_data = zoftApiForUspField(1,30101,field_id,44,0,"usp_react_sql_onchange_update", the_input_data);
		Promise.resolve(api_return_data).then((value)=>{
			console.log('EditInvoiceStatusOrderItemListDataGrid-onCellValueChanged value', value);
			if(value.return_status===0) { //normal return
				console.log('EditInvoiceStatusOrderItemListDataGrid-onCellValueChanged value.return_data', value.return_data);
				if(value.return_data.success) {
//					SetEditData("");
					console.log('EditInvoiceStatusOrderItemListDataGrid-onCellValueChanged value.return_data.output.data',value.return_data.output);
				} else {
					let the_title = "Update Error:" + value.return_data.message + "!"
					the_this.setState({ ...this.state, showModalOk: true, modalTitle:the_title})
				};
			} else {
				let the_title = "Unspecified error, please report it to tech support!"
				the_this.setState({ ...this.state, showModalOk: true, modalTitle:the_title})
				console.log('EditInvoiceStatusOrderItemListDataGrid-onCellValueChanged value.return_data', value.return_data);
			}
		}) 

/*
		console.log('EditInvoiceStatusOrderItemListDataGrid onCellValueChanged obj', obj);
		let field_name = obj.column.colDef.field 
		let the_id = obj.data.id
		let the_data = ""
		if (typeof obj.value==='string')
			the_data = '"' + obj.value + '"'
		else
			the_data = obj.value.toString()
		let the_input_data = '{"' + field_name + '":' + the_data + ',"id":' + the_id.toString() + '}'
		console.log('EditInvoiceStatusOrderItemListDataGrid-onCellValueChanged the_input_data', the_input_data);
		let api_return_data = zoftApiForUsp(1,30101,44,0,"usp_react_sql_onchange_update", the_input_data);
		Promise.resolve(api_return_data).then((value)=>{
			console.log('EditInvoiceStatusOrderItemListDataGrid-onCellValueChanged value', value);
			if(value.return_status===0) { //normal return
				console.log('EditInvoiceStatusOrderItemListDataGrid-onCellValueChanged value.return_data', value.return_data);
				if(value.return_data.success) {
//					SetEditData("");
					console.log('EditInvoiceStatusOrderItemListDataGrid-onCellValueChanged value.return_data.output.data',value.return_data.output);
				} else {
					let the_title = "Update Error:" + value.return_data.message + "!"
					this.setState({ ...this.state, showModalOk: true, modalTitle:the_title})
				};
			} else {
				let the_title = "Unspecified error, please report it to tech support!"
				this.setState({ ...this.state, showModalOk: true, modalTitle:the_title})
				console.log('EditInvoiceStatusOrderItemListDataGrid-onCellValueChanged value.return_data', value.return_data);
			}
		}) 
		*/
	}
	setShowModalOk(a_showModal) {
		this.setState({showModalOk:a_showModal});
	}
	setShowModalYesNo(a_showModal) {
		this.setState({showModalYesNo:a_showModal});
	}
	setResponse(a_response) {
		this.setState({response:a_response});
	}

	onButtonClick(a_text) {
		this.retrieveData()
	}
	/*
	lookupKey(mappings, name){
		//		console.log('EditInvoiceStatusOrderListDataGrid-lookupKey mappings,name', mappings,name);
		for (var key in mappings) {
			if (mappings.hasOwnProperty(key)) {
//			console.log(key + " -> " + mappings[key] + '|' + name);
			if(mappings[key]===name)
				return key
			}
		}
		return 0
	}
	*/
	/*
	lookupValue(mappings, name){
		return mappings[name]
	}	// convert code to value

	makeValueFormatter = params => { 
		return lookupValue(dd_selection, params.value);
	};
	*/

//rowSelection:'multiple',
//rowMultiSelectWithClick:true

//rowSelection='multiple'
	createGridOptions() {
		const options: GridOptions = {      
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true,
				rowMultiSelectWithClick: true,
       			headerCheckboxSelection: true,
				rowDeselection: true,
			},

			editable:function (params) {return the_this.props.dd_access["29"]===undefined ||the_this.props.dd_access["29"]===0?false:true;},

			columnDefs: [
				{headerName:"Item ID",headerClass:"header-id",field:"id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.id= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return false;},
				},
				{headerName:"Item Name",headerClass:"header-name",field:"name",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["53"]===undefined ||the_this.props.dd_access["53"]===0?false:true;},
				},
				{headerName:"Order ID",headerClass:"header-order_id",field:"order_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.order_id= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return false;},
				},
				{headerName:"Product",headerClass:"header-product_id",field:"product_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.product_id= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["48"]===undefined ||the_this.props.dd_access["48"]===0?false:true;},
				},
				{headerName:"Count",headerClass:"header-count",field:"count",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.count= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["49"]===undefined ||the_this.props.dd_access["49"]===0?false:true;},
				},
				{headerName:"Discount",headerClass:"header-discount",field:"discount",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.discount= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["50"]===undefined ||the_this.props.dd_access["50"]===0?false:true;},
				},
				{headerName:"Price",headerClass:"header-price",field:"price",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.price= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["51"]===undefined ||the_this.props.dd_access["51"]===0?false:true;},
				},
				{headerName:"Amount",headerClass:"header-amount",field:"amount",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.amount= params.newValue;return true;},suppressNavigable:false,
				editable:function (params) {return the_this.props.dd_access["52"]===undefined ||the_this.props.dd_access["52"]===0?false:true;},
				},
			],
		}
		return options;
	}
	render() {
		return  (
			<div id="pid_0630_cell_001" style={{position:"absolute",top:0,width:1350,height:200,backgroundColor:"#FFFFFF"}}>
				<div id="pid_0630_cell_001_001" className="ag-theme-balham" 
                    style={{position:"absolute",top:0,left:0,width:1350,height:200,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={this.state.rowSelection} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
				{this.state.showModalSelf?
					<ResponseWindowSelf
						title={"Data Is Loading . . ."} 
					/> :null}
				{this.state.showModalYesNo?
					<ResponseWindowYesNo2
						title={"Current updated record will be overridden, continue?"} 
						setShowModal={(showModel)=>this.setShowModal(showModel)} 
						setResponse={(response)=>this.setResponse(response)}
					/> :null}
				{this.state.showModalOk ? 
					<ResponseWindowOk2 
						title={this.state.modalTitle} 
						setShowModal={(showModel)=>this.setState({...this.state, showModalOk:showModel})}
					/> : 
					null}
			</div>
		)
	}
}

/*
				{this.showModal ? 
					<ResponseWindowYesNo2
						title={"Current updated record will be overridden, continue?"} 
						setShowModal={(showModel)=>this.setShowModal(showModel)} 
						setResponse={(response)=>this.setResponse(response)}
					/> : null}

*/