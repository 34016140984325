import React, {Component, useState, useEffect, useContext} from 'react';
import {ResponseWindowOk,ResponseWindowYesNo,ResponseWindowEdit} from '../../../Modals';
import ApplyPaymentInvoiceListModal from './applypaymentinvoicelistmodal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DatalistInput from 'react-datalist-input';
//import 'react-datalist-input/dist/styles.css';

//import zeditbox1 from 'zeditbox1';
import ZDropdownList from '../../../zcomponents/ZDropdownList';
import ZEditText from '../../../zcomponents/ZEditText';
import ZEditNumber from '../../../zcomponents/ZEditNumber';
import ZTextButton from '../../../zcomponents/ZTextButton';
import ZCheckbox from '../../../zcomponents/ZCheckbox2';
import ZAutoCompleteEditText from '../../../zcomponents/ZAutoCompleteEditText';
import {retrieveApiData,retrieveApiData2,zoftApiForUsp,zoftApiForUspField} from '../../../data_retrieval';

//set @input='[{"request_row_count":1},{"offset_row":0},{"request_rows":99999},{"invoice_id":0},{"invoice_status_id":0},{"billgroup":""},{"category_id":0},{"institution":""},{"dateStart":"2021-01-01"},{"dateEnd":"2022-10-31"},{"po":""},{"recalculate_totaldue":0},{"q_maxonly":0},{"exclude_paid":0},{"exclude_cancelled":0},{"exclude_nih":0}]';
const ApplyPaymentDetailDataGrid  = (props)=> {
    const type_string = 1;
    const type_number = 0;
    const [modalTitle, SetModalTitle] = useState("Warning Message");

    const [showModalInvoiceList, setShowModalInvoiceList] = useState(false);
    const [showModalOk, SetShowModalOk] = useState(false);
    const [showModalEdit, SetShowModalEdit] = useState(false);
    const [showModalYesNo, SetShowModalYesNo] = useState(false);
    const [editData, SetEditData] = useState(false);

    const [response, setResponse] = useState([]);
    const [institution_list, setInstitution_list] = useState([]);
    const [billgroup_list, setBillgroup_list] = useState([]);
    const [order_list,setOrder_list] = useState([]);
    const [update_mode, SetUpdate_mode] = useState(0);
    const [add_invoice_clicked, setAdd_invoice_clicked] = useState(false);
    let tmp_data = {new_record_inserted_or_updated:false,i:-1,
        data:
        {"id": 0,"cc_id":0,"inst_id":0,"type_id": 0, "ref_num":0,"amount":0, "applied_amount":0,
        "fee":0,"credits":0,"balance":0,"verified":0,"notes":"","time_created":""}
    }

    const [detail_data,SetDetail_data] = useState(tmp_data);

    useEffect(() => {
//        SetInstitution_id(institution_id);
        console.log("ApplyPaymentDetailDataGrid-props.dd_access",props.dd_access);
        if(update_mode===0) {
            console.log("ApplyPaymentDetailDataGrid-	props.current_data_from_list.data.id",props.current_data_from_list.data.id)
            console.log("ApplyPaymentDetailDataGrid-	props.current_data_from_list.data.name",props.current_data_from_list.data.name)
    //        console.log("ApplyPaymentDetailDataGrid-	institution_id",institution_id)
            console.log("ApplyPaymentDetailDataGrid-	props.current_data_from_list.data.institution_id",props.current_data_from_list.data.institution_id)
                SetDetail_data({new_record_inserted_or_updated:false,i:props.current_data_from_list.i,data:{...props.current_data_from_list.data}});
        } 
        
    },[props]);

    useEffect(() => {
        if(add_invoice_clicked===true&&response.length>0) {
            console.log("ApplyPaymentDetailDataGrid-useEffect,add_invoice_clicked,response",add_invoice_clicked,response)
            setAdd_invoice_clicked(false)
            let input_parm_tmp = [];
            for(let i=0; i<response.length;i++) {
                input_parm_tmp.push([{"payment_id":detail_data.data.id},{"invoice_id":response[i]}])
            }
            console.log("ApplyPaymentDetailDataGrid-useEffect,input_parm_tmp",input_parm_tmp)
            if(input_parm_tmp.length>0) {
                let api_return_data = zoftApiForUsp(1,30103,55,0,0,"usp_react_sql_insert2_repeat", JSON.stringify(input_parm_tmp));
                Promise.resolve(api_return_data).then((value)=>{
                    console.log('ApplyPaymentDetailDataGrid-useEffect 4, value', value);
                    if(value.return_status===0) { //normal return
                        if(value.return_data.success===true) {
                            console.log('ApplyPaymentDetailDataGrid-useEffect Insert success');
                            props.getCurrentDataFromDetail(detail_data);
                            props.resetCurrentPaymentId(detail_data.data.id)
                        } else {//
                            console.log('ApplyPaymentDetailDataGrid-useEffect Insert Fail');
                        }
                    } else {
                        console.log('ApplyPaymentDetailDataGrid-useEffect Insert Fail');
                    }
                });	
            }
        }
    },[response]);

    useEffect(() => {
        if(editData!="") {
            console.log("ApplyPaymentDataPage-useEffect,editData",editData)
            let tmp_data = detail_data;
            tmp_data.data.name = editData;
            SetDetail_data({new_record_inserted_or_updated:tmp_data.new_record_inserted_or_updated,i:tmp_data.i,data:{...tmp_data.data}});
            insertNewRecord();
        }
    },[editData]);    

    const onChangeTextHandler =(text,field_name,data_type) => {        
        if(update_mode===1) {
            if(field_name==="billgroup") {
                console.log("ApplyPaymentDetailDataGrid-onChangeTextHandler,text",text)
                let input_data = '{"id":16,"search":"' + text + '"}'
                let tmp_data = retrieveApiData(1,30101,0,"usp_react_autocomplete_retrieve", input_data);
                Promise.resolve(tmp_data).then((value)=>{
                    try {
                        console.log("ApplyPaymentDetailDataGrid-onChangeTextHandler,value",value)
                        setBillgroup_list(JSON.parse(value));
                    } catch (e) {
                        console.log('Not JSON');
                    }
                })
            }
            let tmp_data = detail_data;
            if(data_type===type_string)
                tmp_data.data[field_name] = text;
            else
                tmp_data.data[field_name] = parseInt(text);
            SetDetail_data({new_record_inserted_or_updated:tmp_data.new_record_inserted_or_updated,i:tmp_data.i,data:{...tmp_data.data}});
        }
        console.log("ApplyPaymentDataPage-onChangeTextHandler,field_name,text",field_name, text)
	}
    const onClickCheckbox =(field_name)=> {
        if(field_name==="update_mode") {
            props.set_update_mode(update_mode===0?1:0);
            SetUpdate_mode(update_mode===0?1:0);
        }
        if(update_mode===1) {
            let tmp_data = detail_data;
            let the_value = detail_data.data[field_name]===0?1:0;
            tmp_data.data[field_name] = the_value;
            SetDetail_data({new_record_inserted_or_updated:tmp_data.new_record_inserted_or_updated,i:tmp_data.i,data:{...tmp_data.data}});
            console.log("ApplyPaymentDataPage-onClickCheckbox,detail_data,tmp_data",detail_data,tmp_data)
        }
    }

    const popuateData = (a_data)=> {
        SetDetail_data({new_record_inserted_or_updated:detail_data.new_record_inserted_or_updated,i:detail_data.i,data:{...a_data}});
    }
    const insertNewRecord = ()=> {
        let app_id = 1
        let gui_id = 30101
        let new_name = detail_data.data.name===undefined ? '' : detail_data.data.name;
        let the_input = [[{"name":new_name}],[]];
        console.log('ApplyPaymentDetailDataGrid-onButtonClickNew the_input', the_input);
        let tmp_data = zoftApiForUsp(app_id,gui_id,31,1,0,"usp_react_sql_insert2_with_return", JSON.stringify(the_input));
        Promise.resolve(tmp_data).then((value)=>{
            console.log('ApplyPaymentDetailDataGrid-onButtonClickNew value', value);
            if(value.return_status===0) { //normal return
                console.log('ApplyPaymentDetailDataGrid-onButtonClickNew value.return_data', value.return_data);
                if(value.return_data.success) {
                    SetEditData("");
                    console.log('ApplyPaymentDetailDataGrid-onButtonClickNew value.return_data.output.data',value.return_data.output);
                    let the_data = JSON.parse(value.return_data.output);
                    popuateData(the_data.data[0]);
                    props.getCurrentDataFromDetail({new_record_inserted_or_updated:true,i:-1, data:the_data.data[0]});    
                    SetEditData("");
                } else {
                    SetModalTitle("Insert Error:" + value.return_data.message + ", Please enter new name!");
                    SetShowModalEdit(true);
                };
            } else {
                SetModalTitle("Unspecified error, please report it to tech support!");
                SetShowModalOk(true);
                console.log('ApplyPaymentDetailDataGrid-insertNewRecord value.return_data', value.return_data);
            }
        }) 
   }
    const onButtonClickNew = ()=> {
        SetUpdate_mode(1)
        insertNewRecord()
    }

    const onButtonRemoveInvoice = ()=> {
        if(props.delete_invoice_list.length>0) {
            console.log("ApplyPaymentDetailDataGrid-useEffect,props.delete_invoice_list",props.delete_invoice_list)
            let input_parm_tmp = [];
            for(let i=0; i<props.delete_invoice_list.length;i++) {
                input_parm_tmp.push([{"payment_id":detail_data.data.id},{"invoice_id":props.delete_invoice_list[i]}])
            }
            console.log("ApplyPaymentDetailDataGrid-useEffect,input_parm_tmp",input_parm_tmp)
            if(input_parm_tmp.length>0) {
                let api_return_data = zoftApiForUsp(1,30103,55,0,0,"usp_react_sql_delete2_repeat", JSON.stringify(input_parm_tmp));
                Promise.resolve(api_return_data).then((value)=>{
                    console.log('ApplyPaymentDetailDataGrid-useEffect 4, value', value);
                    if(value.return_status===0) { //normal return
                        if(value.return_data.success===true) {
                            console.log('ApplyPaymentDetailDataGrid-useEffect Delete success');
                            props.getCurrentDataFromDetail(detail_data);
                            props.resetDeleteInvoiceList();
                            props.resetCurrentPaymentId(detail_data.data.id)

                        } else {//
                            console.log('ApplyPaymentDetailDataGrid-useEffect Delete Fail');
                        }
                    } else {
                        console.log('ApplyPaymentDetailDataGrid-useEffect Delete Fail');
                    }
                });	
            }
        }
    }

    const onBlurTextHandler = (field_name, field_id, data_type) => {
        let the_id = detail_data.data.id;
        let the_input_data = "";
        if(the_id===undefined)
            return;
            
        console.log('onBlurTextHandler field_name, data_type',field_name, data_type);
        if(update_mode===1) {
            if(data_type===type_string) { //string
                the_input_data = '{"' + field_name + '":"' + detail_data.data[field_name] + '","id":' + the_id.toString() + '}'
            } else {
                let tmp_data = detail_data.data[field_name]===undefined ? 0:detail_data.data[field_name];
                the_input_data = '{"' + field_name + '":' + tmp_data.toString() + ',"id":' + the_id.toString() + '}'
            }
            console.log('ApplyPaymentDetailDataGrid-onBlurTextHandler the_input_data', the_input_data);

            let api_return_data = zoftApiForUspField(1,30301,field_id,31,0,0,"usp_react_sql_onchange_update", the_input_data);
            Promise.resolve(api_return_data).then((value)=>{
                console.log('ApplyPaymentDetailDataGrid-onBlurTextHandler value', value);
                if(value.return_status===0) { //normal return
                    console.log('ApplyPaymentDetailDataGrid-onBlurTextHandler value.return_data', value.return_data);
                    if(value.return_data.success) {
                        SetEditData("");
                        console.log('ApplyPaymentDetailDataGrid-onButtonClickNew value.return_data.output.data',value.return_data.output);
                        props.getCurrentDataFromDetail({new_record_inserted_or_updated:true,i:props.current_data_from_list.i, data:detail_data.data});
                    } else {
                        SetModalTitle("Update Error:" + value.return_data.message + "!");
                        SetShowModalEdit(true);
                    };
                } else {
                    SetModalTitle("Unspecified error, please report it to tech support!");
                    SetShowModalOk(true);
                    console.log('ApplyPaymentDetailDataGrid-onBlurTextHandler value.return_data', value.return_data);
                }
            }) 
        }
    }
    return  (
        <div style={{width:1350,height:155,backgroundColor:"#ffffff",border:"1px solid black"}}>
            <ZEditNumber id="030102detail01"
                x={10} y={10} width={250} height={35} whole_background_color="#ffffff"
                label="Payment ID" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={150} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={true}
                value={detail_data.data.id}
                onchange_func = {(text) => { onChangeTextHandler(text,"id",type_number)}}
                onBlur= {() => {}}
            />
            <ZEditText id="030102detail02"
                x={10} y={60} width={250} height={35} whole_background_color="#ffffff"
                label="Reference Number" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={150} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={props.dd_access["1"]===undefined ||props.dd_access["1"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.ref_num}
                onchange_func = {(text) => { onChangeTextHandler(text,"ref_num",type_string)}}
                onBlur= {() => {onBlurTextHandler("name", 1, type_string)}}
            />
            <ZAutoCompleteEditText id="030102detail03"
                x={10} y={110} width={250} height={35} whole_background_color="#ffffff"
                label="Institution" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={150} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={props.dd_access["2"]===undefined ||props.dd_access["2"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.inst_id}
                onchange_func = {(text) => { onChangeTextHandler(text,"inst_id",type_number)}}
                onBlur= {() => {onBlurTextHandler("inst_id", 2, type_number)}}
                dataList={institution_list}
            />
            <ZEditNumber id="030102detail05"
                x={280} y={10} width={200} height={35} whole_background_color="#ffffff"
                label="Payment Method" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={90} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={props.dd_access["3"]===undefined ||props.dd_access["3"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.type_id}
                onchange_func = {(text) => { onChangeTextHandler(text,"type_id",type_number)}}
                onBlur= {() => {onBlurTextHandler("type_id", 3, type_number)}}
            />
            <ZEditText id="030102detail07"
                x={280} y={60} width={200} height={35} whole_background_color="#ffffff"
                label="Amount" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={90} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={props.dd_access["4"]===undefined ||props.dd_access["4"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.amount}
                onchange_func = {(text) => { onChangeTextHandler(text,"amount",type_string)}}
                onBlur= {() => {onBlurTextHandler("amount", 4, type_string)}}
            />
            <ZEditNumber id="030102detail08"
                x={280} y={110} width={200} height={35} whole_background_color="#ffffff"
                label="Applied Amount" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={90} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={props.dd_access["5"]===undefined ||props.dd_access["5"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.applied_amount}
                onchange_func = {(text) => { onChangeTextHandler(text,"applied_amount",type_number)}}
                onBlur= {() => {onBlurTextHandler("applied_amount", 5, type_number)}}
            />
            <ZEditText id="030102detail11"
                x={500} y={10} width={200} height={35} whole_background_color="#ffffff"
                label="Credits" label_x={0} label_y={0} label_width={80} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={90} button_y={0} button_width={90} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={props.dd_access["6"]===undefined ||props.dd_access["6"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.credits}
                onchange_func = {(text) => { onChangeTextHandler(text,"credits",type_string)}}
                onBlur= {() => {onBlurTextHandler("credits", 6, type_string)}}
            />
            <ZEditNumber id="030102detail12"
                x={500} y={60} width={200} height={35} whole_background_color="#ffffff"
                label="Balance" label_x={0} label_y={0} label_width={80} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={90} button_y={0} button_width={90} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={props.dd_access["7"]===undefined ||props.dd_access["7"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.balance}
                onchange_func = {(text) => { onChangeTextHandler(text,"balance",type_number)}}
                onBlur= {() => {onBlurTextHandler("balance", 7, type_number)}}
            />
            <ZCheckbox id="030102detail13"
                x={480} y={110} width={160} height={35} whole_background_color="#ffffff"
                label="Verified" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={25} button_height={25} button_color="#000000" button_background_color="ffffff"
                disabled={props.dd_access["8"]===undefined ||props.dd_access["8"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.verified}
                onClickCheckbox = {() => { onClickCheckbox("verified")}}
                onBlur= {() => {onBlurTextHandler("verified", 8, type_number)}}
            />
            <ZEditText id="030102detail09"
                x={720} y={10} width={200} height={35} whole_background_color="#ffffff"
                label="Fee" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={100} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={props.dd_access["9"]===undefined ||props.dd_access["9"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.fee}
                onchange_func = {(text) => { onChangeTextHandler(text,"fee",type_string)}}
                onBlur= {() => {onBlurTextHandler("fee", 9, type_string)}}
            />
            <ZEditText id="030102detail04"
                x={720} y={60} width={200} height={35} whole_background_color="#ffffff"
                label="Time Created" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={100} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={true}
                value={detail_data.data.time_created}
                onchange_func = {(text) => { onChangeTextHandler(text,"cur_status_id",type_number)}}
                onBlur= {() => {}}
            />
            <ZEditText id="030102detail14"
                x={720} y={110} width={300} height={35} whole_background_color="#ffffff"
                label="Notes" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={190} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={props.dd_access["10"]===undefined ||props.dd_access["10"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.notes}
                onchange_func = {(text) => { onChangeTextHandler(text,"notes",type_string)}}
                onBlur= {() => {onBlurTextHandler("notes", 10, type_string)}}
            />
            <ZCheckbox id="030102detail34"
                x={1000} y={10} width={150} height={30} whole_background_color="#ffffff" 
                label="Update Mode" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#ff0000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={25} button_height={25} button_color="#000000" button_background_color="ffffff"
                disabled={false}
                value={update_mode}
                onClickCheckbox = {() => { onClickCheckbox("update_mode")}}
                onBlur= {() => {}}
            />
            <ZTextButton id="030102detail32"
                x={1165} y={10} width={140} height={30} whole_background_color="#f0f0f0" 
                border_style="1px solid #000000"
                button_text="New Payment" label_background_color="#f0f0f0"
                button_font_color="#000000" button_background_color="#ffffff"
                disabled={props.dd_access["11"]===undefined ||props.dd_access["11"]===0?true:(update_mode===0?true:false)}
                onButtonClick = {() => { onButtonClickNew()}}
            />
            <ZTextButton id="030102detail32"
                x={1165} y={60} width={140} height={30} whole_background_color="#f0f0f0" 
                border_style="1px solid #000000"
                button_text="Add Invoice" label_background_color="#f0f0f0"
                button_font_color="#00000" button_background_color="#ffffff"
                disabled={props.dd_access["12"]===undefined ||props.dd_access["12"]===0?true:(update_mode===0?true:false)}
                onButtonClick = {() => { setAdd_invoice_clicked(true);setShowModalInvoiceList(true);console.log("EditInvoiceStatusDataPage-onChangeTextHandler,response",response)}}
            />
            <ZTextButton id="030102detail32"
                x={1165} y={110} width={140} height={30} whole_background_color="#f0f0f0" 
                border_style="1px solid #000000"
                button_text="Remove Invoice" label_background_color="#f0f0f0"
                button_font_color="#00000" button_background_color="#ffffff"
                disabled={props.dd_access["13"]===undefined ||props.dd_access["13"]===0?true:(update_mode===0?true:false)}
                onButtonClick = {() => {onButtonRemoveInvoice()}}
            />
             {showModalInvoiceList ? 
                <ApplyPaymentInvoiceListModal setShowModal={setShowModalInvoiceList} setResponse={setResponse} 
            /> : null}
             {showModalOk ? <ResponseWindowOk title={modalTitle} setShowModal={SetShowModalOk} /> : null}
             {showModalEdit ? <ResponseWindowEdit title={modalTitle} setShowModal={SetShowModalEdit} setEditData={SetEditData} /> : null}
             {showModalYesNo ? <ResponseWindowYesNo title={modalTitle} setShowModal={SetShowModalYesNo} setResponse={setResponse}/> : null}
        </div>
    );
}

export default ApplyPaymentDetailDataGrid;
