import React, {Component} from 'react';
//import '@progress/kendo-theme-default/dist/all.css';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../v2/css/bootstrap.min.css';
import "../qbio_style.css"

import Carousel from 'react-bootstrap/Carousel';
import "../styles.css";
import logotransparent from "../v2/img/logotransparent.png"
import christmas1 from "../v2/img/christmas1.png"
import covid4a from "../v2/img/covid4a.png"
import cssDNA3 from "../v2/img/cssDNA3.png"
import ngs1 from "../v2/img/ngs1.png"

import NGSred from "../v2/img/NGS-red.png"
import Sangerred from "../v2/img/Sanger-red.png"
import Cloningred from "../v2/img/Cloning-red.png"
import Oligored from "../v2/img/Oligo-red.png"
import DNAprepred from "../v2/img/DNAprep-red.png"
 
import WebSiteServiceIconLinks from "../WebSite_service_icon_links"
import WebSiteHomeDefaultNavigator from "../WebSiteHome_default_navigator"
import WebSiteHomeDefaultFooter from "../WebSiteHome_default_footer"
const PublicPageAbout=()=> {
    return (
        <>
             <WebSiteHomeDefaultNavigator />
			<div class="main">
				<div class="container justify-content-center p-5">
					<h2 class="py-3">The Quintara Story</h2>
					<p> Quintara's first office was a small room next to a laundry mat in Albany. We started with two sequencing machines and two employees.  I participated in each step of the operation, from picking up samples to emailing data.</p>
					<p>Quintara is now headquartered in Bay Area, CA, the center of the Bay Area's life science community. We have branches in Hayward CA, Cambridge MA, Fort Collin CO, Farmington CT, and Frederick MD. </p>
					<p>We now operate more than 20 state-of-the-art sequencing instruments and automatic sample processors. 30 scientists and technicians work at Quintara. Our growth is based on the goal I had 10 years ago: provide the best gene sequencing service to the life science community.</p>
					<p>Researchers depend on accurate DNA data. Sequencing results could change the direction of a project. That's why we implement both pre-sequencing and post-sequencing quality control. If your sample has difficulty sequencing, we will resolve it. </p>
					<p>Time is valuable. That's why we structure our operation to deliver the fastest turnaround. We can pickup your samples at the end of your workday and deliver the results to you by next morning. We open on weekends and can pickup samples two to three times per day in the whole Bay Area CA, and Boston MA.</p>
					<p>Our goal is to provide the best service for you. This goal has guided us for one decade and will continue to guide us. I welcome you to choose Quintara as your research partner.</p>
					<br />
					Thank you!
					<br />
					<br />
					Best regards,
					<br />
					Richard Shan, Ph.D
					<br />
					<i>Founder, Quintara Biosciences</i>
				</div>
			</div>
			<hr />
			<WebSiteServiceIconLinks />
			<hr />
			<WebSiteHomeDefaultFooter />
        </>
    )
}

export default PublicPageAbout;



