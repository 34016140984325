import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useNavigate
  }
from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './v2/css/bootstrap.min.css';
import "./styles.css";

import "./styles.css";
import NGSred from "./v2/img/NGS-red.png"
import Sangerred from "./v2/img/Sanger-red.png"
import Cloningred from "./v2/img/Cloning-red.png"
import Oligored from "./v2/img/Oligo-red.png"
import DNAprepred from "./v2/img/DNAprep-red.png"

import {WebSiteServiceDnaSequencing as SangerSequencing} from "./public_pages/service/dna_sequencing.js"
import "./WebSite_service_icon_links.css"

/*
				<div class="row text-center justify-content-between">
					<div id="WebSiteServiceIconLinks-card-1" class="card">
                        <div id="WebSiteServiceIconLinks-card-1-text">/service/ngs_services</div>
*/

//const components = PanelBarUtils.mapItemsToComponents(items);
const WebSiteServiceIconLinks = ()=> {
    const navigate = useNavigate();
  
    const handleClick = (path) => {
        navigate(path);
    }
    return (
        <>
			<div id="services" class="container">
				<div class="row text-center justify-content-between">
					<div class="card">
						<div class="card-head">
							<a onClick={()=>handleClick("/service/ngs_services")}> <img class="img-fluid" src={NGSred} /></a>
						</div>
						<div class="card-body">
							<h5 class="d-none d-md-block">
                                <a onClick={()=>handleClick("/service/ngs_services")}>NGSs <br />Sequencing</a>
                            </h5>
						</div>
					</div>
					<div class="card">
						<div class="card-head">
							<a onClick={()=>handleClick("/service/dna_sequencing")}><img class="img-fluid" src={Sangerred} /></a>
						</div>
						<div class="card-body">
							<h5 class="d-none d-md-block">
                                <a onClick={()=>handleClick("/service/dna_sequencing")}>Sanger <br />Sequencing</a>
                            </h5>
						</div>
					</div>
					<div class="card">
						<div class="card-head">
							<a onClick={()=>handleClick("/service/molecular_cloning")}><img class="img-fluid" src={Cloningred} /></a>
						</div>
						<div class="card-body">
							<h5 class="d-none d-md-block">
                                <a onClick={()=>handleClick("/service/molecular_cloning")}>Cloning <br />Services</a>
                            </h5>
						</div>
					</div>
					<div class="card">
						<div class="card-head">
							<a onClick={()=>handleClick("/service/oligo")}><img class="img-fluid" src={Oligored} /></a>
						</div>
						<div class="card-body">
							<h5 class="d-none d-md-block">
                                <a onClick={()=>handleClick("/service/oligo")}> Oligo <br />Synthesis</a>
                            </h5>
						</div>
					</div>
					<div class="card">
						<div class="card-head">
							<a onClick={()=>handleClick("/service/dna_maxiprep")}><img class="img-fluid" src={DNAprepred} /></a>
						</div>
						<div class="card-body">
							<h5 class="d-none d-md-block">
                                <a onClick={()=>handleClick("/service/dnamaxi_prep")}>DNAprep <br />Services</a>
                            </h5>
						</div>
					</div>
				</div>			
			</div>        
        </>
    )
  }


  function WebSiteServices() {return (<div>WebSiteServices</div>)}

export default WebSiteServiceIconLinks;

/*----------------------------------------------------------------
			<div id="services" class="container">
				<div class="row text-center justify-content-between">
					<div class="card">
						<div class="card-head">
                            <Link to="/" data-jzz-gui-player="true">Home</Link>
							<a href="/service/ngs_services"> <img class="img-fluid" src={NGSred} /></a>
						</div>
						<div class="card-body">
							<h4 class="d-none d-md-block"><a href="/service/ngs_services">NGS <br />Sequencing</a></h4>
						</div>
					</div>
					<div class="card">
						<div class="card-head">
							<a href="/service/dna_sequencing"><img class="img-fluid" src={Sangerred} /></a>
						</div>
						<div class="card-body">
							<h4 class="d-none d-md-block"><a href="/service/dna_sequencing">Sanger <br />Sequencing</a></h4>
						</div>
					</div>
					<div class="card">
						<div class="card-head">
							<a href="/service/molecular_cloning"><img class="img-fluid" src={Cloningred} /></a>
						</div>
						<div class="card-body">
							<h4 class="d-none d-md-block"><a href="/service/molecular_cloning">Cloning <br />Services</a></h4>
						</div>
					</div>
					<div class="card">
						<div class="card-head">
							<a href="/service/oligo"><img class="img-fluid" src={Oligored} /></a>
						</div>
						<div class="card-body">
							<h4 class="d-none d-md-block"><a href="/service/oligo"> Oligo <br />Synthesis</a></h4>
						</div>
					</div>
					<div class="card">
						<div class="card-head">
							<a href="/service/dna_maxiprep"><img class="img-fluid" src={DNAprepred} /></a>
						</div>
						<div class="card-body">
							<h4 class="d-none d-md-block"><a href="/service/dnamaxi_prep">DNAprep <br />Services</a></h4>
						</div>
					</div>
				</div>			
			</div>
*/            

