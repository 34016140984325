import React, {Component} from 'react';
//import '@progress/kendo-theme-default/dist/all.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './v2/css/bootstrap.min.css';

import "./styles.css";

const WebSiteHomeDefaultFooter = ()=> {
    return (
        <>
            <footer id="footer2" class="container-fluid padding">
            <div class="row px-5 justify-content-center">
		          <div class="col col-xs-12 col-sm-4 col-md-3 col-lg-2">
                <b>MA Site</b>
                <p>boston@quintarabio.com <br />
                  1-617-943-2768<br />
                  625 Mt Auburn St, Suite 105<br /> Cambridge, MA 02138</p>
              </div>
              <div class="col col-xs-12 col-sm-4 col-md-3 col-lg-2">
                <b>CA Site</b>
                <p>quintara2011@quintarabio.com <br />
                  1-510-910-2168<br />
                  3583 Investment Blvd, Suite 2<br /> Hayward, CA  94545</p>
              </div>
              <div class="col col-xs-12 col-sm-4 col-md-3 col-lg-2">
                <b>CT Site</b>
                <p>ct@quintarabio.com <br />
                1-617-480-9886<br />
                231 Farmington Ave<br /> Farmington, CT 06032</p>
              </div>
              <div class="col col-xs-12 col-sm-4 col-md-3 col-lg-2">
                <b>MD Site</b>
                <p>dc@quintarabio.com <br />
                1-301-761-4835<br />
                5350 Partners Ct, Suite C<br /> Frederick, MD 21703</p>
              </div>
              <div class="col col-xs-12 col-sm-4 col-md-3 col-lg-2 social">
              <a href="https://www.facebook.com/Quintara-Bio-449236155251241/?ref=br_rs" target="_blank" data-jzz-gui-player="true">
                <svg class="svg-inline--fa fa-facebook fa-w-14" aria-hidden="true" data-prefix="fab" data-icon="facebook" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M448 56.7v398.5c0 13.7-11.1 24.7-24.7 24.7H309.1V306.5h58.2l8.7-67.6h-67v-43.2c0-19.6 5.4-32.9 33.5-32.9h35.8v-60.5c-6.2-.8-27.4-2.7-52.2-2.7-51.6 0-87 31.5-87 89.4v49.9h-58.4v67.6h58.4V480H24.7C11.1 480 0 468.9 0 455.3V56.7C0 43.1 11.1 32 24.7 32h398.5c13.7 0 24.8 11.1 24.8 24.7z"></path></svg>
              </a>
              <a href="https://twitter.com/quintara_bio" target="_blank">
              <svg class="svg-inline--fa fa-twitter fa-w-16" aria-hidden="true" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
              </a>
              <a href="">
              <svg class="svg-inline--fa fa-google-plus-g fa-w-20" aria-hidden="true" data-prefix="fab" data-icon="google-plus-g" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><path fill="currentColor" d="M386.061 228.496c1.834 9.692 3.143 19.384 3.143 31.956C389.204 370.205 315.599 448 204.8 448c-106.084 0-192-85.915-192-192s85.916-192 192-192c51.864 0 95.083 18.859 128.611 50.292l-52.126 50.03c-14.145-13.621-39.028-29.599-76.485-29.599-65.484 0-118.92 54.221-118.92 121.277 0 67.056 53.436 121.277 118.92 121.277 75.961 0 104.513-54.745 108.965-82.773H204.8v-66.009h181.261zm185.406 6.437V179.2h-56.001v55.733h-55.733v56.001h55.733v55.733h56.001v-55.733H627.2v-56.001h-55.733z"></path></svg>
              </a>
              <a href="https://www.linkedin.com/company/quintara-biosciences">
                <svg class="svg-inline--fa fa-youtube fa-w-18" aria-hidden="true" data-prefix="fab" data-icon="youtube" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg>
              </a>
              </div>
            </div>
          <div class="footer-copyright text-center">© 2022 Copyright: Quintarabio.com</div>
        </footer>        </>
    )
}

export default WebSiteHomeDefaultFooter;
