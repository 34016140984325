import React, {Component, useState, useEffect, useContext} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './v2/css/bootstrap.min.css';
import './v2/css/qbio_style.css';
import {ResponseWindowOk, ResponseWindowYesNo} from './Modals';
import {ValidatePageWithTokenParm} from './ValidatePage';
import {getAuthUsername,getAuthPassword,setAuthUsername,setAuthPassword} from './Authentication';
import {setToken} from './GetSetToken';
import getApiUrl from './GetApiUrl';
import {retrieveApiData,retrieveData2} from './data_retrieval';
import {setMenuData,getMenuData} from './InternaPageTreeItems';
import {useNavigate} from "react-router-dom";
import "./styles.css";
import logotransparent from "./v2/img/logotransparent.png"

const Signin = ()=> {

    const [modalTitle, setModalTitle] = useState("Warning Message");
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const [username, SetUsername] = useState('');
    const [password, SetPassword] = useState('');
    const [pin, SetPin] = useState(0);
    const [show_pin, SetShow_pin] = useState(false);

    useEffect(() => {
        let s_username = getAuthUsername();
        let s_password = getAuthPassword();
        SetUsername(getAuthUsername());
        SetPassword(getAuthPassword());
        console.log("useEffect-the_uid s_username",s_username);
        console.log("useEffect-the_password s_password",s_password);
    },[]);

    const onClicked = ()=> {
//        const api_url = process.env.NODE_ENV==='development'?process.env.REACT_APP_DEV_PROXY_URL:process.env.REACT_APP_QA_PROXY_URL;
        const api_url = getApiUrl();
        const url = api_url + '/api2/React2/Usp_react_login';

//        alert("Signin-url:" + url);
//        const httpsAgent = new https.Agent({
//            rejectUnauthorized: false,
//        })
        fetch(url, {
            headers: {'Content-Type': 'application/json','Access-Control-Allow-Origin':'*'},
//            agent: httpsAgent,
            mode:'cors',
            method: 'POST',
            body: JSON.stringify({
                'user_name': username,
                'password': password,
                'pin': pin,
            })
        })
        .then(response => response.json())
        .then(data => {
//            alert("data");
//            alert(data);
            console.log("sign-in data",data);
            if(data.success) {
                setAuthUsername(username);
                setAuthPassword(password);
                setToken(data.auth_token);
                let appList = JSON.parse(data.app_list);
                if(appList.length===0) {
                    setModalTitle("Your access to Quintarabio is pending to account review!");
                    setShowModal(true);
                    return;
                }
                let gui_id = appList[0].last_access_gui_id;
                let path = appList[0].last_access_path;
                console.log("data.success appList,path",appList,path);
//                alert("Signin");
                let isValidatedReturned = ValidatePageWithTokenParm(gui_id,data.auth_token);
                var isValidated = Promise.resolve(isValidatedReturned);
                isValidated.then(function(value) {
                    if(value===1) { //
//                        alert("page validated!");
                        let input_parm = [{"request_row_count":1},{"offset_row":0},{"request_rows":500},{"app_id":1}];
                        let tmp_data = retrieveApiData(1,10200,37,"usp_react_sql_retrieve2", JSON.stringify(input_parm));
                        Promise.resolve(tmp_data).then((value)=>{
                            console.log("Sinin-value",value);
//                            alert("row count value=" + value);
                            let total_row_count = parseInt(value);
                            let where_clause = [{"app_id":1}];
                            retrieveData2(1,10200,37,0,total_row_count,where_clause)
                            .then((retrieved_data) => {
                                console.log("Sign-in-retrieved_data",retrieved_data)
                                setMenuData(retrieved_data);
                                let newData = getMenuData();
                                navigate(path,{state:{token:data.auth_token,menuData:newData}});
                            })
                        })
                    } else {
                        setModalTitle("No Access To The Screen");
                        setShowModal(true);
                    }
                });
            } else {
                if(data.error_num===-1) {
                    setModalTitle("Incorrect Username/Password!");
                    setShowModal(true);
                } else if(data.error_num===-2) { // pin needed
                    setModalTitle("Need pin to validate login!");
                    setShowModal(true);
                    SetShow_pin(true);
                }
            }
                console.log('data', data);
        })
        .catch(err => {
            alert('fetch err-11:' + err);
        });    
}
  return (
      <>
        <div className="outer">
            <div className="inner">
                <form>
                    <img class="pb-5 img-fluid" src={logotransparent} alt="Quintara Biosciences" />
                    <h3>Log in</h3>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" className="form-control" placeholder="Enter email" autoComplete="username"
                            value = {username}
                            onChange={event => {SetUsername(event.target.value)}}
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" placeholder="Enter password" autoComplete="current-password" 
                            value = {password}
                            onChange={event => {SetPassword(event.target.value)}}
                        />
                    </div>
                    {show_pin ?
                    <div className="form-group">
                        <label>Pin</label>
                        <input type="number" className="form-control" placeholder="Enter pin" 
                            value = {pin}
                            onChange={event => {SetPin(parseInt(event.target.value))}}
                        />
                    </div>
                    :null
                    }
                    <div className="form-group">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                            <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-secondary btn-lg btn-block"
                        onClick={()=>onClicked()}
                    >Sign-In</button>
                    <p className="forgot-password text-center">
                        <a href="/reset-password#">Reset Password</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="/sign-up#">Registration</a>
                    </p>
                    {showModal ? <ResponseWindowOk title={modalTitle} setShowModal={setShowModal}/> : null}
                </form>
            </div>
        </div>
    </>
    )
}
export default Signin;

/*
                    {showModal ? <ResponseWindow title={modalTitle} setShowModal={setShowModal} /> : null}
*/