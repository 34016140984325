//BEGIN_IMPORT_SECTION
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import getToken, {setToken} from '../../GetSetToken.js';
import getApiUrl, {getPassthroughUrl} from '../../GetApiUrl';

var the_this
export default class SysLookupStringGrid extends React.Component  {

	state = {
		loaded:false,
		gridOptions:{},
		selected_lkup_str_id:0,
		data:[{lookup_id:0,id:0,value:"",description:"",create_by:"",create_dt:"",update_by:"",update_dt:"",}]
	}
	gridApi: GridApi
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: true })
		this.retrieveData()
	}
	onGridReady = params => {
    	this.gridApi = params.api;
 	 };


	retrieveData = () => {
//		let input_data = '{"id":1}'
//		let input_data = '{}'
		let input_data = this.props.lkup_id > 0 ? '{"lookup_id":' + this.props.lkup_id.toString() + '}' : '{}'
        const api_url = getPassthroughUrl();
		console.log("api_url",api_url)
        let the_pin = 0;
		let token = getToken();
		let the_input = '{"usp_name":"usp_react_sql_retrieve","sql_id":16, "input_data":' + input_data + '}';
        fetch(api_url, {
			method: 'POST',headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
        })
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			console.log('SysLkupStrGrid responseJson',responseJson)
			this.setState({retrieved:true});
			if(responseJson.success===true) {
				console.log('SysLkupStrGrid responseJson.output', responseJson.output);
				if(responseJson.output==='NO DATA')
					console.log('SysLkupStrGrid NO DATA')
				else {
					let statusCopy = Object.assign({}, this.state)
					let the_data = JSON.parse(responseJson.output);
					statusCopy.data = the_data;
					statusCopy.loaded = true;
					this.setState(statusCopy);
					console.log('SysLkupStrGrid-this.state.data', this.state.data);
					this.gridApi.setRowData(this.state.data)
					//this.state.data != null ? the_this.onColSelection_DW1(this.state.data[0].id) : the_this.onColSelection_DW1(0)
				}
			} else {
				console.log('SysLkupStrGrid responseJson.error', responseJson.error);
				console.log('SysLkupStrGrid responseJson.message', responseJson.message);
			}
		})	
		.catch((error) => {
			console.log("error",error)
			this.setState({retrieved:false});
//			alert('Please wait, as this may take few seconds to load...Error Encountered')
			//this.insertOrder(1);
		});
	}

	onSelectionChanged = (event)=>{
		const selectedRows =  event.api.getSelectedRows();
		const selectedNodes =  event.api.getSelectedNodes();
		console.log('SysLkupStrGrid selectedNodes:', selectedNodes);
		console.log('SysLkupStrGrid event:', event);
		this.gridApi.forEachNode(function(node, i){
			//console.log('AccessRoleGrid-onSelectionChanged node', node);
			if(node.selected===true) {// selected the data
				console.log('SysLkupStrGrid-onSelectionChanged selected===true node', node);
			}
		})
	}
	onCellValueChanged = ({ data }) => {
  		console.log("SysLkupStrGrid onCellClicked data", data);
	};
	onPasteStart(params) {
	  console.log('SysLkupStrGrid onPasteStart:', params);
	}
	onPasteEnd(params) {
	  console.log('SysLkupStrGrid Callback onPasteEnd:', params);
	}

	onCopySelectedRowsToClipboard(params) {
	  console.log('send to clipboard called with data:');
	  console.log(params.data);
	}
	onCellClicked(event){
//		console.log('AccessRoleGrid event', event.columnApi.columnController.columnDefs[0]);
		console.log("SysLkupStrGrid onCellClicked event", event);

	}
	onCellDoubleClicked(obj){
		console.log('SysLkupStrGrid onCellDoubleClicked(obj)', obj);
	}	
	onCellValueChanged(event) {
		console.log('SysLkupStrGrid onCellValueChanged(event)', event);
	}
	cellClicked(params) {
	console.log('SysLkupStrGrid cellClicked(params)', params);
	  params.node.setSelected(true)
	}
	onCellValueChanged = (obj) => {
		console.log('SysLkupStrGrid onCellValueChanged obj', obj);		
		let field_name = obj.column.colDef.field 
		let the_id = obj.data.id
		let the_lkup_id = obj.data.lookup_id
		let the_data = obj.value
		let token = getToken();
		if (typeof the_data==='string')
			the_data = '"' + the_data + '"'
		let the_input_data = '{"' + field_name + '":' + the_data + ',"id":' + the_id.toString() + ',"lookup_id":' + the_lkup_id.toString() + '}'
		let the_input = '{"usp_name":"usp_react_sql_onchange_update","sql_id":16,"input_data"' + ':' + the_input_data + '}'
		console.log('SysLkupStrGrid-onCellValueChanged the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {
			method: 'POST',headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
         })
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			console.log('SysLkupStrGrid-onCellValueChanged 2 no error');
		})	
		.catch((error) => {
			console.error(error-'Error in update!');
			//this.insertOrder(1);
		});
	}
	onAddRow = () => {
		let lkup_id = this.props.lkup_id > 0 ? this.props.lkup_id : 0
        let first_row = this.state.data === null ? 1 : 0 
        let lkup_num_id = first_row > 0 ? 1 : this.state.data[this.state.data.length - 1].id + 1 

		console.log('SysLkupStrGrid-onAddRow lkup_id', this.props.lkup_id, ' lkup_num_id', lkup_num_id);
		//let the_input_data = '{"id":"' + role_id.toString() + '",app_id":"' + app_id.toString() + '"}'
		let the_input_data = '{"lookup_id":' + lkup_id + ',"id":' + lkup_num_id + ',"description":"New Description" }'
		let the_input = '{"usp_name":"usp_react_sql_insert","sql_id":16,"input_data"' + ':' + the_input_data + '}'
		let token = getToken();
		console.log('SysLkupStrGrid-onAddRow token', token);
		console.log('SysLkupStrGrid-onAddRow the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {method: 'POST', headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})})
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			if(responseJson.output==='Insert OK')
				this.retrieveData()
		})	
		.catch((error) => {
			console.error(error-'Error in AddRow!');
			//this.insertOrder(1);
		});
	}
	onDeleteRow = () => {
		console.log("this.gridApi.rowModel",this.gridApi.rowModel)
        if (window.confirm("Confirm Delete?")){

            let selectedRow = []
            let max_sample_id = 0
            let token = getToken();
            this.gridApi.forEachNode(function(node, i){
            //	console.log('AccessRoleGrid-onDeleteRow node', node);
                if(node.selected===true) {// delete the data
                    let lkup_num_id = node.data.id
                    let lkup_id = node.data.lookup_id				
                    let the_input_data = '{"id":' + lkup_num_id.toString() + ',"lookup_id":' + lkup_id.toString() +'}'
                    let the_input = '{"usp_name":"usp_react_sql_delete","sql_id":16,"input_data"' + ':' + the_input_data + '}'
            //		console.log('AccessRoleGrid-DeleteRow the_input', the_input);
                    const api_url = getPassthroughUrl();
                    return fetch(api_url, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', },
                        body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
                    })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        console.log('SysLkupStrGrid-responseJson', responseJson);
                        console.log('SysLkupStrGrid-responseJson.success', responseJson.success);
                        if(responseJson.success===true) {
                            let statusCopy = Object.assign({}, the_this.state)
                            statusCopy.data.splice(i, 1);
                            the_this.gridApi.setRowData(statusCopy.data)
                        }
                    })	
                    .catch((error) => {
                        console.log('SangerSeqOrderDetail2-error', error);
                    });
                }
    		})
        }     
	}
	createGridOptions() {
		const options: GridOptions = {
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true
			},
			columnDefs: [
				{headerName:"LookupID",width: 80, headerClass:"header-lookup_id",field:"lookup_id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.lookup_id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"ID",width: 80, headerClass:"header-id",field:"id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Value",width: 80, headerClass:"header-value",field:"value",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.value= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Description",width: 200,headerClass:"header-Description",field:"description",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.description= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Create_by",width: 80,headerClass:"header-create_by",field:"create_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Create_dt",width: 80,headerClass:"header-create_dt",field:"create_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_dt= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_by",width: 80,headerClass:"header-update_by",field:"update_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_dt",width: 80,headerClass:"header-update_dt",field:"update_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_dt= params.newValue;return true;},suppressNavigable:true,},
			],
		}
		return options;
	}
	render() {
/*
		if(this.state.loaded===false) {
			this.retrieveData()
		}
*/
		return  (
			<div style={{position:"absolute",top:0,width:650,height:200,backgroundColor:"#FFFFFF"}}>
				<div style={{position:"absolute",left:0,top:0,width:580,height:25,backgroundColor:"#FFFFFF",textAlign:"center"}}>
                Sys Lookup String Grid
				</div>
				<div className="ag-theme-balham" 
                    style={{position:"absolute",top:25,left:0,width:580,height:175,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={"single"} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
				<div id="pid_0630_cell_001_002"
                    style={{position:"absolute",top:25,left:580,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.retrieveData}>Refresh
					</button>
				</div>
				<div id="pid_0630_cell_001_003"
                    style={{position:"absolute",top:55,left:580,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onAddRow}>Add
					</button>
				</div>
				<div id="pid_0630_cell_001_004"
                    style={{position:"absolute",top:85,left:580,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onDeleteRow}>
						Delete
					</button>
				</div>
			</div>
		)
	}
}
