import React, { useState, useEffect, useRef } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import {ResponseWindow} from './Modals';
import './App.css';
import BusinessHome from './BusinessHome'
import WebSite from "./WebSite";
import IdleTimeContext from "./IdleTimeContext"


export default function App () {
  const [modalTitle, setModalTitle] = useState("Warning Message");
  const [showModal, setShowModal] = useState(false);

  let timeout = 1200000
  let remaining = 1200000
  let elapsed = 0
  let lastActive = new Date()
  let isIdle = false

  const handleOnActive = () => {isIdle=false}
  const handleOnIdle = () => {isIdle=true}

  const {
    reset,
    pause,
    resume,
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  })

  const handleReset = () => reset()
  const handlePause = () => pause()
  const handleResume = () => resume()
  const getRemainingTime2 = () => {remaining=getRemainingTime();return remaining}
  const getLastActiveTime2 = () => {lastActive=getLastActiveTime();return lastActive}
  const getElapsedTime2 = () => {elapsed=getElapsedTime();return elapsed}
  const isIdle2 = ()=>{return isIdle}
  
  useEffect(() => {
    handleOnIdle()
  }, [])

  return (
    <>
      <IdleTimeContext.Provider
        value={{
          handleReset:handleReset,
          handlePause:handlePause,
          handleResume:handleResume, 
          getRemainingTime:getRemainingTime2,
          getLastActiveTime:getLastActiveTime2,
          getElapsedTime:getElapsedTime2,
          isIdle:isIdle2
        }}
      >
        <WebSite />
        {showModal ? <ResponseWindow title={"timeout"} setShowModal={setShowModal} /> : null}
      </IdleTimeContext.Provider>
`   </>        
  )
}
