//BEGIN_IMPORT_SECTIONs
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import getToken, {setToken} from '../../GetSetToken.js';
import getApiUrl, {getPassthroughUrl} from '../../GetApiUrl';
import AccessUserRoleGrid from './accessuserrolegrid';
import AccessUserGuiGrid from './accessuserguigrid';
import DataRetrieval from '../../data_retrieval';

var the_this
export default class UserAccountGrid extends React.Component {
	state = {
		loaded:false,
		gridOptions:{},
		selected_role_id:0,
		data:[]
//		data:[{"user_id": 1, "access_failed_count": 0, "display_name": "", "email": "", "password": "", "phone": null, "last_name": null, "first_name": null,"address": null, "city": null, "state_province": null, "country": null, "pin": 0, "active_ind": null, "zip_code": null},{"address": null, "city": null, "state_province": null, "country": null, "pin": 0, "active_ind": null, "zip_code": null,"sec_verify_method": null, "reset_password_ind": null, "last_access": null, "note": null, "create_by": 0, "create_dt": "2022-06-16 21:32:51", "update_by": 0, "update_dt": "2022-06-16 21:32:51"},]
	}
	gridApi: GridApi
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: true })
		this.retrieveData()
	}
	onGridReady = params => {
    	this.gridApi = params.api;
 	 };

	onUserSelection_DW1 = (p_user_id)=>{
		console.log('onUserSelection_DW1');
		var dataDiv = <div >
			<AccessUserRoleGrid
				id = "01010101UserRoleGrid_instance"
				value = ""
				font_color = "#FFFFFF"
				background_color = "#ffffff"
				font_family = "Arial"
				font_size = "10"
				user_id = {p_user_id}
				getUserId = {(user_id) => { this.getUserId(p_user_id)}}
				/>
  		</div>	
		ReactDOM.unmountComponentAtNode(document.getElementById('01010101UserRoleGrid'))
		ReactDOM.render(dataDiv, document.getElementById('01010101UserRoleGrid'))
		return (
				dataDiv
		)			
	}	

	onUserSelection_DW2 = (p_user_id)=>{
		console.log('onUserSelection_DW2');
		let selected_app_id = window.selected_app_id > 0 ? window.selected_app_id : 1
		var dataDiv = 	<div >
		<AccessUserGuiGrid
				id = "01010101UserGuiGrid_instance"
				value = ""
				font_color = "#FFFFFF"
				background_color = "#ffffff"
				font_family = "Arial"
				font_size = "10"
				user_id = {p_user_id}
				getUserId = {(user_id) => { this.getUserId(p_user_id)}}        
				/>
		</div>
		ReactDOM.unmountComponentAtNode(document.getElementById('01010101UserGuiGrid'))
		ReactDOM.render(dataDiv, document.getElementById('01010101UserGuiGrid'))
		return (
				dataDiv
		)			
	}	

	retrieveData() {
		let input_data = '{"request_row_count":1}'
		let sql_id = 2;
		let app_id = 1;
		let data_count = DataRetrieval(app_id,10200,sql_id,input_data);
		let total_row_count = 0;
		let current_row_index = 0;
		let remaining_rows = 0;
		let prev_remaining_rows = 0;
		var timer_user = 0;

		Promise.resolve(data_count).then((value)=>{
			total_row_count = parseInt(value);
			remaining_rows = total_row_count;
			let timer = 0;
			let running_token_is_on = 0;
			let the_data = [];

			if(total_row_count>0) {
				input_data = '{"request_row_count":0,"offset_row":' + current_row_index.toString() + ',"request_rows":' + remaining_rows.toString() + '}'
					let tmp_data = DataRetrieval(app_id,10200,sql_id,input_data);
					Promise.resolve(tmp_data).then((value)=>{
						the_data = the_data.concat(JSON.parse(value))
						let statusCopy = Object.assign({}, this.state)
						statusCopy.data = the_data;
						statusCopy.loaded = true;
						this.setState(statusCopy);
						console.log('AccessUserRoleGrid-this.state.data', this.state.data);
						this.gridApi.setRowData(this.state.data)
					})
			}
		})
/*

		Promise.resolve(data_count).then((value)=>{
			total_row_count = parseInt(value);
			remaining_rows = total_row_count;
			let timer = 0;
			let running_token_is_on = 0;
			let the_data = [];

			if(total_row_count>0) {
				const getData=() => {
					console.log("TIMER IS RUNNING . . . ")
					input_data = '{"request_row_count":0,"offset_row":' + current_row_index.toString() + ',"request_rows":' + remaining_rows.toString() + '}'
					if(running_token_is_on===0) {
						running_token_is_on = 1;
						let tmp_data = DataRetrieval(app_id,10200,sql_id,input_data);
						Promise.resolve(tmp_data).then((value)=>{
							try {
								the_data = the_data.concat(JSON.parse(value))
								current_row_index = the_data.length;
								prev_remaining_rows = remaining_rows;
								remaining_rows = total_row_count - the_data.length;
								console.log('AccessUserRoleGrid retrieveData remaining_rows', remaining_rows);
								if(remaining_rows<=0||remaining_rows===prev_remaining_rows) {
									window.clearInterval(timer_user);
									console.log('AccessUserRoleGrid retrieveData the_data', the_data);
									let statusCopy = Object.assign({}, this.state)
									statusCopy.data = the_data;
									statusCopy.loaded = true;
									this.setState(statusCopy);
									console.log('AccessUserRoleGrid-this.state.data', this.state.data);
									this.gridApi.setRowData(this.state.data)
									timer_user = 0;
								} else {
									running_token_is_on = 0;
								}
							} catch (e) {
								remaining_rows = 0;
								window.clearInterval(timer_user);
								console.log('Not JSON');
							}
						})
					}
				}
				timer_user = window.setInterval(getData, 200);
			}
		})



//		let input_data = '{"id":1}'
		let input_data = '{}'
        const api_url = getPassthroughUrl();
		console.log("api_url",api_url)
        let the_pin = 0;
		let token = getToken();
		let the_input = '{"usp_name":"usp_react_sql_retrieve","sql_id":2, "input_data":' + input_data + '}';
        fetch(api_url, {
			method: 'POST',headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
        })
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			console.log('AccessUseraccountGrid responseJson',responseJson)
			this.setState({retrieved:true});
			if(responseJson.success===true) {
				console.log('AccessUseraccountGrid responseJson.output', responseJson.output);
				if(responseJson.output==='NO DATA')
					console.log('AccessUseraccountGrid NO DATA')
				else {
					let statusCopy = Object.assign({}, this.state)
					let the_data = JSON.parse(responseJson.output);
					statusCopy.data = the_data;
					statusCopy.loaded = true;
					this.setState(statusCopy);
					console.log('AccessUseraccountGrid-this.state.data', this.state.data);
					this.gridApi.setRowData(this.state.data)
				}
			} else {
				console.log('AccessUseraccountGrid responseJson.error', responseJson.error);
				console.log('AccessUseraccountGrid responseJson.message', responseJson.message);
			}
		})
		.catch((error) => {
			console.log("error",error)
			this.setState({retrieved:false});
//			alert('Please wait, as this may take few seconds to load...Error Encountered')
			//this.insertOrder(1);
		});
*/

	}
	onSelectionChanged = (event)=>{
		const selectedRows =  event.api.getSelectedRows();
		const selectedNodes =  event.api.getSelectedNodes();
		console.log('AccessUseraccountGrid selectedNodes:', selectedNodes);
		console.log('AccessUseraccountGrid event:', event);
		/*
		this.gridApi.forEachNode(function(node, i){
			console.log('AccessRoleGrid-onSelectionChanged node', node);
			if(node.selected===true) {// selected the data
				console.log('AccessRoleGrid-onSelectionChanged selected===true node', node);
				the_this.props.getRoleId(node.data.id)
			}
		})
		*/
	}
	onCellValueChanged = ({ data }) => {
  		console.log("AccessUseraccountGrid onCellClicked data", data);
	};
	onPasteStart(params) {
	  console.log('AccessUseraccountGrid onPasteStart:', params);
	}
	onPasteEnd(params) {
	  console.log('AccessUseraccountGrid Callback onPasteEnd:', params);
	}

	onCopySelectedRowsToClipboard(params) {
	  console.log('send to clipboard called with data:');
	  console.log(params.data);
	}
	onCellClicked(event){
//		console.log('AccessUseraccountGrid event', event.columnApi.columnController.columnDefs[0]);
		console.log("AccessUseraccountGrid onCellClicked event", event);

	}
	onCellDoubleClicked(obj){
		console.log('AccessUseraccountGrid onCellDoubleClicked(obj)', obj);
	}	
	onCellValueChanged(event) {
		console.log('AccessUseraccountGrid onCellValueChanged(event)', event);
	}
	cellClicked(params) {
	console.log('AccessUseraccountGrid cellClicked(params)', params);
	  params.node.setSelected(true)
	}
	onCellValueChanged = (obj) => {
		console.log('AccessUseraccountGrid onCellValueChanged obj', obj);		
		let field_name = obj.column.colDef.field 
		let the_id = obj.data.user_id
		let the_data = obj.value
		let token = getToken();
		if (typeof the_data==='string')
			the_data = '"' + the_data + '"'
		let the_input_data = '{"' + field_name + '":' + the_data + ',"user_id":' + the_id.toString() + '}'
		let the_input = '{"usp_name":"usp_react_sql_onchange_update","sql_id":2,"input_data"' + ':' + the_input_data + '}'
		console.log('AccessUseraccountGrid-onCellValueChanged the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {
			method: 'POST',headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
         })
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			console.log('AccessUseraccountGrid-onCellValueChanged 2 no error');
		})	
		.catch((error) => {
			console.error(error-'Error in update!');
			//this.insertOrder(1);
		});
	}
	onAddRow = () => {
		let newData = [{id:0,name:"",description:"",inclusive_ind:"",note:"",create_by:"",create_dt:"",update_by:"",update_dt:"",}]
		let the_input_data = '{"email":"New User Email"}'
		let the_input = '{"usp_name":"usp_react_sql_insert","sql_id":2,"input_data"' + ':' + the_input_data + '}'
		let token = getToken();
		console.log('AccessUseraccountGrid-onAddRow token', token);
		console.log('AccessUseraccountGrid-onAddRow the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {method: 'POST', headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})})
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			if(responseJson.output==='Insert OK')
				this.retrieveData()
		})	
		.catch((error) => {
			console.error(error-'Error in AddRow!');
			//this.insertOrder(1);
		});
	}
	onDeleteRow = () => {
		console.log("this.gridApi.rowModel",this.gridApi.rowModel)
		if (window.confirm("Confirm Delete?")){
			let selectedRow = []
			let max_sample_id = 0
			let token = getToken();
			this.gridApi.forEachNode(function(node, i){
	//			console.log('AccessRoleGrid-onDeleteRow node', node);
				if(node.selected===true) {// delete the data
					let user_id = node.data.user_id
					let the_input_data = '{"user_id":' + user_id.toString() + '}'
					let the_input = '{"usp_name":"usp_react_sql_delete","sql_id":2,"input_data"' + ':' + the_input_data + '}'
	//				console.log('AccessRoleGrid-DeleteRow the_input', the_input);
					const api_url = getPassthroughUrl();
					return fetch(api_url, {method: 'POST', headers: { 'Content-Type': 'application/json', },
						body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
					})
					.then((response) => response.json())
					.then((responseJson) => {
						console.log('AccessUseraccountGrid-responseJson', responseJson);
						console.log('AccessUseraccountGrid-responseJson.success', responseJson.success);
						if(responseJson.success===true) {
						let statusCopy = Object.assign({}, the_this.state)
						statusCopy.data.splice(i, 1);
						the_this.gridApi.setRowData(statusCopy.data)
						//--------Cascading Deletes of children GUIs
							the_input_data = '{"user_id":' + user_id.toString() +'}'
							//the_input = '{"usp_name":"usp_react_sql_general","sql_id":1,"input_data"' + ':' + the_input_data + '}'
							the_input = '{"usp_name":"usp_react_sql_subsql","sql_id":2,"input_data"' + ':' + the_input_data + '}'
							const api_url = getPassthroughUrl();
							return fetch(api_url, {
								method: 'POST',
								headers: { 'Content-Type': 'application/json', },
								body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
							})
							.then((response) => response.json())
							.then((responseJson) => {
								console.log('AccessRoleGrid-responseJson', responseJson);
								console.log('AccessRoleGrid-responseJson.success', responseJson.success);
								/*if(responseJson.success===true) {
								let statusCopy = Object.assign({}, the_this.state)
								statusCopy.data.splice(i, 1);
								the_this.gridApi.setRowData(statusCopy.data)
								}*/
								the_this.onUserSelection_DW1(user_id);
								the_this.onUserSelection_DW1(user_id);
							})	
							.catch((error) => {
								console.log('SangerSeqOrderDetail2-error', error);
							});
						//--------End of Cascading Delete Logic
						}
					})	
					.catch((error) => {
						console.log('SangerSeqOrderDetail2-error', error);
					});
				}
			})
		}	//--If Confirmed Deletion
	}
	createGridOptions() {
		const options: GridOptions = {
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true
			},
			columnDefs: [
				{headerName:"UserID",headerClass:"header-user_id",field:"user_id",hide:false,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.user_id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"access_failed_count",headerClass:"header-access_failed_count",field:"access_failed_count",hide:false,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.access_failed_count= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"display_name",headerClass:"header-display_name",field:"display_name",hide:false,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.display_name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"email",headerClass:"header-email",field:"email",hide:false,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.email= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"password",headerClass:"header-password",field:"password",hide:false,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.passwordhash= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"phone",headerClass:"header-phone",field:"phone",hide:false,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.phone= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"last_name",headerClass:"header-last_name",field:"last_name",hide:false,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.last_name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"first_name",headerClass:"header-first_name",field:"first_name",hide:false,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.first_name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"mid_name",headerClass:"header-mid_name",field:"mid_name",hide:false,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.mid_name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"address",headerClass:"header-address",field:"address",hide:false,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.address= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"city",headerClass:"header-city",field:"city",hide:false,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.city= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"state_province",headerClass:"header-state_province",field:"state_province",hide:false,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.state_province= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"country",headerClass:"header-country",field:"country",hide:false,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.country= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"pin",headerClass:"header-pin",field:"pin",hide:false,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.pin= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"active_ind",headerClass:"header-active_ind",field:"active_ind",hide:false,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.active_ind= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"title",headerClass:"header-title",field:"title",hide:false,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.title= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"zip_code",headerClass:"header-zip_code",field:"zip_code",hide:false,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.zip_code= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"sec_verify_method",headerClass:"header-sec_verify_method",field:"sec_verify_method",hide:false,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"reset_password_ind",headerClass:"header-reset_password_ind",field:"reset_password_ind",hide:false,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.reset_password_ind= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"last_access",headerClass:"header-last_access",field:"last_access",hide:false,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.last_access= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"note",headerClass:"header-note",field:"note",hide:false,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.note= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"create_by",headerClass:"header-create_by",field:"create_by",hide:false,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"create_dt",headerClass:"header-create_dt",field:"create_dt",hide:false,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_dt= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"update_by",headerClass:"header-update_by",field:"update_by",hide:false,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"update_dt",headerClass:"header-update_dt",field:"update_dt",hide:false,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_dt= params.newValue;return true;},suppressNavigable:true,},
			],
		}
		return options;
	}
	render() {
/*
		if(this.state.loaded===false) {
			this.retrieveData()
		}
*/
		return  (
			<div id="pid_0630_cell_001" style={{position:"absolute",top:0,width:1500,height:180,backgroundColor:"#FFFFFF"}}>
				<div id="pid_0630_cell_001_001" className="ag-theme-balham" 
                    style={{position:"absolute",top:0,left:0,width:1500,height:150,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={"single"} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
				<div id="pid_0630_cell_001_002"
                    style={{position:"absolute",top:0,left:1500,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.retrieveData}>Refresh
					</button>
				</div>
				<div id="pid_0630_cell_001_003"
                    style={{position:"absolute",top:30,left:1500,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onAddRow}>Add
					</button>
				</div>
				<div id="pid_0630_cell_001_004"
                    style={{position:"absolute",top:60,left:1500,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onDeleteRow}>
						Delete
					</button>
				</div>
			</div>
		)
	}
}

//[{"login_user_id": 1, "access_failed_count": 0, "display_name": "ZoftTest01@gmail.com", "email": "ZoftTest01@gmail.com", "PasswordHash": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "PhoneNumber": null, "UserName": "ZoftTest01@gmail.com", "last_name": null, "first_name": null, "address": null, "city": null, "state_province": null, "country": null, "TextMsg": null, "phone": null, "title": null, "profession": null, "profession_id": null, "pin": 0, "active_ind": null, "mid_name": null, "zip_code": null, "sec_verify_method": null, "reset_password_ind": null, "last_access": null, "note": null, "create_by": 0, "create_dt": "2022-05-18 01:43:40", "update_by": 0, "update_dt": "2022-05-18 01:43:40"},{"login_user_id": 2, "access_failed_count": 0, "display_name": "SuperUser@quintarabio.com", "email": "SuperUser@quintarabio.com", "PasswordHash": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "PhoneNumber": null, "UserName": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "last_name": null, "first_name": null, "address": null, "city": null, "state_province": null, "country": null, "TextMsg": null, "phone": null, "title": null, "profession": null, "profession_id": null, "pin": 0, "active_ind": null, "mid_name": null, "zip_code": null, "sec_verify_method": null, "reset_password_ind": null, "last_access": null, "note": null, "create_by": 0, "create_dt": "2022-05-18 01:45:40", "update_by": 0, "update_dt": "2022-05-18 01:45:40"}]