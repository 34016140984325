import React, {useRef, useState, useEffect, useContext} from 'react';
import ReactDom from "react-dom";

import {retrieveApiData,retrievalFragment2} from './data_retrieval';

export const ModalRetrieve  = ({rowCountInd,appId,guiId,sqlId,inputParm,setResponse})=> {
    let retrieved = 0;
    let prev_retrieved = 0;
    const elementRef = useRef();
    const [completion,setCompletion] = useState("Data Loading . . . 0%");
    let li_completion = 0;
    let repeated = false;
    const asyncRetrieve = async(app_id,gui_id,sql_id,total_request_rows,offset_row,request_rows,where_clause)=>{
        console.log('ModalRetrieve asyncRetrieve,where_clause',where_clause);
        const fragment = await retrievalFragment2(app_id,gui_id,sql_id,offset_row,request_rows,where_clause);
        console.log('ModalRetrieve asyncRetrieve,fragment - app_id,gui_id,sql_id,retrieved',fragment, app_id,gui_id,sql_id,retrieved);
        if(fragment==="") return [];
        let fragment_json = JSON.parse(fragment);
        if(fragment_json===null) return []; 
        elementRef.current.innerHTML = "Data Loading . . . 0%"
        retrieved = retrieved + fragment_json.length
        if(retrieved===prev_retrieved)
            repeated = true;
        prev_retrieved = retrieved
        if(retrieved<total_request_rows&&!repeated) {
            let new_offset_row = offset_row + fragment_json.length;
            let new_request_rows = request_rows - fragment_json.length;
            li_completion = Math.floor(retrieved*100/total_request_rows);
//            setCompletion("Data Loading . . . " + li_completion.toString() + "%");
            console.log('ModalRetrieve asyncRetrieve,retrieved,total_request_rows,li_completion,fragment_json',retrieved,total_request_rows, li_completion,fragment_json);
            setTimeout(elementRef.current.innerHTML = "Data Loading . . . " + li_completion.toString() + "%",100);
            return fragment_json.concat(await asyncRetrieve(app_id,gui_id,sql_id,total_request_rows,new_offset_row,new_request_rows,where_clause));
        } else {
            li_completion = Math.floor(retrieved*100/total_request_rows);
//            console.log('ModalRetrieve asyncRetrieve,li_completion,retrieve_data_json DONE',li_completion,retrieve_data_json);
            console.log('ModalRetrieve asyncRetrieve,li_completion',li_completion);
            elementRef.current.innerHTML = "Data Loading . . . 100%";
//            setCompletion("Data Loading . . . 100%");
            return fragment_json;
        }
    }

	useEffect(() => {
        console.log('ModalRetrieve useEffect appId,guiId,sqlId,inputParm,setResponse',appId,guiId,sqlId,inputParm,setResponse);
        setTimeout(()=>{ 
            let input_parm0 = inputParm;
            let tmp_data = retrieveApiData(appId,guiId,sqlId,"usp_react_sql_retrieve2", JSON.stringify(input_parm0));
            let where_clause = input_parm0.slice(3,input_parm0.length);
            console.log('ModalRetrieve useEffect input_parm0,where_clause',input_parm0,where_clause);
            if(rowCountInd===1) {
                Promise.resolve(tmp_data).then((value)=>{
                    console.log('ModalRetrieve retrieveData value',value);
                    let total_row_count = parseInt(value);
                    console.log('ModalRetrieve total_row_count',total_row_count);
                    asyncRetrieve(appId,guiId,sqlId,total_row_count,0,total_row_count,where_clause)
                    .then((retrieved_data) => {
                        setResponse(retrieved_data);
                    })
                });
            } else {
                asyncRetrieve(appId,guiId,sqlId,inputParm[2].request_rows,inputParm[1].offset_row,inputParm[2].request_rows,where_clause)
                .then((retrieved_data) => {
                    setResponse(retrieved_data);
                });
            }
        }, 100);
    },[]);
    useEffect(() => {
    },[completion]);        
    return ReactDom.createPortal(
        <div className="container" >
            <div className="modal">
                <div ref={elementRef} style={{position:"absolute",left:20,top:100,width:300,height:60,textAlign:"center",fontSize:16,fontWeight:"bold"}}>"Data Loading . . . 0%"</div>
            </div>
        </div>,
         document.getElementById("portal")
    );
}

export default ModalRetrieve;

/*
    const loadingBar = document.getElementById('loadingBar');
    async function task() {
      return new Promise(res => {
        setTimeout(res, Math.random() * 5000);
      })
    }    
    function loadingBarStatus(current, max) {
      loadingBar.textContent = `Loading ${current} of ${max}`;
    }
    (async() => {
      let current = 1;
      const promises = new Array(100)
        .fill(0)
        .map(() => task().then(() => loadingBarStatus(current++, 100)));
      await Promise.all(promises);
      loadingBar.textContent = `Loading Finished`;
    })();    
                <div ref={elementRef} style={{position:"absolute",left:20,top:100,width:300,height:60,textAlign:"center",fontSize:16,fontWeight:"bold"}}>{completion}</div>

                <div ref={elementRef} style={{position:"absolute",left:20,top:100,width:300,height:60,textAlign:"center",fontSize:16,fontWeight:"bold"}}></div>

*/