import React, {Component, useState, useEffect, useContext} from 'react';
import {ResponseWindowOk,ResponseWindowYesNo,ResponseWindowOkCancel,
ResponseWindow2Options,ResponseWindow3Options,ResponseWindowOk2,
ResponseWindowYesNo2,ResponseWindowOkCancel2,ResponseWindow2Options2,
ResponseWindow3Options2} from '../../../Modals';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//import zeditbox1 from 'zeditbox1';
import ZDropdownList from '../../../zcomponents/ZDropdownList0';
import ZEditText from '../../../zcomponents/ZEditText';
import ZTextButton from '../../../zcomponents/ZTextButton';
import ZAutoCompleteEditText from '../../../zcomponents/ZAutoCompleteEditText';
import {retrieveApiData,zoftApiForUsp} from '../../../data_retrieval';

import BillGroupManageListDataGrid from './billgroupmanagelistdatagrid';
import BillGroupManageDetailDataGrid from './billgroupmanagedetaildatagrid';
//import de from 'date-fns/esm/locale/de/index.js';

//set @input='[{"request_row_count":1},{"offset_row":0},{"request_rows":99999},{"invoice_id":0},{"invoice_status_id":0},{"billgroup":""},{"category_id":0},{"institution":""},{"dateStart":"2021-01-01"},{"dateEnd":"2022-10-31"},{"po":""},{"recalculate_totaldue":0},{"q_maxonly":0},{"exclude_paid":0},{"exclude_cancelled":0},{"exclude_nih":0}]';
const BillGroupManageDataPage  = ()=> {
    const [modalTitle, setModalTitle] = useState("Warning Message");
    const [response, setResponse] = useState(-1);
    const [showModalOk, setShowModalOk] = useState(false);
    const [showModalYesNo, setShowModalYesNo] = useState(false);
    const [institution_list, setInstitution_list] = useState([]);
    const [institution_list2, setInstitution_list2] = useState([]);
    const [status_list, setStatus_list] = useState([]);
    const [delivery_method_list, setDelivery_method_list] = useState([]);
    const [name_list, setName_list] = useState([]);
    const [costcenter_list, setCostcenter_list] = useState([]);
    const [email_to_list, setEmail_to_list] = useState([]);
    const [search_clicked, setSearch_clicked] = useState(false);
    const [prev_clicked, setPrev_clicked] = useState(false);
    const [next_clicked, setNext_clicked] = useState(false);
    const [retrieve_clicked, setRetrieve_clicked] = useState(false);
    const [new_record_in_detail, setNew_record_in_detail] = useState(false);
    const [update_mode,setUpdate_mode] = useState(0);
    const [new_record_inserted_or_updated,setNew_record_inserted_or_updated] = useState(false);
    const [deleting, setDeleting] = useState({"request_deleting":false,"deleted":false,"post_processed":false,"i":-1});
    const [dataCount, setDataCount] = useState(0);
    const type_string = 1;
    const type_number = 0;
    let yesNoSelection = [{id:-1,name:""},{id:0,name:"No"},{id:1,name:"Yes"}];    

    const [current_data_from_list,setCurrent_data_from_list] = useState({i:0,
        data:{
            data:{"id": -1, 
            "name":"",
            "costcenter_id":0,
            "institution_id":0, 
            "status_id":0,
            "bill_frequency":30,
            "term":"",
            "is_autobill":0,
            "is_active":0,
            "is_invoice_per_po":0
            ,"cur_inv_id":0,
            "po_required":0,
            "po":"",
            "is_prepaid":0,
            "review_required":0,
            "email_to":"",
            "email_cc":"",
            "inv_email_subject":"",
            "inv_email_body":"",
            "review_email_to":"",
            "billto_address_id":0,
            "billing_attn":"",
            "billto_room":"",
            "quote_id":0,
            "notes":"",
            "shipto_address_id":0,
            "shipping_attn":"",
            "shipto_room":"",
            "delivery_method":0,
            "prepaid_payment_id":0,
            "cc_onfile":0}}
        }
    );
    const [current_data_from_detail,setCurrent_data_from_detail] = useState(
        {new_record_inserted_or_updated:false,
            i:-1,
            data:{
                data:{"id": 0, 
                    "name":"",
                    "costcenter_id":0,
                    "institution_id":0, 
                    "status_id":0,
                    "bill_frequency":30,
                    "term":"",
                    "is_autobill":0,
                    "is_active":0,
                    "is_invoice_per_po":0
                    ,"cur_inv_id":0,
                    "po_required":0,
                    "po":"",
                    "is_prepaid":0,
                    "review_required":0,
                    "email_to":"",
                    "email_cc":"",
                    "inv_email_subject":"",
                    "inv_email_body":"",
                    "review_email_to":"",
                    "billto_address_id":0,
                    "billing_attn":"",
                    "billto_room":"",
                    "quote_id":0,
                    "notes":"",
                    "shipto_address_id":0,
                    "shipping_attn":"",
                    "shipto_room":"",
                    "delivery_method":0,
                    "prepaid_payment_id":0,
                    "cc_onfile":0
                }
            }    
        }
    );
    const [input_parm, setInput_parm] = useState({
            "request_row_count":1,
            "offset_row":0,
            "request_rows":1000,
            "id":0,
            "name":"",
            "costcenter_id":0,
            "institution":"",
            "quote_id":0,
            "status_id":0,
            "delivery_method":0,
            "email_to":"",
            "po":"",
            "po_required":-1,
            "is_active":-1,
            "is_autobill":-1,
            "is_invoice_per_po":-1,
        }
    );
    let input_parm0 = {
        "request_row_count":1,
        "offset_row":0,
        "request_rows":1000,
        "id":0,
        "name":"",
        "costcenter_id":0,
        "institution":"",
        "quote_id":0,
        "status_id":0,
        "delivery_method":0,
        "email_to":"",
        "po":"",
        "po_required":-1,
        "is_active":-1,
        "is_autobill":-1,
        "is_invoice_per_po":-1,
    }        

    const [billgroup_retrieved, setBillgroup_retrieved] = useState({
        "from_row":0,
        "to_row":0,
        "total_rows":0,
    }
    );
    const setBillgroup_prev_list=(rows)=>{
        if(rows>0) {
            let new_from_row = billgroup_retrieved.from_row - rows
            if(new_from_row<1)
                new_from_row = 1
            let new_to_row = new_from_row + rows - 1
            let retrieved_tmp = billgroup_retrieved
            retrieved_tmp.from_row = new_from_row
            retrieved_tmp.to_row = new_to_row
            setBillgroup_retrieved({...retrieved_tmp})
            console.log("BillgroupManageDataPage setBillgroup_prev_list rows, retrieved_tmp",rows, retrieved_tmp)
        }
    }
    const setBillgroup_next_list=(rows)=>{
        if(rows>0) {
            let new_from_row = billgroup_retrieved.to_row + 1
            let new_to_row = new_from_row + rows - 1
            let retrieved_tmp = billgroup_retrieved
            retrieved_tmp.from_row = new_from_row
            retrieved_tmp.to_row = new_to_row
            setBillgroup_retrieved({...retrieved_tmp})
            console.log("BillgroupManageDataPage-setBillgroup_next_list rows retrieved_tmp",rows,retrieved_tmp)
        }
    }
    const setBillgroup_search_list=(rows,total_rows)=>{
        if(rows>0) {
            let new_from_row = 1
            let new_to_row = rows
            let retrieved_tmp = billgroup_retrieved
            retrieved_tmp.from_row = new_from_row
            retrieved_tmp.to_row = new_to_row
            retrieved_tmp.total_rows =total_rows
            setBillgroup_retrieved({...retrieved_tmp})
    //            setInvoice_retrieved({"invoice_from_row":new_from_row,"invoice_to_row":new_to_row,"total_invoice_rows":total_rows})
            console.log("EditInvoiceStatusDataPage-setBillgroup_search_list rows,total_rows",retrieved_tmp)
        }
    }
    
    

	useEffect(() => { // initialization-didMount
        console.log("BillGroupManageDataPage-componentDidMount")
        let input_data = '[{"request_row_count":0},{"offset_row":0},{"request_rows":99999},{"type":"Billing"}]'
        let tmp_data = retrieveApiData(1,30102,33,"usp_react_sql_retrieve2", input_data);
        Promise.resolve(tmp_data).then((value)=>{
            try {
                console.log("BillGroupManageDataPage-useEffect,33-value",value)
                setStatus_list(JSON.parse(value))
            } catch (e) {
                console.log('Not JSON');
            }
        })
        let input_data1 = '[{"request_row_count":0},{"offset_row":0},{"request_rows":99999}]'
        let tmp_data1 = retrieveApiData(1,30102,40,"usp_react_sql_retrieve2", input_data1);
        Promise.resolve(tmp_data1).then((value)=>{
            try {
                console.log("BillGroupManageDataPage-useEffect,40-value",value)
                setCostcenter_list(JSON.parse(value))
            } catch (e) {
                console.log('Not JSON');
            }
        })
        let input_data2 = '[{"request_row_count":0},{"offset_row":0},{"request_rows":99999}]'
        let tmp_data2 = retrieveApiData(1,30102,41,"usp_react_sql_retrieve2", input_data2);
        Promise.resolve(tmp_data2).then((value)=>{
            try {
                console.log("BillGroupManageDataPage-componentDidMount,value",value)
                setInstitution_list2(JSON.parse(value))
            } catch (e) {
                console.log('Not JSON');
            }
        })
    },[]);

	useEffect(() => {
        if(response===1) {
            setResponse(-1)
            if(deleting.request_deleting===true) {
                setDeleting({"request_deleting":false,"deleted":deleting.deleted,"post_processed":deleting.post_processed,i:deleting.i})
                if(current_data_from_list.i>-1) {
                    let the_input = [{"id":current_data_from_list.data.id}];
                    let api_return_data = zoftApiForUsp(1,30102,39,0,0,"usp_react_sql_delete2", JSON.stringify(the_input));
                    Promise.resolve(api_return_data).then((value)=>{
                        console.log('BillGroupManageDataPage-useEffect(() [response], value', value);
                        if(value.return_status===0) { //normal return
                            console.log('BillGroupManageDetailDataGrid-useEffect value.return_data', value.return_data);
                            if(value.return_data.success) {
                                // successfully delete a record, delete the current row from the list grid, and re-select i-1 row as current row
                                console.log('BillGroupManageDetailDataGrid-useEffect value.return_data.output.data',value.return_data.output);
    //                            props.getCurrentDataFromDetail({new_record_inserted_or_updated:true,i:props.current_data_from_list.i, data:detail_data.data});
                                setDeleting({"request_deleting":false,"deleted":true,"post_processed":deleting.post_processed,i:deleting.i})
                            } else {
                                setModalTitle("Delete Error:" + value.return_data.message + "!");
                                setShowModalOk(true);
                            };
                        } else {
                            setModalTitle("Unspecified error, please report it to tech support!");
                            setShowModalOk(true);
                            console.log('BillGroupManageDetailDataGrid-onBlurTextHandler value.return_data', value.return_data);
                        }
                    }) 
                }
            }
        }
    },[response]);

    const getDataCount=(count)=>{
        setDataCount(count);
    }
    const getCurrentDataFromList = (a_data)=>{
        console.log("BillGroupManageDataPage-getCurrentDataFromList-a_data", a_data);
        setCurrent_data_from_list(a_data)
        console.log("BillGroupManageDataPage-getCurrentDataFromList-current_data", current_data_from_list);
    }
    const getCurrentDataFromDetail = (a_data)=>{
        console.log("BillGroupManageDataPage-getCurrentDataFromDetail-a_data", a_data);
        setCurrent_data_from_detail({new_record_inserted_or_updated:a_data.new_record_inserted_or_updated,
            i:a_data.i,data:{...a_data.data}})
        console.log("BillGroupManageDataPage-getCurrentDataFromDetail-current_data", current_data_from_detail);
    }
    const setInsertingStatus = (a_inserting_status)=>{
        setNew_record_inserted_or_updated(a_inserting_status);
        console.log("BillGroupManageDataPage-setInsertingStatus-a_inserting_status", a_inserting_status);
        console.log("BillGroupManageDataPage-getCurrentDataFromDetail-current_data", current_data_from_detail);
    }

    const onChangeTextHandler =(text,i,field_name,parm_type) => {
        console.log("BillGroupManageDataPage-onChangeTextHandler 1,field_name,text",field_name, text)
        let input_parm_tmp = input_parm;
        if(parm_type===type_number)
            input_parm_tmp[field_name] = parseInt(text);
        else
            input_parm_tmp[field_name] = text;
		if(field_name==="name") {
            let input_data = '{"id":16,"search":"' + text + '"}'
            console.log("BillGroupManageDataPage-onChangeTextHandler,text",text)
            let tmp_data = retrieveApiData(1,30102,0,"usp_react_autocomplete_retrieve", input_data);
            Promise.resolve(tmp_data).then((value)=>{
                try {
                    console.log("BillGroupManageDataPage-onChangeTextHandler,value",value)
                    setName_list(JSON.parse(value))
                } catch (e) {
                    console.log('Not JSON');
                }
            })
		} else if(field_name==="institution") {
            let input_data = '{"id":15,"search":"' + text + '"}'
            console.log("BillGroupManageDataPage-onChangeTextHandler,text",text)
            let tmp_data = retrieveApiData(1,30102,0,"usp_react_autocomplete_retrieve", input_data);
            Promise.resolve(tmp_data).then((value)=>{
                try {
                    console.log("BillGroupManageDataPage-onChangeTextHandler,value",value)
                    setInstitution_list(JSON.parse(value))
                } catch (e) {
                    console.log('Not JSON');
                }
            })
		} else if(field_name==="email_to") {
//            setEmail_to(text)
            let input_data = '{"id":21,"search":"' + text + '"}'
            console.log("BillGroupManageDataPage-onChangeTextHandler,text",text)
            let tmp_data = retrieveApiData(1,30102,0,"usp_react_autocomplete_retrieve", input_data);
            Promise.resolve(tmp_data).then((value)=>{
                try {
                    console.log("BillGroupManageDataPage-onChangeTextHandler,value",value)
                    setEmail_to_list(JSON.parse(value))
                } catch (e) {
                    console.log('Not JSON');
                }
            })
		} 
        setInput_parm({...input_parm_tmp});        
        console.log("BillGroupManageDataPage-onChangeTextHandler,input_parm,input_parm_tmp",input_parm,input_parm_tmp)
	}
    const onButtonClick = (button_name)=> {
        if(button_name==="Clear") {
            setInput_parm({...input_parm0})
        } else if(button_name==="Search") {
            let filter_selected = true;
            /*
            if(input_parm===input_parm0)
                filter_selected = false;
            if(filter_selected===false) {
                setModalTitle("Please Specify Search Parameters, Or Use Retrieve All!");
                setShowModalOk(true);
            } else
            */
            if(search_clicked===true) {
                setModalTitle("Wait! Data are being retrieved!");
                setShowModalOk(true);
            } else {
                console.log("BillGroupManageDataPage-onChangeTextHandle - Search_clicked input_parm",input_parm)
                setSearch_clicked(true);
                // default the data.i to -1 before retrieving
                setCurrent_data_from_list({i:-1,data:current_data_from_list.data})
            }
        } else if(button_name==="Prev") {
            if(billgroup_retrieved.total_rows<=1000||billgroup_retrieved.from_row<1000) { 
                // ignore it
            } else {
                setPrev_clicked(true)
            }
        } else if(button_name==="Next") {
            if(billgroup_retrieved.total_rows<=1000||billgroup_retrieved.to_row===billgroup_retrieved.total_rows) { 
                // ignore it
            } else {
                setNext_clicked(true)
            }
        } else if(button_name==="Retrieve") {
                let input_parm_tmp = input_parm
                input_parm_tmp[3].id = 0
                input_parm_tmp[4].name = ''
                input_parm_tmp[5].costcenter_id = 0
                input_parm_tmp[6].institution = ''
                input_parm_tmp[7].quote_id = 0
                input_parm_tmp[8].status_id = 0
                input_parm_tmp[9].delivery_method = 0
                input_parm_tmp[10].email_to = ''
                input_parm_tmp[11].po = ''
                input_parm_tmp[12].po_required = -1
                input_parm_tmp[13].is_active = -1
                input_parm_tmp[14].is_autobill = -1
                input_parm_tmp[15].is_invoice_per_po = -1
                setInput_parm([...input_parm_tmp])
                console.log("BillGroupManageDataPage-onChangeTextHandle - Retrieve clicked input_parm",input_parm)
                setRetrieve_clicked(true);
        }else if(button_name==="Delete") {
            setDeleting({"request_deleting":true,"deleted":false,"post_processed":deleting.post_processed,i:current_data_from_list.i})
//            setInput_parm([...input_parm_tmp])
            console.log("BillGroupManageDataPage-onChangeTextHandle - Delete clicked input_parm",input_parm)
            setModalTitle("Do you want to delete the selected item?");
            setShowModalYesNo(true);
//            if(response===1) {
//                alert("Delete")
//            }
        } 
    }
    return  (
        <div style={{width:1500,height:700,backgroundColor:"#ffffff"}}>
            <ZEditText id="030102datapage01"
                x={10} y={10} width={50} height={90} whole_background_color="#ffffff"
                label="ID" label_x={10} label_y={0} label_width={50} label_height={20}
                label_color="#000000" label_background_color="#ffffff"  text_align="center"
                button_x={10} button_y={20} button_width={50} button_height={30} button_color="#000000" button_background_color="ffffff"
                value={input_parm.id}
                onchange_func = {(text) => { onChangeTextHandler(text,3,"id",type_number)}}
                onBlur= {() => {}}
            />
             <ZAutoCompleteEditText id="030102datapage02"
                x={70} y={10} width={120} height={90} whole_background_color="#ffffff"
                label="Name" label_x={10} label_y={0} label_color="#000000" label_background_color="#ffffff"
                button_x={10} button_y={20} button_width={120} button_height={30} button_color="#000000" button_background_color="ffffff"
                value={input_parm.name}
                onchange_func = {(text) => { onChangeTextHandler(text,4,"name",type_string)}}
                onBlur= {() => {}}
                dataList={name_list}
            />
           <ZDropdownList id="030102datapage03"
                x={200} y={10} width={120} height={90} whole_background_color="#ffffff"
                label="Cost Center" label_x={10} label_y={0} label_width={120} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                button_x={10} button_y={20} button_width={120} button_height={30} button_color="#000000" button_background_color="ffffff"
                value={input_parm.costcenter_id}
                onchange_func = {(text) => { onChangeTextHandler(text,5,"costcenter_id",type_number)}}
                onBlur= {() => {}}
                dataList={costcenter_list}
            />
            <ZAutoCompleteEditText id="030102datapage04"
                x={330} y={10} width={120} height={90} whole_background_color="#ffffff"
                label="Institution" label_x={10} label_y={0} label_color="#000000" label_background_color="#ffffff"
                button_x={10} button_y={20} button_width={120} button_height={30} button_color="#000000" button_background_color="ffffff"
                value={input_parm.institution}
                onchange_func = {(text) => { onChangeTextHandler(text,6,"institution",type_string)}}
                onBlur= {() => {}}
                dataList={institution_list}
            />

            <ZEditText id="030102atapage05"
                x={460} y={10} width={90} height={90} whole_background_color="#ffffff"
                label="Quote ID" label_x={10} label_y={0} label_color="#000000" label_background_color="#ffffff"
                button_x={10} button_y={20} button_width={90} button_height={30} button_color="#000000" button_background_color="ffffff"
                value={input_parm.quote_id}
                onchange_func = {(text) => { onChangeTextHandler(text,7,"quote_id",type_number)}}
                onBlur= {() => {}}
            />
           <ZDropdownList id="030102datapage06"
                x={560} y={10} width={120} height={90} whole_background_color="#ffffff"
                label="Status" label_x={10} label_y={0} label_width={120} label_height={20}
                label_color="#000000" label_background_color="#ffffff"  text_align="left"
                button_x={10} button_y={20} button_width={120} button_height={30} button_color="#000000" button_background_color="ffffff"
                value={input_parm.status_id}
                onchange_func = {(text) => { onChangeTextHandler(text,8,"status_id",type_number)}}
                onBlur= {() => {}}
                dataList={status_list}
            />
           <ZDropdownList id="030102datapage07"
                x={690} y={10} width={120} height={90} whole_background_color="#ffffff"
                label="Delivery Method" label_x={10} label_y={0} label_width={120} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                button_x={10} button_y={20} button_width={120} button_height={30} button_color="#000000" button_background_color="ffffff"
                value={input_parm.delivery_method}
                onchange_func = {(text) => { onChangeTextHandler(text,9,"delivery_method", type_number)}}
                onBlur= {() => {}}
                dataList={delivery_method_list}
            />
            <ZAutoCompleteEditText id="030102datapage08"
                x={820} y={10} width={120} height={90} whole_background_color="#ffffff"
                label="Email To" label_x={10} label_y={0} label_color="#000000" label_background_color="#ffffff"
                button_x={10} button_y={20} button_width={120} button_height={30} button_color="#000000" button_background_color="ffffff"
                value={input_parm.email_to}
                onchange_func = {(text) => { onChangeTextHandler(text,10,"email_to", type_string)}}
                onBlur= {() => {}}
                dataList={email_to_list}
            />
            <ZEditText id="030102datapage09" 
                x={950} y={10} width={90} height={90} whole_background_color="#ffffff"
                label="PO" label_x={10} label_y={0} label_color="#000000" label_background_color="#ffffff"
                button_x={10} button_y={20} button_width={90} button_height={30} button_color="#000000" button_background_color="ffffff"
                value={input_parm.po}
                onchange_func = {(text) => { onChangeTextHandler(text,11,"po",type_string)}}
                onBlur= {() => {}}
            />
           <ZDropdownList id="030102datapage10"
                x={1050} y={10} width={100} height={90} whole_background_color="#ffffff"
                label="PO Required" label_x={10} label_y={0} label_width={120} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                button_x={10} button_y={20} button_width={100} button_height={30} button_color="#000000" button_background_color="ffffff"
                value={input_parm.po_required}
                onchange_func = {(text) => { onChangeTextHandler(text,12,"po_required",type_number)}}
                onBlur= {() => {}}
                dataList={yesNoSelection}
            />
          <ZDropdownList id="030102datapage11"
                x={1160} y={10} width={80} height={90} whole_background_color="#ffffff"
                label="Is Active" label_x={10} label_y={0} label_width={80} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                button_x={10} button_y={20} button_width={80} button_height={30} button_color="#000000" button_background_color="ffffff"
                value={input_parm.is_active}
                onchange_func = {(text) => { onChangeTextHandler(text,13,"is_active",type_number)}}
                onBlur= {() => {}}
                dataList={yesNoSelection}
            />
           <ZDropdownList id="030102datapage12"
                x={1250} y={10} width={80} height={90} whole_background_color="#ffffff"
                label="Is Autobill" label_x={10} label_y={0} label_width={80} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                button_x={10} button_y={20} button_width={80} button_height={30} button_color="#000000" button_background_color="ffffff"
                value={input_parm.is_autobill}
                onchange_func = {(text) => { onChangeTextHandler(text,14,"is_autobill",type_number)}}
                onBlur= {() => {}}
                dataList={yesNoSelection}
            />
           <ZDropdownList id="030102datapage13"
                x={1340} y={10} width={120} height={90} whole_background_color="#ffffff"
                label="Is Invoice Per PO" label_x={10} label_y={0} label_width={120} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                button_x={10} button_y={20} button_width={120} button_height={30} button_color="#000000" button_background_color="ffffff"
                value={input_parm.is_invoice_per_po}
                onchange_func = {(text) => { onChangeTextHandler(text,15,"is_invoice_per_po",type_number)}}
                onBlur= {() => {}}
                dataList={yesNoSelection}
            />

            <div id="03010118" style={{position:"absolute",left:20,top:70,width:290,height:30, borderBottom:"0px solid black"}}>
                <scan style={{position:"absolute",left:5,top:2,width:290,height:15}} >
                    {billgroup_retrieved.total_rows} total records   {billgroup_retrieved.from_row} - {billgroup_retrieved.to_row}
                </scan>
                <ZTextButton id="03010117"
                    x={250} y={2} width={60} height={30} whole_background_color="#ffffff" 
                    border_style="0px solid #000000"
                    button_text="Prev" label_background_color="#ffffff"
                    button_font_color="#000000" button_background_color="#ffffff"
                    onButtonClick = {() => { onButtonClick("Prev")}}
                    />
                <ZTextButton id="03010117"
                    x={300} y={2} width={60} height={30} whole_background_color="#ffffff" 
                    border_style="0px solid #000000"
                    button_text="Next" label_background_color="#ffffff"
                    button_font_color="#000000" button_background_color="#ffffff"
                    onButtonClick = {() => { onButtonClick("Next")}}
                />
            </div>
            <ZTextButton id="030102datapage15"
                x={850} y={70} width={200} height={25} whole_background_color="#ffffff" 
                border_style="1px solid #000000"
                button_text="Delete Selected Rows" label_background_color="#ffffff"
                button_font_color="#000000" button_background_color="#ffffff"
                onButtonClick = {() => { onButtonClick("Delete")}}
            />

            <ZTextButton id="030102datapage14"
                x={1100} y={70} width={100} height={25} whole_background_color="#ffffff" 
                border_style="1px solid #000000"
                button_text="Search" label_background_color="#ffffff"
                button_font_color="#000000" button_background_color="#ffffff"
                onButtonClick = {() => { onButtonClick("Search")}}
                onBlur= {() => {}}
            />
            <ZTextButton id="030102datapage15"
                x={1250} y={70} width={200} height={25} whole_background_color="#ffffff" 
                border_style="1px solid #000000"
                button_text="Clear Search Form" label_background_color="#ffffff"
                button_font_color="#000000" button_background_color="#ffffff"
                onButtonClick = {() => { onButtonClick("Clear")}}
            />
           <div style={{position:"absolute",left:20,top:100,width:1650,height:300}}>
                <BillGroupManageListDataGrid
                    costcenter_list={costcenter_list}
                    status_list={status_list}
                    institution_list={institution_list2}
                    current_data_from_detail={current_data_from_detail}
                    new_record_inserted_or_updated={new_record_inserted_or_updated}
                    search_clicked={search_clicked}
                    prev_clicked={prev_clicked}
                    next_clicked={next_clicked}

                    retrieve_clicked={retrieve_clicked}
                    input_parm={input_parm}
                    new_record_in_detail={new_record_in_detail}
                    update_mode={update_mode}
                    deleting={deleting}
                    billgroup_retrieved={billgroup_retrieved}

                    getCurrentDataFromList={(data)=>{getCurrentDataFromList(data)}}
                    getCurrentDataFromDetail={(data)=>{getCurrentDataFromDetail(data)}}
                    getDataCount={(count)=>{getDataCount(count)}}
                    newRecordInserted={(a_inserting)=>{setNew_record_inserted_or_updated(a_inserting)}}
                    retrieved={() =>{setSearch_clicked(false);setRetrieve_clicked(false);console.log("BillGroupManageDataPage-retrieved, search_clicked",search_clicked,retrieve_clicked)}}
                    retrieved2={() =>{setSearch_clicked(false)}}
                    resetDeleting={()=>{setDeleting({"request_deleting":deleting.request_deleting,"deleted":deleting.deleted,"post_processed":true,"i":deleting.i})}}
                    getRetrievedSearch={(retrieved_rows,total_rows) =>{setSearch_clicked(false);setBillgroup_search_list(retrieved_rows,total_rows);console.log("EditInvoiceStatusDataPage-retrieved, search_clicked",search_clicked)}}
                    getRetrievedPrev={(retrieved_rows) =>{setPrev_clicked(false);setBillgroup_prev_list(retrieved_rows);}}
                    getRetrievedNext={(retrieved_rows) =>{setNext_clicked(false);setBillgroup_next_list(retrieved_rows);}}
                />
            </div>
            <div style={{position:"absolute",left:20,top:400,width:1450,height:310}}>
                <BillGroupManageDetailDataGrid
                    costcenter_list={costcenter_list}
                    status_list={status_list}
                    institution_list={institution_list2}
                    current_data_from_list={current_data_from_list}
                    getCurrentDataFromDetail={(data)=>{getCurrentDataFromDetail(data)}}
                    newRecordInserted={(a_inserting)=>{setNew_record_in_detail(a_inserting)}}
                    set_update_mode={(a_mode)=>{setUpdate_mode(a_mode)}}
                    deleting={deleting}
                    resetDeleting={()=>{setDeleting({"request_deleting":deleting.request_deleting,"deleted":deleting.deleted,"post_processed":true,"i":deleting.i})}}
                />
            </div>
             {showModalOk ? <ResponseWindowOk title={modalTitle} setShowModal={setShowModalOk} /> : null}
             {showModalYesNo ? <ResponseWindowYesNo title={modalTitle} setShowModal={setShowModalYesNo} setResponse={setResponse}/> : null}
        </div>
    );
}

export default BillGroupManageDataPage;

/*
            <ZTextButton id="030102datapage14"
                x={670} y={70} width={100} height={25} whole_background_color="#ffffff" 
                border_style="1px solid #000000"
                button_text="Retrieve All" label_background_color="#ffffff"
                button_font_color="#000000" button_background_color="#ffffff"
                onButtonClick = {() => { onButtonClick("Retrieve")}}
                onBlur= {() => {}}
            />
*/