import React, {Component, useState, useEffect} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './v2/css/bootstrap.min.css';
import './v2/css/qbio_style.css';
import {ResponseWindow} from './Modals';
import getApiUrl from './GetApiUrl';
import GetInternalPagePath from './GetInternalPagePath';
import ValidatePage from './ValidatePage';

import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useLocation,
    useNavigate
  }
from "react-router-dom";
import "./styles.css";
import logotransparent from "./v2/img/logotransparent.png"

const ResetPassword = (props)=> {

    const [modalTitle, setModalTitle] = useState("Warning Message");
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [new_password, setNew_password] = useState('');

    useEffect(() => {
        // code to run on component mount
        console.log("useEffect-email",email);
        console.log("useEffect-the_password",password);
    }, []);


    let pin = 0;
    const handleClick = (path) => {
        navigate(path);
    }  
    const onChangeTextHandler = setter => e => {
    setter(e.target.value);
    };
    const onClicked = ()=> {
//        const api_url = process.env.NODE_ENV==='development'?process.env.REACT_APP_DEV_PROXY_URL:process.env.REACT_APP_QA_PROXY_URL;
        const url = getApiUrl() + '/api2/React2/Usp_react_reset_password';
        let the_pin = 0;
        console.log("url",url);
//        alert(url);
        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
             body: JSON.stringify({
                'email': email,
                'password': password,
                'new_password': new_password,
                'pin': 0,
            })
        })
        .then(response => response.json())
        .then(data => {
           if(data.success){
                setModalTitle("Your password has been successfully reset, check your email for the access pin!");
                setShowModal(true);
           } else {
                setModalTitle("Error In Reset Password!");
                setShowModal(true);
           }
            console.log('data', data);
        })
        .catch(err => {
            alert('fetch err:' + err); 
        });
    }

  return (
      <>
        <div className="outer">
            <div className="inner">
                <form>
                    <img class="pb-5 img-fluid" src={logotransparent} alt="Quintara Biosciences" />
                    <h3>Reset Password</h3>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" className="form-control" placeholder="Enter email" 
                            value = {email}
                            onChange={event => {setEmail(event.target.value)}}
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" placeholder="Enter password" 
                            value = {password}
                            onChange={event => {setPassword(event.target.value)}}
                        />
                    </div>
                    <div className="form-group">
                        <label>New Password</label>
                        <input type="password" className="form-control" placeholder="Enter new password" 
                            value = {new_password}
                            onChange={event => {setNew_password(event.target.value)}}
                        />
                    </div>
                    <button type="submit" className="btn btn-secondary btn-lg btn-block"
                        onClick={()=>onClicked()}
                    >Submit</button>
                    <p className="forgot-password text-center">
                        <a href="/sign-in#">Login</a>
                    </p>
                    {showModal ? <ResponseWindow title={modalTitle} setShowModal={setShowModal} /> : null}
                </form>
            </div>
        </div>
    </>
    )
}
export default ResetPassword;
