import React, {Component, useState, useEffect, useContext} from 'react';
import ReactDom from "react-dom";
import {ResponseWindowOk} from '../../../Modals';

import DatePicker, {moment} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import moment from 'moment'

//import zeditbox1 from 'zeditbox1';
import ZDropdownList from '../../../zcomponents/ZDropdownList';
import ZTextButton from '../../../zcomponents/ZTextButton';
import ZAutoCompleteEditText2 from '../../../zcomponents/ZAutoCompleteEditText2';
import {retrieveApiData} from '../../../data_retrieval';

import ApplyPaymentInvoiceDataGridForModal from './applypaymentinvoicedatagridformodal';

const ApplyPaymentInvoiceListModal  = ({setShowModal, setResponse})=> {
    const type_string = 1;
    const type_number = 0;
    let dd_billgroup = {}

    const [modalTitle, setModalTitle] = useState("Warning Message");
    const [showModalOk, setShowModalOk] = useState(false);

    const [customer_list, setCustomer_list] = useState([]);
    const [product_list, setProduct_list] = useState([]);
    const [category_list, setCategory_list] = useState([]);
    const [billgroup_list, setBillgroup_list] = useState([]);
    const [billgroup_object_list, setBillgroup_object_list] = useState({});
    const [customer_object_list, setCustomer_object_list] = useState({});
    const [institution_list, setInstitution_list] = useState([]);
    const [action_list, setAction_list] = useState([]);
    const [search_clicked, setSearch_clicked] = useState(false);
    const [prev_clicked, setPrev_clicked] = useState(false);
    const [next_clicked, setNext_clicked] = useState(false);
    const [dataCount, setDataCount] = useState(0);
    const [current_invoice_id, setCurrent_invoice_id] = useState(0);
    const [selected_orders, setSelected_orders] = useState([]);

    let input_parm0 = {
        "request_row_count":1,
        "offset_row":0,
        "request_rows":1000,
        "category_id":0,
        "product_id":0,
        "billgroup_id":0,
        "billgroup":"",
        "billgroup_search":"",
        "customer_id":0,
        "customer_search":"",
        "category_id":0,
        "institution":"",
        "start_date":"",
        "end_date":"",
    }
    const [input_parm, setInput_parm] = useState({...input_parm0})
    const getDataCount=(count)=>{
        setDataCount(count);
    }
    const [invoice_list,setInvoice_list] =  useState([]);

    const [invoice_retrieved, setInvoice_retrieved] = useState({
        "from_row":0,
        "to_row":0,
        "total_rows":0,
    }
    );
    const setInvoice_prev_list=(rows)=>{
        if(rows>0) {
            let new_from_row = invoice_retrieved.from_row - rows
            if(new_from_row<1)
                new_from_row = 1
            let new_to_row = new_from_row + rows - 1
            let retrieved_tmp = invoice_retrieved
            retrieved_tmp.from_row = new_from_row
            retrieved_tmp.to_row = new_to_row
            setInvoice_retrieved({...retrieved_tmp})
            console.log("ApplyPaymentInvoiceListModal setInvoice_prev_list rows, retrieved_tmp",rows, retrieved_tmp)
        }
    }
    const setInvoice_next_list=(rows)=>{
        if(rows>0) {
            let new_from_row = invoice_retrieved.to_row + 1
            let new_to_row = new_from_row + rows - 1
            let retrieved_tmp = invoice_retrieved
            retrieved_tmp.from_row = new_from_row
            retrieved_tmp.to_row = new_to_row
            setInvoice_retrieved({...retrieved_tmp})
            console.log("ApplyPaymentInvoiceListModal-setInvoice_next_list rows retrieved_tmp",rows,retrieved_tmp)
        }
    }
    const setInvoice_search_list=(rows,total_rows)=>{
        if(rows>0) {
            let new_from_row = 1
            let new_to_row = rows
            let retrieved_tmp = invoice_retrieved
            retrieved_tmp.from_row = new_from_row
            retrieved_tmp.to_row = new_to_row
            retrieved_tmp.total_rows =total_rows
            setInvoice_retrieved({...retrieved_tmp})
    //            setInvoice_retrieved({"invoice_from_row":new_from_row,"invoice_to_row":new_to_row,"total_invoice_rows":total_rows})
            console.log("ApplyPaymentInvoiceListModal-setInvoice_search_list rows,total_rows",retrieved_tmp)
        }
    }



    const onChangeTextHandler =(text,field_name,parm_type) => {
        console.log("ApplyPaymentInvoiceListModal-onChangeTextHandler 1,field_name,text",field_name, text)
        let input_parm_tmp = input_parm;
        if(parm_type===type_number)
            input_parm_tmp[field_name] = parseInt(text);
        else
            input_parm_tmp[field_name] = text;
        if(field_name==="billgroup_search") {
            let input_data = '{"id":23,"search":"' + text + '"}'
            let tmp_data = retrieveApiData(1,30103,0,"usp_react_autocomplete_retrieve2", input_data);
            Promise.resolve(tmp_data).then((value)=>{
                try {
                    console.log("ApplyPaymentInvoiceListModal-onChangeTextHandler,value",value)
                    let tmp_list = JSON.parse(value);
                    console.log("ApplyPaymentInvoiceListModal-onChangeTextHandler,tmp_list",tmp_list)
                    let pair_list = [];
                    for(let i=0;i<tmp_list.length;i++) {
                        let the_key = Object.keys(tmp_list[i])[0];
                        dd_billgroup[the_key] = tmp_list[i][the_key];
                        pair_list.push({"id":tmp_list[i][the_key],"name":the_key});
                    }
                    console.log("ApplyPaymentInvoiceListModal-onChangeTextHandler,dd_billgroup",dd_billgroup)
                    setBillgroup_object_list({...dd_billgroup})
                    setBillgroup_list(pair_list);
                    console.log("ApplyPaymentInvoiceListModal-onChangeTextHandler,input_parm_tmp",input_parm_tmp)
                } catch (e) {
                    console.log('Not JSON');
                }
            })
        } else if(field_name==="customer_search") {
            let input_data = '{"id":24,"search":"' + text + '"}'
            let tmp_data = retrieveApiData(1,30103,0,"usp_react_autocomplete_retrieve2", input_data);
            Promise.resolve(tmp_data).then((value)=>{
                try {
                    console.log("ApplyPaymentInvoiceListModal-onChangeTextHandler,value",value)
                    let tmp_list = JSON.parse(value);
                    console.log("ApplyPaymentInvoiceListModal-onChangeTextHandler,tmp_list",tmp_list)
                    let pair_list = [];
                    for(let i=0;i<tmp_list.length;i++) {
                        let the_key = Object.keys(tmp_list[i])[0];
                        dd_billgroup[the_key] = tmp_list[i][the_key];
                        pair_list.push({"id":tmp_list[i][the_key],"name":the_key});
                    }
                    console.log("ApplyPaymentInvoiceListModal-onChangeTextHandler,dd_billgroup",dd_billgroup)
                    setCustomer_object_list({...dd_billgroup})
                    setCustomer_list(pair_list);
                    console.log("ApplyPaymentInvoiceListModal-onChangeTextHandler,input_parm_tmp",input_parm_tmp)
                } catch (e) {
                    console.log('Not JSON');
                }
            })
            console.log("EditInvoiceStatusOrderListModal-onChangeTextHandler,input_parm_tmp",input_parm_tmp);
        }
        setInput_parm({...input_parm_tmp});
        console.log("ApplyPaymentInvoiceListModal-onChangeTextHandler,field_name,text",field_name, text)
	}

    const onButtonClick = (button_name)=> {
        if(button_name==="Reset") {
            setInput_parm({...input_parm0});
            setBillgroup_list([])
            setCustomer_list([])
        } else if(button_name==="Search") {
            if(input_parm===input_parm0) {
                setModalTitle("No Search Parameters Specified!");
                setShowModalOk(true);
            } else if(search_clicked===true) {
                setModalTitle("Wait! Data are being retrieved!");
                setShowModalOk(true);
            } else {
                console.log("ApplyPaymentInvoiceListModal-onChangeTextHandle - Search_clicked input_parm",input_parm)
                setSearch_clicked(true);
            }
        } else if(button_name==="Prev") {
            if(invoice_retrieved.total_rows<=1000||invoice_retrieved.from_row<1000) { 
                // ignore it
            } else {
                setPrev_clicked(true)
            }
        } else if(button_name==="Next") {
            if(invoice_retrieved.total_rows<=1000||invoice_retrieved.to_row===invoice_retrieved.total_rows) { 
                // ignore it
            } else {
                setNext_clicked(true)
            }
        } 
}

    const handleStartDateChange =(date)=> {
        let input_parm_tmp = input_parm;
        input_parm_tmp.DateStart = date;
//        let tmp_date = new Date(date).toLocaleDateString('en-US');
        input_parm_tmp.start_date = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate()
        console.log("ApplyPaymentInvoiceListModal-handleEndDateChange,input_parm_tmp.start_date",input_parm_tmp.start_date);
        setInput_parm({...input_parm_tmp});
    }

    const handleEndDateChange =(date)=> { 
        let input_parm_tmp = input_parm;
        input_parm_tmp.DateEnd = date;
        input_parm_tmp.end_date = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate()
        console.log("ApplyPaymentInvoiceListModal-handleEndDateChange,input_parm_tmp.end_date",input_parm_tmp.end_date)
        setInput_parm({...input_parm_tmp});
    }


	useEffect(() => {
//        this.setState({ ...this.state,billgroup:text}) ;
        console.log("EditInvoiceStatusOrderListModal-useEffect")
        let li_product_sql_id = 26;
        let li_category_sql_id = 34;
        let input_data = '[{"request_row_count":0},{"offset_row":0},{"request_rows":9999}]'
        let tmp_data = retrieveApiData(1,30103,li_product_sql_id,"usp_react_sql_retrieve2", input_data);
        Promise.resolve(tmp_data).then((value)=>{
            try {
                console.log("EditInvoiceStatusOrderListModal-useEffect,value",value)
                setProduct_list(JSON.parse(value))
            } catch (e) {
                console.log('Not JSON');
            }
        })
        let input_data2 = '[{"request_row_count":0},{"offset_row":0},{"request_rows":9999}]'
        let tmp_data2 = retrieveApiData(1,30101,34,"usp_react_sql_retrieve2", input_data2);
        Promise.resolve(tmp_data2).then((value)=>{
            try {
//                console.log("EditInvoiceStatusDataPage-useEffect[],value",value)
                setCategory_list(JSON.parse(value))
            } catch (e) {
                console.log('Not JSON');
            }
        })


    },[]);

    useEffect(() => {
        input_parm.billgroup_id = billgroup_object_list[input_parm.billgroup]
        console.log("EditInvoiceStatusOrderListModal-useEffect,billgroup_object_list",billgroup_object_list)
        console.log("EditInvoiceStatusOrderListModal-useEffect,billgroup_list",billgroup_list)
        console.log("EditInvoiceStatusOrderListModal-useEffect,input_parm",input_parm)
    },[billgroup_object_list,billgroup_list,input_parm]);

    return ReactDom.createPortal(
        <div className="container" >
            <div className="modal" style={{left:0,top:0,width:1200,height:700,backgroundColor:"#ffffff"}}>
            <ZDropdownList id="03010102"
                    x={20} y={20} width={130} height={70} whole_background_color="#ffffff"
                    label="Product" label_x={0} label_y={0} label_width={150} label_height={20}
                    label_color="#000000" label_background_color="#ffffff"
                    button_x={0} button_y={20} button_width={130} button_height={24} button_color="#000000" button_background_color="ffffff"
                    value={input_parm.product_id}
                    onchange_func = {(text) => { onChangeTextHandler(text,"product_id",type_number)}}
                    onBlur= {() => {}}
                    dataList={product_list}
                />
                <ZAutoCompleteEditText2
                    x={160} y={20} width={150} height={70} whole_background_color="#ffffff"
                    label="Bill Group" label_x={0} label_y={0} label_width={150} label_height={20}
                    label_color="#000000" label_background_color="#ffffff"
                    button_x={0} button_y={20} button_width={150} button_height={24} button_color="#000000" button_background_color="ffffff"
                    value_search={input_parm.billgroup_search}
                    value_select={input_parm.billgroup_id}
                    onchange_func_search = {(text) => { onChangeTextHandler(text,"billgroup_search",type_string)}}
                    onchange_func_select = {(text) => { onChangeTextHandler(text,"billgroup_id",type_number)}}
                    onBlur= {() => {}}
                    dataList={billgroup_list}
                />
                <ZAutoCompleteEditText2
                    x={320} y={20} width={130} height={70} whole_background_color="#ffffff"
                    label="Customer" label_x={0} label_y={0} label_width={150} label_height={20}
                    label_color="#000000" label_background_color="#ffffff"
                    button_x={0} button_y={20} button_width={130} button_height={24} button_color="#000000" button_background_color="ffffff"
                    value_search={input_parm.customer_search}
                    value_select={input_parm.customer_id}
                    onchange_func_search = {(text) => { onChangeTextHandler(text,"customer_search",type_string)}}
                    onchange_func_select = {(text) => { onChangeTextHandler(text,"customer_id",type_number)}}
                    onBlur= {() => {}}
                    dataList={customer_list}
                />
                <div id="03010106" style={{position:"absolute",left:460,top:20,width:150,height:70,backgroundColor:"#ffffff"}}>
                    <scan style={{position:"absolute",left:0,top:0}}>
                        Start Date
                    </scan>
                    <div  style={{position:"absolute",left:0,top:20,width:100,height:30}}>
                        <DatePicker
                                selected={input_parm.DateStart}
                                onChange={handleStartDateChange}
                                dateFormat="MM/dd/yyyy"
                        />
                    </div>
                </div>
                <div id="03010107" style={{position:"absolute",left:640,top:20,width:150,height:70,backgroundColor:"#ffffff"}}>
                    <scan style={{position:"absolute",left:0,top:0}}>
                        End Date
                    </scan>
                    <div style={{position:"absolute",left:0,top:20,width:100,height:30}}>
                        <DatePicker
                            selected={input_parm.DateEnd}
                            onChange={handleEndDateChange}
                            dateFormat="MM/dd/yyyy"
                        />
                    </div>
                </div>
                <ZTextButton id="03010117"
                    x={820} y={40} width={70} height={24} whole_background_color="#ffffff" 
                    border_style="1px solid #000000"
                    button_text="Reset" label_background_color="#ffffff"
                    button_font_color="#000000" button_background_color="#ffffff"
                    onButtonClick = {() => { onButtonClick("Reset")}}
                />
                <ZTextButton id="03010117"
                    x={895} y={40} width={70} height={24} whole_background_color="#ffffff" 
                    border_style="1px solid #000000"
                    button_text="Search" label_background_color="#ffffff"
                    button_font_color="#000000" button_background_color="#ffffff"
                    onButtonClick = {() => { onButtonClick("Search")}}
                />
                <button style={{position:"absolute",left:975, top:30, width:50,height:40}} 
                    onClick={() => {setResponse(invoice_list);setShowModal(false)}}>Add
                </button>
                <button style={{position:"absolute",left:1030, top:30, width:65,height:40}} 
                    onClick={() => {setShowModal(false)}}>Cancel
                </button>
                <scan style={{position:"absolute",left:460,top:80,width:250,height:15}} >
                        {invoice_retrieved.total_rows} total records  {invoice_retrieved.from_row} - {invoice_retrieved.to_row}
                </scan>

                <ZTextButton id="03010117"
                    x={720} y={80} width={60} height={30} whole_background_color="#ffffff" 
                    border_style="0px solid #000000"
                    button_text="Prev" label_background_color="#ffffff"
                    button_font_color="#000000" button_background_color="#ffffff"
                    onButtonClick = {() => { onButtonClick("Prev")}}
                />
                <ZTextButton id="03010117"
                    x={780} y={80} width={60} height={30} whole_background_color="#ffffff" 
                    border_style="0px solid #000000"
                    button_text="Next" label_background_color="#ffffff"
                    button_font_color="#000000" button_background_color="#ffffff"
                    onButtonClick = {() => { onButtonClick("Next")}}
                />
                <div style={{position:"absolute",left:20,top:105,width:1070,height:550}}>
                    <ApplyPaymentInvoiceDataGridForModal
                        search_clicked={search_clicked}
                        prev_clicked={prev_clicked}
                        next_clicked={next_clicked}
                        input_parm={input_parm}
                        invoice_retrieved={invoice_retrieved}
                        getResponse={(response)=>{setInvoice_list(response);console.log("ApplyPaymentInvoiceListModal response",response)}}
                        getRetrievedSearch={(retrieved_rows,total_rows) =>{setSearch_clicked(false);setInvoice_search_list(retrieved_rows,total_rows);console.log("EditInvoiceStatusDataPage-retrieved, search_clicked",search_clicked)}}
                        getRetrievedPrev={(retrieved_rows) =>{setPrev_clicked(false);setInvoice_prev_list(retrieved_rows);}}
                        getRetrievedNext={(retrieved_rows) =>{setNext_clicked(false);setInvoice_next_list(retrieved_rows);}}
                    />
                </div>

            </div>
            {showModalOk ? <ResponseWindowOk title={modalTitle} setShowModal={setShowModalOk} /> : null}
        </div>,
         document.getElementById("portal")
    );

}

export default ApplyPaymentInvoiceListModal;

/*


            <ZDropdownList id="03010102"
                    x={20} y={20} width={130} height={70} whole_background_color="#ffffff"
                    label="Product" label_x={0} label_y={0} label_width={150} label_height={20}
                    label_color="#000000" label_background_color="#ffffff"
                    button_x={0} button_y={20} button_width={130} button_height={24} button_color="#000000" button_background_color="ffffff"
                    value={input_parm.product_id}
                    onchange_func = {(text) => { onChangeTextHandler(text,"product_id",type_number)}}
                    onBlur= {() => {}}
                    dataList={product_list}
                />
                <ZAutoCompleteEditText2
                    x={160} y={20} width={130} height={70} whole_background_color="#ffffff"
                    label="Bill Group" label_x={0} label_y={0} label_width={150} label_height={20}
                    label_color="#000000" label_background_color="#ffffff"
                    button_x={0} button_y={20} button_width={130} button_height={24} button_color="#000000" button_background_color="ffffff"
                    value_search={input_parm.billgroup_search}
                    value_select={input_parm.billgroup_id}
                    onchange_func_search = {(text) => { onChangeTextHandler(text,"billgroup_search",type_string)}}
                    onchange_func_select = {(text) => { onChangeTextHandler(text,"billgroup_id",type_number)}}
                    onBlur= {() => {}}
                    dataList={billgroup_list}
                />
                <ZAutoCompleteEditText2
                    x={310} y={20} width={130} height={70} whole_background_color="#ffffff"
                    label="Customer" label_x={0} label_y={0} label_width={150} label_height={20}
                    label_color="#000000" label_background_color="#ffffff"
                    button_x={0} button_y={20} button_width={130} button_height={24} button_color="#000000" button_background_color="ffffff"
                    value_search={input_parm.customer_search}
                    value_select={input_parm.customer_id}
                    onchange_func_search = {(text) => { onChangeTextHandler(text,"customer_search",type_string)}}
                    onchange_func_select = {(text) => { onChangeTextHandler(text,"customer_id",type_number)}}
                    onBlur= {() => {}}
                    dataList={customer_list}
                />
                <div id="03010106" style={{position:"absolute",left:460,top:20,width:150,height:70,backgroundColor:"#ffffff"}}>
                    <scan style={{position:"absolute",left:0,top:0}}>
                        Start Date
                    </scan>
                    <div  style={{position:"absolute",left:0,top:20,width:100,height:30}}>
                        <DatePicker
                                selected={input_parm.DateStart}
                                onChange={handleStartDateChange}
                                dateFormat="MM/dd/yyyy"
                        />
                    </div>
                </div>
                <div id="03010107" style={{position:"absolute",left:640,top:20,width:150,height:70,backgroundColor:"#ffffff"}}>
                    <scan style={{position:"absolute",left:0,top:0}}>
                        End Date
                    </scan>
                    <div style={{position:"absolute",left:0,top:20,width:100,height:30}}>
                        <DatePicker
                            selected={input_parm.DateEnd}
                            onChange={handleEndDateChange}
                            dateFormat="MM/dd/yyyy"
                        />
                    </div>
                </div>
                <ZTextButton id="03010117"
                    x={820} y={40} width={70} height={24} whole_background_color="#ffffff" 
                    border_style="1px solid #000000"
                    button_text="Reset" label_background_color="#ffffff"
                    button_font_color="#000000" button_background_color="#ffffff"
                    onButtonClick = {() => { onButtonClick("Reset")}}
                />
                <ZTextButton id="03010117"
                    x={895} y={40} width={70} height={24} whole_background_color="#ffffff" 
                    border_style="1px solid #000000"
                    button_text="Search" label_background_color="#ffffff"
                    button_font_color="#000000" button_background_color="#ffffff"
                    onButtonClick = {() => { onButtonClick("Search")}}
                />
                <button style={{position:"absolute",left:975, top:30, width:50,height:40}} 
                    onClick={() => {setResponse(invoice_list);setShowModal(false)}}>Add
                </button>
                <button style={{position:"absolute",left:1030, top:30, width:65,height:40}} 
                    onClick={() => {setShowModal(false)}}>Cancel
                </button>
                <div id="03010118" style={{position:"absolute",left:20,top:48,width:290,height:40, borderBottom:"0px solid black"}}>
                    <scan style={{position:"absolute",left:500,top:35,width:250,height:15}} >
                        {invoice_retrieved.total_rows} total records  {invoice_retrieved.from_row} - {invoice_retrieved.to_row}
                    </scan>
                    <ZTextButton id="03010117"
                        x={740} y={35} width={60} height={30} whole_background_color="#ffffff" 
                        border_style="0px solid #000000"
                        button_text="Prev" label_background_color="#ffffff"
                        button_font_color="#000000" button_background_color="#ffffff"
                        onButtonClick = {() => { onButtonClick("Prev")}}
                    />
                    <ZTextButton id="03010117"
                        x={800} y={35} width={60} height={30} whole_background_color="#ffffff" 
                        border_style="0px solid #000000"
                        button_text="Next" label_background_color="#ffffff"
                        button_font_color="#000000" button_background_color="#ffffff"
                        onButtonClick = {() => { onButtonClick("Next")}}
                    />
                </div>

*/
