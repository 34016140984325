import React, {Component,useContext, useState, useEffect} from 'react';
//import 'bootstrap/dist/css/bootstrap.css';
//import './v2/css/bootstrap.min.css';
import {useNavigate,  Link,
    Route,} from "react-router-dom";
//import "./styles.css";
import "./qbio_style.css"
import "./treeview2.css"
import ValidatePage from './ValidatePage';
import TreemenuContext from "./TreemenuContext"
import {getBuiltMenuItem,getMenuData, menuItems2} from './InternaPageTreeItems';
import Signin from './Signin';
import {ResponseWindowOk} from './Modals';

var g_id = 0;

const RecursiveItem = ({sub_menu_id, getMenuId, getTreemenuId, title,path,id,enabled, expanded,nested}) => {
  
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("Warning Message");
  const {currentId}  = useContext(TreemenuContext);
  const {setCurrentId} = useContext(TreemenuContext);
  console.log("RecursiveItem,title,path,id,enabled", title,path,id,enabled,expanded);
  const handleClick = (a_id,path,enabled) => {
    console.log("InternalPageTreeMenu a_id,enabled", a_id,enabled);
    let isValidatedReturned = ValidatePage(a_id);
    var isValidated = Promise.resolve(isValidatedReturned);
    isValidated.then(function(value) {
        if(value===1) { //
          let currentElement = document.getElementById("treemenuitem" + a_id.toString());
          if(currentElement!=null) {
            let parentElement = currentElement.parentElement.parentElement;
            if(parentElement!=null) {
              if(parentElement.tagName.toLowerCase()=="li") { // single item_title
                parentElement.parentElement.classList.add("active");  // nested ul
                parentElement = parentElement.parentElement.previousSibling;
                parentElement.classList.add("caret-down");
              } else {
                parentElement.classList.add("caret-down");
                parentElement.nextSibling.classList.add("active");
              }
              parentElement = parentElement.parentElement.parentElement;
              while(parentElement.tagName.toLowerCase()=="ul") {
                parentElement.classList.add("active"); 
                parentElement.previousSibling.classList.add("caret-down");  
                parentElement = parentElement.parentElement.parentElement;
              }
            }
          }
          if(a_id===0||enabled===false) return;
          setCurrentId(a_id);
          getMenuId(a_id);
          getTreemenuId(a_id);      
        } else {
            setModalTitle("No Access To The Screen");
            setShowModal(true);
        }
    });
  }

  const getFontWeight = (a_id,a_currentId,a_enabled) => {
    if(a_enabled===false) return 100;
    if(a_id===a_currentId)  return 700;
    if(a_currentId===Math.floor(a_currentId/10000)*10000) { // a_currentId is layer1 item
        if(a_id===a_currentId)
          return 700;
        else
          return 300;
    } else { // a_currentId to layer 2 or 3 item
      if(Math.floor(a_id/10000)===Math.floor(a_currentId/10000)) { //a_id and a_currentId belong to the same layer 1, 
        if(a_id===Math.floor(a_id/10000)*10000) { //a_id is layer1 item, it is parent of a_currentId
            return 500;
        } else { // need to check layer 2
          let a_id2 = a_id - Math.floor(a_id/10000)*10000;
          let a_currentId2 = a_currentId - Math.floor(a_currentId/10000)*10000;
          if(a_currentId2===Math.floor(a_currentId2/100)*100) { // a_currentId is layer2 item
            if(a_id===a_currentId)
              return 700;
            else
              return 300;
          } else { //a_currentId is layer3 item
            if(a_id2===Math.floor(a_id2/100)*100) { // a_id2 is layer 2 item, parent of a_currentId
              return 500;
            } else { 
              return 300;
            }
          }
        }
      }
    }
    return 300;
  }
  let hasChildren = false;
  try {
    if(nested.length > 0) hasChildren = true;
  } catch(e) {
    console.log("RecursiveItem e",e);
    navigate("/sign-in");
  }
  let fWeight = getFontWeight(id,currentId,enabled);
  let fColor = (fWeight===700)?"#000000":(fWeight===100)?"#ff000":"#0000ff";
  return (
    <>
    {showModal ? <ResponseWindowOk title={modalTitle} setShowModal={setShowModal} /> : null}     
    {hasChildren?
      <li>
        <span className={"caret"} id={'treemenuitemcaret' + id.toString()}>
          <a href="#" onClick={() => handleClick(id,path,enabled)} >
            <span id={'treemenuitem' + id.toString()} style={{fontWeight:getFontWeight(id,currentId,enabled),fontColor:getFontWeight(id,currentId,enabled)===700?"#000000":"#0000ff"}}>{title}</span>
          </a>
        </span>
        <ul class="nested" id={'treemenuitemnested' + id.toString()}>
      {nested.map((item) => (
        <RecursiveItem key={item.title} sub_menu_id={sub_menu_id} getMenuId={getMenuId}  getTreemenuId={getTreemenuId} {...item} />
      ))}
        </ul>
      </li>
    :enabled?
      <li>
          <a href="#" onClick={() => handleClick(id, path)}>
            <span id={'treemenuitem' + id===null||id===undefined?"":id.toString()} style={{fontWeight:getFontWeight(id,currentId,enabled),fontColor:getFontWeight(id,currentId,enabled)===700?"#000000":"#0000ff"}}>{title}</span>
          </a>
      </li>
      :null
    }
  </>    
  )
}
//getMenuId
const InternalPageTreeMenu = (props)=> {
  let menuItems = getBuiltMenuItem();
  const [validated,setValidated] = useState(true);

  console.log("InternalPageTreeMenu-menuItems",menuItems);
  if(menuItems==={}||menuItems===null||menuItems===undefined) {
    setValidated(false);
  }
  const [mainId, setMainId] = useState(props.currentId);
  const navigate = useNavigate();
  let mounted = false;
  const getTreemenuId=(a_menu_id)=>{
    setMainId(a_menu_id)
  }
  const handleClick = (path) => {
      navigate(path);
  };

  useEffect(() => {
    var toggler = document.getElementsByClassName("caret");
    
    if(!mounted) {
      for (let i = 0; i < toggler.length; i++) {
        toggler[i].addEventListener("click", function() {
          this.parentElement.querySelector(".nested").classList.toggle("active");
          this.classList.toggle("caret-down");
        });
      }
    }
    
    
    if(document.getElementById("treemenuitemcaret0")!=null&&document.getElementById("treemenuitemcaret0")!=undefined) {
      document.getElementById("treemenuitemcaret0").classList.add("caret-down");
      document.getElementById("treemenuitemnested0").classList.add("active");
    }
    
    let id_list = [];
    let curId = props.currentId;
    let level1Id = Math.floor(curId/10000)*10000;
    id_list.push(level1Id);
    if(curId>level1Id) {
      let level2remain = curId - level1Id;
      let level2Id = level1Id + Math.floor(level2remain/100)*100
      id_list.push(level2Id);
      if(curId>level2Id)
        id_list.push(curId);
    }

//    let id_list = [30000,30100,30101];
    for(let i=0;i<id_list.length;i++) {
      let currentElement = document.getElementById("treemenuitem" + id_list[i].toString());
      if(currentElement!=null) {
        let parentElement = currentElement.parentElement.parentElement;
        if(parentElement.tagName.toLowerCase()=="li") { // single item_title
          parentElement.parentElement.classList.add("active");  // nested ul
          parentElement = parentElement.parentElement.previousSibling;
          parentElement.classList.add("caret-down");
        } else {
          parentElement.classList.add("caret-down");
          parentElement.nextSibling.classList.add("active");
        }
        parentElement = parentElement.parentElement.parentElement;
        while(parentElement.tagName.toLowerCase()=="ul") {
          parentElement.classList.add("active"); 
          parentElement.previousSibling.classList.add("caret-down");  
          parentElement = parentElement.parentElement.parentElement;
        }
      }
    }
    setMainId(props.currentId);
//    displayAncestorElement(props.currentId);
    mounted = true;

  }, [props.currentId]);
  useEffect(() => {
    return () => {
      let toggler2 = document.getElementsByClassName("caret");  
      /*
      for (let i = 0; i < toggler2.length; i++) {
        toggler2[i].removeListener("click");
      }
      */
    };
  });

  return (
      <>
            <RecursiveItem sub_menu_id={props.sub_menu_id} getMenuId={props.getMenuId} getTreemenuId={getTreemenuId} {...menuItems} />
      </>
  )
}   

export default InternalPageTreeMenu;

/*
const RecursiveItem = ({sub_menu_id, getMenuId, getTreemenuId, title,path,id,enabled, expanded,nested}) => {
*/