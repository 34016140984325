// import getToken, {setToken} from './GetSetToken.js';
import getToken from './GetSetToken';

import getApiUrl, {getPassthroughUrl,getPassthroughUrl2} from './GetApiUrl';

const DataRetrieval = async(app_id,gui_id,sql_id,input_data)=> {
    // page authentication
    const api_url = getPassthroughUrl();
    let token = getToken();
    // get row count
//    let input_data = '{"request_row_count":1}'
    let the_input = '{"usp_name":"usp_react_sql_retrieve","sql_id":' + sql_id.toString() + ', "input_data":' + input_data + '}';
    const settings = {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
        "auth_token": token,"app_id":app_id,
        "gui_id":gui_id,
        "input": the_input, 
        })
    };
    try {
        const fetchResponse = await fetch(api_url, settings);
        const data = await fetchResponse.json();
        console.log("DataRetrieval-data", data)
        if(data.success) { // the access is validated
            return data.output;
        } else {
            return "";
        }

    } catch (error) {
        console.log("DataRetrieval-error", error)
        return "";
    } 
}

export const retrieveRowCount = async(app_id,gui_id,sql_id,where_clause)=> {
    // page authentication
    const api_url = getPassthroughUrl();
    let token = getToken();
    // get row count
    let input_data = '{"request_row_count":1'
    if(where_clause==="") 
        input_data = input_data + '}'
    else
        input_data = input_data + ',' + where_clause + '}'
    console.log("retrievalFragment-input_data", input_data)
 //   let the_input = '{"usp_name":"usp_react_sql_retrieve","sql_id":' + sql_id.toString() + ', "input_data":' + input_data + '}';
    let the_input = '{"usp_name":"usp_react_sql_retrieve","sql_id":' + sql_id.toString() + ', "input_data":' + input_data + '}';
    const settings = {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
        "auth_token": token,"app_id":app_id,
        "gui_id":gui_id,
        "input": the_input, 
        })
    };
    try {
        const fetchResponse = await fetch(api_url, settings);
        const data = await fetchResponse.json();
        console.log("retrievalFragment-data", data)
        if(data.success) { // the access is validated
            return data.output;
        } else {
            return "";
        }

    } catch (error) {
        console.log("retrievalFragment-error", error)
        return "";
    } 
}

//usp_react_autocomplete_retrieve

export const retrieveApiData = async(app_id,gui_id,sql_id,usp_name,input_data)=> {
    // page authentication
    const api_url = getPassthroughUrl();
    let token = getToken();
    console.log("retrieveApiData-input_data", input_data)
    let the_input = '{"usp_name":"' + usp_name + '","sql_id":' + sql_id.toString() + ',"input_data":' + input_data + '}';

    const settings = {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
        "auth_token": token,"app_id":app_id,
        "gui_id":gui_id,
        "input": the_input, 
        })
    };
    try {
        const fetchResponse = await fetch(api_url, settings);
        const data = await fetchResponse.json();
        console.log("retrieveApiData-data", data)
        if(data.success) { // the access is validated
            return data.output;
        } else {
            return "";
        }

    } catch (error) {
        console.log("retrieveApiData-error", error)
        return "";
    } 
}

// with sub_sql_id
export const zoftApiForUsp = async(app_id,gui_id,sql_id,subsql_id,subsql_count,usp_name,input_data)=> {
    // page authentication
    const api_url = getPassthroughUrl();
    let token = getToken();
    console.log("zoftApiForUsp-input_data", input_data)
    let the_input = '';
    if(sql_id>0) {
        if(subsql_id>0) {
            if(subsql_count>0) {
                the_input = '{"usp_name":"' + usp_name + '","sql_id":' + sql_id.toString() + ',"subsql_id":' + subsql_id.toString() +',"subsql_count":' + subsql_count.toString() +',"input_data":' + input_data + '}';
            } else {
                the_input = '{"usp_name":"' + usp_name + '","sql_id":' + sql_id.toString() + ',"subsql_id":' + subsql_id.toString() +',"input_data":' + input_data + '}';
            }
        } else {
            the_input = '{"usp_name":"' + usp_name + '","sql_id":' + sql_id.toString() + ',"input_data":' + input_data + '}';
        }
    } else {
        the_input = '{"usp_name":"' + usp_name + '","input_data":' + input_data + '}';
    }
    const settings = {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
        "auth_token": token,"app_id":app_id,
        "gui_id":gui_id,
        "input": the_input, 
        })
    };
    try {
        const fetchResponse = await fetch(api_url, settings);
        const data = await fetchResponse.json();
        console.log("retrieveApiData2-data", data)
        return {"return_status":0,"return_data":data}
    } catch (error) {
        console.log("retrieveApiData2-error", error)
        return {"return_status":-1,"return_data":error}
    } 
}
//zoftApiForUspField(1,30101,field_id,43,0,"usp_react_sql_onchange_update", the_input_data);
export const zoftApiForUspField = async(app_id,gui_id,field_id,sql_id,subsql_id,usp_name,input_data)=> {
    // page authentication
    const api_url = getPassthroughUrl2();
    let token = getToken();
    console.log("zoftApiForUspField-input_data", input_data)
    let the_input = '';
    if(sql_id>0) {
        if(subsql_id>0) {
            the_input = '{"usp_name":"' + usp_name + '","sql_id":' + sql_id.toString() + ',"subsql_id":' + subsql_id.toString() +',"input_data":' + input_data + '}';
        } else {
            the_input = '{"usp_name":"' + usp_name + '","sql_id":' + sql_id.toString() + ',"input_data":' + input_data + '}';
        }
    } else {
        the_input = '{"usp_name":"' + usp_name + '","input_data":' + input_data + '}';
    }
    const settings = {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
        "auth_token": token,"app_id":app_id,
        "gui_id":gui_id,
        "field_id":field_id,
        "input": the_input, 
        })
    };
    try {
        console.log("zoftApiForUspField-settings", settings);
        const fetchResponse = await fetch(api_url, settings);
        const data = await fetchResponse.json();
        console.log("zoftApiForUspField-data", data)
        return {"return_status":0,"return_data":data}
    } catch (error) {
        console.log("zoftApiForUspField-error", error)
        return {"return_status":-1,"return_data":error}
    } 
}

export const retrieveApiData2 = async(app_id,gui_id,sql_id,sub_sql_id,usp_name,input_data)=> {
    // page authentication
    const api_url = getPassthroughUrl();
    let token = getToken();
    console.log("retrieveApiData2-input_data", input_data)
    let the_input = '{"usp_name":"' + usp_name + '","sql_id":' + sql_id.toString() + ',"subsql_id":' + sub_sql_id.toString() +',"input_data":' + input_data + '}';

    const settings = {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
        "auth_token": token,"app_id":app_id,
        "gui_id":gui_id,
        "input": the_input, 
        })
    };
    try {
        const fetchResponse = await fetch(api_url, settings);
        const data = await fetchResponse.json();
        console.log("retrieveApiData2-data", data)
        return {"return_status":0,"return_data":data}
        } catch (error) {
        console.log("retrieveApiData2-error", error)
        return {"return_status":-1,"return_data":error}
    } 
}
const retrievalFragment = async(app_id,gui_id,sql_id,offset_row,request_rows,where_clause)=> {
    // page authentication
    const api_url = getPassthroughUrl();
    let token = getToken();
    // get row count
    let input_data = '{"request_row_count":0,"offset_row":' + offset_row.toString() + ',"request_rows":' + request_rows.toString()
    if(where_clause==="") 
        input_data = input_data + '}'
    else
        input_data = input_data + ',' + where_clause + '}'
    console.log("retrievalFragment-input_data", input_data)
    let the_input = '{"usp_name":"usp_react_sql_retrieve","sql_id":' + sql_id.toString() + ', "input_data":' + input_data + '}';
    const settings = {
        headers: {'Content-Type': 'application/json',},
        method: 'POST',
        body: JSON.stringify({
        "auth_token": token,"app_id":app_id,
        "gui_id":gui_id,
        "input": the_input, 
        })
    };
    try {
        const fetchResponse = await fetch(api_url, settings);
        const data = await fetchResponse.json();
        console.log("retrievalFragment-data", data)
        if(data.success) { // the access is validated
            return data.output;
        } else {
            return "";
        }

    } catch (error) {
        console.log("retrievalFragment-error", error)
        return "";
    } 
}
export const retrievalFragment2 = async(app_id,gui_id,sql_id,offset_row,request_rows,where_clause)=> {
    // page authentication
    const api_url = getPassthroughUrl();
    let token = getToken();
    console.log("retrievalFragment2-where_clause", where_clause)
    // get row count
    let input_data = [{"request_row_count":0},{"offset_row":offset_row},{"request_rows":request_rows}];
    if(where_clause!=undefined) {
        for(let i=0; i<where_clause.length; i++) {
            input_data.push({});
            input_data[input_data.length-1] = where_clause[i]
        }
    } else {
        return "[]";
    }
    input_data.concat(where_clause);
    console.log("retrievalFragment2-input_data", input_data)
    let the_input = '{"usp_name":"usp_react_sql_retrieve2","sql_id":' + sql_id.toString() + ', "input_data":' + JSON.stringify(input_data) + '}';
    const settings = {
        headers: {'Content-Type': 'application/json',},
        method: 'POST',
        body: JSON.stringify({
        "auth_token": token,"app_id":app_id,
        "gui_id":gui_id,
        "input": the_input, 
        })
    };
    try {
        console.log("retrievalFragment2-settings",JSON.stringify(settings));
        const fetchResponse = await fetch(api_url, settings);
        const data = await fetchResponse.json();
        console.log("retrievalFragment2-data", data)
        if(data.success) { // the access is validated
            return data.output;
        } else {
            return "";
        }

    } catch (error) {
        console.log("retrievalFragment2-error", error)
        return "";
    } 
}

export const retrieveData = async(app_id,gui_id,sql_id,offset_row,request_rows,where_clause)=>{
    console.log("retrieveData,app_id,gui_id,sql_id,offset_row,request_rows,where_clause",app_id,gui_id,sql_id,offset_row,request_rows,where_clause)
    const fragment = await retrievalFragment(app_id,gui_id,sql_id,offset_row,request_rows,where_clause);
    if(fragment==="") return [];
    let fragment_json = JSON.parse(fragment);
    if(fragment_json===null)
        return [];
    if(fragment_json.length<request_rows) {
        let new_offset_row = fragment_json.length;
        let new_request_rows = request_rows - fragment_json.length;
        return fragment_json.concat(await retrieveData(app_id,gui_id,sql_id,new_offset_row,new_request_rows,where_clause));
    } else {
        return fragment_json;
    }
}

export const retrieveData2 = async(app_id,gui_id,sql_id,offset_row,request_rows,where_clause)=>{
    const fragment = await retrievalFragment2(app_id,gui_id,sql_id,offset_row,request_rows,where_clause);
    if(fragment==="") return [];
    let fragment_json = JSON.parse(fragment);
    if(fragment_json===null)
        return [];
    if(fragment_json.length<request_rows) {
        let new_offset_row = offset_row + fragment_json.length;
        let new_request_rows = request_rows - fragment_json.length;
        return fragment_json.concat(await retrieveData2(app_id,gui_id,sql_id,new_offset_row,new_request_rows,where_clause));
    } else {
        return fragment_json;
    }
}

export default DataRetrieval;