import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useNavigate
  }
from "react-router-dom";

import "./../../styles.css";

//import '@progress/kendo-theme-default/dist/all.css';
import './../../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Navbar from 'react-bootstrap/Navbar';
import './../../v2/css/bootstrap.min.css';
//import amplicon_sequencing from './../../v2/img/amplicon sequencing.png'


/*
import NGSred from "./v2/img/NGS-red.png"
import Sangerred from "./v2/img/Sanger-red.png"
import Cloningred from "./v2/img/Cloning-red.png"
import Oligored from "./v2/img/Oligo-red.png"
import DNAprepred from "./v2/img/DNAprep-red.png"
*/

//const components = PanelBarUtils.mapItemsToComponents(items);
const PublicPageServiceDnaAssembly = ()=> {
    const navigate = useNavigate();
  
    const handleClick = (path) => {
        navigate(path);
    }
return(
    <>
<div id="nav">
<nav>
    <ul>
        <li><a href="/services"><b>Services</b></a>
            <ul>
                <li><a href="/service/genefragment">Q-Block Gene Fragment</a></li>
                <li><a href="/service/oligo">Oligo Synthesis</a></li>
        <li><a href="/service/dna_sequencing">DNA Sequencing</a>
        <ul>
            <li><a href="/service/dna_sequencing">Basic Sequencing</a></li>
            <li><a href="#">Bacteria Sequencing</a></li>
            <li><a href="/service/amplicon_sequencing">Amplicon Sequencing</a></li>
            <li><a href="/service/primer_walking">Primer Walking</a></li>
        </ul>
        </li>
        <li><a href="/service/ngs_services">NGS Services</a>
        <ul>
            <li><a href="/service/ngs_dnaseq">DNA Sequencing</a></li>
            <li><a href="/service/ngs_rnaseq">RNA Sequencing</a></li>
            <li><a href="/service/ngs_cellseq">Single Cell Sequencing</a></li>
        </ul>
        </li>
        <li><a href="/service/ab_sequencing">Ab Sequencing</a></li>
        <li><a href="/service/dna_maxiprep">Plasmid DNA Maxiprep</a></li>
        <li><a href="/service/molecular_cloning">Molecular Cloning</a></li>
        <li><a href="/service/dna_assembly">DNA Assembly</a></li>
        </ul>
            </li>
        <li><a href="#"><b>Products</b></a>
            <ul>
                <li><a href="/product/q_maxseq">Q-Max Seq</a></li>
                <li><a href="/product/seqcard">SeqCard</a></li>
                <li><a href="/service/oligo">Custom Oligo Synthsis</a></li>
            </ul>
        </li>
        <li><a href="/support"><b>Tools</b></a>
            <ul>
                <li><a href="/support#1">Software</a></li>
                <li><a href="/support#2">FAQ</a></li>
                <li><a href="/support#3">Primer List</a></li>
                <li><a href="/qbtools/findPrimer">Search for QB Primer</a></li>
            </ul>
        </li>
        <li><a href="/user/myqb"><b>Order</b></a></li>
        <li><a href="/press"><b>News</b></a></li>
        <li><a href="#"><b>About Us</b></a>
            <ul>
                <li><a href="/about">About Us</a></li>
                <li><a href="/career">Careers</a></li>
                <li><a href="/contact">Contact and Location</a></li>
                <li><a href="/pickup">Sample Pickup Schedule</a></li>
                <li><a href="/user/request_info">Request Information</a></li>
            </ul>
        </li>
        <li>
            <a href="/login"><b>Login</b></a>
        </li>
    </ul>
</nav>
</div>

<div id="header">
    <a href="/"><image src="/assets/images/logotransparent.png" title="Quintara Bio"></image></a>
    <div style={{margin:"auto", position: "relative", height: 250}}>
    <p>
        <b>Billing</b> <br />
        1-510-990-2168 <br />
        accounting@quintarabio.com
    </p>
    <p>
        <b>D.C. Area</b> <br />
        1-301-761-4835 <br />
        dc@quintarabio.com
    </p>
    <p>
        <b>Boston Area</b> <br />
        1-617-943-2768 <br />
        boston@quintarabio.com
    </p>
    <p>
        <b>San Francisco Bay Area</b> <br />
        phone: 1-415-738-2509<br />
        info@quintarabio.com
    </p>
    </div>
</div>



</>





)
}
  
function WebSiteServices() {return (<div>WebSiteServices</div>)}

export default PublicPageServiceDnaAssembly;