import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import QuoteContext from "./QuoteContext";
import SplitPaneContext from "./SplitPaneContext";

const SplitPane = ({ children, ...props }) => {
//  const [clientDataPageHeight, clientDataPageHeight] = useState(null);
  const [clientHeight, setClientHeight] = useState(0);
  const [clientWidth, setClientWidth] = useState(null);
  const yDividerPos = useRef(null);
  const xDividerPos = useRef(null);
  const {paneHeight}  = useContext(QuoteContext);

  const onMouseHoldDown = (e) => {
    yDividerPos.current = e.clientY;
    xDividerPos.current = e.clientX;
  };

  const onMouseHoldUp = () => {
    yDividerPos.current = null;
    xDividerPos.current = null;
  };

  const onMouseHoldMove = (e) => {
    if (!yDividerPos.current && !xDividerPos.current) {
      return;
    }
    setClientHeight(clientHeight + e.clientY - yDividerPos.current);
    setClientWidth(clientWidth + e.clientX - xDividerPos.current);
    yDividerPos.current = e.clientY;
    xDividerPos.current = e.clientX;
  };

  useEffect(() => {
    document.addEventListener("mouseup", onMouseHoldUp);
    document.addEventListener("mousemove", onMouseHoldMove);
    let split_pane = document.getElementById("the_split_pane");
      return () => {
        document.removeEventListener("mouseup", onMouseHoldUp);
        document.removeEventListener("mousemove", onMouseHoldMove);
      };
    });
  return (
    <div id="the_split_pane" {...props}>
      <SplitPaneContext.Provider
          value={{
            clientHeight,
            setClientHeight,
            clientWidth,
            setClientWidth,
            onMouseHoldDown,
          }}
        >
          {children}
      </SplitPaneContext.Provider>
    </div>
  );
};


/*
    <div style={{height:200}} {...props}>
*/

export const Divider = (props) => {
  const { onMouseHoldDown } = useContext(SplitPaneContext);

  return <div {...props} onMouseDown={onMouseHoldDown} />;
};

export const SplitPaneTop = (props) => {
  const topRef = createRef();
  const { clientHeight, setClientHeight } = useContext(SplitPaneContext);
 // const { quotes, setCurrQuote } = useContext(QuoteContext);
 
  useEffect(() => {
    if (!clientHeight) {
      setClientHeight(topRef.current.clientHeight);
      return;
    }

    topRef.current.style.minHeight = clientHeight + "px";
    topRef.current.style.maxHeight = clientHeight + "px";
  }, [clientHeight]);

  return (
    <div {...props} className="split-pane-top" ref={topRef}>
    </div>
  );
};

export const SplitPaneBottom = (props) => {
//  const { currQuote } = useContext(QuoteContext);
  return (
    <div {...props} className="split-pane-bottom">
    </div>
  );
};

export const SplitPaneLeft = (props) => {
  const topRef = createRef();
  const { clientWidth, setClientWidth } = useContext(SplitPaneContext);
  const {leftPanePortion}  = useContext(QuoteContext);
  const {paneHeight}  = useContext(QuoteContext);

  useEffect(() => {
    if (!clientWidth) {
      setClientWidth(topRef.current.clientWidth*leftPanePortion / 100);
      return;
    }
    topRef.current.style.minWidth = clientWidth + "px";
    topRef.current.style.maxWidth = clientWidth + "px";

    topRef.current.style.minHeight = paneHeight + "px";
//    topRef.current.style.maxHeight = paneHeight + "px";
  }, [clientWidth]);

  return <div {...props} className="split-pane-left" ref={topRef} />;
};

export const SplitPaneRight = ({ children, ...props }) => {
  const topRef = createRef()
//  const { title, quotes, currQuote } = useContext(QuoteContext);
  const { title} = useContext(QuoteContext);
 
 // const quote = quotes.find((el) => el.id === currQuote);
  const {paneHeight}  = useContext(QuoteContext);

  useEffect(() => {
    topRef.current.style.minHeight = props.paneHeight + "px";
    topRef.current.style.maxHeight = props.paneHeight + "px";
    topRef.current.style.clientHeight = props.paneHeight + "px";
    console.log('SplitPaneRight Height', paneHeight)
  });
  return (
    <div {...props} className="split-pane-right" ref={topRef} >
      {children}
      {title}
    </div>
  );
};

/*
export const SplitPaneRight = ({ children, ...props }) => {
  const topRef = createRef()
//  const { title, quotes, currQuote } = useContext(QuoteContext);
  const { title} = useContext(QuoteContext);
 
 // const quote = quotes.find((el) => el.id === currQuote);
  const {paneHight}  = useContext(QuoteContext);

  useEffect(() => {
    topRef.current.style.minHeight = paneHight + "px";
    topRef.current.style.maxHeight = paneHight + "px";
  });
  return (
    <div {...props} className="split-pane-right" ref={topRef}>
      {children}
      {title}
    </div>
  );
};
*/

export default SplitPane;
