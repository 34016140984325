//BEGIN_IMPORT_SECTIONs
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
//import { AgGridReact,AgGridColumn } from '@ag-grid-community/react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import {zoftApiForUsp, retrieveApiData,retrieveData2} from '../../../data_retrieval';
import {ResponseWindowYesNo2,ResponseWindowSelf} from '../../../Modals';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
//import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

import { ModalRetrieve } from '../../../ModalRetrieval';
import { ModalRetrieve2 } from '../../../ModalRetrieval2';

var the_this;
export default class EditInvoiceStatusListDataGrid extends React.Component {
	dd_select_status={}
	dd_select_quote={}
	selectedRows=[];
	input_parm0 = [
		{"request_row_count":1},
		{"offset_row":0},
		{"request_rows":1000},
		{"invoice_id":0},
		{"invoice_status_id":0},
		{"billgroup":""},
		{"category_id":0},
		{"institution":""},
		{"start_date":""},
		{"end_date":""},
		{"po":""},
		{"recalculate_totaldue":0},
		{"q_maxonly":0},
		{"exclude_paid":0},
		{"exclude_cancelled":0},
		{"exclude_nih":0},
	]
	state = {
		loaded:false,
		modules:[ClientSideRowModelModule],
		gridOptions:{},
		rowSelection: 'multiple',
		selected_building_id:0,
		data:[
//			{"checked": false, "inv_name":"","inv_num":0,"cat":0, "institute":0,"billgroup":0,"total_due":0,"quote_id":0,"prepaid":0,"invoice_date":"","past_due":0,
//			"status_id":0,"po":"","ref":"","user_name":"","status_date":"","pdf_inv":0,"xls_inv":0,"notes":"","detail":0,"ar_report":0}
		],
		offset_row : 0,
		request_rows:50,
		showModalRetrieve:false,
		input_parm:[],
		gui_id:0,
		sql_id:0,
		retrieve_source:1, // 1=search, 2=prev, 3=next
		total_rows:0, // 1=search, 2=prev, 3=next
		from_row:1,
		rowCountInd:1,
	}
	setResponse = (data) => {
		let statusCopy = Object.assign({}, this.state)
		statusCopy.data = data;
		this.gridApi.setRowData(this.state.data)
		statusCopy.showModalRetrieve = false;
		if(this.state.retrieve_source===1) {
			this.props.getRetrievedSearch(data.length,statusCopy.total_rows);
		} else if(this.state.retrieve_source===2) {
			this.props.getRetrievedPrev(data.length);
		} else {
			this.props.getRetrievedNext(data.length);
		}
		this.setState(statusCopy);
	}
	gridApi: GridApi
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: true })
	}
	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		this.input_parm0[0].request_row_count = 1;
		this.input_parm0[1].offset_row = this.props.input_parm.offset_row;
		this.input_parm0[2].request_rows = this.props.input_parm.request_rows;
		this.input_parm0[3].invoice_id = this.props.input_parm.invoice_id;
		this.input_parm0[4].invoice_status_id = this.props.input_parm.invoice_status_id;
		this.input_parm0[5].billgroup = this.props.input_parm.billgroup;
		this.input_parm0[6].category_id = this.props.input_parm.category_id;
		this.input_parm0[7].institution = this.props.input_parm.institution;
		this.input_parm0[8].start_date = this.props.input_parm.start_date;
		this.input_parm0[9].end_date = this.props.input_parm.end_date;
		this.input_parm0[10].po = this.props.input_parm.po;
		this.input_parm0[11].recalculate_totaldue = this.props.input_parm.recalculate_totaldue;
		this.input_parm0[12].q_maxonly = this.props.input_parm.q_maxonly;
		this.input_parm0[13].exclude_paid = this.props.input_parm.exclude_paid;
		this.input_parm0[14].exclude_cancelled = this.props.input_parm.exclude_cancelled;
		this.input_parm0[15].exclude_nih = this.props.input_parm.exclude_nih;

		for(let i = 0; i < this.props.invoice_status_list.length;i++)
			this.dd_select_status[this.props.invoice_status_list[i].id] = this.props.invoice_status_list[i].name;	
		if(this.props.search_clicked===true&&this.props.search_clicked!==prevProps.search_clicked) {
			console.log("EditInvoiceStatusListDataGrid-?Search Clicked")
			// get total row count
			let sql_id = this.props.input_parm.invoice_update_list===0?51:52;
			let app_id = 1;
			let gui_id = 30102;
			this.input_parm0[2].request_rows = 999999;
			//			input_parm0 = this.props.input_parm.slice(0,this.props.input_parm.length-1);
			// since this.input_parm0[0].request_row_count = 1, the api returns the total number of rows
            let tmp_data = retrieveApiData(app_id,gui_id,sql_id,"usp_react_sql_retrieve2", JSON.stringify(this.input_parm0));
            Promise.resolve(tmp_data).then((value)=>{
                console.log('componentDidUpdate retrieveData value',value);
                let total_row_count = parseInt(value);
				if(total_row_count>0) {
					this.input_parm0[2].request_rows = this.props.input_parm.request_rows;
					this.SearchRetrieveData(total_row_count);
				}
			})
		}
		if(this.props.prev_clicked===true&&this.props.prev_clicked!==prevProps.prev_clicked) {
			this.PrevRetrieveData();
		}
		if(this.props.next_clicked===true&&this.props.next_clicked!==prevProps.next_clicked) {
			console.log('EditInvoiceStatusListDataGrid componentDidUpdate next_clicked this.props.invoice_retrieved',this.props.invoice_retrieved);
			this.NextRetrieveData();
		}
		// update cur_status_id
		if(this.props.update_invoicestatusid>0&&this.props.update_invoicestatusid!=prevProps.update_invoicestatusid) {
            let input_parm_tmp = [];
			for(let i=0;i<this.selectedRows.length;i++) {
				input_parm_tmp.push([{"cur_status_id":this.props.update_invoicestatusid},{"invoice_id":this.state.data[this.selectedRows[i]].inv_num}])
			}
            console.log("EditInvoiceStatusGrid-componentDidUpdate input_parm_tmp",input_parm_tmp)
			if(input_parm_tmp.length>0) {
				let sql_id = 53;
				let subsql_id = 4;
				let subsql_count = 2;
				console.log('EditInvoiceStatusGrid-componentDidUpdate input_parm_tmp',input_parm_tmp);
				let api_return_data = zoftApiForUsp(1,30101,sql_id,subsql_id,subsql_count,"usp_react_sql_cmd_for_subsql_repeat", JSON.stringify(input_parm_tmp));
				Promise.resolve(api_return_data).then((value)=>{
					console.log('EditInvoiceStatusGrid-componentDidUpdate 4, value', value);
					if(value.return_status===0) { //normal return
						if(value.return_data.success===true) {
							console.log('EditInvoiceStatusGrid-componentDidUpdate Update success');
							let statusCopy = Object.assign({}, this.state)
							for(let i=0;i<this.selectedRows.length;i++) {
								statusCopy.data[this.selectedRows[i]].status_id = this.props.update_invoicestatusid;
								this.setState(statusCopy);
								this.gridApi.setRowData(this.state.data);
							}
						} else {//
							console.log('EditInvoiceStatusGrid-componentDidUpdate Update Fail');
						}
					} else {
						console.log('EditInvoiceStatusGrid-componentDidUpdate Update Fail');
					}
				});
			}
			/* using usp_react_sql_update_repeat
			let input_parm_tmp = [];
			for(let i=0;i<this.selectedRows.length;i++) {
				input_parm_tmp.push({"id":this.state.data[this.selectedRows[i]].inv_num,"cur_status_id":this.props.update_invoicestatusid})
			}
			if(input_parm_tmp.length>0) {
				console.log('EditInvoiceStatusGrid-componentDidUpdate input_parm_tmp',input_parm_tmp);
				let api_return_data = zoftApiForUsp(1,30102,51,0,0,"usp_react_sql_update_repeat", JSON.stringify(input_parm_tmp));
				Promise.resolve(api_return_data).then((value)=>{
					console.log('EditInvoiceStatusGrid-componentDidUpdate 4, value', value);
					if(value.return_status===0) { //normal return
						if(value.return_data.success===true) {
							console.log('EditInvoiceStatusGrid-componentDidUpdate Update success');
							let statusCopy = Object.assign({}, this.state)
							for(let i=0;i<this.selectedRows.length;i++) {
								statusCopy.data[this.selectedRows[i]].status_id = this.props.update_invoicestatusid;
								this.setState(statusCopy);
								this.gridApi.setRowData(this.state.data);
							}
						} else {//
							console.log('EditInvoiceStatusGrid-componentDidUpdate Update Fail');
						}
					} else {
						console.log('EditInvoiceStatusGrid-componentDidUpdate Update Fail');
					}
				});
			}
			*/
		}
	}
	onGridReady = params => {
    	this.gridApi = params.api;
 	};
	SearchRetrieveData = (total_rows) => {
		console.log('EditInvoiceStatusGrid SearchRetrieveData 0 this.props.input_parm',this.props.input_parm);
		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.rowCountInd = 1;
		let sql_id = this.props.input_parm.invoice_update_list===0?51:52;
		let app_id = 1;
		console.log('EditInvoiceStatusGrid SearchRetrieveData this.props.input_parm',this.props.input_parm);
		statusCopy0.sql_id = sql_id;
		statusCopy0.showModalRetrieve = true;
		statusCopy0.data = [];
		statusCopy0.input_parm = this.input_parm0;
		if(total_rows<1000)
			statusCopy0.input_parm[2].request_rows = total_rows;
		statusCopy0.retrieve_source = 1
		statusCopy0.total_rows = total_rows;
		statusCopy0.from_row = 1;
		this.setState(statusCopy0);
	}
	PrevRetrieveData = () => {
		let offset_row = this.props.invoice_retrieved.invoice_from_row - 1000;
		if(offset_row<0) offset_row = 0;
		let input_parm2 = [
			{"request_row_count":0},
			{"offset_row":offset_row},
			{"request_rows":1000},
			{"invoice_id":this.props.input_parm.invoice_id},
			{"invoice_status_id":this.props.input_parm.invoice_status_id},
			{"billgroup":this.props.input_parm.billgroup},
			{"category_id":this.props.input_parm.category_id},
			{"institution":this.props.input_parm.institution},
			{"start_date":this.props.input_parm.start_date},
			{"end_date":this.props.input_parm.end_date},
			{"po":this.props.input_parm.po},
			{"recalculate_totaldue":this.props.input_parm.recalculate_totaldue},
			{"q_maxonly":this.props.input_parm.q_maxonly},
			{"exclude_paid":this.props.input_parm.exclude_paid},
			{"exclude_cancelled":this.props.input_parm.exclude_cancelled},
			{"exclude_nih":this.props.input_parm.exclude_nih},
		]
		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.rowCountInd = 0;
		statusCopy0.input_parm = input_parm2;
		console.log('EditInvoiceStatusGrid PrevRetrieveData 0 statusCopy0.input_parm',statusCopy0.input_parm);
		statusCopy0.sql_id = this.props.input_parm.invoice_update_list===0?51:52;;
		statusCopy0.showModalRetrieve = true;
//		statusCopy0.data = [];
		statusCopy0.from_row = this.props.invoice_retrieved.invoice_from_row - 1000;
		statusCopy0.retrieve_source = 2
		this.setState(statusCopy0);
	}
	NextRetrieveData = () => {
		let input_parm3 = [
			{"request_row_count":0},
			{"offset_row":this.props.invoice_retrieved.invoice_to_row},
			{"request_rows":this.props.input_parm.request_rows},
			{"invoice_id":this.props.input_parm.invoice_id},
			{"invoice_status_id":this.props.input_parm.invoice_status_id},
			{"billgroup":this.props.input_parm.billgroup},
			{"category_id":this.props.input_parm.category_id},
			{"institution":this.props.input_parm.institution},
			{"start_date":this.props.input_parm.start_date},
			{"end_date":this.props.input_parm.end_date},
			{"po":this.props.input_parm.po},
			{"recalculate_totaldue":this.props.input_parm.recalculate_totaldue},
			{"q_maxonly":this.props.input_parm.q_maxonly},
			{"exclude_paid":this.props.input_parm.exclude_paid},
			{"exclude_cancelled":this.props.input_parm.exclude_cancelled},
			{"exclude_nih":this.props.input_parm.exclude_nih},
		]
		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.input_parm = input_parm3;
		statusCopy0.rowCountInd = 0;
		console.log('EditInvoiceStatusGrid NextRetrieveData 0 statusCopy0.input_parm',statusCopy0.input_parm);
		statusCopy0.sql_id = this.props.input_parm.invoice_update_list===0?51:52;;
		statusCopy0.showModalRetrieve = true;
		statusCopy0.from_row = this.props.invoice_retrieved.invoice_to_row + 1;
		statusCopy0.retrieve_source = 3
		this.setState(statusCopy0);
	}

	onSelectionChanged = (event)=>{
		the_this.selectedRows = [];
//		const selectedRows =  event.api.getSelectedRows();
		const selectedNodes =  event.api.getSelectedNodes();
		console.log('EditInvoiceStatusGrid selectedNodes',selectedNodes);
		if(selectedNodes.length===0) return;
		selectedNodes.forEach(function(node, i){
			the_this.selectedRows.push(node.rowIndex);
		});
		console.log('EditInvoiceStatusGrid selectedNodes[0].rowIndex, selectedNodes[0].data.inv_num',selectedNodes[0].rowIndex,selectedNodes[0].data.inv_num);
		console.log('EditInvoiceStatusGrid event.api', event.api);	
		if(the_this.props.update_mode===0) { // if update_mode==1 the selected row will not be populated to detailed datagrid
			the_this.setState({updated_from_detail_allowed:true})
			the_this.props.getCurrentInvoiceIdFromList(selectedNodes[0].data.inv_num)
			the_this.props.getOrderId(0) // to make Order Item List Retrieval work
//			console.log('EditInvoiceStatusGrid selectedNodes[0].data.row, selectedNodes[0].data.inv_num',selectedNodes[0].data.row,selectedNodes[0].data.inv_num);
		}
	}
	onCellClicked(event){
		console.log("InstitutionGrid onCellClicked event", event);
	}
	onCellDoubleClicked(obj){
		console.log('InstitutionGrid onCellDoubleClicked(obj)', obj);
	}	
	onCellValueChanged(event) {
		console.log('InstitutionGrid onCellValueChanged(event)', event);
	}
/*
	onCellValueChanged = (obj) => {
		console.log('AccessRoleGuiGrid onCellValueChanged obj', obj);		
		let field_name = obj.column.colDef.field 
		let the_role_id = obj.data.role_id
		let the_gui_id = obj.data.gui_id
		let the_data = obj.value
		let token = getToken();
		if (typeof the_data==='string')
			the_data = '"' + the_data + '"'
			let the_input_data = '{"' + field_name + '":' + the_data + ',"role_id":' + the_role_id.toString() + ',"gui_id":' + the_gui_id.toString() + '}'
			let the_input = '{"usp_name":"usp_react_sql_onchange_update","sql_id":3,"input_data"' + ':' + the_input_data + '}'
			console.log('AccessRoleGuiGrid-onCellValueChanged the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {
			method: 'POST',headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
         })
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			console.log('AccessRoleGuiGrid-onCellValueChanged 2 no error');
		})	
		.catch((error) => {
			console.error(error-'Error in update!');
			//this.insertOrder(1);
		});
	}
*/

//rowSelection:'multiple',
//rowMultiSelectWithClick:true
//rowSelection='multiple'
// valueGetter: "node.rowIndex + the_this.props.invoice_retrieved.invoice_from_row",
// valueGetter: function (params) { return params.node.rowIndex + the_this.props.invoice_retrieved.invoice_from_row;},

	createGridOptions() {
		const options: GridOptions = {
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true,
				rowMultiSelectWithClick: true,
       			headerCheckboxSelection: true,
				rowDeselection: true,
			},
			columnDefs: [
				{headerName:"",headerClass:"header-checked",field:"checked",hide:false,width:30,sortable:true,headerCheckboxSelection:true,checkboxSelection:true,editable:true,valueSetter:function (params) {params.data.checked=params.newValue;return true;},suppressNavigable:true,
				headerCheckboxSelectionFilteredOnly: true,
				},
				{headerName:"Row",headerClass:"header-row",field:"row",hide:false,width:60,sortable:false,checkboxSelection:false,
					editable:false,suppressNavigable:false,
					valueGetter: function (params) { return params.node.rowIndex + the_this.state.from_row;},
				},
				{headerName:"Invoice Name",headerClass:"header-inv_name",field:"inv_name",hide:false,width:160,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.inv_name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Invoice #",headerClass:"header-inv_num",field:"inv_num",hide:false,width:100,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.inv_num= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Category",headerClass:"header-cat",field:"cat",hide:false,sortable:false,width:160,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.cat= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Institution",headerClass:"header-institute",field:"institute",hide:false,sortable:false,width:160,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.institute= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"BillGroup",headerClass:"header-billgroup",field:"billgroup",hide:false,sortable:false,width:160,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.billgroup= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Total Due",headerClass:"header-total_due",field:"total_due",hide:false,sortable:false,width:100,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.total_due= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Quote",headerClass:"header-quote_id",field:"quote_id",hide:false,sortable:false,width:160,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.quote_id= params.newValue;return true;},suppressNavigable:true,
				valueFormatter: function (params) {return the_this.dd_select_quote[params.value]},},
				{headerName:"Prepaid",headerClass:"header-prepaid",field:"prepaid",hide:false,sortable:false,width:100,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.prepaid= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Invoice Date",headerClass:"header-invoice_date",field:"invoice_date",hide:false,sortable:false,width:150,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.invoice_date= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Paste Due (days)",headerClass:"header-past_due",field:"past_due",hide:false,sortable:false,width:160,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.status_id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Current Status",headerClass:"header-status_id",field:"status_id",hide:false,sortable:false,width:100,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.prepaid= params.newValue;return true;},suppressNavigable:true,
				valueFormatter: function (params) {return the_this.dd_select_status[params.value]},},
				{headerName:"PO",headerClass:"header-po",field:"po",hide:false,sortable:false,width:100,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.po= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"REF",headerClass:"header-ref",field:"ref",hide:false,sortable:false,width:50,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.ref= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"User",headerClass:"header-user_name",field:"user_name",hide:false,sortable:false,width:100,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.user_name= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Status Date",headerClass:"header-status_date",field:"status_date",hide:false,sortable:false,width:100,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.status_date= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Pdf/xls",headerClass:"header-pdf_inv",field:"pdf_inv",hide:false,sortable:false,width:100,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.pdf_inv= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Notes",headerClass:"header-notes",field:"notes",hide:false,sortable:false,width:160,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.notes= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Detail",headerClass:"header-detail",field:"detail",hide:false,sortable:false,width:160,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.detail= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Collection",headerClass:"header-ar_report",field:"ar_report",hide:false,sortable:false,width:160,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.ar_report= params.newValue;return true;},suppressNavigable:true,},
			],
		}
		return options;
	}
	render() {
		return  (
			<div id="pid_0630_cell_001" style={{position:"absolute",top:0,width:1350,height:300,backgroundColor:"#FFFFFF"}}>
				<div id="pid_0630_cell_001_001" className="ag-theme-balham" 
                    style={{position:"absolute",top:0,left:0,width:1350,height:300,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={this.state.rowSelection} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
					>
					</AgGridReact>
				</div>
				{this.state.showModalSelf?
					<ResponseWindowSelf
						title={"Data Is Loading . . ."} 
					/> :null}
				{this.state.showModalYesNo?
					<ResponseWindowYesNo2
						title={"Current updated record will be overridden, continue?"} 
						setShowModal={(showModel)=>this.setShowModal(showModel)} 
						setResponse={(response)=>this.setResponse(response)}
					/> :null}
				{this.state.showModalRetrieve?
					<ModalRetrieve
						rowCountInd={this.state.rowCountInd}
						appId={1}	
						guiId={30102}
						sqlId={this.state.sql_id}
						inputParm={this.state.input_parm}
						setResponse={(response)=>this.setResponse(response)}
					/> :null}			
			</div>
		)
	}
}

