import React, {Component} from 'react';
//import '@progress/kendo-theme-default/dist/all.css';
import './App.css';
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useNavigate
  }
from "react-router-dom";

import "./styles.css";
//import "./qbio_style.css"
import "./qb363v3_clone.css";

import turnaround from "./assets/images/turnaround.gif"
import quality from "./assets/images/quality.gif"
import service from "./assets/images/service.gif"

import facebook from "./assets/images/facebook.png"
import twitter from "./assets/images/twitter.png"
import google from "./assets/images/google.png"
import linkedin from "./assets/images/linkedin.png"


const InternalUserPageFooter = ()=> {
  const navigate = useNavigate();
  const handleClick = (path) => {
      navigate(path);
  }  


    return (
        <>
            <div id="mainbottom">
                <div id="footer">
                <div class="social">
                    <a href="https://www.facebook.com/pages/QuintaraBio/449236155251241?ref=br_rs"><img src={facebook} /></a>
                    <a href="https://twitter.com/quintara_bio"><img src={twitter} /></a>
                    <a href="https://plus.google.com/111222527525773281701/about"><img src={google} /></a>
                    <a href="http://www.linkedin.com/company/quintara-biosciences"><img src={linkedin} /></a>
                </div>
                <div class="fmenu" style={{paddingRight:20}}>        
                    <b><font color="#fff">COMPANY</font></b><br />
                    <div style={{color:"#dddddd",fontSize:"small"}}>
                    <a onClick={()=>handleClick("/about")}>About Us</a><br />
                    <a onClick={()=>handleClick("/contact")}>Contact</a><br />
                    <a onClick={()=>handleClick("/career")}>Careers</a><br />
                    <a onClick={()=>handleClick("/service/request")}>Request Info</a><br />
                    <a onClick={()=>handleClick("/support#2")}>FAQ</a>
                    </div>
                </div>
                <div class="fmenu">
                    <b><font color="#fff">SERVICES</font></b><br />
                    <div style={{color:"#dddddd",fontSize:"small"}}>
                    <a onClick={()=>handleClick("/service/dna_sequencing")}>DNA Sequencing</a><br />
                    <a onClick={()=>handleClick("/service/ngs_services")}>NGS Services</a><br />
                    <a onClick={()=>handleClick("/service/ab_sequencing")}>Ab Sequencing</a><br />
                    <a onClick={()=>handleClick("/service/dna_maxiprep")}>Plasmid DNA Maxiprep</a><br />
                    <a onClick={()=>handleClick("/service/molecular_cloning")}>Molecular Cloning</a>
                    </div>
                </div>
                <div class="fmenu">
                    <b><font color="#fff">PRODUCTS</font></b><br />
                    <div style={{color:"#dddddd",fontSize:"small"}}>
                    <a onClick={()=>handleClick("/product/q_maxseq")}>Q-Max Seq</a><br />
                    <a onClick={()=>handleClick("/product/seqcard")}>SeqCard</a><br />
                    <a onClick={()=>handleClick("/service/oligo")}>Custom Oligo Synthsis</a>
                    </div>
                </div>
                <div class="fmenu">
                    <b><font color="#fff">SAMPLE PICKUP</font></b><br />
                    <div style={{color:"#dddddd",fontSize:"small"}}>
                    <a onClick={()=>handleClick("/pickup/umassmed")}>UMASS</a><br />
                    <a onClick={()=>handleClick("/pickup/yale")}>Yale</a><br />
                    <a onClick={()=>handleClick("/pickup/longwood")}>Longwood</a>
                    </div>
                </div>
                <div style={{clear:"both"}}></div>
            <br />
            <p><font size="2">© 2008-2020 Quintara Biosciences -3583 Investment Blvd, Suite 2, Hayward, CA 94545 | 625 Mt Auburn Street, Suite 105, Cambridge, MA 02138</font> </p>
            </div>
            </div>

        </>
    )
}   

export default InternalUserPageFooter;