import React, {createContext, Component,useEffect, useState, useContext, useRef} from 'react';
//import 'bootstrap-css-only/css/bootstrap.min.css';
import {useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import './v2/css/bootstrap.min.css';
import "./styles.css";
import "./qbio_style_internal_user.css";
import {ResponseWindow,ResponseWindowOk} from './Modals';

import InternalPageHeader from "./InternalPageHeader2"
import InternalPageFooter from "./InternalPageFooter"
import InternalPageTreeMenu from "./InternalPageTreeMenu"
import getToken from "./GetSetToken"
import {getBuiltMenuItem,getMenuData} from './InternaPageTreeItems';
import ValidatePage from './ValidatePage';

import SplitPane, {
  Divider,
  SplitPaneBottom,
  SplitPaneLeft,
  SplitPaneRight,
  SplitPaneTop,
} from "./SplitPane";
import QuoteContext from "./QuoteContext";
import TreemenuContext from "./TreemenuContext"
import IdleTimeContext from "./IdleTimeContext"

const getMenuList=(menu_id, sub_menu_id)=>{
  let sub_menu_title = '';
  let submenu_list_tmp = [];
  let submenuindex_tmp = 0;
  let menuItems = getBuiltMenuItem();
  console.log("InternalPageBase-menuItems",menuItems)
  for (let i = 0; i < menuItems.nested[menu_id-1].nested.length; i++) {
    sub_menu_title = menuItems.nested[menu_id-1].nested[i].title;
    if(menuItems.nested[menu_id-1].nested[i].enabled) {
      let submenu_item_tmp = {title:'A',path:'#',id:0};
      submenu_item_tmp.title = menuItems.nested[menu_id-1].nested[i].title;
      submenu_item_tmp.path = menuItems.nested[menu_id-1].nested[i].path;
      submenu_item_tmp.id = menuItems.nested[menu_id-1].nested[i].id;
      if(submenu_item_tmp.id===sub_menu_id) {
        submenuindex_tmp = submenu_list_tmp.length;
      }
      submenu_list_tmp.push(submenu_item_tmp);
    }
    for (let j = 0; j < menuItems.nested[menu_id-1].nested[i].nested.length; j++) {
      if(menuItems.nested[menu_id-1].nested[i].nested[j].enabled) {
        console.log('sub_menu_title i' + i.toString(),menuItems.nested[menu_id-1].nested[i].title);
        let submenu_item_tmp2 = {title:'A',path:'#',id:0};
        submenu_item_tmp2.title = sub_menu_title + '-' + menuItems.nested[menu_id-1].nested[i].nested[j].title;
        submenu_item_tmp2.path = menuItems.nested[menu_id-1].nested[i].nested[j].path;
        submenu_item_tmp2.id = menuItems.nested[menu_id-1].nested[i].nested[j].id;
        if(submenu_item_tmp2.id===sub_menu_id) {
          submenuindex_tmp = submenu_list_tmp.length;
        }
        submenu_list_tmp.push(submenu_item_tmp2);
        console.log('item_title',menuItems.nested[menu_id-1].nested[i].nested[j].title);
      }
    }
  }
  return submenu_list_tmp;
}

const InternalPageBase = (props)=> {
  const [modalTitle, setModalTitle] = useState("Warning Message");
  const [showModal, setShowModal] = useState(false);
  const [showModalOk, setShowModalOk] = useState(false);
  const {getRemainingTime} = useContext(IdleTimeContext);
  const {getElapsedTime} = useContext(IdleTimeContext);
  const {isIdle} = useContext(IdleTimeContext);
  const scrollSubmenuStyle = { width: "1600px", maxHeight: "60px" };  
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  let submenu_list_tmp = [];
  let submenuindex_tmp = 0;
  console.log("InternalPageBase 1 = getMenuList(props.menu_id, props.sub_menu_id)");
  try {
    submenu_list_tmp = getMenuList(props.menu_id, props.sub_menu_id);
    console.log("InternalPageBase 2 = submenu_list_tmp",submenu_list_tmp);
  } catch (e) {
    console.log("InternalPageBase submenu_list_tmp = getMenuList(props.menu_id, props.sub_menu_id)");
    navigate("/sign-in");
  }
  console.log("InternalPageBase props.menu_id",props.menu_id);
  console.log("InternalPageBase props.submenu_list_tmp",submenu_list_tmp);
  const [submenu_list, setSubmenu_list] = useState(submenu_list_tmp);
  const [head_menu_id, setHead_menu_id] = useState(props.menu_id);
  const [currentId, setCurrentId] = useState(props.sub_menu_id);
  const [submenuindex, setSubmenuindex] = useState(submenuindex_tmp);
  const [scrollBarOn, setScrollBarOn] = useState(false);

//    const [menuItemId, setMenuItemId] = useState(props.sub_menu_id.toString());
  const getMenuId=(a_menu_id)=> {
//        alert('InternalPageBase trigger from Tree Menu:' + a_menu_id.toString());
        setHead_menu_id(Math.floor(a_menu_id/10000));
        setCurrentId(a_menu_id);
        submenu_list_tmp = getMenuList(Math.floor(a_menu_id/10000), a_menu_id);
        console.log("InternalPageBase trigger from Tree Menu: getMenuId()",a_menu_id);
        console.log("InternalPageBase trigger from Tree Menu: submenu_list_tmp",submenu_list_tmp);
        setSubmenu_list(submenu_list_tmp);
        console.log("InternalPageBase trigger from Tree Menu: submenu_list",submenu_list);
        let the_index = 0;
        for (let i = 0; i <submenu_list_tmp.length;i++) {
          if(submenu_list_tmp[i].id===a_menu_id) {
            the_index = i;
            setSubmenuindex(i);
            break;
          }
        }
        console.log("InternalPageBase trigger from Tree Menu: the_index-",the_index.toString());
        setSubmenuindex(the_index);
        console.log("InternalPageBase trigger from Tree Menu: submenu_list_tmp[the_index]",submenu_list_tmp[the_index]);
        try {
          console.log("InternalPageBase submenu_list_tmp[the_index].path",submenu_list_tmp[the_index].path)
          console.log("InternalPageBase getToken()",getToken())
          console.log("InternalPageBase getMenuData()",getMenuData())
          navigate(submenu_list_tmp[the_index].path,{state:{token:getToken(),menuData:getMenuData()}});
        } catch (e) {
          console.log("InternalPageBase navigate(submenu_list_tmp[the_index].path,e",submenu_list_tmp[the_index].path,e);
          navigate("/sign-in");
        }
        
    } 
    const getHeaderMenuId=(a_menu_id)=> {
    //  alert('InternalPageBase from HeaderMenuID:' + a_menu_id.toString());
      let header_menu_id=Math.floor(a_menu_id/10000);
      submenu_list_tmp = getMenuList(header_menu_id, a_menu_id);
      setSubmenu_list(submenu_list_tmp);
      let the_index = 0;
      for (let i = 0; i <submenu_list_tmp.length;i++) {
        if(submenu_list_tmp[i].id===a_menu_id) {
          the_index = i;
          setSubmenuindex(i);
          break;
        }
      }
      setCurrentId(a_menu_id);
      setTitle(submenu_list_tmp[the_index].title);
    }   
  
  useEffect(() => {
    console.log("submenuindex", submenuindex);
  }, [submenuindex])

  useEffect(() => {
    //console.log('1');
        let menuDiv = document.getElementById("scrollSubmenu");
        let theMenuBarWidth = parseInt(scrollSubmenuStyle.width);
        var menuItem;
        var totalWidth = 0;
        setTimeout(function(){
          let the_index = 0;
          let totalWidth2 = 0;
          for (let i = 0; i <submenu_list.length;i++) {
            menuItem = document.getElementById("submenuItem" + (i+1).toString());
            if(menuItem!=null) {
              totalWidth = totalWidth + parseInt(menuItem.clientWidth);
              totalWidth2 = totalWidth2 + parseInt(menuItem.clientWidth);
            }
            if(currentId===submenu_list[i].id) {
              if(menuItem!=null) {
                if(menuDiv.scrollLeft > menuItem.offsetLeft) {
                  menuDiv.scrollLeft = menuItem.offsetLeft - parseInt(menuItem.clientWidth) ;
                } else {
                  let diff = totalWidth - theMenuBarWidth;
                  if(diff>0)
                    menuDiv.scrollLeft = diff;
                }
              } else {
                let diff = totalWidth - theMenuBarWidth;
                if(diff>0)
                  menuDiv.scrollLeft = diff;
              }
            }
          }
          if(totalWidth2>theMenuBarWidth)
            setScrollBarOn(true);
        }, 100);
        setInterval(() => {
          let remaining = getRemainingTime()
          let elapsed = getElapsedTime()
          let this_isIdle = isIdle()
          if(this_isIdle&&remaining<=0) {
              setModalTitle("Idle Timeout");
              setShowModal(true);
              navigate("/sign-in");
              let url = window.location.href
              window.location.href = url + '?#'
          }
          console.log("remaining=" + remaining.toString())
          console.log("elapsed=" + elapsed.toString())
        }, 4000)
  },[]);

    const leftPanePortion = 20; 
    const handleClick = (a_submenu_index) => { 
      let clickedId = submenu_list[a_submenu_index].id;
      let isValidatedReturned = ValidatePage(clickedId);
      let isValidated = Promise.resolve(isValidatedReturned);
      isValidated.then(function(value) {
          if(value===1) { //
            submenuindex_tmp = a_submenu_index;
            //      alert(a_submenu_index.toString())
            setSubmenuindex(a_submenu_index);
            setTitle(submenu_list[a_submenu_index].title);
            setCurrentId(submenu_list[a_submenu_index].id);
            try {
              console.log("InternalPageBase submenu_list[a_submenu_index].path",submenu_list[a_submenu_index].path)
              console.log("InternalPageBase getToken()",getToken())
              console.log("InternalPageBase getMenuData()",getMenuData())
              navigate(submenu_list[a_submenu_index].path,{state:{token:getToken(),menuData:getMenuData()}});
            } catch (e) {
              console.log("InternalPageBase navigate(submenu_list[a_submenu_index].path,{state:{token:getToken(),menuData:getMenuData()}});e",e);
              navigate("/sign-in");
            }
          } else {
  //          alert("Not validated!")
              setModalTitle("No Access To The Screen");
              setShowModalOk(true);
          }
      });
  
      return;
    }

    const { data_component, paneHeight } = props;
     return (
        <>
          <InternalPageHeader />
          <div class="row">
              <div class="col-12 d-flex align-items-stretch">
                <QuoteContext.Provider value={{leftPanePortion, paneHeight}}>
                  <SplitPane className="split-pane-row">
                      <SplitPaneLeft>
                        <TreemenuContext.Provider value={{currentId, setCurrentId}}>
                        <div id="InternalPageTreeMenu-container" style={{overflowY:"auto",scrollbarWidth:"thin"}}>
                            <InternalPageTreeMenu currentId={currentId} getMenuId = {(id) => { getMenuId(id)}}/>
                        </div>
                        </TreemenuContext.Provider>
                      </SplitPaneLeft>
                      <Divider className="separator-col" />
                      <SplitPaneRight paneHeight={{paneHeight}}>
                        <div style={scrollSubmenuStyle}>
                          <div class="row">
                            <div id="scrollSubmenu" class="scrollsubmenu">
                            {submenu_list.map((item, i)=>{
                                  return (
                                    <a id={"submenuItem" + (i+1).toString()} href="#" onClick={() => handleClick(i)}  style={item.id===currentId?{fontWeight:"bold"}:{fontWeight:"normal"}}>
                                      {item.title}
                                    </a>
                                  )
                                }
                            )}
                            </div>
                          </div>
                          {scrollBarOn===false &&
                            <hr/>
                          }
                          <div class="row">
                            <div class="col-12 d-flex align-items-stretch">
                              <div class="content">
                                {data_component}
                              </div>
                            </div>
                          </div>
                          </div>
                      </SplitPaneRight>
                    </SplitPane>
                </QuoteContext.Provider>
                </div>
            </div>
            <div class="row">
            </div>
            {showModal ? <ResponseWindow title={modalTitle} setShowModal={setShowModal} /> : null}
            {showModalOk ? <ResponseWindowOk title={modalTitle} setShowModal={setShowModalOk} /> : null}

            <InternalPageFooter />
        </>
    )
}




/*
                        <TreemenuContext.Provider value={{currentId, setCurrentId}}>
                          <div style={{width:300,height:700,overflowY:"hidden", overflowX:"hidden",scrollbarWidth:"thin"}}>
                            <InternalPageTreeMenu currentId={currentId} getMenuId = {(id) => { getMenuId(id)}}/>
                          </div>
                        </TreemenuContext.Provider>

                                      <scan style={i===submenuindex?{fontWeight:"bold"}:{fontWeight:"normal"}}>{item.title}</scan> 

tabheaderDiv.style.backgroundColor = selectedBackgroundColor;

function Form() {
  const textInput = useRef();
  const focusTextInput = () => textInput.current.focus();

  return (
    <input type="text" ref={textInput} />
    <button onClick={focusTextInput}>Focus the text input</button>
  );
}
          <InternalPageHeader header_menu_list={header_menu_list} currentId={currentId} getMenuId={(id) => { getHeaderMenuId(id)}}/>
          <div class="row">
              <div class="col-12 d-flex align-items-stretch">
                <QuoteContext.Provider value={{leftPanePortion}}>
                  <SplitPane className="split-pane-row">
                      <SplitPaneLeft>
                        <TreemenuContext.Provider value={{currentId, setCurrentId}}>
                          <div style={{height:700,overflowY:"auto", overflowX:"hidden",scrollbarWidth:"thin"}}>
                            <InternalPageTreeMenu currentId={currentId} getMenuId = {(id) => { getMenuId(id)}}/>
                          </div>
                        </TreemenuContext.Provider>
                      </SplitPaneLeft>
                      <Divider className="separator-col" />
                      <SplitPaneRight paneHeight={{paneHeight}}>
                        <div style={scrollSubmenuStyle}>
                          <div class="row">
                            <div id="scrollSubmenu" class="scrollsubmenu">
                            {submenu_list.map((item, i)=>{
                                  return (
                                    <a id={"submenuItem" + (i+1).toString()} href="#" onClick={() => handleClick(i)} 
                                      style={{fontWeight:i===submenuindex?"bold":"normal"}}>{item.title}</a>
                                  )
                                }
                            )}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 d-flex align-items-stretch">
                              <div class="content">
                                {data_component}
                              </div>
                            </div>
                          </div>
                          </div>
                      </SplitPaneRight>
                    </SplitPane>
                </QuoteContext.Provider>
                </div>
            </div>
            <div class="row">
            </div>
            {showModal ? <ResponseWindow title={modalTitle} setShowModal={setShowModal} /> : null}
            <InternalPageFooter />

*/


export default InternalPageBase;

/*
                       <div style={{width:100,height:700,backgroundColor:"#FFFFFF"}}>
                        </div>
*/

