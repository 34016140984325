import React, {Component, useState, useEffect, useContext} from 'react';
import {ResponseWindow, ResponseWindowOk,ResponseWindowYesNo} from '../../../Modals';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//import zeditbox1 from 'zeditbox1';
import ZDropdownList from '../../../zcomponents/ZDropdownList';
import ZEditText from '../../../zcomponents/ZEditText';
import ZTextButton from '../../../zcomponents/ZTextButton';
import ZCheckbox from '../../../zcomponents/ZCheckbox';
import ZDatePicker from '../../../zcomponents/ZDatePicker';
import ZAutoCompleteEditText from '../../../zcomponents/ZAutoCompleteEditText';
import {zoftApiForUsp,retrieveApiData} from '../../../data_retrieval';

import EditInvoiceStatusListDataGrid from './editinvoicestatuslistdatagrid';
import EditInvoiceStatusDetailDataGrid from './editinvoicestatusdetaildatagrid';//set @input='[{"request_row_count":1},{"offset_row":0},{"request_rows":99999},{"invoice_id":0},{"invoice_status_id":0},{"billgroup":""},{"category_id":0},{"institution":""},{"dateStart":"2021-01-01"},{"dateEnd":"2022-10-31"},{"po":""},{"recalculate_totaldue":0},{"q_maxonly":0},{"exclude_paid":0},{"exclude_cancelled":0},{"exclude_nih":0}]';
import EditInvoiceStatusOrderListDataGrid from './editinvoicestatusorderlistdatagrid';
import EditInvoiceStatusOrderItemListDataGrid from './editinvoicestatusorderitemlistdatagrid';

const EditInvoiceStatusDataPage  = ()=> {
    const type_string = 1;
    const type_number = 0;
    const [modalTitle, setModalTitle] = useState("Warning Message");
    const [showModal, setShowModal] = useState(false);
    const [showModalOk, setShowModalOk] = useState(false);
    const [showModalYesNo, setShowModalYesNo] = useState(false);
    const [response, setResponse] = useState(-1);
    const [update_invoicestatusid, setUpdate_invoicestatusid] = useState(0);
    const [invoice_status_list, setInvoice_status_list] = useState([]);
    const [billgroup_list, setBillgroup_list] = useState([]);
    const [category_list, setCategory_list] = useState([]);
    const [institution_list, setInstitution_list] = useState([]);
//    const [action_list, setAction_list] = useState([]);
    const [total_invoices_retrieved, setTotal_invoices_retrieved] = useState(0);
    const [search_clicked, setSearch_clicked] = useState(false);
    const [prev_clicked, setPrev_clicked] = useState(false);
    const [next_clicked, setNext_clicked] = useState(false);
    const [add_order_clicked, setAdd_order_clicked] = useState(false);
//    const [retrieve_clicked, setRetrieve_clicked] = useState(false);
    const [new_record_in_detail, setNew_record_in_detail] = useState(false);
    const [update_mode,setUpdate_mode] = useState(0);
    const [new_record_inserted_or_updated,setNew_record_inserted_or_updated] = useState(false);
    const [deleting, setDeleting] = useState({"request_deleting":false,"deleted":false,"post_processed":false,"i":-1});
    const [dataCount, setDataCount] = useState(0);
    const [current_order_id, setCurrent_order_id] = useState(0);
    const [insert_order_list,setInsert_order_list] = useState([]);
    const [delete_order_list,setDelete_order_list] = useState([]);
    const [dd_access, setDd_access] = useState({});

    const [current_invoice_id_from_detail, setCurrent_invoice_id_from_detail] = useState(0);
    const [current_invoice_id_from_list, setCurrent_invoice_id_from_list] = useState(0);
    const [current_invoice_id, setCurrent_invoice_id] = useState(0);

    const [invoice_retrieved, setInvoice_retrieved] = useState({
            "invoice_from_row":0,
            "invoice_to_row":0,
            "total_invoice_rows":0,
        }
    );
    const [current_data_from_list,setCurrent_data_from_list] = useState({
        i:0,
        data:
        {
        "checked": 0, "inv_name":"","inv_num":0,"cat":0, "institute":0,"billgroup":0,"total_due":0,"quote_id":0,"prepaid":0,"invoice_date":"","past_due":0,
        "status_id":0,"po":"","ref":"","user_name":"","status_date":"","pdf_inv":0,"xls_inv":0,"notes":"","detail":0,"ar_report":0
        }
    });


    const [current_data_from_detail,setCurrent_data_from_detail] = useState({
        new_record_inserted_or_updated:false,
        i:-1,
        data:
        {
            "checked": 0, "inv_name":"","inv_num":0,"cat":0, "institute":0,"billgroup":0,"total_due":0,"quote_id":0,"prepaid":0,"invoice_date":"","past_due":0,
            "status_id":0,"po":"","ref":"","user_name":"","status_date":"","pdf_inv":0,"xls_inv":0,"notes":"","detail":0,"ar_report":0}
        }
    );

    let input_parm0 = {
        "request_row_count":1,
        "offset_row":0,
        "request_rows":1000,
        "invoice_id":0,
        "invoice_status_id":0,
        "billgroup":"",
        "category_id":0,
        "institution":"",
        "start_date":"",
        "end_date":"",
        "po":"",
        "recalculate_totaldue":0,
        "q_maxonly":0,
        "exclude_paid":0,
        "exclude_cancelled":0,
        "exclude_nih":0,
        "invoice_update_list":0,
    }
    const [input_parm, setInput_parm] = useState(input_parm0);
    const getDataCount=(count)=>{
        setDataCount(count);
    }
    // when click Prev button, set invoice_retrieved dataset backward
    const setInvoice_prev_list=(rows)=>{
        if(rows>0) {
            let new_from_row = invoice_retrieved.invoice_from_row - rows
            if(new_from_row<1)
                new_from_row = 1
            let new_to_row = new_from_row + rows - 1
            let invoice_retrieved_tmp = invoice_retrieved
            invoice_retrieved_tmp.invoice_from_row = new_from_row
            invoice_retrieved_tmp.invoice_to_row = new_to_row
            setInvoice_retrieved({...invoice_retrieved_tmp})
//            setInvoice_retrieved({"invoice_from_row":new_from_row,"invoice_to_row":new_to_row,"total_invoice_rows":invoice_retrieved.total_invoice_rows})
//            setInvoice_retrieved({"invoice_from_row":new_from_row,"invoice_to_row":new_to_row,"total_invoice_rows":invoice_retrieved.total_invoice_rows})
            console.log("EditInvoiceStatusDataPage setInvoice_prev_list rows, invoice_retrieved_tmp",rows, invoice_retrieved_tmp)
        }
    }
    // when click Next button, set invoice_retrieved dataset forward
    const setInvoice_next_list=(rows)=>{
        if(rows>0) {
            let new_from_row = invoice_retrieved.invoice_to_row + 1
            let new_to_row = new_from_row + rows - 1
            let invoice_retrieved_tmp = invoice_retrieved
            invoice_retrieved_tmp.invoice_from_row = new_from_row
            invoice_retrieved_tmp.invoice_to_row = new_to_row
            setInvoice_retrieved({...invoice_retrieved_tmp})
//            setInvoice_retrieved({"invoice_from_row":new_from_row,"invoice_to_row":new_to_row,"total_invoice_rows":invoice_retrieved.total_invoice_rows})
            console.log("EditInvoiceStatusDataPage-setInvoice_next_list rows invoice_retrieved_tmp",rows,invoice_retrieved_tmp)
        }
    }
    // when click Search button, the retrieval will start from row 1
    const setInvoice_search_list=(rows,total_rows)=>{
        if(rows>0) {
            let new_from_row = 1
            let new_to_row = rows
            let invoice_retrieved_tmp = invoice_retrieved
            invoice_retrieved_tmp.invoice_from_row = new_from_row
            invoice_retrieved_tmp.invoice_to_row = new_to_row
            invoice_retrieved_tmp.total_invoice_rows =total_rows
            setInvoice_retrieved({...invoice_retrieved_tmp})
//            setInvoice_retrieved({"invoice_from_row":new_from_row,"invoice_to_row":new_to_row,"total_invoice_rows":total_rows})
            console.log("EditInvoiceStatusDataPage-setInvoice_search_list rows,total_rows",invoice_retrieved_tmp)
        }
    }

    const onChangeTextHandler =(text,field_name,parm_type) => {
        console.log("EditInvoiceStatusDataPage-onChangeTextHandler 1,field_name,text",field_name, text)
        let input_parm_tmp = input_parm;
        if(parm_type===type_number)
            input_parm_tmp[field_name] = parseInt(text);
        else
            input_parm_tmp[field_name] = text;
        if(field_name==="billgroup") {
//            setBillgroup(text)
            console.log("EditInvoiceStatusDataPage-onChangeTextHandler,text",text)
            let input_data = '{"id":16,"search":"' + text + '"}'
            let tmp_data = retrieveApiData(1,30101,0,"usp_react_autocomplete_retrieve", input_data);
            Promise.resolve(tmp_data).then((value)=>{
                try {
                    console.log("EditInvoiceStatusDataPage-onChangeTextHandler,value",value)
                    setBillgroup_list(JSON.parse(value));
                } catch (e) {
                    console.log('Not JSON');
                }
            })
		} else if(field_name==="institution") {
//            setInstitution(text)
            let input_data = '{"id":15,"search":"' + text + '"}'
            console.log("EditInvoiceStatusDataPage-onChangeTextHandler,text",text)
            let tmp_data = retrieveApiData(1,30101,0,"usp_react_autocomplete_retrieve", input_data);
            Promise.resolve(tmp_data).then((value)=>{
                try {
                    console.log("EditInvoiceStatusDataPage-onChangeTextHandler,value",value)
                    setInstitution_list(JSON.parse(value))
                } catch (e) {
                    console.log('Not JSON');
                }
            })
		} else if(field_name==="update_invoicestatusid") {
            setUpdate_invoicestatusid(input_parm_tmp[field_name]);
        }
        setInput_parm({...input_parm_tmp});
        console.log("EditInvoiceStatusDataPage-onChangeTextHandler,field_name,text",field_name, text)
	}
    const onClickCheckbox =(field_name)=> {
        let input_parm_tmp = input_parm;
        let the_value = input_parm[field_name];
        the_value = the_value===0?1:0;
        input_parm_tmp[field_name] = the_value;
        setInput_parm({...input_parm_tmp});
    }

    const onButtonClick = (button_name)=> {
        if(button_name==="Clear Form") {
            setInput_parm({...input_parm0});
        } else if(button_name==="Search Invoices") {
            if(input_parm===input_parm0) {
                setModalTitle("No Search Parameters Specified!");
                setShowModal(true);
            } else if(search_clicked===true) {
                setModalTitle("Wait! Data are being retrieved!");
                setShowModal(true);
            } else {
                console.log("EditInvoiceStatusDataPage-onChangeTextHandle - Search_clicked input_parm",input_parm)
                setSearch_clicked(true);
            }
        } else if(button_name==="Prev") {
            if(invoice_retrieved.total_invoice_rows<=1000||invoice_retrieved.invoice_from_row<1000) { 
                // ignore it
            } else {
                setPrev_clicked(true)
            }
        } else if(button_name==="Next") {
            if(invoice_retrieved.total_invoice_rows<=1000||invoice_retrieved.invoice_to_row===invoice_retrieved.total_invoice_rows) { 
                // ignore it
            } else {
                setNext_clicked(true)
            }
        } else if(button_name==="Download Invoices") {
            
        } else if(button_name==="Email Invoices") {
            
        }
    }
    const handleStartDateChange =(date)=> {
        let input_parm_tmp = input_parm;
        input_parm_tmp.dateStart = date;
//        let start_date = tmp_date.toString();
        input_parm_tmp.start_date = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate()
        input_parm_tmp.start_date = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate()
        console.log("EditInvoiceStatusDataPage-handleEndDateChange,input_parm_tmp.start_date",input_parm_tmp.start_date);
        setInput_parm({...input_parm_tmp});
    }
   
    const handleEndDateChange =(date)=> { 
        let input_parm_tmp = input_parm;
        input_parm_tmp.dateEnd = date;
        input_parm_tmp.end_date = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate()
        console.log("EditInvoiceStatusDataPage-handleEndDateChange,input_parm_tmp.end_date",input_parm_tmp.end_date);
        setInput_parm({...input_parm_tmp});
    }

	useEffect(() => {
        let input_data1 = '[{"request_row_count":0},{"offset_row":0},{"request_rows":9999},{"type":"Billing"}]'
        let tmp_data1 = retrieveApiData(1,30101,33,"usp_react_sql_retrieve2", input_data1);
        Promise.resolve(tmp_data1).then((value)=>{
            try {
                console.log("EditInvoiceStatusDataPage-useEffect[],tmp_data1-value",value)
                setInvoice_status_list(JSON.parse(value))
            } catch (e) {
                console.log('Not JSON');
            }
        })
        let input_data2 = '[{"request_row_count":0},{"offset_row":0},{"request_rows":9999}]'
        let tmp_data2 = retrieveApiData(1,30101,34,"usp_react_sql_retrieve2", input_data2);
        Promise.resolve(tmp_data2).then((value)=>{
            try {
//                console.log("EditInvoiceStatusDataPage-useEffect[],value",value)
                setCategory_list(JSON.parse(value))
            } catch (e) {
                console.log('Not JSON');
            }
        })
        /*
        let input_data3 = '[{"request_row_count":0},{"offset_row":0},{"request_rows":9999},{"gui_id":30101}]'
        let tmp_data3 = retrieveApiData(1,30101,57,"usp_react_sql_retrieve2", input_data3);
        Promise.resolve(tmp_data3).then((value)=>{
            try {
                console.log("EditInvoiceStatusDataPage-useEffect[],tmp_data3-value",value)
                let return_data = JSON.parse(value)
                let tmp_data = {}
                for(let i = 0; i < return_data.length;i++)
                    tmp_data[return_data[i].field_id] = return_data[i].access_mode
                setDd_access(tmp_data)
            } catch (e) {
                console.log('Not JSON');
            }
        })
        */
        let app_id = 1
        let gui_id = 30101
        let the_input = ""
        let tmp_data = zoftApiForUsp(app_id,gui_id,0,0,0,"usp_react_get_field_access_list", JSON.stringify(the_input));
        Promise.resolve(tmp_data).then((value)=>{
            console.log('EditInvoiceStatusDataPage-useEffect value', value);
            if(value.return_status===0) { //normal return
                if(value.return_data.success) {
                    let jdata = JSON.parse(value.return_data.output)
                    console.log('EditInvoiceStatusDataPage-useEffect jdata', jdata);
                    let dd_tmp = {}
                    for(let i = 0; i < jdata.length;i++)
                        dd_tmp[jdata[i].field_id.toString()] = jdata[i].access_mode
                    console.log('EditInvoiceStatusDataPage-useEffect dd_tmp', dd_tmp);
                    setDd_access(dd_tmp)
                    console.log('EditInvoiceStatusDataPage-useEffect dd_access', dd_access);
                } 
            }
        }) 
    },[]);

    useEffect(() => {
        console.log("useEffect setInvoice_retrieved",invoice_retrieved)
    },[setInvoice_retrieved]);
    useEffect(() => {
        console.log("useEffect EditInvoiceStatusDataPage-dd_access",dd_access)
    },[dd_access]);
    /*
	useEffect(() => {
        if(response===1) {
            setResponse(-1)
            if(deleting.request_deleting===true) {
                setDeleting({"request_deleting":false,"deleted":deleting.deleted,"post_processed":deleting.post_processed,i:deleting.i})
                if(current_data_from_list.i>-1) {
                    let the_input = [{"id":current_data_from_list.data.id}];
                    let api_return_data = zoftApiForUsp(1,30102,39,0,"usp_react_sql_delete2", JSON.stringify(the_input));
                    Promise.resolve(api_return_data).then((value)=>{
                        console.log('BillGroupManageDataPage-useEffect(() [response], value', value);
                        if(value.return_status===0) { //normal return
                            console.log('BillGroupManageDetailDataGrid-useEffect value.return_data', value.return_data);
                            if(value.return_data.success) {
                                // successfully delete a record, delete the current row from the list grid, and re-select i-1 row as current row
                                console.log('BillGroupManageDetailDataGrid-useEffect value.return_data.output.data',value.return_data.output);
    //                            props.getCurrentDataFromDetail({new_record_inserted_or_updated:true,i:props.current_data_from_list.i, data:detail_data.data});
                                setDeleting({"request_deleting":false,"deleted":true,"post_processed":deleting.post_processed,i:deleting.i})
                            } else {
                                setModalTitle("Delete Error:" + value.return_data.message + "!");
                                setShowModalOk(true);
                            };
                        } else {
                            setModalTitle("Unspecified error, please report it to tech support!");
                            setShowModalOk(true);
                            console.log('BillGroupManageDetailDataGrid-onBlurTextHandler value.return_data', value.return_data);
                        }
                    }) 
                }
            }
        }
    },[response]);
    */
    // getCurrentInvoiceIDFromList  
    return  (
        <div style={{width:1500,height:1050,backgroundColor:"#ffffff"}}>
            <ZEditText id="03010101"
                x={20} y={0} width={100} height={70} whole_background_color="#ffffff"
                label="Invoice ID" label_x={0} label_y={0} label_width={100} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                button_x={0} button_y={20} button_width={100} button_height={24} button_color="#000000" button_background_color="ffffff"
                value={input_parm.invoice_id}
                onchange_func = {(text) => { onChangeTextHandler(text,"invoice_id",type_number)}}
                onBlur= {() => {}}
            />
            <ZDropdownList id="03010102"
                x={130} y={0} width={150} height={70} whole_background_color="#ffffff"
                label="Invoice Status" label_x={0} label_y={0} label_width={150} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                button_x={0} button_y={20} button_width={150} button_height={24} button_color="#000000" button_background_color="ffffff"
                value={input_parm.invoice_status_id}
                onchange_func = {(text) => { onChangeTextHandler(text,"invoice_status_id",type_number)}}
                onBlur= {() => {}}
                dataList={invoice_status_list}
            />
            <ZAutoCompleteEditText id="03010103"
                x={290} y={0} width={150} height={70} whole_background_color="#ffffff"
                label="Bill Group" label_x={0} label_y={0} label_color="#000000" label_background_color="#ffffff"
                button_x={0} button_y={20} button_width={150} button_height={24} button_color="#000000" button_background_color="ffffff"
                value={input_parm.billgroup}
                onchange_func = {(text) => { onChangeTextHandler(text,"billgroup",type_string)}}
                onBlur= {() => {}}
                dataList={billgroup_list}
            />
            <ZDropdownList id="03010104"
                x={450} y={0} width={150} height={70} whole_background_color="#ffffff"
                label="Category" label_x={0} label_y={0} label_color="#000000" label_background_color="#ffffff"
                button_x={0} button_y={20} button_width={150} button_height={24} button_color="#000000" button_background_color="ffffff"
                value={input_parm.category_id}
                onchange_func = {(text) => { onChangeTextHandler(text,"category_id",type_number)}}
                dataList={category_list}
                onBlur= {() => {}}
            />
            <ZAutoCompleteEditText id="03010105"
                x={610} y={0} width={150} height={70} whole_background_color="#ffffff"
                label="Institution" label_x={0} label_y={0} label_color="#000000" label_background_color="#ffffff"
                button_x={0} button_y={20} button_width={150} button_height={24} button_color="#000000" button_background_color="ffffff"
                value={input_parm.institution}
                onchange_func = {(text) => { onChangeTextHandler(text,"institution",type_string)}}
                onBlur= {() => {}}
                dataList={institution_list}
            />
            <div id="03010106" style={{position:"absolute",left:770,top:0,width:150,height:70,backgroundColor:"#ffffff"}}>
                <scan style={{position:"absolute",left:0,top:0}}>
                    Start Date
                </scan>
                <div  style={{position:"absolute",left:0,top:20,width:100,height:30}}>
                    <DatePicker
                            selected={input_parm.dateStart}
                            onChange={handleStartDateChange}
                            dateFormat="MM/dd/yyyy"
                    />
                </div>
            </div>
            <div id="03010107" style={{position:"absolute",left:955,top:0,width:100,height:70,backgroundColor:"#ffffff"}}>
                <scan style={{position:"absolute",left:0,top:0}}>
                    End Date
                </scan>
                <div style={{position:"absolute",left:0,top:20,width:100,height:30}}>
                    <DatePicker
                        selected={input_parm.dateEnd}
                        onChange={handleEndDateChange}
                        dateFormat="MM/dd/yyyy"
                    />
                </div>
            </div>
            <ZEditText id="03010108"
                x={1130} y={0} width={100} height={90} whole_background_color="#ffffff"
                label="PO" label_x={10} label_y={0} label_color="#000000" label_background_color="#ffffff"
                button_x={10} button_y={20} button_width={100} button_height={24} button_color="#000000" button_background_color="ffffff"
                value={input_parm.po}
                onchange_func = {(text) => { onChangeTextHandler(text,"po",type_string)}}
                onBlur= {() => {}}
            />
            <ZTextButton id="03010109"
                x={1250} y={20} width={125} height={30} whole_background_color="#ffffff" 
                border_style="1px solid #000000"
                button_text="Search Invoices" label_background_color="#ffffff"
                button_font_color="#000000" button_background_color="#ffffff"
                onButtonClick = {() => { onButtonClick("Search Invoices")}}
                onBlur= {() => {}}
            />

            <ZCheckbox id="03010110"
                x={20} y={50} width={220} height={50} whole_background_color="#ffffff"
                label="Recalculate Total Due?" label_x={10} label_y={0} label_width={150} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                button_x={0} button_y={0} button_width={18} button_height={18} button_color="#000000" button_background_color="ffffff"
                value={input_parm.recalculate_totaldue}
                onClickCheckbox = {() => { onClickCheckbox("recalculate_totaldue")}}
                font_size={14} font_weight="normal"
            />
            <ZCheckbox id="03010111"
                x={195} y={50} width={150} height={50} whole_background_color="#ffffff"
                label="Q-Max Only" label_x={10} label_y={0} label_width={150} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                button_x={0} button_y={0} button_width="18px" button_height="18px" button_color="#000000" button_background_color="ffffff"
                value={input_parm.q_maxonly}
                onClickCheckbox = {() => { onClickCheckbox("q_maxonly")}}
                font_size={14} font_weight="normal"
            />
            <ZCheckbox id="03010112"
                x={300} y={50} width={150} height={50} whole_background_color="#ffffff"
                label="Exclude paid" label_x={10} label_y={0} label_width={150} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                button_x={0} button_y={0} button_width="18px" button_height="18px" button_color="#000000" button_background_color="ffffff"
                value={input_parm.exclude_paid}
                onClickCheckbox = {() => { onClickCheckbox("exclude_paid")}}
                font_size={14} font_weight="normal"
            />
            <ZCheckbox id="03010113"
                x={420} y={50} width={150} height={50} whole_background_color="#ffffff"
                label="Exclude cancelled" label_x={10} label_y={0} label_width={150} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                value={input_parm.exclude_cancelled}
                button_x={0} button_y={0} button_width="18px" button_height="18px" button_color="#000000" button_background_color="ffffff"
                onClickCheckbox = {() => { onClickCheckbox("exclude_cancelled")}}
                font_size={14} font_weight="normal"
            />
            <ZCheckbox id="03010113"
                x={570} y={50} width={200} height={50} whole_background_color="#ffffff"
                label="Exclude NIH" label_x={10} label_y={0} label_width={150} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                button_x={0} button_y={0} button_width="18px" button_height="18px" button_color="#000000" button_background_color="ffffff"
                value={input_parm.exclude_nih}
                onClickCheckbox = {() => { onClickCheckbox("exclue_nih")}}
                font_size={14} font_weight="normal"
            />
            <ZCheckbox id="03010110"
                x={20} y={75} width={220} height={50} whole_background_color="#ffffff"
                label="Retrieve for Invoice Update" label_x={10} label_y={0} label_width={150} label_height={20}
                label_color="#000000" label_background_color="#ffffff"
                button_x={0} button_y={0} button_width={18} button_height={18} button_color="#000000" button_background_color="ffffff"
                value={input_parm.invoice_update_list}
                onClickCheckbox = {() => { onClickCheckbox("invoice_update_list")}}
                font_size={14} font_weight="heavy"
            />

            <div style={{position:"absolute",left:685,top:48,width:555,height:73, border:"1px solid black"}}>
                <scan style={{position:"absolute",left:15,top:2,width:550,height:15}} >Update Status/Actions in this group applied to all checked invoices</scan>
                <ZDropdownList id="03010114"
                    x={5} y={30} width={200} height={40} whole_background_color="#ffffff"
                    label_x={0} label_y={0} label_width={0} label_height={0}
                    button_x={10} button_y={2} button_width={200} button_height={25} button_color="#000000" button_background_color="ffffff"
                    disabled={dd_access["0"]===0?true:false}
                    onchange_func = {(text) => { onChangeTextHandler(text,"update_invoicestatusid",type_number)}}
                    value={input_parm.update_invoicestatusid}
                    dataList={invoice_status_list}
                    placeholder="Action"
                    onBlur= {() => {}}
                />
                <ZTextButton id="03010115"
                    x={230} y={30} width={150} height={30} whole_background_color="#ffffff" 
                    border_style="1px solid #000000"
                    disabled={dd_access["1"]===0?true:false}
                    button_text="Download Invoices" label_background_color="#ffffff"
                    button_font_color="#000000" button_background_color="#ffffff"
                    onButtonClick = {() => { onButtonClick("Download Invoices")}}
                />
                <ZTextButton id="03010116"
                    x={390} y={30} width={150} height={30} whole_background_color="#ffffff" 
                    border_style="1px solid #000000"
                    disabled={dd_access["2"]===0?true:false}
                    button_text="Email Invoices" label_background_color="#ffffff"
                    button_font_color="#000000" button_background_color="#ffffff"
                    onButtonClick = {() => { onButtonClick("Email Invoices")}}
                />
            </div>
            <ZTextButton id="03010117"
                    x={1250} y={60} width={125} height={30} whole_background_color="#ffffff" 
                    border_style="1px solid #000000"
                    button_text="Clear Form" label_background_color="#ffffff"
                    button_font_color="#000000" button_background_color="#ffffff"
                    onButtonClick = {() => { onButtonClick("Clear Form")}}
            />
            <div id="03010118" style={{position:"absolute",left:20,top:105,width:290,height:30, borderBottom:"0px solid black"}}>
                <scan style={{position:"absolute",left:5,top:2,width:290,height:15}} >
                    {invoice_retrieved.total_invoice_rows} total invoices    {invoice_retrieved.invoice_from_row} - {invoice_retrieved.invoice_to_row}
                </scan>
                <ZTextButton id="03010117"
                    x={250} y={2} width={60} height={30} whole_background_color="#ffffff" 
                    border_style="0px solid #000000"
                    button_text="Prev" label_background_color="#ffffff"
                    button_font_color="#000000" button_background_color="#ffffff"
                    onButtonClick = {() => { onButtonClick("Prev")}}
                />
                <ZTextButton id="03010117"
                    x={300} y={2} width={60} height={30} whole_background_color="#ffffff" 
                    border_style="0px solid #000000"
                    button_text="Next" label_background_color="#ffffff"
                    button_font_color="#000000" button_background_color="#ffffff"
                    onButtonClick = {() => { onButtonClick("Next")}}
                />
            </div>
            <div id="03010118" style={{position:"absolute",left:320,top:80,width:400,height:30, borderBottom:"0px solid black"}}>
                <a style={{position:"absolute",left:0,top:2,width:100,height:15}} href="/billing/dump_invoice/" download>bill statement ||</a>
                <a style={{position:"absolute",left:100,top:2,width:150,height:15}} href="/billing/account_balance/" download>dump account history ||</a>
                <a style={{position:"absolute",left:250,top:2,width:130,height:15}} href="/billing/quotation/" download>download quote</a>
            </div>
            <div style={{position:"absolute",left:20,top:130,width:1350,height:300}}>
                <EditInvoiceStatusListDataGrid
                    category_list={category_list}
                    billgroup_list={billgroup_list}
                    status_list={invoice_status_list}
                    institution_list={institution_list}
                    dd_access={dd_access}
                    current_invoice_id_from_detail={current_invoice_id_from_detail}
                    new_record_inserted_or_updated={new_record_inserted_or_updated}
                    invoice_retrieved={invoice_retrieved}
                    search_clicked={search_clicked}
                    prev_clicked={prev_clicked}
                    next_clicked={next_clicked}
                    input_parm={input_parm}
                    new_record_in_detail={new_record_in_detail}
                    update_mode={update_mode}
                    deleting={deleting}
                    update_invoicestatusid={input_parm.update_invoicestatusid}
                    invoice_status_list={invoice_status_list}
                    getInvoiceId={(id)=>{setCurrent_invoice_id_from_list(id)}}
                    getCurrentInvoiceIdFromList={(id)=>{console.log("EditInvoiceStatusDataPage id",id);setCurrent_invoice_id(id);setCurrent_invoice_id_from_list(id)}}
                    getDataCount={(count)=>{getDataCount(count)}}
                    getRetrievedSearch={(retrieved_rows,total_rows) =>{setSearch_clicked(false);setInvoice_search_list(retrieved_rows,total_rows);console.log("EditInvoiceStatusDataPage-retrieved, search_clicked",search_clicked)}}
                    getRetrievedPrev={(retrieved_rows) =>{setPrev_clicked(false);setInvoice_prev_list(retrieved_rows);}}
                    getRetrievedNext={(retrieved_rows) =>{setNext_clicked(false);setInvoice_next_list(retrieved_rows);}}
                    resetDeleting={()=>{setDeleting({"request_deleting":deleting.request_deleting,"deleted":deleting.deleted,"post_processed":true,"i":deleting.i})}}
                    getOrderId={(order_id)=>{setCurrent_order_id(order_id)}}
            />
            </div>
            <div style={{position:"absolute",left:20,top:430,width:1350,height:210}}>
                <EditInvoiceStatusDetailDataGrid
                    status_list={invoice_status_list}
                    institution_list={institution_list}
                    billgroup_list={billgroup_list}
                    category_list={category_list}
                    dd_access={dd_access}
//                    invoice_id={current_invoice_id_from_list}
                    current_invoice_id_from_list={current_invoice_id_from_list}
                    delete_order_list={delete_order_list}
                    resetDeleteOrderList={()=>setDelete_order_list([])}
                    resetCurrentInvoiceId={(a_invoice_id)=>{setCurrent_invoice_id(0);setCurrent_invoice_id(a_invoice_id)}}
                    getCurrentInvoiceIdFromDetail={(id)=>{setCurrent_invoice_id(id);setCurrent_invoice_id_from_detail(id)}}
                    getInsertOrderList={(a_order_list)=>{setInsert_order_list(a_order_list)}}
                    newRecordInserted={(a_inserting)=>{setNew_record_in_detail(a_inserting)}}
                    set_update_mode={(a_mode)=>{setUpdate_mode(a_mode)}}
                    toggleAdd_order_clicked={(a_mode)=>{setAdd_order_clicked(a_mode)}}
                    deleting={deleting}
                />
            </div>
            <div style={{position:"absolute",left:20,top:640,width:1350,height:200}}>
                <EditInvoiceStatusOrderListDataGrid
                    getDeleteOrderList={(a_order_list)=>{setDelete_order_list(a_order_list)}}
                    getOrderId={(order_id)=>{setCurrent_order_id(order_id);console.log("EditInvoiceStatusDataPage-retrieved, order_id",order_id)}}
                    invoice_id={current_invoice_id}
                    dd_access={dd_access}
                    />
            </div>
            <div style={{position:"absolute",left:20,top:840,width:1350,height:200}}>
                <EditInvoiceStatusOrderItemListDataGrid
                    invoice_id={current_invoice_id}
                    order_id={current_order_id}
                    dd_access={dd_access}
                />
            </div>
            {showModal ? <ResponseWindow title={modalTitle} setShowModal={setShowModal} /> : null}
            {showModalOk ? <ResponseWindowOk title={modalTitle} setShowModal={setShowModalOk} /> : null}
            {showModalYesNo ? <ResponseWindowYesNo title={modalTitle} setShowModal={setShowModalYesNo} setResponse={setResponse}/> : null}

        </div>

    );
}

export default EditInvoiceStatusDataPage;
