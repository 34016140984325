import React, {Component, useState, useEffect, useContext} from 'react';
import {ResponseWindowOk,ResponseWindowYesNo,ResponseWindowEdit} from '../../../Modals';
import EditInvoiceStatusOrderListDataGridForModal from './editinvoicestatusorderlistmodal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//import zeditbox1 from 'zeditbox1';
import ZDropdownList from '../../../zcomponents/ZDropdownList';
import ZEditText from '../../../zcomponents/ZEditText';
import ZEditNumber from '../../../zcomponents/ZEditNumber';
import ZTextButton from '../../../zcomponents/ZTextButton';
import ZCheckbox from '../../../zcomponents/ZCheckbox2';
import ZAutoCompleteEditText from '../../../zcomponents/ZAutoCompleteEditText';
import {retrieveApiData,retrieveData2,zoftApiForUsp,zoftApiForUspField} from '../../../data_retrieval';

//set @input='[{"request_row_count":1},{"offset_row":0},{"request_rows":99999},{"invoice_id":0},{"invoice_status_id":0},{"billgroup":""},{"category_id":0},{"institution":""},{"dateStart":"2021-01-01"},{"dateEnd":"2022-10-31"},{"po":""},{"recalculate_totaldue":0},{"q_maxonly":0},{"exclude_paid":0},{"exclude_cancelled":0},{"exclude_nih":0}]';
const EditInvoiceStatusDetailDataGrid  = (props)=> {
    const type_string = 1;
    const type_number = 0;
    const [modalTitle, SetModalTitle] = useState("Warning Message");

    const [showModalOrderList, setShowModalOrderList] = useState(false);
    const [showModalOk, SetShowModalOk] = useState(false);
    const [showModalEdit, SetShowModalEdit] = useState(false);
    const [showModalYesNo, SetShowModalYesNo] = useState(false);
    const [editData, SetEditData] = useState(false);

    const [response, setResponse] = useState([]);
    const [institution_list, setInstitution_list] = useState([]);
    const [billgroup_list, setBillgroup_list] = useState([]);
    const [update_mode, SetUpdate_mode] = useState(0);
    const [add_order_clicked, setAdd_order_clicked] = useState(false);

    let tmp_data0= {insert_or_update:false,i:-1,
        data:{"id": 0,
        "name":"",
        "billgroup_id":0,
        "cur_status_id":0,
        "total_due":0,
        "est_total_due":0,
        "time_created":"",
        "po":"",
        "credits":0,
        "verified":0
        ,"invoice_date":"",
        "tax":0,
        "shipping":0,
        "user_id":0,
        "notes":"",
        "category_id":0,
        "product_id":0,}
    }

    const [detail_data,setDetail_data] = useState({insert_or_update:false,i:-1,data:{...tmp_data0.data}});
    useEffect(() => {

    console.log("EditInvoiceDetailDataGrid-useEffect((),update_mode",update_mode)
    },[]);

	useEffect(() => {
        console.log("EditInvoiceDetailDataGrid-	props.dd_access",props.dd_access)
        if(update_mode===0&&detail_data.data.id!=props.current_invoice_id_from_list) {
            console.log("EditInvoiceDetailDataGrid-props.current_invoice_id_from_list",props.current_invoice_id_from_list)
            let input_parm0 = [
                {"request_row_count":1},
                {"offset_row":0},
                {"request_rows":9999},
                {"invoice_id":props.current_invoice_id_from_list},
            ]
            console.log('EditInvoiceStatusDetailDataGrid retrieve in useEffect 0');
            let sql_id = 53;
            let app_id = 1;
            let tmp_data = retrieveApiData(1,30101,sql_id,"usp_react_sql_retrieve2", JSON.stringify(input_parm0));
            let where_clause = input_parm0.slice(3,input_parm0.length);
            Promise.resolve(tmp_data).then((value)=>{
                console.log('EditInvoiceDetailDataGrid useEffect value',value);
                let total_row_count = parseInt(value);
                retrieveData2(1,30101,sql_id,0,total_row_count,where_clause)
                .then((retrieved_data) => {
                    console.log('EditInvoiceStatusOrderListDataGrid retrieveData retrieved_data',retrieved_data[0]);
                    setDetail_data({insert_or_update:false,i:props.current_invoice_id_from_list,data:{...retrieved_data[0]}});
                })
            });
        }
    },[props]);
    useEffect(() => {
        if(add_order_clicked===true&&response.length>0) {
            console.log("EditInvoiceDetailDataGrid-useEffect,add_order_clicked,response",add_order_clicked,response)
            setAdd_order_clicked(false)
            let input_parm_tmp = [];
            for(let i=0; i<response.length;i++) {
                input_parm_tmp.push({"id":response[i],"invoice_id":detail_data.data.id})
            }
            console.log("EditInvoiceDetailDataGrid-useEffect,input_parm_tmp",input_parm_tmp)
            if(input_parm_tmp.length>0) {
                let api_return_data = zoftApiForUsp(1,30102,53,0,0,"usp_react_sql_update_repeat", JSON.stringify(input_parm_tmp));
                Promise.resolve(api_return_data).then((value)=>{
                    console.log('EditInvoiceDetailDataGrid-useEffect 4, value', value);
                    if(value.return_status===0) { //normal return
                        if(value.return_data.success===true) {
                            console.log('EditInvoiceDetailDataGrid-useEffect Update success');
                            props.resetCurrentInvoiceId(detail_data.data.id)
                        } else {//
                            console.log('EditInvoiceDetailDataGrid-useEffect Update Fail');
                        }
                    } else {
                        console.log('EditInvoiceDetailDataGrid-useEffect Update Fail');
                    }
                });	
            }
        }
    },[response]);

    const onChangeTextHandler =(text,field_name,data_type) => {
        if(update_mode===1) {
            if(field_name==="billgroup") {
                console.log("EditInvoiceStatusDetailDataGrid-onChangeTextHandler,text",text)
                let input_data = '{"id":16,"search":"' + text + '"}'
                let tmp_data = retrieveApiData(1,30101,0,"usp_react_autocomplete_retrieve", input_data);
                Promise.resolve(tmp_data).then((value)=>{
                    try {
                        console.log("EditInvoiceStatusDetailDataGrid-onChangeTextHandler,value",value)
                        setBillgroup_list(JSON.parse(value));
                    } catch (e) {
                        console.log('Not JSON');
                    }
                })
            }
            let tmp_data = detail_data;
            if(data_type===type_string)
                tmp_data.data[field_name] = text;
            else
                tmp_data.data[field_name] = parseInt(text);
            setDetail_data({insert_or_update:tmp_data.insert_or_update,i:tmp_data.i,
               data:{...tmp_data.data},});
        }
        console.log("EditInvoiceStatusDataPage-onChangeTextHandler,field_name,text",field_name, text)
	}
    const onClickCheckbox =(field_name)=> {
        if(field_name==="update_mode") {
            props.set_update_mode(update_mode===0?1:0);
            SetUpdate_mode(update_mode===0?1:0);
        }
        if(update_mode===1) {
            let tmp_data = detail_data;
            let the_value = detail_data.data[field_name]===0?1:0;
            tmp_data.data[field_name] = the_value;
            setDetail_data({insert_or_update:tmp_data.insert_or_update,i:tmp_data.i,
                data:{...tmp_data.data},});
            console.log("EditInvoiceStatusDataPage-onClickCheckbox,detail_data,tmp_data",detail_data,tmp_data)
        }
    }

    const popuateData = (a_data)=> {
        setDetail_data({insert_or_update:detail_data.insert_or_update,i:detail_data.i,
            data:{...a_data},});
    }
    const insertNewRecord = ()=> {
        let app_id = 1
        let gui_id = 30101
        let the_input = [[],[]];
        console.log('EditInvoiceDetailDataGrid-onButtonClickNew the_input', the_input);
        
        let tmp_data = zoftApiForUsp(app_id,gui_id,53,1,3,"usp_react_sql_cmd_with_return", JSON.stringify(the_input));
        Promise.resolve(tmp_data).then((value)=>{
            console.log('EditInvoiceDetailDataGrid-onButtonClickNew value', value);
            if(value.return_status===0) { //normal return
                console.log('EditInvoiceDetailDataGrid-onButtonClickNew value.return_data', value.return_data);
                if(value.return_data.success) {
                    console.log('EditInvoiceDetailDataGrid-onButtonClickNew value.return_data.output.data',value.return_data.output);
                    let the_data = JSON.parse(value.return_data.output);
                    console.log('EditInvoiceDetailDataGrid-onButtonClickNew the_data',the_data);
                    console.log('EditInvoiceDetailDataGrid-onButtonClickNew the_data.data[0]',the_data.data[0]);
                    popuateData(the_data.data[0]);
                    props.getCurrentInvoiceIdFromDetail(the_data.data[0].id);
                } else {
                    SetModalTitle("Insert Error:" + value.return_data.message + ", Please enter new name!");
                    SetShowModalEdit(false);
                };
            } else {
                SetModalTitle("Unspecified error, please report it to tech support!");
                SetShowModalOk(true);
                console.log('EditInvoiceDetailDataGrid-insertNewRecord value.return_data', value.return_data);
            }
        }) 
   }
    const onButtonClickNew = ()=> {
        SetUpdate_mode(1)
        insertNewRecord()
    }

    const onButtonClickAddOrder = ()=> {
        console.log("EditInvoiceDetailDataGrid-onButtonClickAddOrder,detail_data",detail_data)
        if(detail_data.data.id===0) {
            SetModalTitle("No invoice selected,cannot add order!");
            SetShowModalOk(true);
        } else {
            setAdd_order_clicked(true);
            setShowModalOrderList(true);
            console.log("EditInvoiceDetailDataGrid-onButtonClickAddOrder,response",response)
        }

    }

    const onButtonClickRemoveOrder = ()=> {
        console.log("EditInvoiceDetailDataGrid-onButtonClickRemoveOrder,props.delete_order_list",props.delete_order_list)
        let input_parm_tmp = [];
        if(props.delete_order_list.length===0) {
            SetModalTitle("No order selected,cannot remove!");
            SetShowModalOk(true);
            return;
        }
        for(let i=0; i<props.delete_order_list.length;i++) {
            input_parm_tmp.push({"id":props.delete_order_list[i],"invoice_id":0})
        } 
        console.log("EditInvoiceDetailDataGrid-onButtonClickRemoveOrder,input_parm_tmp",input_parm_tmp)
        if(input_parm_tmp.length>0) {
            let api_return_data = zoftApiForUsp(1,30102,53,0,0,"usp_react_sql_update_repeat", JSON.stringify(input_parm_tmp));
            Promise.resolve(api_return_data).then((value)=>{
                console.log('EditInvoiceDetailDataGrid-onButtonClickRemoveOrder 4, value', value);
                if(value.return_status===0) { //normal return
                    if(value.return_data.success===true) {
                        console.log('EditInvoiceDetailDataGrid-onButtonClickRemoveOrder Update success');
                        props.resetDeleteOrderList();
                        props.resetCurrentInvoiceId(detail_data.data.id)
                    } else {//
                        console.log('EditInvoiceDetailDataGrid-onButtonClickRemoveOrder Update Fail');
                    }
                } else {
                    console.log('EditInvoiceDetailDataGrid-onButtonClickRemoveOrder Update Fail');
                }
            });
        }
    }

    const onBlurTextHandler = (field_name,field_id, data_type) => {
        let the_id = detail_data.data.id==null ? 0 : detail_data.data;
        let the_input_data = "";
        if(the_id===undefined)
            return;
        console.log('onBlurTextHandler field_name, data_type',field_name, data_type);
        if(update_mode===1) {
            if(data_type===type_string) { //string
                the_input_data = '{"' + field_name + '":"' + detail_data.data[field_name] + '","id":' + the_id.toString() + '}'
            } else {
                let tmp_data = detail_data.data[field_name]===undefined ? 0:detail_data.data[field_name];
                if(tmp_data===undefined||tmp_data===null) tmp_data = 0;
                the_input_data = '{"' + field_name + '":' + tmp_data.toString() + ',"id":' + the_id.toString() + '}'
            }
            console.log('EditInvoiceDetailDataGrid-onBlurTextHandler the_input_data', the_input_data);

            let api_return_data = zoftApiForUspField(1,30102,field_id, 31,0,"usp_react_sql_onchange_update", the_input_data);
            Promise.resolve(api_return_data).then((value)=>{
                console.log('EditInvoiceDetailDataGrid-onBlurTextHandler value', value);
                if(value.return_status===0) { //normal return
                    console.log('EditInvoiceDetailDataGrid-onBlurTextHandler value.return_data', value.return_data);
                    if(value.return_data.success) {
                        SetEditData(true);
                        console.log('EditInvoiceDetailDataGrid-onButtonClickNew value.return_data.output.data',value.return_data.output);
//                        props.getCurrentDataFromDetail({insert_or_update:true,i:props.current_data_from_list.i, data:detail_data.data});
                        props.getCurrentInvoiceIdFromDetail(the_id);
                    } else {
                        SetModalTitle("Update Error:" + value.return_data.message + "!");
                        SetShowModalEdit(false);
                    };
                } else {
                    SetModalTitle("Unspecified error, please report it to tech support!");
                    SetShowModalOk(true);
                    console.log('EditInvoiceDetailDataGrid-onBlurTextHandler value.return_data', value.return_data);
                }
            }) 
        }
    }
    return  (
        <div style={{width:1350,height:210,backgroundColor:"#ffffff",border:"1px solid black"}}>
            <ZEditNumber id="030102detail01"
                x={10} y={10} width={200} height={35} whole_background_color="#ffffff"
                label="Invoice Number" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={100} button_height={35} button_color="#000000" button_background_color="#ffffff"
                disabled={true}
                value={detail_data.data.id}
                onchange_func = {(text) => { onChangeTextHandler(text,"id",type_number)}}
                onBlur= {() => {}}
            />
            <ZEditText id="030102detail02"
                x={10} y={60} width={200} height={35} whole_background_color="#ffffff"
                label="Invoice Name" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={100} button_height={35} button_color="#000000" button_background_color="#ffffff"
                disabled={props.dd_access["8"]===undefined || props.dd_access["8"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.name}
                onchange_func = {(text) => { onChangeTextHandler(text,"name",type_string)}}
                onBlur= {() => {onBlurTextHandler("name", 8, type_string)}}
            />
            <ZAutoCompleteEditText id="030102detail03"
                x={10} y={110} width={200} height={35} whole_background_color="#ffffff"
                label="Bill Group" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={100} button_height={35} button_color="#000000" button_background_color="#ffffff"
                disabled={props.dd_access["9"]===undefined ||props.dd_access["9"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.billgroup_id}
                onchange_func = {(text) => { onChangeTextHandler(text,"billgroup_id",type_number)}}
                onBlur= {() => {onBlurTextHandler("billgroup_id", 9, type_number)}}
                dataList={billgroup_list}
            />
            <ZDropdownList id="030102detail04"
                x={10} y={160} width={200} height={35} whole_background_color="#ffffff"
                label="Status" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={100} button_height={35} button_color="#000000" button_background_color="#ffffff"
                disabled={props.dd_access["10"]===undefined ||props.dd_access["10"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.cur_status_id}
                onchange_func = {(text) => { onChangeTextHandler(text,"cur_status_id",type_number)}}
                onBlur= {() => {onBlurTextHandler("institution_id", 10, type_number)}}
                dataList={props.status_list}
            />
            <ZEditNumber id="030102detail05"
                x={230} y={10} width={200} height={35} whole_background_color="#ffffff"
                label="Total Due" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={90} button_height={35} button_color="#000000" button_background_color="#ffffff"
                disabled={props.dd_access["11"]===undefined ||props.dd_access["11"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.total_due}
                onchange_func = {(text) => { onChangeTextHandler(text,"total_due",type_number)}}
                onBlur= {() => {onBlurTextHandler("total_due", 11, type_number)}}
            />
            <ZEditText id="030102detail07"
                x={230} y={60} width={200} height={35} whole_background_color="#ffffff"
                label="Due Date" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={90} button_height={35} button_color="#000000" button_background_color="#ffffff"
                disabled={props.dd_access["12"]===undefined ||props.dd_access["12"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.due_date}
                onchange_func = {(text) => { onChangeTextHandler(text,"due_date",type_string)}}
                onBlur= {() => {onBlurTextHandler("due_date", 12, type_string)}}
            />
            <ZEditNumber id="030102detail08"
                x={230} y={110} width={200} height={35} whole_background_color="#ffffff"
                label="Estimated Total Due" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={90} button_height={35} button_color="#000000" button_background_color="#ffffff"
                disabled={props.dd_access["13"]===undefined ||props.dd_access["13"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.est_total_due}
                onchange_func = {(text) => { onChangeTextHandler(text,"est_total_due",type_number)}}
                onBlur= {() => {onBlurTextHandler("est_total_due", 13, type_number)}}
            />
            <ZEditText id="030102detail09"
                x={230} y={160} width={200} height={35} whole_background_color="#ffffff"
                label="Time Created" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={90} button_height={35} button_color="#000000" button_background_color="#ffffff"
                disabled={true}
                value={detail_data.data.time_created}
                onchange_func = {(text) => { onChangeTextHandler(text,"time_created",type_string)}}
                onBlur= {() => {}}
            />
            <ZEditText id="030102detail11"
                x={450} y={10} width={200} height={35} whole_background_color="#ffffff"
                label="PO" label_x={0} label_y={0} label_width={80} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={90} button_y={0} button_width={90} button_height={35} button_color="#000000" button_background_color="#fffff"
                disabled={props.dd_access["14"]===undefined ||props.dd_access["14"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.po}
                onchange_func = {(text) => { onChangeTextHandler(text,"po",type_string)}}
                onBlur= {() => {onBlurTextHandler("po", 14, type_string)}}
            />
            <ZEditNumber id="030102detail12"
                x={450} y={60} width={200} height={35} whole_background_color="#ffffff"
                label="Credits" label_x={0} label_y={0} label_width={80} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={90} button_y={0} button_width={90} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={props.dd_access["15"]===undefined ||props.dd_access["15"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.credits}
                onchange_func = {(text) => { onChangeTextHandler(text,"credits",type_number)}}
                onBlur= {() => {onBlurTextHandler("credits", 15, type_number)}}
            />
            <ZCheckbox id="030102detail13"
                x={430} y={110} width={160} height={35} whole_background_color="#ffffff"
                label="Verified" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={25} button_height={25} button_color="#000000" button_background_color="#ffffff"
                disabled={props.dd_access["16"]===undefined ||props.dd_access["16"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.verified}
                onClickCheckbox = {() => { onClickCheckbox("verified")}}
                onBlur= {() => {onBlurTextHandler("verified", 16, type_number)}}
            />
            <ZEditText id="030102detail14"
                x={450} y={160} width={200} height={35} whole_background_color="#ffffff"
                label="Invoice Date" label_x={0} label_y={0} label_width={80} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={90} button_y={0} button_width={90} button_height={35} button_color="#000000" button_background_color="#ffffff"
                disabled={props.dd_access["17"]===undefined ||props.dd_access["17"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.invoice_date}
                onchange_func = {(text) => { onChangeTextHandler(text,"invoice_date",type_string)}}
                onBlur= {() => {onBlurTextHandler("invoice_date", 17, type_string)}}
            />
            <ZEditNumber id="030102detail16"
                x={630} y={10} width={280} height={35} whole_background_color="#ffffff"
                label="Tax" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={170} button_height={35} button_color="#000000" button_background_color="#ffffff"
                disabled={props.dd_access["18"]===undefined ||props.dd_access["18"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.tax}
                onchange_func = {(text) => { onChangeTextHandler(text,"tax",type_number)}}
                onBlur= {() => {onBlurTextHandler("tax", 18, type_number)}}
            />
            <ZEditNumber id="030102detail17"
                x={630} y={60} width={280} height={35} whole_background_color="#ffffff"
                label="Shipping" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={170} button_height={35} button_color="#000000" button_background_color="#ffffff"
                disabled={props.dd_access["19"]===undefined ||props.dd_access["19"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.shipping}
                onchange_func = {(text) => { onChangeTextHandler(text,"shipping",type_number)}}
                onBlur= {() => {onBlurTextHandler("shipping", 19, type_number)}}
            />
            <ZEditNumber id="030102detail18"
                x={630} y={110} width={280} height={35} whole_background_color="#ffffff"
                label="User ID" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={170} button_height={35} button_color="#000000" button_background_color="#ffffff"
                disabled={props.dd_access["20"]===undefined ||props.dd_access["20"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.user_id}
                onchange_func = {(text) => { onChangeTextHandler(text,"user_id",type_number)}}
                onBlur= {() => {onBlurTextHandler("user_id", 20, type_number)}}
            />
            <ZEditText id="030102detail19"
                x={630} y={160} width={280} height={35} whole_background_color="#ffffff"
                label="Notes" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={170} button_height={35} button_color="#000000" button_background_color="#ffffff"
                disabled={props.dd_access["21"]===undefined ||props.dd_access["21"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.notes}
                onchange_func = {(text) => { onChangeTextHandler(text,"notes",type_string)}}
                onBlur= {() => {onBlurTextHandler("notes", 21, type_string)}}
            />
            <ZEditNumber id="030102detail21"
                x={920} y={110} width={200} height={35} whole_background_color="#ffffff"
                label="Category" label_x={0} label_y={0} label_width={80} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={90} button_y={0} button_width={120} button_height={35} button_color="#000000" button_background_color="#ffffff"
                disabled={props.dd_access["22"]===undefined ||props.dd_access["22"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.catogory_id}
                onchange_func = {(text) => { onChangeTextHandler(text,"catogory_id",type_number)}}
                onBlur= {() => {onBlurTextHandler("catogory_id", 22, type_number)}}
            />
            <ZEditNumber id="030102detail22"
                x={920} y={160} width={200} height={35} whole_background_color="#ffffff"
                label="Product" label_x={0} label_y={0} label_width={80} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={90} button_y={0} button_width={120} button_height={35} button_color="#000000" button_background_color="#ffffff"
                disabled={props.dd_access["23"]===undefined ||props.dd_access["23"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.product_id}
                onchange_func = {(text) => { onChangeTextHandler(text,"product_id",type_number)}}
                onBlur= {() => {onBlurTextHandler("product_id", 23, type_number)}}
            />
            <ZCheckbox id="030102detail34"
                x={1060} y={10} width={160} height={30} whole_background_color="#ffffff" 
                label="Update Mode" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#ff0000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={25} button_height={25} button_color="#000000" button_background_color="#ffffff"
                disabled={props.dd_access["28"]===undefined ||props.dd_access["28"]===0?true:false}
                value={update_mode}
                onClickCheckbox = {() => { onClickCheckbox("update_mode")}}
                onBlur= {() => {}}
            />
            <ZTextButton id="030102detail32"
                x={1165} y={50} width={160} height={30} whole_background_color="#f0f0f0" 
                disabled={props.dd_access["24"]===undefined ||props.dd_access["24"]===0?true:(update_mode===0?true:false)}
                border_style="1px solid #000000"
                button_text="New Invoice" label_background_color="#f0f0f0"
                button_font_color="#000000" button_background_color="#ffffff"
                onButtonClick = {() => { onButtonClickNew()}}
            />
            <ZTextButton id="030102detail32"
                x={1165} y={105} width={160} height={30} whole_background_color="#f0f0f0" 
                disabled={props.dd_access["28"]===undefined ||props.dd_access["28"]===0?true:(update_mode===0?true:false)}
                border_style="1px solid #000000"
                button_text="Add Orders" label_background_color="#f0f0f0"
                button_font_color="#00000" button_background_color="#ffffff"
                onButtonClick = {() => { onButtonClickAddOrder() }}
            />
            <ZTextButton id="030102detail32"
                x={1165} y={160} width={160} height={30} whole_background_color="#f0f0f0" 
                disabled={props.dd_access["27"]===undefined ||props.dd_access["27"]===0?true:(update_mode===0?true:false)}
                border_style="1px solid #000000"
                button_text="Remove Order" label_background_color="#f0f0f0"
                button_font_color="#00000" button_background_color="#ffffff"
                onButtonClick = {() => {onButtonClickRemoveOrder()}}
            />
             {showModalOrderList ? <EditInvoiceStatusOrderListDataGridForModal setShowModal={setShowModalOrderList} setResponse={setResponse} /> : null}
             {showModalOk ? <ResponseWindowOk title={modalTitle} setShowModal={SetShowModalOk} /> : null}
             {showModalEdit ? <ResponseWindowEdit title={modalTitle} setShowModal={SetShowModalEdit} setEditData={SetEditData} /> : null}
             {showModalYesNo ? <ResponseWindowYesNo title={modalTitle} setShowModal={SetShowModalYesNo} setResponse={setResponse}/> : null}
        </div>
    );
}

export default EditInvoiceStatusDetailDataGrid;
