import React, {Component} from 'react';

import {
    useNavigate
  }
from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import "./qbio_style.css"


import logotransparent from "./v2/img/logotransparent.png"
import logotransparent2 from "./v2/img/logo-quintara2.png"
import whiteColor from "./v2/img/bg-white.png"
import blueColor from "./v2/img/bg-blue.png"

const WebSiteHomeDefaultNavigator = ()=> {
  const navigate = useNavigate();
  const handleClick = (path) => {
//      alert('path:' + path);
//      navigate(path + '#');
    navigate(path)
    let url = window.location.href
    if(url.indexOf("#") ===-1)
        window.location.href = url + '?#'
  }  
  // navbar-light bg-light fixed-top padding
    return (
        <>
            <nav id="home_page_header" class="navbar navbar-expand-md navbar-light fixed-top padding py-5" >
                <div class="container-fluid d-flex align-items-center pt-3 mx-36">
                    <a onClick={()=>handleClick("/")} class="logo mr-auto" style={{borderColor:"#ffffff"}}>
                        <img src={logotransparent2} alt="Quintara Biosciences" style={{width:200,borderColor:"#ffffff"}}/>
                    </a>
                    <nav class="nav-menu-new" style={{left:-0}}>
                        <ul >
                            <li  style={{marginLeft: -32}}>
                                <a onClick={()=>handleClick("/") }>Home</a>
                            </li>
                            <li >
                                <a onClick={()=>handleClick("/about")}>About</a>
                            </li >
                            <li class="drop-down"><a onClick={()=>handleClick("/services")}>Services</a>
                                <ul>
                                    <li><a onClick={()=>handleClick("/service/dna_sequencing")}>Sanger Sequencing</a></li>
                                    <li><a onClick={()=>handleClick("/service/ngs_services")}>NGS Services</a></li>
                                    <li><a onClick={()=>handleClick("/service/dna_maxiprep")}>DNAprep Services</a></li>
                                    <li><a onClick={()=>handleClick("/service/oligo")}>Oligo Synthesis</a></li>
                                    <li><a onClick={()=>handleClick("/service/covid")}>COVID-19 Sequencing</a></li>
                                </ul>
                            </li>
                            <li class="drop-down"><a onClick={()=>handleClick("/support")}>Tools</a>
                                <ul>
                                    <li><a onClick={()=>handleClick("/support_faq")}>FAQ</a></li>
                                    <li><a onClick={()=>handleClick("/qbtools/findPrimer")}>Search for QB Primers</a></li>
                                </ul>
                            </li>
                            <li><a onClick={()=>handleClick("/user/myqb")}>Order</a></li>
                            <li><a onClick={()=>handleClick("/contact")}>Contact</a></li>
                        </ul>
                    </nav>
                    <nav class="nav-menu-new" style={{left:-150}}>
                    <span style={{width:100}}><a style={{width:100}} onClick={()=>handleClick("/sign-in")} class="login-btn">Login</a></span>
                    </nav>
                </div>
            </nav>
        </>
    )
}

export default WebSiteHomeDefaultNavigator;
