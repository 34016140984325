//BEGIN_IMPORT_SECTION
import React, {Component} from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import getToken, {setToken} from '../../GetSetToken.js';
import getApiUrl, {getPassthroughUrl} from '../../GetApiUrl';
import {retrieveApiData,retrieveData2} from '../../data_retrieval';

// const DataRetrieval = async(app_id,gui_id,sql_id)=> {
var the_this
export default class AccessAppGuiGrid extends React.Component  {
	state = {
		loaded:false,
		gridOptions:{},
		app_id:0,
		gui_id:0,
		data:[]
//		data:[{app_id:0,gui_id:0,description:"",path:"",enabled:0,control_status:0,note:"",create_by:0,create_dt:"",update_by:0,update_dt:"",}]
	}
	gridApi: GridApi
	constructor(props) {
		super(props);
	}
	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if(this.props.app_id!==prevProps.app_id) {
			this.retrieveData();
		}
	}	

	componentDidMount() {
		the_this = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: false })
		console.log('AccessAppGuiGrid-this.state.data', this.state.data);
	}

	onGridReady = params => {
    	this.gridApi = params.api;
 	 };

	retrieveData() {
		let input_parm = [
			{"request_row_count":1},
			{"offset_row":0},
			{"request_rows":9999},
			{"app_id":this.props.app_id},
		]
		let sql_id = 32;
		let app_id = 1;
		console.log('AccessAppGuiFieldGrid retrieveData this.props.input_parm',this.props.app_id);
		let tmp_data = retrieveApiData(1,10200,sql_id,"usp_react_sql_retrieve2", JSON.stringify(input_parm));
        let where_clause = input_parm.slice(3,input_parm.length);
		Promise.resolve(tmp_data).then((value)=>{
			console.log('AccessAppGuiGrid retrieveData value',value);
			let total_row_count = parseInt(value);
			retrieveData2(1,10200,sql_id,0,total_row_count,where_clause)
			.then((retrieved_data) => {
				console.log('AccessAppGuiGrid retrieveData retrieved_data',retrieved_data);
				let statusCopy = Object.assign({}, this.state)
				statusCopy.data = retrieved_data;
				this.setState(statusCopy);
				this.gridApi.setRowData(this.state.data)
			})
		});
	}
	onSelectionChanged = (event)=>{
		const selectedNodes =  event.api.getSelectedNodes();
		console.log('AccessAppGuiGrid the_this.gridApi', the_this.gridApi);
		the_this.props.getAppGuiId(selectedNodes[0].data.app_id,selectedNodes[0].data.gui_id);
	}
	onCellValueChanged = ({ data }) => {
  		console.log("AccessAppGuiGrid onCellClicked data", data);
	};
	onPasteStart(params) {
	  console.log('AccessAppGuiGrid onPasteStart:', params);
	}
	onPasteEnd(params) {
	  console.log('AccessAppGuiGrid Callback onPasteEnd:', params);
	}

	onCopySelectedRowsToClipboard(params) {
	  console.log('send to clipboard called with data:');
	  console.log(params.data);
	}
	onCellClicked(event){
//		console.log('AccessRoleGrid event', event.columnApi.columnController.columnDefs[0]);
		console.log("AccessAppGuiGrid onCellClicked event", event);

	}
	onCellDoubleClicked(obj){
		console.log('AccessAppGuiGrid onCellDoubleClicked(obj)', obj);
	}	
	onCellValueChanged(event) {
		console.log('AccessAppGuiGrid onCellValueChanged(event)', event);
	}
	cellClicked(params) {
	console.log('AccessAppGuiGrid cellClicked(params)', params);
	  params.node.setSelected(true)
	}
	onCellValueChanged = (obj) => {
		console.log('AccessAppGuiGrid onCellValueChanged obj', obj);		

		let field_name = obj.column.colDef.field 
		let the_app_id = obj.data.app_id
		let the_gui_id = obj.data.gui_id
		let the_data = obj.value
		let token = getToken();
		if (typeof the_data==='string')
			the_data = '"' + the_data + '"'
			let the_input_data = '{"' + field_name + '":' + the_data + ',"app_id":' + the_app_id.toString() + ',"gui_id":' + the_gui_id.toString() + '}'
			let the_input = '{"usp_name":"usp_react_sql_onchange_update","sql_id":32,"input_data"' + ':' + the_input_data + '}'
			console.log('AccessAppGuiGrid-onCellValueChanged the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {
			method: 'POST',headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
         })
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			console.log('AccessAppGuiGrid-onCellValueChanged 2 no error');
		})	
		.catch((error) => {
			console.error(error-'Error in update!');
			//this.insertOrder(1);
		});
		
	}
	onAddRow = () => {
//		let newData = [{app_id:this.props.app_id,gui_id:0,description:"NEW ROW",path:"DEFINE PATH",enabled:0,control_status:"",note:"",create_by:"",create_dt:"",update_by:"",update_dt:"",}]
		console.log('AccessAppGuiGrid-onAddRow');
		let newData = {app_id:0,gui_id:0,description:"",path:"",enabled:0,control_status:0,note:"",create_by:0,create_dt:"",update_by:0,update_dt:"",};

		let statusCopy = Object.assign({}, this.state)
		statusCopy.data.push(newData);
		this.setState(statusCopy);
		console.log('AccessAppGrid-this.state.data', this.state.data);
		
		this.gridApi.setRowData(statusCopy.data)
		
/*
		try {
			this.gridApi.applyTransaction({add:newData})
		} catch (e) {
			console.log('AccessAppGuiGrid-onAddRow e', e);
		}
		*/
	}

	onSaveRow = () => {
		let there_is_insert = false;
		let breaking = false;
		this.gridApi.forEachNode(function(node, i){
//			if(i>120)
//				console.log('AccessAppGuiGrid-onSaveRow i, node.data.create_by', i, node.data.create_by);
			if(node.data.create_dt===""&&breaking===false) {// to be inserted
				console.log('AccessAppGuiGrid-onSaveRow node', node);
				let app_id = node.data.app_id
				let gui_id = node.data.gui_id
				let description = node.data.description
				let path = node.data.path
				let the_input = [{"app_id":app_id},{"gui_id":gui_id},{"description":description}, {"path":path}];
				console.log('AccessAppGuiGrid-onSaveRow the_input', the_input);
				let tmp_data = retrieveApiData(1,10200,32,"usp_react_sql_insert2", JSON.stringify(the_input));
				Promise.resolve(tmp_data).then((value)=>{
					console.log('AccessAppGuiGrid-onSaveRow value', value);
					if(value==='Insert OK') {
						there_is_insert = true;
						the_this.retrieveData()
						console.log('AccessAppGuiGrid-onSaveRow inserted');
					} else 
						breaking = true;
				}) 
			}
		})
		/*
		console.log('AccessAppGuiGrid-onSaveRow there_is_insert,breaking',there_is_insert,breaking);
		if(there_is_insert===true&&breaking===false) {
			this.retrieveData()
		} if(breaking)
			alert("Cannot save inserted row!")
			*/
	}


	onDeleteRow = () => {
		console.log("this.gridApi.rowModel",this.gridApi.rowModel)
		let selectedRow = []
		let max_sample_id = 0
		let token = getToken();
		let there_is_deleting = false;
		this.gridApi.forEachNode(function(node, i){
//			console.log('AccessRoleGrid-onDeleteRow node', node);
			if(node.selected===true) {// delete the data
				let app_id = node.data.app_id
				let gui_id = node.data.gui_id
				let the_input = [{"app_id":app_id},{"gui_id":gui_id}];
				let tmp_data = retrieveApiData(1,10200,32,"usp_react_sql_delete2", JSON.stringify(the_input));
				Promise.resolve(tmp_data).then((value)=>{
					console.log('AccessAppGuiGrid-onDeleteRow value', value);
					if(value==='Delete OK') {
						there_is_deleting = true;
						the_this.retrieveData()
					}
				})
			}
		})
//		if(there_is_deleting===true)
//			this.retrieveData()
	}

	createGridOptions() {
		const options: GridOptions = {
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true
			},
			columnDefs: [
				{headerName:"AppID",width: 60, headerClass:"header-app_id",field:"app_id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.app_id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"GuiID",width: 60, headerClass:"header-gui_id",field:"gui_id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.gui_id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Description",width: 120, headerClass:"header-description",field:"description",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.description= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Path",width: 400, headerClass:"header-path",field:"path",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.path= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Enabled",width: 80, headerClass:"header-enabled",field:"enabled",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.enabled= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"ControlStatus",width: 120, headerClass:"header-control_status",field:"control_status",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.control_status= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Note",width: 80, headerClass:"header-note",field:"note",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.note= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Create_by",width: 80,headerClass:"header-create_by",field:"create_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Create_dt",width: 80,headerClass:"header-create_dt",field:"create_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_dt= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_by",width: 100,headerClass:"header-update_by",field:"update_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_dt",width: 100,headerClass:"header-update_dt",field:"update_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_dt= params.newValue;return true;},suppressNavigable:true,},
			],
		}
		return options;
	}
	render() {
		return  (
			<div style={{position:"absolute",top:0,width:510,height:390,backgroundColor:"#FFFFFF"}}>
				<div style={{position:"absolute",left:20,top:0,width:510,height:25,backgroundColor:"#FFFFFF",textAlign:"left"}}>
					App GUI
				</div>
				<div className="ag-theme-balham" 
                    style={{position:"absolute",top:25,left:0,width:510,height:380,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={"single"} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
				<div id="pid_0630_cell_001_003"
                    style={{position:"absolute",top:0,left:100,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onAddRow}>Add
					</button>
				</div>
				<div id="pid_0630_cell_001_004"
                    style={{position:"absolute",top:0,left:200,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onDeleteRow}>
						Delete
					</button>
				</div>
				<div id="pid_0630_cell_001_005"
                    style={{position:"absolute",top:0,left:300,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onSaveRow}>
						Save
					</button>
				</div>
			</div>
		)
	}
}