import React, {Component, useState, useEffect} from 'react';
//import '@progress/kendo-theme-default/dist/all.css';
import './App.css';
import './qbio_style_internal_user.css';
import 'bootstrap/dist/css/bootstrap.css';
import './v2/css/bootstrap.min.css';
import "./qbio_style_internal_user.css";
import {
    BrowserRouter as Router,
    useNavigate
  }
from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import logotransparent from "./v2/img/logotransparent.png"
import { setToken } from './Garbage/GetSetToken';
import { setMenuData } from './InternaPageTreeItems';

//current_internal_group
//fontWeight:property.current_internal_group==



const InternalUserPageHeader = (props)=> {
  const navigate = useNavigate();
  const g_id = props.menu_id;
  let header_id = Math.floor(props.currentId/10000);
  //let menu_list_tmp = [10100,20100,30100,40100,50100,60100];
  //currentId
  //menu_list_tmp[header_id-1] = props.currentId
  const [currentId,setCurrentId] = useState(props.currentId);
  const [menu_list,setMenu_list] = useState(props.header_menu_list);

  const handleClick = (id) => {
        setToken("0");
        setMenuData([]);
        navigate("/sign-in");
        let url = window.location.href
        if(url.indexOf("#") ===-1)
            window.location.href = url + '?#'
    }


  useEffect(() => {
    setMenu_list(props.header_menu_list);
//      alert('InternalPageHeader-useEffect');
//      setCurrentId(menu_list[props.menu_id-1]);
  });
    return (
        <>
         <div>
            <title>Quintara Biosciences</title>
            <div class="row" style={{height:"60"}}>
                <div class="col-lg-3 d-flex align-items-stretch" >
                    <a href="/" data-jzz-gui-player="true"><img src={logotransparent} title="Quintara Bio" /></a>
                </div>
                <div class="col-lg-2 d-flex align-items-stretch" >
                    <p>
                        <b>San Francisco Bay Area</b> <br/>
                            phone: 1-415-738-2509<br/>
                            info@quintarabio.com
                    </p>
                </div>
                <div class="col-lg-2 d-flex align-items-stretch" >
                    <p>
                        <b>Boston Area</b> <br/>
                        1-617-943-2768 <br/>
                        boston@quintarabio.com
                    </p>
                </div>
                <div class="col-lg-2 d-flex align-items-stretch" >
                    <p>
                    <b>D.C. Area</b> <br/>
                        1-301-761-4835 <br/>
                        dc@quintarabio.com
                     </p>
                </div>
                <div class="col-lg-2 d-flex align-items-stretch" >
                    <p>
                        <b>Billing</b> <br/>
                        1-510-990-2168 <br/>
                        accounting@quintarabio.com
                    </p> 
                </div>  
                <div class="col-lg-1 d-flex align-items-stretch" >
                    <span><a onClick={()=>handleClick()} class="login-btn">Logout</a></span>
                </div>  
        </div>
        <hr/>
     </div>        
        </>
    )
}




export default InternalUserPageHeader;
