//BEGIN_IMPORT_SECTION
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import getToken, {setToken} from '../../GetSetToken.js';
import getApiUrl, {getPassthroughUrl} from '../../GetApiUrl';
import BussLookupDescriptionGrid from './busslookupdescriptiongrid';

var the_this
export default class BussLookupColumnGrid extends React.Component  {
	state = {
		loaded:false,
		gridOptions:{},
		selected_col_id:0,
		data:[{id:0,lookup_id:"",column_name:"",note:"",create_by:"",create_dt:"",update_by:"",update_dt:"",}]
	}
	gridApi: GridApi
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: true })
		this.retrieveData()
	}
	onGridReady = params => {
    	this.gridApi = params.api;
 	 };

	  onColSelection_DW1 = (p_column_id,p_table_id)=>{
		console.log('onColSelection_DW1');
		var dataDiv = <div >
                <BussLookupDescriptionGrid
                    id = "01010101DescriptionGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    table_id = {p_table_id}
                    col_id = {p_column_id}
                    getTableId = {(table_id) => { this.getTableId(table_id)}}
                    getColId = {(col_id) => { this.getColId(col_id)}} 
                />
             </div>   
			ReactDOM.unmountComponentAtNode(document.getElementById('01010101DescriptionGrid'))
			ReactDOM.render(dataDiv, document.getElementById('01010101DescriptionGrid'))
			return (
					dataDiv
			)
	}

	retrieveData = () => {
//		let input_data = '{"id":1}'
//		let input_data = '{}'
		let input_data = this.props.table_id > 0 ? '{"lookup_id":' + this.props.table_id.toString() + '}' : '{}'
        const api_url = getPassthroughUrl();
		console.log("api_url",api_url)
        let the_pin = 0;
		let token = getToken();
		let the_input = '{"usp_name":"usp_react_sql_retrieve","sql_id":12, "input_data":' + input_data + '}';
        fetch(api_url, {
			method: 'POST',headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
        })
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			console.log('BussColumnGrid responseJson',responseJson)
			this.setState({retrieved:true});
			if(responseJson.success===true) {
				console.log('BussColumnGrid responseJson.output', responseJson.output);
				if(responseJson.output==='NO DATA')
					console.log('BussColumnGrid NO DATA')
				else {
					let statusCopy = Object.assign({}, this.state)
					let the_data = JSON.parse(responseJson.output);
					statusCopy.data = the_data;
					statusCopy.loaded = true;
					this.setState(statusCopy);
					console.log('BussColumnGrid-this.state.data', this.state.data);
					this.gridApi.setRowData(this.state.data)
					//this.state.data != null ? the_this.onColSelection_DW1(this.state.data[0].id) : the_this.onColSelection_DW1(0)
				}
			} else {
				console.log('BussColumnGrid responseJson.error', responseJson.error);
				console.log('BussColumnGrid responseJson.message', responseJson.message);
			}
		})	
		.catch((error) => {
			console.log("error",error)
			this.setState({retrieved:false});
//			alert('Please wait, as this may take few seconds to load...Error Encountered')
			//this.insertOrder(1);
		});
	}

	onSelectionChanged = (event)=>{
		const selectedRows =  event.api.getSelectedRows();
		const selectedNodes =  event.api.getSelectedNodes();
		console.log('BussColumnGrid selectedNodes:', selectedNodes);
		console.log('BussColumnGrid event:', event);
		this.gridApi.forEachNode(function(node, i){
			//console.log('AccessRoleGrid-onSelectionChanged node', node);
			if(node.selected===true) {// selected the data
				console.log('BussColumnGrid-onSelectionChanged selected===true node', node);
				//this.setState({ ...this.state,selected_role_id:node.data.id}) ;
				console.log('BussColumnGrid-onSelectionChanged calling GUI');
				the_this.onColSelection_DW1(node.data.id, node.data.lookup_id);
			}
		})
	}
	onCellValueChanged = ({ data }) => {
  		console.log("BussColumnGrid onCellClicked data", data);
	};
	onPasteStart(params) {
	  console.log('BussColumnGrid onPasteStart:', params);
	}
	onPasteEnd(params) {
	  console.log('BussColumnGrid Callback onPasteEnd:', params);
	}

	onCopySelectedRowsToClipboard(params) {
	  console.log('send to clipboard called with data:');
	  console.log(params.data);
	}
	onCellClicked(event){
//		console.log('AccessRoleGrid event', event.columnApi.columnController.columnDefs[0]);
		console.log("BussColumnGrid onCellClicked event", event);

	}
	onCellDoubleClicked(obj){
		console.log('BussColumnGrid onCellDoubleClicked(obj)', obj);
	}	
	onCellValueChanged(event) {
		console.log('BussColumnGrid onCellValueChanged(event)', event);
	}
	cellClicked(params) {
	console.log('BussColumnGrid cellClicked(params)', params);
	  params.node.setSelected(true)
	}
	onCellValueChanged = (obj) => {
		console.log('BussColumnGrid onCellValueChanged obj', obj);		
		let field_name = obj.column.colDef.field 
		let the_id = obj.data.id
		let the_table_id = obj.data.lookup_id
		let the_data = obj.value
		let token = getToken();
		if (typeof the_data==='string')
			the_data = '"' + the_data + '"'
		let the_input_data = '{"' + field_name + '":' + the_data + ',"id":' + the_id.toString() + ',"lookup_id":' + the_table_id.toString() + '}'
		let the_input = '{"usp_name":"usp_react_sql_onchange_update","sql_id":12,"input_data"' + ':' + the_input_data + '}'
		console.log('BussColumnGrid-onCellValueChanged the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {
			method: 'POST',headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
         })
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			console.log('BussColumnGrid-onCellValueChanged 2 no error');
		})	
		.catch((error) => {
			console.error(error-'Error in update!');
			//this.insertOrder(1);
		});
	}
	onAddRow = () => {
		let table_id = this.props.table_id > 0 ? this.props.table_id : 0
//		let newData = [{id:role_id,name:"",description:"",app_id:app_id,inclusive_ind:"",note:"",create_by:"",create_dt:"",update_by:"",update_dt:"",}]
		
		console.log('BussColumnGrid-onAddRow table_id', this.props.table_id);
		//let the_input_data = '{"id":"' + role_id.toString() + '",app_id":"' + app_id.toString() + '"}'
		let the_input_data = '{"lookup_id":' + table_id + ',"column_name":"New Column"}'
		let the_input = '{"usp_name":"usp_react_sql_insert","sql_id":12,"input_data"' + ':' + the_input_data + '}'
		let token = getToken();
		console.log('BussColumnGrid-onAddRow token', token);
		console.log('BussColumnGrid-onAddRow the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {method: 'POST', headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})})
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			if(responseJson.output==='Insert OK')
				this.retrieveData()
		})	
		.catch((error) => {
			console.error(error-'Error in AddRow!');
			//this.insertOrder(1);
		});
	}
	onDeleteRow = () => {
		console.log("this.gridApi.rowModel",this.gridApi.rowModel)
        if (window.confirm("Confirm Delete?")){

            let selectedRow = []
            let max_sample_id = 0
            let token = getToken();
            this.gridApi.forEachNode(function(node, i){
            //	console.log('AccessRoleGrid-onDeleteRow node', node);
                if(node.selected===true) {// delete the data
                    let col_id = node.data.id
                    let table_id = node.data.lookup_id				
                    let the_input_data = '{"id":' + col_id.toString() + ',"lookup_id":' + table_id.toString() +'}'
                    let the_input = '{"usp_name":"usp_react_sql_delete","sql_id":12,"input_data"' + ':' + the_input_data + '}'
            //		console.log('AccessRoleGrid-DeleteRow the_input', the_input);
                    const api_url = getPassthroughUrl();
                    return fetch(api_url, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', },
                        body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
                    })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        console.log('BussColumnGrid-responseJson', responseJson);
                        console.log('BussColumnGrid-responseJson.success', responseJson.success);
                        if(responseJson.success===true) {
                            let statusCopy = Object.assign({}, the_this.state)
                            statusCopy.data.splice(i, 1);
                            the_this.gridApi.setRowData(statusCopy.data)

                            //--------Cascading Deletes of children GUIs
                                the_input_data = '{"lookup_column_id":' + col_id.toString() +'}' //+ '"lookup_id":' + table_id.toString() 
                                //the_input = '{"usp_name":"usp_react_sql_general","sql_id":1,"input_data"' + ':' + the_input_data + '}'
                                the_input = '{"usp_name":"usp_react_sql_subsql","sql_id":12,"input_data"' + ':' + the_input_data + '}'
                                const api_url = getPassthroughUrl();
                                return fetch(api_url, {
                                    method: 'POST',
                                    headers: { 'Content-Type': 'application/json', },
                                    body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
                                })
                                .then((response) => response.json())
                                .then((responseJson) => {
                                    console.log('BussColumnGrid-responseJson', responseJson);
                                    console.log('BussColumnGrid-responseJson.success', responseJson.success);
                                    /*if(responseJson.success===true) {
                                    let statusCopy = Object.assign({}, the_this.state)
                                    statusCopy.data.splice(i, 1);
                                    the_this.gridApi.setRowData(statusCopy.data)
                                    }*/
                                    the_this.onColSelection_DW1(col_id);
                                })	
                                .catch((error) => {
                                    console.log('SangerSeqOrderDetail2-error', error);
                                });
                            //--------End of Cascading Delete Logic
                        }
                    })	
                    .catch((error) => {
                        console.log('SangerSeqOrderDetail2-error', error);
                    });
                }
    		})
        }     
	}
	createGridOptions() {
		const options: GridOptions = {
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true
			},
			columnDefs: [
				{headerName:"ColumnID",width: 80, headerClass:"header-id",field:"id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"TableID",width: 80, headerClass:"header-lookup_id",field:"lookup_id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.lookup_id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Name",width: 80, headerClass:"header-column_name",field:"column_name",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.column_name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Note",width: 200,headerClass:"header-note",field:"note",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.note= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Create_by",width: 80,headerClass:"header-create_by",field:"create_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Create_dt",width: 80,headerClass:"header-create_dt",field:"create_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_dt= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_by",width: 80,headerClass:"header-update_by",field:"update_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_dt",width: 80,headerClass:"header-update_dt",field:"update_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_dt= params.newValue;return true;},suppressNavigable:true,},
			],
		}
		return options;
	}
	render() {
/*
		if(this.state.loaded===false) {
			this.retrieveData()
		}
*/
		return  (
			<div style={{position:"absolute",top:0,width:650,height:200,backgroundColor:"#FFFFFF"}}>
				<div style={{position:"absolute",left:0,top:0,width:580,height:25,backgroundColor:"#FFFFFF",textAlign:"center"}}>
					Buss Column Grid
				</div>
				<div className="ag-theme-balham" 
                    style={{position:"absolute",top:25,left:0,width:580,height:175,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={"single"} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
				<div id="pid_0630_cell_001_002"
                    style={{position:"absolute",top:25,left:580,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.retrieveData}>Refresh
					</button>
				</div>
				<div id="pid_0630_cell_001_003"
                    style={{position:"absolute",top:55,left:580,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onAddRow}>Add
					</button>
				</div>
				<div id="pid_0630_cell_001_004"
                    style={{position:"absolute",top:85,left:580,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onDeleteRow}>
						Delete
					</button>
				</div>
			</div>
		)
	}
}

//[{"login_user_id": 1, "access_failed_count": 0, "display_name": "ZoftTest01@gmail.com", "email": "ZoftTest01@gmail.com", "PasswordHash": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "PhoneNumber": null, "UserName": "ZoftTest01@gmail.com", "last_name": null, "first_name": null, "address": null, "city": null, "state_province": null, "country": null, "TextMsg": null, "phone": null, "title": null, "profession": null, "profession_id": null, "pin": 0, "active_ind": null, "mid_name": null, "zip_code": null, "sec_verify_method": null, "reset_password_ind": null, "last_access": null, "note": null, "create_by": 0, "create_dt": "2022-05-18 01:43:40", "update_by": 0, "update_dt": "2022-05-18 01:43:40"},{"login_user_id": 2, "access_failed_count": 0, "display_name": "SuperUser@quintarabio.com", "email": "SuperUser@quintarabio.com", "PasswordHash": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "PhoneNumber": null, "UserName": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "last_name": null, "first_name": null, "address": null, "city": null, "state_province": null, "country": null, "TextMsg": null, "phone": null, "title": null, "profession": null, "profession_id": null, "pin": 0, "active_ind": null, "mid_name": null, "zip_code": null, "sec_verify_method": null, "reset_password_ind": null, "last_access": null, "note": null, "create_by": 0, "create_dt": "2022-05-18 01:45:40", "update_by": 0, "update_dt": "2022-05-18 01:45:40"}]