//BEGIN_IMPORT_SECTIONs
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import getToken, {setToken} from '../../GetSetToken.js';
import getApiUrl, {getPassthroughUrl} from '../../GetApiUrl';


var the_this
var sslt_this
export default class CategoryGrid extends React.Component {
	dd_selection={};
	state = {
		loaded:false,
		gridOptions:{},
		selected_building_id:0,
		data:[{"id": 1, "name": "", "description": "", "code": 0},],
		offset_row:0,
		request_rows:50,
	}
	gridApi: GridApi
		constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this = this; 
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: true })
		this.retrieveData(0,50)
	}
	onGridReady = params => {
    	this.gridApi = params.api;
 	};


	 retrievePrev = () => {
		let offset_row;
		offset_row = this.state.offset_row - 50
		if (offset_row < 0) {offset_row = 0}
		console.log('CategoryGrid-retrievePrev NEW OFFSET ', offset_row, ' OLD OFFSET ' + this.state.offset_row, this.state.request_rows);
		this.retrieveData(offset_row, 50);
	}
		
	retrieveNext = () => {
			let offset_row;
			offset_row = this.state.offset_row + 50;
			//if (offset_row < 0) {offset_row = 12}
			console.log('CategoryGrid-retrieveNext  NEW OFFSET ', offset_row, ' OLD OFFSET ' + this.state.offset_row, this.state.request_rows);
			this.retrieveData(offset_row, 50);
		}
		

	retrieveData = (offset_row, request_rows) => {
		let input_data =  '{"offset_row":' + offset_row + ',"request_rows":' + request_rows + '}'; //'{"id":1}';//"offset_row":12,"request_rows":50
		const api_url = getPassthroughUrl();
		console.log("api_url",api_url)
        let the_pin = 0;
		let token = getToken();
		let the_input = '{"usp_name":"usp_react_sql_retrieve","sql_id":18, "input_data":' + input_data + '}';
        fetch(api_url, {
			method: 'POST',headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
        })
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			console.log('RouteGrid responseJson',responseJson)
			this.setState({retrieved:true});
			if(responseJson.success===true) {
				console.log('CategoryGrid responseJson.output', responseJson.output);
				if(responseJson.output==='NO DATA'){
					console.log('RouteGrid NO DATA')}
				else {
					let statusCopy = Object.assign({}, this.state)
					let the_data = JSON.parse(responseJson.output);
					statusCopy.data = the_data;
					statusCopy.loaded = true;
					statusCopy.offset_row = offset_row; statusCopy.request_rows = request_rows; 
					this.setState(statusCopy);
					console.log('RouteGrid-this.state.data', this.state.data);
					this.gridApi.setRowData(this.state.data)
				}
			} else {
				console.log('CategoryGrid responseJson.error', responseJson.error);
				console.log('CategoryGrid responseJson.message', responseJson.message);
			}
		})
		.catch((error) => {
			console.log("error",error)
			this.setState({retrieved:false});
//			alert('Please wait, as this may take few seconds to load...Error Encountered')
			//this.insertOrder(1);
		});
	}
	onSelectionChanged = (event)=>{
		const selectedRows =  event.api.getSelectedRows();
		const selectedNodes =  event.api.getSelectedNodes();
		console.log('CategoryGrid selectedNodes:', selectedNodes);
		console.log('CategoryGrid event:', event);
		/*
		this.gridApi.forEachNode(function(node, i){
			console.log('AccessRoleGrid-onSelectionChanged node', node);
			if(node.selected===true) {// selected the data
				console.log('CategoryGrid-onSelectionChanged selected===true node', node);
				the_this.props.getRoleId(node.data.id)
			}
		})
		*/
	}
	/*onCellValueChanged = ({ data }) => {
  		console.log("CategoryGrid onCellClicked data", data);
	};*/
	onPasteStart(params) {
	  console.log('CategoryGrid onPasteStart:', params);
	}
	onPasteEnd(params) {
	  console.log('CategoryGrid Callback onPasteEnd:', params);
	}

	onCopySelectedRowsToClipboard(params) {
	  console.log('send to clipboard called with data:');
	  console.log(params.data);
	}
	onCellClicked(event){
		console.log("CategoryGrid onCellClicked event", event);

	}
	onCellDoubleClicked(obj){
		console.log('CategoryGrid onCellDoubleClicked(obj)', obj);
	}	
	onCellValueChanged(event) {
		console.log('CategoryGrid onCellValueChanged(event)', event);
	}
	cellClicked(params) {
	console.log('CategoryGrid cellClicked(params)', params);
	  params.node.setSelected(true)
	}
	onCellValueChanged = (obj) => {
		console.log('CategoryGrid onCellValueChanged obj', obj);		
		let field_name = obj.column.colDef.field
		let the_id = obj.data.id
		let the_data = obj.value
		let token = getToken();
		if (typeof the_data==='string')
			the_data = '"' + the_data + '"'
		let the_input_data = '{"' + field_name + '":' + the_data + ',"id":' + the_id.toString() + '}'
		let the_input = '{"usp_name":"usp_react_sql_onchange_update","sql_id":18,"input_data"' + ':' + the_input_data + '}'
		console.log('CategoryGrid-onCellValueChanged the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {
			method: 'POST',headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
         })
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			console.log('CategoryGrid-onCellValueChanged 2 no error');
			this.retrieveData()
		})	
		.catch((error) => {
			console.error(error-'Error in update!');
			//this.insertOrder(1);
		});
	}
	onAddRow = () => {
		let state_id = 1; 
		let the_input_data = '{"name":"New Name"}'
		//let the_input_data = '{"name":"New Name"}'
		let the_input = '{"usp_name":"usp_react_sql_insert","sql_id":18,"input_data"' + ':' + the_input_data + '}'
		let token = getToken();
		console.log('CategoryGrid-onAddRow token', token);
		console.log('CategoryGrid-onAddRow the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {method: 'POST', headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})})
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			if(responseJson.output==='Insert OK')
				this.retrieveData()
		})	
		.catch((error) => {
			console.error(error-'Error in AddRow!');
			//this.insertOrder(1);
		});
	}
	onDeleteRow = () => {
		console.log("this.gridApi.rowModel",this.gridApi.rowModel)
		if (window.confirm("Confirm Delete?")){
			let selectedRow = []
			let max_sample_id = 0
			let token = getToken();
			this.gridApi.forEachNode(function(node, i){
	//			console.log('AccessRoleGrid-onDeleteRow node', node);
				if(node.selected===true) {// delete the data
					let id = node.data.id
					let the_input_data = '{"id":' + id.toString() + '}'
					let the_input = '{"usp_name":"usp_react_sql_delete","sql_id":18,"input_data"' + ':' + the_input_data + '}'
	//				console.log('AccessRoleGrid-DeleteRow the_input', the_input);
					const api_url = getPassthroughUrl();
					return fetch(api_url, {method: 'POST', headers: { 'Content-Type': 'application/json', },
						body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
					})
					.then((response) => response.json())
					.then((responseJson) => {
						console.log('CategoryGrid-responseJson', responseJson);
						console.log('CategoryGrid-responseJson.success', responseJson.success);
						if(responseJson.success===true) {
						let statusCopy = Object.assign({}, the_this.state)
						statusCopy.data.splice(i, 1);
						the_this.gridApi.setRowData(statusCopy.data)
						}
					})	
					.catch((error) => {
						console.log('SangerSeqOrderDetail2-error', error);
					});
				}
			})
		}	//--If Confirmed Deletion
	}
	createGridOptions() {
		const options: GridOptions = {
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true
			},
			columnDefs: [
				{headerName:"ID",headerClass:"header-id",field:"id",hide:false,width:110,sortable:true,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Name",headerClass:"header-name",field:"name",hide:false,width:180,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Description",headerClass:"header-description",field:"description",hide:false,width:180,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.description= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Code",headerClass:"header-code",field:"code",hide:false,width:170,sortable:true,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.code= params.newValue;return true;},suppressNavigable:false,},
		
			],
		}
		return options;
	}
	render() {
/*
		if(this.state.loaded===false) {
			this.retrieveData()
		}
*/

		return  (
			<div id="pid_0630_cell_001" style={{position:"absolute",top:0,width:500,height:250,backgroundColor:"#FFFFFF"}}>
				<div id="pid_0630_cell_001_001" className="ag-theme-balham" 
                    style={{position:"absolute",top:0,left:0,width:500,height:250,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={"single"} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
				<div id="pid_0630_cell_001_002"
                    style={{position:"absolute",top:25,left:500,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.retrievePrev}>Prev
					</button>
				</div>
				<div id="pid_0630_cell_001_003"
                    style={{position:"absolute",top:55,left:500,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onAddRow}>Add
					</button>
				</div>
				<div id="pid_0630_cell_001_004"
                    style={{position:"absolute",top:85,left:500,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onDeleteRow}>
						Delete
					</button>
				</div>
				<div id="pid_0630_cell_001_005"
                    style={{position:"absolute",top:115,left:500,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.retrieveNext}>
						Next
					</button>
				</div>
			</div>
		)
	}
}

//[{"login_user_id": 1, "access_failed_count": 0, "display_name": "ZoftTest01@gmail.com", "email": "ZoftTest01@gmail.com", "PasswordHash": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "PhoneNumber": null, "UserName": "ZoftTest01@gmail.com", "last_name": null, "first_name": null, "address": null, "city": null, "state_province": null, "country": null, "TextMsg": null, "phone": null, "title": null, "profession": null, "profession_id": null, "pin": 0, "active_ind": null, "mid_name": null, "zip_code": null, "sec_verify_method": null, "reset_password_ind": null, "last_access": null, "note": null, "create_by": 0, "create_dt": "2022-05-18 01:43:40", "update_by": 0, "update_dt": "2022-05-18 01:43:40"},{"login_user_id": 2, "access_failed_count": 0, "display_name": "SuperUser@quintarabio.com", "email": "SuperUser@quintarabio.com", "PasswordHash": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "PhoneNumber": null, "UserName": "$2a$12$KHoDEXG8RFiq8IRf2A5QwONH2FXs.LPQnJsDa9PifWwR77h1kigHm", "last_name": null, "first_name": null, "address": null, "city": null, "state_province": null, "country": null, "TextMsg": null, "phone": null, "title": null, "profession": null, "profession_id": null, "pin": 0, "active_ind": null, "mid_name": null, "zip_code": null, "sec_verify_method": null, "reset_password_ind": null, "last_access": null, "note": null, "create_by": 0, "create_dt": "2022-05-18 01:45:40", "update_by": 0, "update_dt": "2022-05-18 01:45:40"}]