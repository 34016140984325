//Design Tool--
import React, {Component} from 'react';
//import SangerSeqAccessCtrlUserGrid from 'api/layout/547.js'
import AccessAppGrid from './accessappgrid';
import AccessAppGuiGrid from './accessappguigrid';
import AccessAppGuiFieldGrid from './accessappguifieldgrid';
import AccessRoleGrid from './accessrolegrid';
import AccessRoleAppGuiGrid from './accessroleappguigrid';
import AccessRoleAppGuiFieldGrid from './accessroleappguifieldgrid';
import AccessUserListGrid from './accessuserlistgrid';
import AccessUserRoleGrid from './accessuserrolegrid';
import AccessUserGuiGrid from './accessuserguigrid';
import AccessUserGuiFieldGrid from './accessuserguifieldgrid';

export default class AccessCtrlDataPage extends React.Component {
	state = {
		user_id:0,
		role_id:0,
        app_id:0,
        gui_id:0,
        field_id:0,
        app_gui_id:0,
        app_field_id:0,
        role_app_id:0,
        role_gui_id:0,
        role_field_id:0,
        user_role_id:0,
        user_app_id:0,
        user_gui_id:0,
        user_field_id:0,
	}
	componentDidMount() {
        console.log("AccessCtrlDataPage componentDidMount")
	}
    getAppId = (app_id) => 
	{
		console.log('AccessCtrlDataPage getAppId', app_id)
		this.setState({ app_id:app_id}) ;
	}
    getGuiId = (gui_id) => 
	{
		console.log('AccessCtrlDataPage getGuiId', gui_id)
		this.setState({ gui_id:gui_id}) ;		
	}
    getRoleAppId = (role_id,app_id) => 
	{
		console.log('AccessCtrlDataPage getRoleAppId,role_id,app_id', role_id,app_id)
		this.setState({ role_id:role_id,role_app_id:app_id}) ;		
	}
    getRoleAppGuiId = (gui_id) =>
	{
		console.log('AccessCtrlDataPage getRoleAppGuiId', gui_id)
		this.setState({ role_gui_id:gui_id}) ;		
	}
    getRoleAppGuiFieldId = (role_id,app_id,gui_id,field_id) => 
	{
		console.log('AccessCtrlDataPage getAppGuiFieldId', role_id,gui_id,field_id)
		this.setState({ role_id:role_id,role_app_id:app_id,role_gui_id:gui_id,role_field_id:field_id}) ;			
	}
    getAppGuiId = (app_id,gui_id) => 
	{
		console.log('AccessCtrlDataPage getGuiId', gui_id)
		this.setState({ app_id:app_id,gui_id:gui_id,app_gui_id:gui_id}) ;		
	}
    getAppGuiFieldId = (app_id,gui_id,field_id) => 
	{
		console.log('AccessCtrlDataPage getAppGuiFieldId', app_id,gui_id,field_id)
		this.setState({app_id:app_id,app_gui_id:gui_id,app_field_id:field_id}) ;			
	}
    getUserId = (user_id) => 
	{
		console.log('AccessCtrlDataPage getUserId', user_id)
		this.setState({user_id:user_id}) ;		
	}
    getUserRoleId = (user_id,role_id,app_id) => 
	{
		console.log('AccessCtrlDataPage getUserId', user_id)
		this.setState({ user_id:user_id,user_role_id:role_id,user_app_id:app_id}) ;			
	}
    getUserAppGuiId = (app_id,gui_id) => 
	{
		console.log('AccessCtrlDataPage getUserId-user_id,app_id,gui_id', app_id,gui_id)
		this.setState({user_app_id:app_id,user_gui_id:gui_id});		
	}
    getUserAppGuiFieldId = (user_id,role_id,app_id,gui_id,field_id) => 
	{
		console.log('AccessCtrlDataPage getUserId,user_id,role_id,app_id,gui_id,field_id', user_id,role_id,app_id,gui_id,field_id);
		this.setState({user_id:user_id,user_role_id:role_id,user_app_id:app_id,user_gui_id:gui_id,user_field_id:field_id});		
	}

//-----Render Script-----
    render(){
    return  (
        <div style={{width:1450,height:1070,backgroundColor:"#ffffff"}}>
           <div  id = "01020001AppGrid" 
                style={{left:0,top:0, width:510,height:175,backgroundColor:"#FFFFFF"}}>
                <AccessAppGrid
                    id = "01010201AppGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getAppId = {(app_id) => { this.getAppId(app_id)}}
                />
            </div> 
            <div id = "01020002AppGuiGrid" 
                style={{position:"absolute",left:0, top: 190, width:650,height:400,backgroundColor:"#FFFFFF"}}>
                <AccessAppGuiGrid
                    id = "01020002AppGuiGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    app_id = {this.state.app_id}
                    getAppGuiId = {(app_id,gui_id) => { this.getAppGuiId(app_id,gui_id)}}
                />
            </div>
            <div id = "01020002AccessRoleAppGuiFieldGrid" 
                style={{position:"absolute",left:0, top: 605, width:650,height:410,backgroundColor:"#FFFFFF"}}>
                <AccessAppGuiFieldGrid
                    id = "01020002AppGuiGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    app_id = {this.state.app_id}
                    gui_id = {this.state.app_gui_id}
                    getAppGuiFieldId = {(app_id,gui_id,field_id) => { this.getAppGuiId(app_id,gui_id,field_id)}}
                />
            </div>
            <div  id = "01020003RoleGrid" 
                style={{position:"absolute",left:520, top: 0, width:520,height:200,backgroundColor:"#FFFFFF"}}>
                <AccessRoleGrid
                    id = "01020003RoleGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getRoleAppId = {(role_id,app_id) => { this.getRoleAppId(role_id,app_id)}}
                />
            </div>
            <div  id = "01020004RoleAppGuiGrid" 
                style={{position:"absolute",left:520, top: 215, width:530,height:420,backgroundColor:"#FFFFFF"}}>
                <AccessRoleAppGuiGrid
                    id = "01020004RoleGuiGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    role_id = {this.state.role_id}
                    app_id={this.state.role_app_id}
                    getRoleAppGuiId={(gui_id) => { this.getRoleAppGuiId(gui_id)}}
                />
            </div>
            <div  id = "01020004RoleAppGuiFieldGrid"
                style={{position:"absolute",left:520, top: 650, width:530,height:325,backgroundColor:"#FFFFFF"}}>
                <AccessRoleAppGuiFieldGrid
                    id = "01020004RoleGuiGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    app_id={this.state.role_app_id}
                    role_id = {this.state.role_id}
                    gui_id = {this.state.role_gui_id}
                />
            </div>
            <div  id = "01020005UserListGrid" 
                style={{position:"absolute",left:1060, top: 0, width:530,height:200,backgroundColor:"#FFFFFF"}}>
                <AccessUserListGrid
                    id = "01020005UserListGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getUserId = {(user_id) => { this.getUserId(user_id)}}
                />
            </div>
            <div  id = "01020006UserRoleGrid" 
                style={{position:"absolute",left:1060, top: 215, width:480,height:200,backgroundColor:"#FFFFFF"}}>
                <AccessUserRoleGrid
                    id = "01020006UserRoleGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    user_id={this.state.user_id}
                />
            </div>
            <div  id = "01020007UserGuiGrid" 
                style={{position:"absolute",left:1060, top: 430, width:480,height:300,backgroundColor:"#FFFFFF"}}>
                <AccessUserGuiGrid
                    id = "01020007UserGuiGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    user_id = {this.state.user_id}
                    getUserAppGuiId  = {(app_id,gui_id) => { this.getUserAppGuiId(app_id,gui_id)}}
                    />
            </div>
            <div  id = "01020007UserGuiGrid" 
                style={{position:"absolute",left:1060, top: 750, width:480,height:200,backgroundColor:"#FFFFFF"}}>
                <AccessUserGuiFieldGrid
                    id = "01020007UserGuiFieldGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    user_id = {this.state.user_id}
                    app_id={this.state.user_app_id}
                    gui_id={this.state.user_gui_id}
                    getUserAppGuiFieldId={(user_id,app_id,gui_id,field_id) => { this.getUserAppGuiFieldId(user_id,app_id,gui_id,field_id)}}
                />
            </div>

        </div>
		);
	}
}

