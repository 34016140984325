//BEGIN_IMPORT_SECTIONs
import React, {Component, useState} from 'react';
import ReactDOM from 'react-dom';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
//import { AgGridReact,AgGridColumn } from '@ag-grid-community/react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import {retrieveApiData,retrieveData2,zoftApiForUsp} from '../../../data_retrieval';
import {ResponseWindowYesNo2,ResponseWindowSelf} from '../../../Modals';
import ZTextButton from '../../../zcomponents/ZTextButton';
import { ModalRetrieve } from '../../../ModalRetrieval';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
//import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

var the_this;
var is_retrieving = false;
var retrieve_counting = 0;
export default class BillGroupManageListDataGrid extends React.Component {
	dd_select_status={}
	dd_select_costcenter={}
	dd_select_institution={}
	input_parm0 = [
		{"request_row_count":1},
		{"offset_row":0},
		{"request_rows":1000},
		{"id":0},
		{"name":""},
		{"costcenter_id":0},
		{"institution":""},
		{"quote_id":0},
		{"status_id":0},
		{"delivery_method":0},
		{"email_to":""},
		{"po":""},
		{"po_required":-1},
		{"is_active":-1},
		{"is_autobill":-1},
		{"is_invoice_per_po":-1},
	]

	state = {
		loaded:false,
		modules:[ClientSideRowModelModule],
		gridOptions:{},
		rowSelection: 'single',
		selected_building_id:0,
		data:[],
		offset_row : 0,
		request_rows:50,
		updated_from_detail_allowed:true,
		showModalOk:false,
		showModalYesNo:false,
		showModalSelf:false,
		response:0,
		current_time:(new Date()),
		counter:1,
		showModalRetrieve:false,
		input_parm:[],
		retrieve_source:1, // 1=search, 2=prev, 3=next
		total_rows:0, // 1=search, 2=prev, 3=next
		from_row:1,
		rowCountInd:1,
	}

	gridApi: GridApi
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: true })
		for(let i = 0; i < this.props.status_list.length;i++)
			this.dd_select_status[this.props.status_list[i].id] = this.props.status_list[i].name;	
		for(let i = 0; i < this.props.costcenter_list.length;i++)
			this.dd_select_costcenter[this.props.costcenter_list[i].id] = this.props.costcenter_list[i].name;
		for(let i = 0; i < this.props.institution_list.length;i++)
			this.dd_select_institution[this.props.institution_list[i].id] = this.props.institution_list[i].name;
	}
	componentDidUpdate(prevProps) {
		console.log('BillGroupManageListDataGrid-componentDidUpdate	this.dd_select_status',this.costcenter_list);
		console.log('BillGroupManageListDataGrid-componentDidUpdate this.props.search_clicked',this.props.search_clicked);

		this.input_parm0[0].request_row_count = this.props.input_parm.request_row_count
		this.input_parm0[1].offset_row = this.props.input_parm.offset_row
		this.input_parm0[2].request_rows = this.props.input_parm.request_rows
		this.input_parm0[3].id = this.props.input_parm.id
		this.input_parm0[4].name = this.props.input_parm.name
		this.input_parm0[5].costcenter_id = this.props.input_parm.costcenter_id
		this.input_parm0[6].institution = this.props.input_parm.institution
		this.input_parm0[7].quote_id = this.props.input_parm.quote_id
		this.input_parm0[8].status_id = this.props.input_parm.status_id
		this.input_parm0[9].delivery_method = this.props.input_parm.delivery_method
		this.input_parm0[10].email_to = this.props.input_parm.email_to
		this.input_parm0[11].po = this.props.input_parm.po
		this.input_parm0[12].po_required = this.props.input_parm.po_required
		this.input_parm0[13].is_active = this.props.input_parm.is_active
		this.input_parm0[14].is_autobill = this.props.input_parm.is_autobill
		this.input_parm0[15].is_invoice_per_po = this.props.input_parm.is_invoice_per_po

		if(this.props.search_clicked===true&&this.props.search_clicked!==prevProps.search_clicked) {
//			alert("search clicked")
/*
			console.log('BillGroupManageListDataGrid-componentDidUpdate this.props.search_clicked===true');
			let statusCopy = Object.assign({}, this.state)
			statusCopy.data = [];
			this.setState(statusCopy);
			this.gridApi.setRowData(this.state.data)
			this.retrieveData();
*/
			// get total row count
			let sql_id = 42;
			let app_id = 1;
			let gui_id = 30102;
			this.input_parm0[2].request_rows = 999999;
			//			input_parm0 = this.props.input_parm.slice(0,this.props.input_parm.length-1);
            let tmp_data = retrieveApiData(app_id,gui_id,sql_id,"usp_react_sql_retrieve2", JSON.stringify(this.input_parm0));
            Promise.resolve(tmp_data).then((value)=>{
                console.log('componentDidUpdate retrieveData value',value);
                let total_row_count = parseInt(value);
				if(total_row_count>0) {
					this.input_parm0[2].request_rows = this.props.input_parm.request_rows;
					this.SearchRetrieveData(total_row_count);
				}
			})
		}
		if(this.props.prev_clicked===true&&this.props.prev_clicked!==prevProps.prev_clicked) {
			this.PrevRetrieveData();
		}
		if(this.props.next_clicked===true&&this.props.next_clicked!==prevProps.next_clicked) {
			console.log('BillGroupManageListDataGrid componentDidUpdate next_clicked this.props.invoice_retrieved',this.props.invoice_retrieved);
			this.NextRetrieveData();
		}


		if(this.props.retrieve_clicked===true&&this.props.retrieve_clicked!==prevProps.retrieve_clicked) {
			this.retrieveData();
		}		console.log('BillGroupManageListDataGrid-componentDidUpdate current_data_from_detail', this.props.current_data_from_detail);
//		console.log('BillGroupManageListDataGrid-componentDidUpdate this.props.new_record_inserted_or_updated', this.props.new_record_inserted_or_updated);
		let now = new Date();
		let time_diff = now - this.state.current_time;
		console.log('BillGroupManageListDataGrid-componentDidUpdate Date.now, this.state.current_time,time_diff',(new Date()), this.state.current_time,time_diff);
		if(this.props.current_data_from_detail.new_record_inserted_or_updated===true&&time_diff>2000) {
			console.log('BillGroupManageListDataGrid-componentDidUpdate this.props.current_data_from_detail THIS IS THE ONE',this.props.current_data_from_detail);
			console.log('BillGroupManageListDataGrid-componentDidUpdate prevProps.current_data_from_detail THIS IS THE ONE',prevProps.current_data_from_detail);
			let statusCopy = Object.assign({}, this.state)
			if(this.props.current_data_from_detail.i===-1) { // new added data from detail datagrid
				statusCopy.updated_from_detail_allowed = false;
				statusCopy.data.push(this.props.current_data_from_detail.data);
				statusCopy.current_time = new Date();
				this.setState(statusCopy);
				this.gridApi.setRowData(this.state.data);
			} else if(this.props.current_data_from_detail.i<statusCopy.data.length) { //updated from detail datagrid
				statusCopy.updated_from_detail_allowed = false;
				statusCopy.current_time = new Date();
				statusCopy.data[this.props.current_data_from_detail.i] = this.props.current_data_from_detail.data;
				this.setState(statusCopy);
				this.gridApi.setRowData(this.state.data);
			} else {
				console.log("BillGroupManageListDataGrid-componentDidUpdate error")
			}
		}
		console.log('BillGroupManageListDataGrid-componentDidUpdate-this.props.deleting',this.props.deleting);
		// delete the row from grid row
		if(this.props.deleting.deleted===true&&this.props.deleting.post_processed===false) {
			console.log('BillGroupManageListDataGrid-componentDidUpdate-this.props.deleting.i',this.props.deleting.i);
			let statusCopy = Object.assign({}, this.state)
			if(statusCopy.data.length>this.props.deleting.i) {
				statusCopy.data.splice(this.props.deleting.i,1);
				this.setState(statusCopy);
				this.gridApi.setRowData(this.state.data);
				this.props.resetDeleting();
//				alert("list data removed");
				this.gridApi.forEachNode(node => {
					console.log('BillGroupManageListDataGrid-componentDidUpdate-node.rowIndex,this.props.deleting.i',node,node.rowIndex,this.props.deleting.i);
					if(node.rowIndex==this.props.deleting.i) {
						console.log('BillGroupManageListDataGrid-componentDidUpdate- the node selected');
						node.setSelected(true);
					}
				});

			} else {
				console.log("BillGroupManageListDataGrid-componentDidUpdate error incorrect row index")
			}
		}
	}
	setResponse = (data) => {
		let statusCopy = Object.assign({}, this.state)
		statusCopy.data = data;
		this.gridApi.setRowData(this.state.data)
		statusCopy.showModalRetrieve = false;
		if(this.state.retrieve_source===1) {
			this.props.getRetrievedSearch(data.length,statusCopy.total_rows);
		} else if(this.state.retrieve_source===2) {
			this.props.getRetrievedPrev(data.length);
		} else {
			this.props.getRetrievedNext(data.length);
		}
		this.setState(statusCopy);
	}	
	onGridReady = params => {
    	this.gridApi = params.api;
 	};

	 SearchRetrieveData = (total_rows) => {
		console.log('BillGroupManageListDataGrid SearchRetrieveData 0 this.props.input_parm',this.props.input_parm);
		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.rowCountInd = 1;
		let sql_id = 42;
		let app_id = 1;
		console.log('BillGroupManageListDataGrid SearchRetrieveData this.props.input_parm',this.props.input_parm);
		statusCopy0.sql_id = sql_id;
		statusCopy0.showModalRetrieve = true;
		statusCopy0.data = [];
		statusCopy0.input_parm = this.input_parm0;
		if(total_rows<1000)
			statusCopy0.input_parm[2].request_rows = total_rows;
		statusCopy0.retrieve_source = 1
		statusCopy0.total_rows = total_rows;
		statusCopy0.from_row = 1;
		this.setState(statusCopy0);
	}
	PrevRetrieveData = () => {
		let input_parm2 = [
			{"request_row_count":0},
			{"offset_row":this.props.billgroup_retrieved.from_row - 1000},
			{"request_rows":1000},
			{"id":this.props.input_parm.id},
			{"name":this.props.input_parm.name},
			{"costcenter_id":this.props.input_parm.costcenter_id},
			{"institution":this.props.input_parm.institution},
			{"quote_id":this.props.input_parm.quote_id},
			{"status_id":this.props.input_parm.status_id},
			{"delivery_method":this.props.input_parm.delivery_method},
			{"email_to":this.props.input_parm.email_to},
			{"po":this.props.input_parm.po},
			{"po_required":this.props.input_parm.po_required},
			{"is_active":this.props.input_parm.is_active},
			{"is_autobill":this.props.input_parm.is_autobill},
			{"is_invoice_per_po":this.props.input_parm.is_invoice_per_po},
		]
		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.rowCountInd = 0;
		statusCopy0.input_parm = input_parm2;
		console.log('BillGroupManageListDataGrid PrevRetrieveData 0 statusCopy0.input_parm',statusCopy0.input_parm);
		statusCopy0.sql_id = this.props.input_parm.invoice_update_list===0?51:52;;
		statusCopy0.showModalRetrieve = true;
//		statusCopy0.data = [];
		statusCopy0.from_row = this.props.billgroup_retrieved.from_row - 1000;
		statusCopy0.retrieve_source = 2
		this.setState(statusCopy0);
	}
	NextRetrieveData = () => {
		let input_parm3 = [
			{"request_row_count":0},
			{"offset_row":this.props.billgroup_retrieved.to_row + 1},
			{"request_rows":this.props.input_parm.request_rows},
			{"id":this.props.input_parm.id},
			{"name":this.props.input_parm.name},
			{"costcenter_id":this.props.input_parm.costcenter_id},
			{"institution":this.props.input_parm.institution},
			{"quote_id":this.props.input_parm.quote_id},
			{"status_id":this.props.input_parm.status_id},
			{"delivery_method":this.props.input_parm.delivery_method},
			{"email_to":this.props.input_parm.email_to},
			{"po":this.props.input_parm.po},
			{"po_required":this.props.input_parm.po_required},
			{"is_active":this.props.input_parm.is_active},
			{"is_autobill":this.props.input_parm.is_autobill},
			{"is_invoice_per_po":this.props.input_parm.is_invoice_per_po},
		]
		console.log('BillGroupManageListDataGrid NextRetrieveData 0 this.props.input_parm',this.props.input_parm);
		let statusCopy0 = Object.assign({}, this.state)

		statusCopy0.rowCountInd = 0;
		statusCopy0.input_parm = input_parm3;
		console.log('BillGroupManageListDataGrid PrevRetrieveData 0 statusCopy0.input_parm',statusCopy0.input_parm);
		statusCopy0.sql_id = 42;
		statusCopy0.showModalRetrieve = true;
		statusCopy0.from_row = this.props.billgroup_retrieved.to_row + 1;
		statusCopy0.retrieve_source = 3
		this.setState(statusCopy0);
	}



	retrieveData = () => {
		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.showModalRetrieve = true;
		statusCopy0.data = [];
		this.setState(statusCopy0);
		this.gridApi.setRowData(this.state.data)

		let sql_id = 39;
		let app_id = 1;
	//	let input_parm0 = this.props.input_parm;

		let input_parm0 = [
			{"request_row_count":1},
			{"offset_row":0},
			{"request_rows":9999},
			{"id":0},
			{"name":""},
			{"costcenter_id":0},
			{"institution":""},
			{"quote_id":0},
			{"status_id":0},
			{"delivery_method":0},
			{"email_to":""},
			{"po":""},
			{"po_required":-1},
			{"is_active":-1},
			{"is_autobill":-1},
			{"is_invoice_per_po":-1},
		]
		input_parm0[0].request_row_count = this.props.input_parm.request_row_count
		input_parm0[1].offset_row = this.props.input_parm.offset_row
		input_parm0[2].request_rows = this.props.input_parm.request_rows
		input_parm0[3].id = this.props.input_parm.id
		input_parm0[4].name = this.props.input_parm.name
		input_parm0[5].costcenter_id = this.props.input_parm.costcenter_id
		input_parm0[6].institution = this.props.input_parm.institution
		input_parm0[7].quote_id = this.props.input_parm.quote_id
		input_parm0[8].status_id = this.props.input_parm.status_id
		input_parm0[9].delivery_method = this.props.input_parm.delivery_method
		input_parm0[10].email_to = this.props.input_parm.email_to
		input_parm0[11].po = this.props.input_parm.po
		input_parm0[12].po_required = this.props.input_parm.po_required
		input_parm0[13].is_active = this.props.input_parm.is_active
		input_parm0[14].is_autobill = this.props.input_parm.is_autobill
		input_parm0[15].is_invoice_per_po = this.props.input_parm.is_invoice_per_po
		
		if(input_parm0[6].institution==="")
			sql_id = 42;

		statusCopy0.showModalRetrieve = true;		
		statusCopy0.data = [];
		statusCopy0.input_parm = input_parm0;
		this.setState(statusCopy0);
		this.gridApi.setRowData(this.state.data)
	}

	onSelectionChanged = (event)=>{
		const selectedRows =  event.api.getSelectedRows();
		const selectedNodes =  event.api.getSelectedNodes();
		console.log('BillGroupManageListDataGrid the_this.gridApi', the_this.gridApi);
//		console.log('BillGroupManageListDataGrid selectedNodes[0].id', selectedNodes[0].id);
//		the_this.setState({showModalOk:true});
		if(the_this.props.update_mode===0) { // if update_mode==1 the selected row will not be populated to detailed datagrid
			this.setState({updated_from_detail_allowed:true})
			the_this.props.getCurrentDataFromList({i:selectedNodes[0].id, data:selectedNodes[0].data});
			console.log('BillGroupManageListDataGrid selectedNodes[0].data', selectedNodes.rowIndex, selectedNodes[0].data);
		}
	}
	onCellClicked(event){
		console.log("InstitutionGrid onCellClicked event", event);
		the_this.setState({showModalYesNo:true});
	}
	onCellDoubleClicked(obj){
		console.log('InstitutionGrid onCellDoubleClicked(obj)', obj);
	}
	onCellValueChanged(event) {
		console.log('InstitutionGrid onCellValueChanged(event)', event);
	}
	setShowModalOk(a_showModal) {
		this.setState({showModalOk:a_showModal});
	}
	setShowModalYesNo(a_showModal) {
		this.setState({showModalYesNo:a_showModal});
	}
	setResponse(a_response) {
		this.setState({response:a_response});
	}

	onButtonClick(a_text) {
		this.retrieveData()
	}
	/*
	lookupKey(mappings, name){
		//		console.log('BillGroupManageListDataGrid-lookupKey mappings,name', mappings,name);
		for (var key in mappings) {
			if (mappings.hasOwnProperty(key)) {
//			console.log(key + " -> " + mappings[key] + '|' + name);
			if(mappings[key]===name)
				return key
			}
		}
		return 0
	}
	*/
	/*
	lookupValue(mappings, name){
		return mappings[name]
	}	// convert code to value

	makeValueFormatter = params => { 
		return lookupValue(dd_selection, params.value);
	};
	*/

//rowSelection:'multiple',
//rowMultiSelectWithClick:true

//rowSelection='multiple'
	createGridOptions() {
		const options: GridOptions = {      
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true,
				rowMultiSelectWithClick: true,
       			headerCheckboxSelection: true,
				rowDeselection: true,
			},		
			columnDefs: [

				{headerName:"",headerClass:"header-checked",field:"checked",hide:false,width:30,sortable:true,headerCheckboxSelection:true,checkboxSelection:true,editable:true,valueSetter:function (params) {params.data.checked= params.newValue;return true;},suppressNavigable:true,
				headerCheckboxSelection: true,
				headerCheckboxSelectionFilteredOnly: true,
				checkboxSelection: true,
				},
				{
					headerName: "Row",
					valueGetter: function (params) { return params.node.rowIndex + the_this.state.from_row;},
					width:50,
				},
				{headerName:"ID",headerClass:"header-id",field:"id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.id= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Name",headerClass:"header-name",field:"name",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Cost Center",headerClass:"header-costcenter_id",field:"costcenter_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.costcenter_id= params.newValue;return true;},suppressNavigable:false,
				valueFormatter: function (params) {return the_this.dd_select_costcenter[params.value]},},
				{headerName:"Institution",headerClass:"header-institution_id",field:"institution_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.institution_id= params.newValue;return true;},suppressNavigable:false,
				valueFormatter: function (params) {return the_this.dd_select_institution[params.value]},},
				{headerName:"Status",headerClass:"header-status_id",field:"status_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.status_id= params.newValue;return true;},suppressNavigable:false,
				valueFormatter: function (params) {return the_this.dd_select_status[params.value]},},
				{headerName:"Bill Frequency",headerClass:"header-bill_frequency",field:"bill_frequency",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.bill_frequency= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Term",headerClass:"header-term",field:"term",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.term= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Is Autobill",headerClass:"header-is_autobill",field:"is_autobill",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.is_autobill= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Is Active",headerClass:"header-is_active",field:"is_active",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.is_active= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Is Invoice Per PO",headerClass:"header-is_invoice_per_po",field:"is_invoice_per_po",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.is_invoice_per_po= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Current Invoice ID",headerClass:"header-cur_inv_id",field:"cur_inv_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.cur_inv_id= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"PO Required",headerClass:"header-po_required",field:"po_required",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.po_required= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"PO",headerClass:"header-po",field:"po",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.po= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Is Prepaid",headerClass:"header-is_prepaid",field:"is_prepaid",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.is_prepaid= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Review Required",headerClass:"header-review_required",field:"review_required",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.review_required= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Email To",headerClass:"header-email_to",field:"email_to",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.email_to= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Email CC",headerClass:"header-email_cc",field:"email_cc",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.email_cc= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Invoice Email Subject",headerClass:"header-inv_email_subject",field:"inv_email_subject",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.inv_email_subject= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Invoice Email Body",headerClass:"header-inv_email_body",field:"inv_email_body",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.inv_email_body= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Review Email To",headerClass:"header-review_email_to",field:"review_email_to",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.review_email_to= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Bill To Address",headerClass:"header-billto_address_id",field:"billto_address_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.billto_address_id= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Billing Attention",headerClass:"header-billing_attn",field:"billing_attn",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.billing_attn= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Bill To Room",headerClass:"header-billto_room",field:"billto_room",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.billto_room= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Quote ID",headerClass:"header-quote_id",field:"quote_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.quote_id= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Notes",headerClass:"header-notes",field:"notes",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.notes= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Ship To Address",headerClass:"header-shipto_address_id",field:"shipto_address_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.shipto_address_id= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Shipping Attention",headerClass:"header-shipping_attn",field:"shipping_attn",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.shipping_attn= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Ship To Room",headerClass:"header-shipto_room",field:"shipto_room",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.shipto_room= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Delivery Method",headerClass:"header-delivery_method",field:"delivery_method",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.delivery_method= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Prepaid Payment",headerClass:"header-prepaid_payment_id",field:"prepaid_payment_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.prepaid_payment_id= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"CC Online",headerClass:"header-cc_onfile",field:"cc_onfile",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.cc_onfile= params.newValue;return true;},suppressNavigable:false,},
			],
		}
		return options;
	}
	render() {
		return  (
			<div id="pid_0630_cell_001" style={{position:"absolute",top:0,width:1450,height:300,backgroundColor:"#FFFFFF"}}>
				<div id="pid_0630_cell_001_001" className="ag-theme-balham" 
                    style={{position:"absolute",top:0,left:0,width:1450,height:300,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={this.state.rowSelection} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
				{this.state.showModalSelf?
					<ResponseWindowSelf
						title={"Data Is Loading . . ."} 
					/> :null}
				{this.state.showModalYesNo?
					<ResponseWindowYesNo2
						title={"Current updated record will be overridden, continue?"} 
						setShowModal={(showModel)=>this.setShowModal(showModel)} 
						setResponse={(response)=>this.setResponse(response)}
					/> :null}
				{this.state.showModalRetrieve?
					<ModalRetrieve
						rowCountInd={this.state.rowCountInd}
						appId={1}
						guiId={30302}
						sqlId={42}
						inputParm={this.state.input_parm}
						setResponse={(response)=>this.setResponse(response)}
					/> :null}
			</div>
		)
	}
}

/*
				{this.showModal ? 
					<ResponseWindowYesNo2
						title={"Current updated record will be overridden, continue?"} 
						setShowModal={(showModel)=>this.setShowModal(showModel)} 
						setResponse={(response)=>this.setResponse(response)}
					/> : null}

*/