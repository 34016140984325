import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useNavigate
  }
from "react-router-dom";

import "./../../styles.css";

//import '@progress/kendo-theme-default/dist/all.css';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Navbar from 'react-bootstrap/Navbar';
import './../../v2/css/bootstrap.min.css';

import WebSiteHomeDefaultNavigator from "../../WebSiteHome_default_navigator"
import WebSiteHomeDefaultFooter from "../../WebSiteHome_default_footer"

/*
import NGSred from "./v2/img/NGS-red.png"
import Sangerred from "./v2/img/Sanger-red.png"
import Cloningred from "./v2/img/Cloning-red.png"
import Oligored from "./v2/img/Oligo-red.png"
import DNAprepred from "./v2/img/DNAprep-red.png"
*/

//const components = PanelBarUtils.mapItemsToComponents(items);
const PublicPageServiceDnaSequencing = ()=> {
    const navigate = useNavigate();
  
    const handleClick = (path) => {
        navigate(path);
    }
    return (
        <>
             <WebSiteHomeDefaultNavigator />
            <div id="main">
                <div class="shadow"></div>
                <div class="wrapper">
                <div class="center">
                    <div id="coda-nav-1" class="coda-nav">
                        <ul id="submenu">
                            <li><a href="#1">DNA Sequencing</a></li>
                            <li><a href="#2">Sample Submission</a></li>
                            <li><a href="#3">Free Primers</a></li>
                            <li><a href="#4">Specialized Services</a></li>
                            <li><a href="/contact">Sample Pickup Schedule</a></li>
                            <li><a href="/user/myqb">Order</a></li>
                        </ul>
                    </div>
                <div class="thirds">
                    <div class="wide">
                        <div class="coda-slider-wrapper">
                            <div class="coda-slider preload" id="coda-slider-1">
                                <div id="dna_sequencing" class="panel">
                                    <font color="#3B7092"><h2>DNA Sequencing</h2></font>
            <div id="wrap">
                <div id="left_col">
            <p>QuintaraBio has been providing the highest quality Sanger DNA Sequencing services to the life science research community since 2005. Our mission as the leading service provider is to ensure excellent customer service, fast turnaround time, advanced technical support and offer the best value to our customers. 
            <br /><br />
            SimpliSeq<sup>TM</sup> DNA Sequencing is the most commonly requested service, for plasmid DNA, PCR fragment, phage DNA, with primer premixed or unmixed.
            <br /><br />
            DirectSeq<sup>TM</sup> Colony Sequencing is the best one-stop service for customers that prefer to minimize the risk of low copy number or purification quality. Just send us your bacteria colonies on agar plates or in liquid culture, and expect your data delivered to your email account within 24 hours.
            <br /><br />
            QuintSeq<sup>TM</sup> On-demand Sequencing is our premium service designed to expedite the process and shorten your research cycle. After sequencing the bacterial colonies and delivering the data, we keep your colonies and plasmid for one month. During this period, we’ll send back the colony or purified plasmid based on your request without additional charge.
            </p>
                                    </div>
                            <div id="right_col">
                                    <h4>Benefits</h4>
                                    <ul>
                                        <li>The most flexible sequencing service with the highest success rate.</li>
                                        <li>Skilled analysis of your sequencing results.</li>
                                        <li>Expert troubleshooting to optimize more complex samples (hairpin, GC-rich templates).</li>
                                        <li>Additional services (template amplification, PCR purification) are included in the service package.</li>
                                        <li>Optional sequence editing and sequence alignment are available free of charge.</li>
                                    </ul>
                                    <h4>Quality</h4>
                                    <ul>
                                        <li>Average read lengths: 700-1000 bp</li>
                                        <li>Color printouts of chromatographs are provided free of charge upon request.</li>
                                        <li>Optional sequence alignment saves time when analyzing sequencing results.</li>
                                        <li>Sequence editing is provided upon request. (Sequence editing means a manual proofread performed by an experienced staff to correct mistakes /uncertainties in sequences generated by the automatic base caller)</li>
                                        <li>Troubleshooting: We propose further troubleshooting steps based on the sequencing results. It is your decision whether we continue sequencing your samples.</li>
                                        <li>Repeat policy: Failed samples will be repeated based on our interpretation of your sequencing results. The repeat is free of charge.</li>
                                    </ul></div>
                                </div>
            <div class="wide">
            <table id="prices">
            <tr>
                <th>Service Name</th> 
                <th>Price/rxn <br />(rxn 1-47)*</th> 
                <th>Price/rxn (<br />rxn 48-96)</th> 
                <th>Price/rxn <br />(rxn &gt;96)</th> 
                <th style={{width:110}}>Turnaround Time**</th> 
                <th>Deliverable</th>
            </tr>
            <tr>
                <td>SimpliSeq<sup>™</sup> DNA Sequencing</td>
                <td>$6</td>
                <td><a href="/contact">Contact for info</a></td>
                <td><a href="/contact">Contact for info</a></td>
                <td>12 Hours</td>
                <td>Analyzed data</td>
            </tr>
            <tr>
                <td>Q-MaxSeq<sup>™</sup> DNA Sequencing***</td>
                <td>$4</td>
                <td><a href="/contact">Contact for info</a></td>
                <td><a href="/contact">Contact for info</a></td>
                <td>12 Hours</td>
                <td>Analyzed data</td>
            </tr>
            <tr>
                <td>DirectSeq<sup>™</sup> Colony Sequencing</td>
                <td>$9</td>
                <td><a href="/contact">Contact for info</a></td>
                <td><a href="/contact">Contact for info</a></td>
                <td>24 Hours</td>
                <td>Analyzed data</td>
            </tr>
            <tr>
                <td>QuintSeq<sup>™</sup> On-Demand Sequencing</td>
                <td>$12</td>
                <td><a href="/contact">Contact for info</a></td>
                <td><a href="/contact">Contact for info</a></td>
                <td>24 Hours</td>
                <td>Data and plasmid</td>
            </tr>
            <tr>
                <td>PCR Cleanup</td>
                <td>$3</td>
                <td><a href="/contact">Contact for info</a></td>
                <td><a href="/contact">Contact for info</a></td>
                <td>1 Hour</td>
                <td>Cleaned sample</td>
            </tr>
            <tr>
                <td>Rolling Circle Amplification</td>
                <td>$4</td>
                <td><a href="/contact">Contact for info</a></td>
                <td><a href="/contact">Contact for info</a></td>
                <td>12 Hours</td>
                <td>Amplified Sample</td>
            </tr>
            <tr>
                <td>Plasmid Miniprep</td>
                <td>$9</td>
                <td><a href="/contact">Contact for info</a></td>
                <td><a href="/contact">Contact for info</a></td>
                <td>24 Hours</td>
                <td>Purified Plasmid</td>
            </tr>
            <tr>
                <td>Primer Walking <br />Complete Plasmid Sequencing</td>
                <td>$0.10/bp</td>
                <td><a href="/contact">Contact for info</a></td>
                <td><a href="/contact">Contact for info</a></td>
                <td>3-5 days</td>
                <td>Analyzed Data</td>
            </tr>
            </table>
            <p>*For regional/promotion/academic discounted price, please contact the regional sales rep click <a href="/contact">here</a>.</p>
            <p>**Estimated turnaround time by sample submission before the cutoff time in different territories. Please contact the regional sales rep for actual pickup schedule click <a href="/contact">here</a>.</p>
            <p>*** Prepaid 96 reactions each order, flexible sample submission quantity afterwards</p>
            </div>
            </div>
            <div id="sample_preparation" class="panel">
            <font color="#3B7092"><h2>Sample Submission Instructions</h2></font>
            <div id="wrap">
                <div id="left_col">
            <font color="#61B64A" size="3"><h4><i>Sample Requirements</i></h4></font>
            <br />
            <b>Plasmid Samples</b>
            <br />
            <ul>
                <li>Plasmid DNA purified by commercially available kits</li>
                <li>Low copy number plasmids need to be further concentrated by spin column, ETOH precipitation, or RCA before sequencing</li>
                <li>Check quality and concentration of plasmid by gel electrophoresis and A260/A280</li>
            </ul>
            <b>PCR Samples</b>
            <br />
            <ul>
                <li>PCR products need to be purified to eliminate primers and dNTPs</li>
                <li>Check quality and concentration after purification by gel electrophoresis (2ul) confirming the presence of one specific band</li>
                <li>For unpurified PCR samples, please mark as  “unpurified PCR”</li>
            </ul>
            <b>E. coli colonies</b>
            <br />
            <ul>
                <li>Colonies need to grow for at least 16 hours at 37°C to reach a good visible size</li>
                <li>Place all samples in either tightly sealed 8-strip tubes or strip capped 96-well plate</li>
            </ul>
            </div>
            <div id="right_col">
            <font color="#61B64A" size="3"><h4><i>Sample Submission Guide</i></h4></font>
            <br />
            <table border="1">
                <tr>
                <td width="20%"><b>Step 1</b></td>
                <td width="80%">Visit www.quintarabio.com to <a href="/user/register">register</a> for an account (PO: purchase order of your lab; if you will pay your bill by credit card, just write CC).</td>
                </tr>
                <tr>
                <td width="20%"><b>Step 2</b></td>
                <td width="80%">Log in to your Quintara account. Select “Online Form” and fill in sample information, print the order receipt upon submission.</td>
                </tr>
                <tr>
                <td width="20%"><b>Step 3</b></td>
                <td width="80%">Prepare samples according to the instructions on this page and view our free universal primers <a href="/public/assets/Quintara_basic_primerList.xls">here</a>; if you are using one of our universal primers you do not need to add primer to your samples.</td>
                </tr>
                <tr>
                <td width="20%"><b>Step 4</b></td>
                <td width="80%">Place your samples and order receipt in the nearest Quintara Drop Box. Exact pickup time varies depending on location (contact your local representative). </td>
                </tr>
            </table>
            </div>
            </div>
            <div class="wide">
                <h2>Selection Guide for Seq-A-Strip DNA Sequencing Service</h2>
                <p>Please submit sequencing samples in 8-strip PCR Tubes; if sending primer separately, send in a 1.5ml tube.</p>
                <br />
                <div id="wrap">
                <div id="left_col">
                        <b><i><font color="#61B64A" size="3">Plasmid/Purified PCR</font></i></b>
                        <br />
                        <br />
                        <font size="3"><b>Premixed</b></font>
                        <br />
                        <br />
                        <b>One set of 8-strip PCR tube:</b>
                        <br />
                        <br />
                        <img src="/assets/images/PCR_Strip.png" />
                        <br />
                        Mixed in the same tube: Template + Primer
                        <br /><br />
                        <img src="/assets/images/Premixed_Sample.png" width="90%" height="90%" />
            <br />
            <br />
                        <font size="3"><b>Premixed for success</b></font>
                        <br />
                        <i>Advantages of premixed samples:</i>
                        <br />
                        <ul>
                            <li>Track samples easily</li>
                            <li>Get results faster</li>
                            <li>Enjoy low cost per reaction</li>
                            <li>Improve success rate significantly</li>
                        </ul>
            </div>
                <div id="right_col">
                        <b><i><font color="#61B64A" size="3">Unpurified PCR</font></i></b>
                        <br />
                        <br />
                        <font size="3"><b>Unmixed</b></font>
                        <br />
                        <br />
                        <b>Two sets of tubes:</b>
                        <br />
                        <br />
                        <img src="/assets/images/Strip_and_Primer.png" />
                        <br />
                        <b>Tube 1:</b> Template     <b>Tube 2:</b> Primer
                        <br /><br />
                        Primer (5 pmol/μl or 5 μM), 10 μl. Or, use our free primer library. <br />
                        <br />
                                <img src="/assets/images/Unmixed_Sample.png" width="90%" height="90%" />
            <br />
            <b>Primer For Sequencing:</b> <br />
            Prefer Tm: 50-60C, GC content: 40-60%
            </div>
            </div>
            </div>
            <div class="wide">
                <h2>How to Label Sample Tubes</h2>
                <div id="wrap">
                    <div id="left_col">
                    <p>Label the samples with your initials followed by numbers</p>
                    <br />
                    <img src="/assets/images/label_samples.png" />
                    <br />
                    8-strip PCR tube for samples containing template
                    <br />
                    <font color="#61B64A"><b>Mark</b></font> the first tube near the bottom of the tube
                    </div>
                    <div id="right_col">
                    <b>Example:</b> Samples from John Smith
                    <br />
                    <img src="/assets/images/label_tube.png" />
                    <br />
                    Label on the cap as well as on the side of the tube
                    </div>
                </div>
            </div>
                    <h2>E. Coli Colonies</h2>
                    <b>Pick a colony and resuspend in diH<sub>2</sub>O or Tris Buffer</b>
                    <br /><br />
                    <b>Two sets of tubes:</b>
                    <br />
                    <img src="/assets/images/Strip_and_Primer.png" />
                    <br />
                    <b>Tube 1:</b> Colony Suspension     <b>Tube 2:</b> Primer
                    <br /><br />
                    <ul>
                    <li>Colonies need to grow for at least 16hrs at 37°C to reach good visible size</li>
                    <li>Pick a single colony with sterile tip and resuspend in 30ul sterile  diH<sub>2</sub>O or Tris buffer (10mM, ph8.0), 15 ul will be submitted to us, and the rest 15 ul will be kept by clients for future uses</li>
                    <li>Prepare 2 separate tubes: 1 contains 15ul colony suspension, 1 contains 5ul primer at 5pmol/ul</li>
                    </ul>
                    <h2>Plasmid Walking</h2>
                    <ul>
                    <li>10ug plasmid DNA</li>
                    <li>Vector information if whole plasmid sequencing is to be done </li>
                    <li>10ul vector primers for first round of sequencing at 5uM </li>
                    <li>Reference or expected sequence if any </li>
                    </ul>
            </div>
                                <div id="free_primers" class="panel">
                                    <h2>Free Primers</h2>
                                    <h3>Available Common Primers <a href="/assets/Quintara_basic_primerList.xls"> (Complete List in Excel)</a> ||  <a href="/qbtools/findPrimer">Search Online </a></h3>
                                    <table id="primers">
                                        <tr>
                                            <th>Name</th> <th>Description </th> <th>Sequence</th> </tr>
                                        <tr>
                                            <td> 3'AOX1 </td>
                                            <td> For Pichia vectors with AOX1 terminator, reverse primer </td>
                                            <td>GCAAATGGCATTCTGACATCC</td>
                                        </tr>
                                        <tr>
                                            <td> 5'AOX1 </td>
                                            <td> For Pichia vectors with AOX1 promoter, forward primer </td>
                                            <td>GACTGGTCCAATTGACAAGC </td>
                                        </tr>
                                        <tr>
                                            <td> Alpha-factor </td>
                                            <td>Alpha factor signal sequence, forward primer</td>
                                            <td>TACTATTGCCAGCATTGCTGC </td>
                                        </tr>
                                        <tr>
                                            <td>Amp-R</td>
                                            <td>5' end of ampiciltrn resistance gene, reverse primer</td>
                                            <td>ATAATACCGCGCCACATAGC </td>
                                        </tr>
                                        <tr>
                                            <td> CAT-R </td>
                                            <td>5' end of chloramphenicol resistance gene, reverse primer</td>
                                            <td>GCAACTGACTGAAATGCCTC </td>
                                        </tr>
                                        <tr>
                                            <td> CMV Forward </td>
                                            <td> Human CMV immediate early promoter, forward primer</td>
                                            <td> CGCAAATGGGCGGTAGGCGTG</td>
                                        </tr>
                                        <tr>
                                            <td> CRE-R </td>
                                            <td> 5' end of Cre recombinase, reverse primer</td>
                                            <td> GCAAACGGACAGAAGCATTT</td>
                                        </tr>
                                        <tr>
                                            <td> EF-1a Forward </td>
                                            <td> Human elongation factor-1a promoter, forward primer</td>
                                            <td> TCAAGCCTCAGACAGTGGTTC </td>
                                        </tr>
                                        <tr>
                                            <td> GAL1 </td>
                                            <td>S. cerevisiae GAL1 promoter, forward primer</td>
                                            <td> AATATACCTCTATACTTTAACGTC</td>
                                        </tr>
                                        <tr>
                                            <td> Gal10pro-F </td>
                                            <td>S. cerevisiae GAL10 promoter, forward primer</td>
                                            <td>GGTGGTAATGCCATGTAATATG</td>
                                        </tr>
                                        <tr>
                                            <td> Gal4 N-term </td>
                                            <td>3' end of Gal4 DNA binding domain, forward primer</td>
                                            <td>GAGTAGTAACAAAGGTCAA</td>
                                        </tr>
                                        <tr>
                                            <td> Gal4-AD </td>
                                            <td>3' end of Gal4 activation domain, forward primer</td>
                                            <td>AATACCACTACAATGGAT </td>
                                        </tr>
                                        <tr>
                                            <td> GFP-F </td>
                                            <td>3' end of GFP, forward primer </td>
                                            <td>GGTCCTTCTTGAGTTTGTAAC</td>
                                        </tr>
                                        <tr>
                                            <td> GFP-R </td>
                                            <td>5' end of GFP, reverse primer</td>
                                            <td>CCATCTAATTCAACAAGAATTGGGACAAC </td>
                                        </tr>
                                        <tr>
                                            <td> IRES-F </td>
                                            <td>3' end of IRES, forward primer</td>
                                            <td> TGGCTCTCCTCAAGCGTATT</td>
                                        </tr>
                                        <tr>
                                            <td> IRES-R </td>
                                            <td>5' end of IRES, reverse primer</td>
                                            <td> CCTCACATTGCCAAAAGACG</td>
                                        </tr>
                                        <tr>
                                            <td> LacI-R </td>
                                            <td>5' end of LacI, reverse primer</td>
                                            <td> GGCATACTCTGCGACATCGT</td>
                                        </tr>
                                        <tr>
                                            <td> LacZ-R </td>
                                            <td>5' end of LacZ, reverse primer</td>
                                            <td> GACAGTATCGGCCTCAGGAA</td>
                                        </tr>
                                        <tr>
                                            <td> M13 (-21) Forward </td>
                                            <td>In lacZ gene</td>
                                            <td> TGTAAAACGACGGCCAGT</td>
                                        </tr>
                                        <tr>
                                            <td> M13 (-40) </td>
                                            <td>In lacZ gene</td>
                                            <td> GTTTTCCCAGTCACGAC</td>
                                        </tr>
                                        <tr>
                                            <td> M13 Reverse </td>
                                            <td>In lacZ gene</td>
                                            <td> CAGGAAACAGCTATGAC</td>
                                        </tr>
                                        <tr>
                                            <td> M13/pUC Forward </td>
                                            <td>In lacZ gene</td>
                                            <td> CCCAGTCACGACGTTGTAAAACG </td>
                                        </tr>
                                        <tr>
                                            <td> M13/pUC Reverse </td>
                                            <td>In lacZ gene</td>
                                            <td> AGCGGATAACAATTTCACACAGG </td>
                                        </tr>
                                        <tr>
                                            <td> pBAD Forward </td>
                                            <td>For vectors with E. cotr araBAD promoter, forward primer</td>
                                            <td> ATGCCATAGCATTTTTATCC </td>
                                        </tr>
                                        <tr>
                                            <td> pBAD Reverse </td>
                                            <td>For vectors with E. cotr araBAD promoter, reverse primer</td>
                                            <td> GATTTAATCTGTATCAGG </td>
                                        </tr>
                                        <tr>
                                            <td> T3 </td>
                                            <td>T3 promoter, forward primer</td>
                                            <td> GCAATTAACCCTCACTAAAGG</td>
                                        </tr>
                                        <tr>
                                            <td> T7 </td>
                                            <td>T7 promoter, forward primer</td>
                                            <td> TAATACGACTCACTATAGGG</td>
                                        </tr>
                                        <tr>
                                            <td> T7 Terminal </td>
                                            <td>T7 terminator, reverse primer </td>
                                            <td> GCTAGTTATTGCTCAGCGG </td>
                                        </tr>
                                    </table>
                                </div>
                <div id="special_services" class="panel">
                <h2>Specialized Services</h2>
                    <div class="round">
                        <div id="wrap">
                        <div id="left_col">
                    <img src="/assets/images/RCA.png" /> RCA <br /><br />
                    <img src="/assets/images/walking.png" /> <a href="/service/primer_walking">Primer Walking</a><br /><br />
                    <img src="/assets/images/ampseq.png" /> <a href="/service/amplicon_sequencing">Amplicon Sequencing</a><br /><br />
                    <img src="/assets/images/miniprep.png" /> Plasmid DNA Miniprep <br /><br />
                    <img src="/assets/images/pcrpur.png" /> PCR Purification <br /><br />
                        </div>
                        <div id="right_col">
                    <img src="/assets/images/fragment.png" /> Fragment Analysis <br /><br />
                    <img src="/assets/images/mutation.png" /> Mutation Detection <br /><br />
                    <img src="/assets/images/unknown.png" /> Unknown Plasmid Sequencing <br /><br />
                    <img src="/assets/images/maxiprep.png" /> <a href="/service/dna_maxiprep">Plasmid DNA Maxiprep</a> <br /><br />
                        </div>
                    </div>
                    </div>
                </div>
                            <div id="order" class="panel">
                                <h2>Order</h2>
                                <h4>Redirecting</h4>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>


        </div>

        <hr />
			<WebSiteHomeDefaultFooter />


</>
   )
}


function WebSiteServices() {return (<div>WebSiteServices</div>)}

export default PublicPageServiceDnaSequencing;



/*----------------------------------------------------------------
            <div id="header">
                <a href="/"><image src="/assets/images/logotransparent.png" title="Quintara Bio"></image></a>
                <div style={{paddingRight: 40}}>
                <p>
                    <b>Billing</b> <br />
                    1-510-990-2168 <br />
                    accounting@quintarabio.com
                </p>
                <p>
                    <b>D.C. Area</b> <br />
                    1-301-761-4835 <br />
                    dc@quintarabio.com
                </p>
                <p>
                    <b>Boston Area</b> <br />
                    1-617-943-2768 <br />
                    boston@quintarabio.com
                </p>
                <p>
                    <b>San Francisco Bay Area</b> <br />
                    phone: 1-415-738-2509<br />
                    info@quintarabio.com
                </p>
                </div>
            </div>
            <div id="nav">
                <nav>
                    <ul>
                        <li><a href="/services"><b>Services</b></a>
                            <ul>
                                <li><a href="/service/genefragment">Q-Block Gene Fragment</a></li>
                                <li><a href="/service/oligo">Oligo Synthesis</a></li>
                                <li><a href="/service/dna_sequencing">DNA Sequencing</a>
                                    <ul>
                                        <li><a href="/service/dna_sequencing">Basic Sequencing</a></li>
                                        <li><a href="#">Bacteria Sequencing</a></li>
                                        <li><a href="/service/amplicon_sequencing">Amplicon Sequencing</a></li>
                                        <li><a href="/service/primer_walking">Primer Walking</a></li>
                                    </ul>
                                </li>
                                <li><a href="/service/ngs_services">NGS Services</a>
                                <ul>
                                    <li><a href="/service/ngs_dnaseq">DNA Sequencing</a></li>
                                    <li><a href="/service/ngs_rnaseq">RNA Sequencing</a></li>
                                    <li><a href="/service/ngs_cellseq">Single Cell Sequencing</a></li>
                                </ul>
                                </li>
                                <li><a href="/service/ab_sequencing">Ab Sequencing</a></li>
                                <li><a href="/service/dna_maxiprep">Plasmid DNA Maxiprep</a></li>
                                <li><a href="/service/molecular_cloning">Molecular Cloning</a></li>
                                <li><a href="/service/dna_assembly">DNA Assembly</a></li>
                            </ul>
                        </li>
                        <li><a href="#"><b>Products</b></a>
                            <ul>
                                <li><a href="/product/q_maxseq">Q-Max Seq</a></li>
                                <li><a href="/product/seqcard">SeqCard</a></li>
                                <li><a href="/service/oligo">Custom Oligo Synthsis</a></li>
                            </ul>
                        </li>
                        <li><a href="/support"><b>Tools</b></a>
                            <ul>
                                <li><a href="/support#1">Software</a></li>
                                <li><a href="/support#2">FAQ</a></li>
                                <li><a href="/support#3">Primer List</a></li>
                                <li><a href="/qbtools/findPrimer">Search for QB Primer</a></li>
                            </ul>
                        </li>
                        <li><a href="/user/myqb"><b>Order</b></a></li>
                        <li><a href="/press"><b>News</b></a></li>
                        <li><a href="#"><b>About Us</b></a>
                            <ul>
                                <li><a href="/about">About Us</a></li>
                                <li><a href="/career">Careers</a></li>
                                <li><a href="/contact">Contact and Location</a></li>
                                <li><a href="/pickup">Sample Pickup Schedule</a></li>
                                <li><a href="/user/request_info">Request Information</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="/login"><b>Login</b></a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div id="main">
                <div class="shadow"></div>
                <div class="wrapper">
            <div class="center">
                <div id="coda-nav-1" class="coda-nav">
                    <ul id="submenu">
                        <li><a href="#1">DNA Sequencing</a></li>
                        <li><a href="#2">Sample Submission</a></li>
                        <li><a href="#3">Free Primers</a></li>
                        <li><a href="#4">Specialized Services</a></li>
                        <li><a href="/contact">Sample Pickup Schedule</a></li>
                        <li><a href="/user/myqb">Order</a></li>
                    </ul>
                </div>
                <div class="thirds">
                    <div class="wide">
                        <div class="coda-slider-wrapper">
                            <div class="coda-slider preload" id="coda-slider-1">
                                <div id="dna_sequencing" class="panel">
                                    <font color="#3B7092"><h2>DNA Sequencing</h2></font>
            <div id="wrap">
                <div id="left_col">
            <p>QuintaraBio has been providing the highest quality Sanger DNA Sequencing services to the life science research community since 2005. Our mission as the leading service provider is to ensure excellent customer service, fast turnaround time, advanced technical support and offer the best value to our customers. 
            <br /><br />
            SimpliSeq<sup>TM</sup> DNA Sequencing is the most commonly requested service, for plasmid DNA, PCR fragment, phage DNA, with primer premixed or unmixed.
            <br /><br />
            DirectSeq<sup>TM</sup> Colony Sequencing is the best one-stop service for customers that prefer to minimize the risk of low copy number or purification quality. Just send us your bacteria colonies on agar plates or in liquid culture, and expect your data delivered to your email account within 24 hours.
            <br /><br />
            QuintSeq<sup>TM</sup> On-demand Sequencing is our premium service designed to expedite the process and shorten your research cycle. After sequencing the bacterial colonies and delivering the data, we keep your colonies and plasmid for one month. During this period, we’ll send back the colony or purified plasmid based on your request without additional charge.
            </p>
                                    </div>
                            <div id="right_col">
                                    <h4>Benefits</h4>
                                    <ul>
                                        <li>The most flexible sequencing service with the highest success rate.</li>
                                        <li>Skilled analysis of your sequencing results.</li>
                                        <li>Expert troubleshooting to optimize more complex samples (hairpin, GC-rich templates).</li>
                                        <li>Additional services (template amplification, PCR purification) are included in the service package.</li>
                                        <li>Optional sequence editing and sequence alignment are available free of charge.</li>
                                    </ul>
                                    <h4>Quality</h4>
                                    <ul>
                                        <li>Average read lengths: 700-1000 bp</li>
                                        <li>Color printouts of chromatographs are provided free of charge upon request.</li>
                                        <li>Optional sequence alignment saves time when analyzing sequencing results.</li>
                                        <li>Sequence editing is provided upon request. (Sequence editing means a manual proofread performed by an experienced staff to correct mistakes /uncertainties in sequences generated by the automatic base caller)</li>
                                        <li>Troubleshooting: We propose further troubleshooting steps based on the sequencing results. It is your decision whether we continue sequencing your samples.</li>
                                        <li>Repeat policy: Failed samples will be repeated based on our interpretation of your sequencing results. The repeat is free of charge.</li>
                                    </ul></div>
                                </div>
            <div class="wide">
            <table id="prices">
            <tr>
                <th>Service Name</th> 
                <th>Price/rxn <br />(rxn 1-47)*</th> 
                <th>Price/rxn (<br />rxn 48-96)</th> 
                <th>Price/rxn <br />(rxn &gt;96)</th> 
                <th style={{width:110}}>Turnaround Time**</th> 
                <th>Deliverable</th>
            </tr>
            <tr>
                <td>SimpliSeq<sup>™</sup> DNA Sequencing</td>
                <td>$6</td>
                <td><a href="/contact">Contact for info</a></td>
                <td><a href="/contact">Contact for info</a></td>
                <td>12 Hours</td>
                <td>Analyzed data</td>
            </tr>
            <tr>
                <td>Q-MaxSeq<sup>™</sup> DNA Sequencing***</td>
                <td>$4</td>
                <td><a href="/contact">Contact for info</a></td>
                <td><a href="/contact">Contact for info</a></td>
                <td>12 Hours</td>
                <td>Analyzed data</td>
            </tr>
            <tr>
                <td>DirectSeq<sup>™</sup> Colony Sequencing</td>
                <td>$9</td>
                <td><a href="/contact">Contact for info</a></td>
                <td><a href="/contact">Contact for info</a></td>
                <td>24 Hours</td>
                <td>Analyzed data</td>
            </tr>
            <tr>
                <td>QuintSeq<sup>™</sup> On-Demand Sequencing</td>
                <td>$12</td>
                <td><a href="/contact">Contact for info</a></td>
                <td><a href="/contact">Contact for info</a></td>
                <td>24 Hours</td>
                <td>Data and plasmid</td>
            </tr>
            <tr>
                <td>PCR Cleanup</td>
                <td>$3</td>
                <td><a href="/contact">Contact for info</a></td>
                <td><a href="/contact">Contact for info</a></td>
                <td>1 Hour</td>
                <td>Cleaned sample</td>
            </tr>
            <tr>
                <td>Rolling Circle Amplification</td>
                <td>$4</td>
                <td><a href="/contact">Contact for info</a></td>
                <td><a href="/contact">Contact for info</a></td>
                <td>12 Hours</td>
                <td>Amplified Sample</td>
            </tr>
            <tr>
                <td>Plasmid Miniprep</td>
                <td>$9</td>
                <td><a href="/contact">Contact for info</a></td>
                <td><a href="/contact">Contact for info</a></td>
                <td>24 Hours</td>
                <td>Purified Plasmid</td>
            </tr>
            <tr>
                <td>Primer Walking <br />Complete Plasmid Sequencing</td>
                <td>$0.10/bp</td>
                <td><a href="/contact">Contact for info</a></td>
                <td><a href="/contact">Contact for info</a></td>
                <td>3-5 days</td>
                <td>Analyzed Data</td>
            </tr>
            </table>
            <p>*For regional/promotion/academic discounted price, please contact the regional sales rep click <a href="/contact">here</a>.</p>
            <p>**Estimated turnaround time by sample submission before the cutoff time in different territories. Please contact the regional sales rep for actual pickup schedule click <a href="/contact">here</a>.</p>
            <p>*** Prepaid 96 reactions each order, flexible sample submission quantity afterwards</p>
            </div>
            </div>
            <div id="sample_preparation" class="panel">
            <font color="#3B7092"><h2>Sample Submission Instructions</h2></font>
            <div id="wrap">
                <div id="left_col">
            <font color="#61B64A" size="3"><h4><i>Sample Requirements</i></h4></font>
            <br />
            <b>Plasmid Samples</b>
            <br />
            <ul>
                <li>Plasmid DNA purified by commercially available kits</li>
                <li>Low copy number plasmids need to be further concentrated by spin column, ETOH precipitation, or RCA before sequencing</li>
                <li>Check quality and concentration of plasmid by gel electrophoresis and A260/A280</li>
            </ul>
            <b>PCR Samples</b>
            <br />
            <ul>
                <li>PCR products need to be purified to eliminate primers and dNTPs</li>
                <li>Check quality and concentration after purification by gel electrophoresis (2ul) confirming the presence of one specific band</li>
                <li>For unpurified PCR samples, please mark as  “unpurified PCR”</li>
            </ul>
            <b>E. coli colonies</b>
            <br />
            <ul>
                <li>Colonies need to grow for at least 16 hours at 37°C to reach a good visible size</li>
                <li>Place all samples in either tightly sealed 8-strip tubes or strip capped 96-well plate</li>
            </ul>
            </div>
            <div id="right_col">
            <font color="#61B64A" size="3"><h4><i>Sample Submission Guide</i></h4></font>
            <br />
            <table border="1">
                <tr>
                <td width="20%"><b>Step 1</b></td>
                <td width="80%">Visit www.quintarabio.com to <a href="/user/register">register</a> for an account (PO: purchase order of your lab; if you will pay your bill by credit card, just write CC).</td>
                </tr>
                <tr>
                <td width="20%"><b>Step 2</b></td>
                <td width="80%">Log in to your Quintara account. Select “Online Form” and fill in sample information, print the order receipt upon submission.</td>
                </tr>
                <tr>
                <td width="20%"><b>Step 3</b></td>
                <td width="80%">Prepare samples according to the instructions on this page and view our free universal primers <a href="/public/assets/Quintara_basic_primerList.xls">here</a>; if you are using one of our universal primers you do not need to add primer to your samples.</td>
                </tr>
                <tr>
                <td width="20%"><b>Step 4</b></td>
                <td width="80%">Place your samples and order receipt in the nearest Quintara Drop Box. Exact pickup time varies depending on location (contact your local representative). </td>
                </tr>
            </table>
            </div>
            </div>
            <div class="wide">
                <h2>Selection Guide for Seq-A-Strip DNA Sequencing Service</h2>
                <p>Please submit sequencing samples in 8-strip PCR Tubes; if sending primer separately, send in a 1.5ml tube.</p>
                <br />
                <div id="wrap">
                <div id="left_col">
                        <b><i><font color="#61B64A" size="3">Plasmid/Purified PCR</font></i></b>
                        <br />
                        <br />
                        <font size="3"><b>Premixed</b></font>
                        <br />
                        <br />
                        <b>One set of 8-strip PCR tube:</b>
                        <br />
                        <br />
                        <img src="/assets/images/PCR_Strip.png" />
                        <br />
                        Mixed in the same tube: Template + Primer
                        <br /><br />
                        <img src="/assets/images/Premixed_Sample.png" width="90%" height="90%" />
            <br />
            <br />
                        <font size="3"><b>Premixed for success</b></font>
                        <br />
                        <i>Advantages of premixed samples:</i>
                        <br />
                        <ul>
                            <li>Track samples easily</li>
                            <li>Get results faster</li>
                            <li>Enjoy low cost per reaction</li>
                            <li>Improve success rate significantly</li>
                        </ul>
            </div>
                <div id="right_col">
                        <b><i><font color="#61B64A" size="3">Unpurified PCR</font></i></b>
                        <br />
                        <br />
                        <font size="3"><b>Unmixed</b></font>
                        <br />
                        <br />
                        <b>Two sets of tubes:</b>
                        <br />
                        <br />
                        <img src="/assets/images/Strip_and_Primer.png" />
                        <br />
                        <b>Tube 1:</b> Template     <b>Tube 2:</b> Primer
                        <br /><br />
                        Primer (5 pmol/μl or 5 μM), 10 μl. Or, use our free primer library. <br />
                        <br />
                                <img src="/assets/images/Unmixed_Sample.png" width="90%" height="90%" />
            <br />
            <b>Primer For Sequencing:</b> <br />
            Prefer Tm: 50-60C, GC content: 40-60%
            </div>
            </div>
            </div>
            <div class="wide">
                <h2>How to Label Sample Tubes</h2>
                <div id="wrap">
                    <div id="left_col">
                    <p>Label the samples with your initials followed by numbers</p>
                    <br />
                    <img src="/assets/images/label_samples.png" />
                    <br />
                    8-strip PCR tube for samples containing template
                    <br />
                    <font color="#61B64A"><b>Mark</b></font> the first tube near the bottom of the tube
                    </div>
                    <div id="right_col">
                    <b>Example:</b> Samples from John Smith
                    <br />
                    <img src="/assets/images/label_tube.png" />
                    <br />
                    Label on the cap as well as on the side of the tube
                    </div>
                </div>
            </div>
                    <h2>E. Coli Colonies</h2>
                    <b>Pick a colony and resuspend in diH<sub>2</sub>O or Tris Buffer</b>
                    <br /><br />
                    <b>Two sets of tubes:</b>
                    <br />
                    <img src="/assets/images/Strip_and_Primer.png" />
                    <br />
                    <b>Tube 1:</b> Colony Suspension     <b>Tube 2:</b> Primer
                    <br /><br />
                    <ul>
                    <li>Colonies need to grow for at least 16hrs at 37°C to reach good visible size</li>
                    <li>Pick a single colony with sterile tip and resuspend in 30ul sterile  diH<sub>2</sub>O or Tris buffer (10mM, ph8.0), 15 ul will be submitted to us, and the rest 15 ul will be kept by clients for future uses</li>
                    <li>Prepare 2 separate tubes: 1 contains 15ul colony suspension, 1 contains 5ul primer at 5pmol/ul</li>
                    </ul>
                    <h2>Plasmid Walking</h2>
                    <ul>
                    <li>10ug plasmid DNA</li>
                    <li>Vector information if whole plasmid sequencing is to be done </li>
                    <li>10ul vector primers for first round of sequencing at 5uM </li>
                    <li>Reference or expected sequence if any </li>
                    </ul>
            </div>
                                <div id="free_primers" class="panel">
                                    <h2>Free Primers</h2>
                                    <h3>Available Common Primers <a href="/assets/Quintara_basic_primerList.xls"> (Complete List in Excel)</a> ||  <a href="/qbtools/findPrimer">Search Online </a></h3>
                                    <table id="primers">
                                        <tr>
                                            <th>Name</th> <th>Description </th> <th>Sequence</th> </tr>
                                        <tr>
                                            <td> 3'AOX1 </td>
                                            <td> For Pichia vectors with AOX1 terminator, reverse primer </td>
                                            <td>GCAAATGGCATTCTGACATCC</td>
                                        </tr>
                                        <tr>
                                            <td> 5'AOX1 </td>
                                            <td> For Pichia vectors with AOX1 promoter, forward primer </td>
                                            <td>GACTGGTCCAATTGACAAGC </td>
                                        </tr>
                                        <tr>
                                            <td> Alpha-factor </td>
                                            <td>Alpha factor signal sequence, forward primer</td>
                                            <td>TACTATTGCCAGCATTGCTGC </td>
                                        </tr>
                                        <tr>
                                            <td>Amp-R</td>
                                            <td>5' end of ampiciltrn resistance gene, reverse primer</td>
                                            <td>ATAATACCGCGCCACATAGC </td>
                                        </tr>
                                        <tr>
                                            <td> CAT-R </td>
                                            <td>5' end of chloramphenicol resistance gene, reverse primer</td>
                                            <td>GCAACTGACTGAAATGCCTC </td>
                                        </tr>
                                        <tr>
                                            <td> CMV Forward </td>
                                            <td> Human CMV immediate early promoter, forward primer</td>
                                            <td> CGCAAATGGGCGGTAGGCGTG</td>
                                        </tr>
                                        <tr>
                                            <td> CRE-R </td>
                                            <td> 5' end of Cre recombinase, reverse primer</td>
                                            <td> GCAAACGGACAGAAGCATTT</td>
                                        </tr>
                                        <tr>
                                            <td> EF-1a Forward </td>
                                            <td> Human elongation factor-1a promoter, forward primer</td>
                                            <td> TCAAGCCTCAGACAGTGGTTC </td>
                                        </tr>
                                        <tr>
                                            <td> GAL1 </td>
                                            <td>S. cerevisiae GAL1 promoter, forward primer</td>
                                            <td> AATATACCTCTATACTTTAACGTC</td>
                                        </tr>
                                        <tr>
                                            <td> Gal10pro-F </td>
                                            <td>S. cerevisiae GAL10 promoter, forward primer</td>
                                            <td>GGTGGTAATGCCATGTAATATG</td>
                                        </tr>
                                        <tr>
                                            <td> Gal4 N-term </td>
                                            <td>3' end of Gal4 DNA binding domain, forward primer</td>
                                            <td>GAGTAGTAACAAAGGTCAA</td>
                                        </tr>
                                        <tr>
                                            <td> Gal4-AD </td>
                                            <td>3' end of Gal4 activation domain, forward primer</td>
                                            <td>AATACCACTACAATGGAT </td>
                                        </tr>
                                        <tr>
                                            <td> GFP-F </td>
                                            <td>3' end of GFP, forward primer </td>
                                            <td>GGTCCTTCTTGAGTTTGTAAC</td>
                                        </tr>
                                        <tr>
                                            <td> GFP-R </td>
                                            <td>5' end of GFP, reverse primer</td>
                                            <td>CCATCTAATTCAACAAGAATTGGGACAAC </td>
                                        </tr>
                                        <tr>
                                            <td> IRES-F </td>
                                            <td>3' end of IRES, forward primer</td>
                                            <td> TGGCTCTCCTCAAGCGTATT</td>
                                        </tr>
                                        <tr>
                                            <td> IRES-R </td>
                                            <td>5' end of IRES, reverse primer</td>
                                            <td> CCTCACATTGCCAAAAGACG</td>
                                        </tr>
                                        <tr>
                                            <td> LacI-R </td>
                                            <td>5' end of LacI, reverse primer</td>
                                            <td> GGCATACTCTGCGACATCGT</td>
                                        </tr>
                                        <tr>
                                            <td> LacZ-R </td>
                                            <td>5' end of LacZ, reverse primer</td>
                                            <td> GACAGTATCGGCCTCAGGAA</td>
                                        </tr>
                                        <tr>
                                            <td> M13 (-21) Forward </td>
                                            <td>In lacZ gene</td>
                                            <td> TGTAAAACGACGGCCAGT</td>
                                        </tr>
                                        <tr>
                                            <td> M13 (-40) </td>
                                            <td>In lacZ gene</td>
                                            <td> GTTTTCCCAGTCACGAC</td>
                                        </tr>
                                        <tr>
                                            <td> M13 Reverse </td>
                                            <td>In lacZ gene</td>
                                            <td> CAGGAAACAGCTATGAC</td>
                                        </tr>
                                        <tr>
                                            <td> M13/pUC Forward </td>
                                            <td>In lacZ gene</td>
                                            <td> CCCAGTCACGACGTTGTAAAACG </td>
                                        </tr>
                                        <tr>
                                            <td> M13/pUC Reverse </td>
                                            <td>In lacZ gene</td>
                                            <td> AGCGGATAACAATTTCACACAGG </td>
                                        </tr>
                                        <tr>
                                            <td> pBAD Forward </td>
                                            <td>For vectors with E. cotr araBAD promoter, forward primer</td>
                                            <td> ATGCCATAGCATTTTTATCC </td>
                                        </tr>
                                        <tr>
                                            <td> pBAD Reverse </td>
                                            <td>For vectors with E. cotr araBAD promoter, reverse primer</td>
                                            <td> GATTTAATCTGTATCAGG </td>
                                        </tr>
                                        <tr>
                                            <td> T3 </td>
                                            <td>T3 promoter, forward primer</td>
                                            <td> GCAATTAACCCTCACTAAAGG</td>
                                        </tr>
                                        <tr>
                                            <td> T7 </td>
                                            <td>T7 promoter, forward primer</td>
                                            <td> TAATACGACTCACTATAGGG</td>
                                        </tr>
                                        <tr>
                                            <td> T7 Terminal </td>
                                            <td>T7 terminator, reverse primer </td>
                                            <td> GCTAGTTATTGCTCAGCGG </td>
                                        </tr>
                                    </table>
                                </div>
                <div id="special_services" class="panel">
                <h2>Specialized Services</h2>
                    <div class="round">
                        <div id="wrap">
                        <div id="left_col">
                    <img src="/assets/images/RCA.png" /> RCA <br /><br />
                    <img src="/assets/images/walking.png" /> <a href="/service/primer_walking">Primer Walking</a><br /><br />
                    <img src="/assets/images/ampseq.png" /> <a href="/service/amplicon_sequencing">Amplicon Sequencing</a><br /><br />
                    <img src="/assets/images/miniprep.png" /> Plasmid DNA Miniprep <br /><br />
                    <img src="/assets/images/pcrpur.png" /> PCR Purification <br /><br />
                        </div>
                        <div id="right_col">
                    <img src="/assets/images/fragment.png" /> Fragment Analysis <br /><br />
                    <img src="/assets/images/mutation.png" /> Mutation Detection <br /><br />
                    <img src="/assets/images/unknown.png" /> Unknown Plasmid Sequencing <br /><br />
                    <img src="/assets/images/maxiprep.png" /> <a href="/service/dna_maxiprep">Plasmid DNA Maxiprep</a> <br /><br />
                        </div>
                    </div>
                    </div>
                </div>
                            <div id="order" class="panel">
                                <h2>Order</h2>
                                <h4>Redirecting</h4>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div id="footer">
                <div class="social">
                    <a href="https://www.facebook.com/pages/QuintaraBio/449236155251241?ref=br_rs"><img src="/assets/images/facebook.png" /></a>
                    <a href="https://twitter.com/quintara_bio"><img src="/assets/images/twitter.png" /></a>
                    <a href="https://plus.google.com/111222527525773281701/about"><img src="/assets/images/google.png" /></a>
                    <a href="http://www.linkedin.com/company/quintara-biosciences"><img src="/assets/images/linkedin.png" /></a>
                </div>
                <div class="fmenu" style={{paddingRight:20}}>
                    <b><font color="#fff">COMPANY</font></b><br />
                    <a href="/about">About Us</a>
                    <a href="/contact">Contact</a>
                    <a href="/career">Careers</a>
                    <a href="/service/request">Request Info</a>
                    <a href="/support#2">FAQ</a>
                </div>
                <div class="fmenu">
                    <b><font color="#fff">SERVICES</font></b><br />
                    <a href="/service/dna_sequencing">DNA Sequencing</a>
                    <a href="/service/ngs_services">NGS Services</a>
                    <a href="/service/ab_sequencing">Ab Sequencing</a>
                    <a href="/service/dna_maxiprep">Plasmid DNA Maxiprep</a>
                    <a href="/service/molecular_cloning">Molecular Cloning</a>
                </div>
                <div class="fmenu">
                    <b><font color="#fff">PRODUCTS</font></b><br />
                    <a href="/product/q_maxseq">Q-Max Seq</a>
                    <a href="/product/seqcard">SeqCard</a>
                    <a href="/service/oligo">Custom Oligo Synthsis</a>
                </div>
                <div class="fmenu">
                    <b><font color="#fff">SAMPLE PICKUP</font></b><br />
                    <a href="/pickup/umassmed">UMASS</a>
                    <a href="/pickup/yale">Yale</a>
                    <a href="/pickup/longwood">Longwood</a>
                </div>
                <div style={{clear:"both"}}></div>
                <br />
                <p><font size="2">© 2008-2020 Quintara Biosciences -3583 Investment Blvd, Suite 2, Hayward, CA 94545 | 625 Mt Auburn Street, Suite 105, Cambridge, MA 02138</font> </p>
            </div>
            </div>

*/

