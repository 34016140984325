import React, {Component, useState, useEffect, useContext} from 'react';
import {ResponseWindowOk,ResponseWindowYesNo,ResponseWindowEdit} from '../../../Modals';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//import zeditbox1 from 'zeditbox1';
import ZDropdownList from '../../../zcomponents/ZDropdownList';
import ZEditText from '../../../zcomponents/ZEditText';
import ZEditNumber from '../../../zcomponents/ZEditNumber';
import ZTextButton from '../../../zcomponents/ZTextButton';
import ZCheckbox from '../../../zcomponents/ZCheckbox2';
import ZAutoCompleteEditText from '../../../zcomponents/ZAutoCompleteEditText';
import {retrieveApiData,retrieveApiData2,zoftApiForUsp,zoftApiForUspField} from '../../../data_retrieval';

//set @input='[{"request_row_count":1},{"offset_row":0},{"request_rows":99999},{"invoice_id":0},{"invoice_status_id":0},{"billgroup":""},{"category_id":0},{"institution":""},{"dateStart":"2021-01-01"},{"dateEnd":"2022-10-31"},{"po":""},{"recalculate_totaldue":0},{"q_maxonly":0},{"exclude_paid":0},{"exclude_cancelled":0},{"exclude_nih":0}]';
const BillGroupManageDetailDataGrid  = (props)=> {
    const type_string = 1;
    const type_number = 0;
    const [modalTitle, SetModalTitle] = useState("Warning Message");
    const [showModalOk, SetShowModalOk] = useState(false);
    const [showModalEdit, SetShowModalEdit] = useState(false);
    const [showModalYesNo, SetShowModalYesNo] = useState(false);
    const [editData, SetEditData] = useState(false);

    const [response, setResponse] = useState(0);
    const [institution_list, setInstitution_list] = useState([]);
    const [update_mode, SetUpdate_mode] = useState(0);
    let tmp_data = {new_record_inserted_or_updated:false,i:-1,
        data:{"id": 0, 
        "name":"",
        "costcenter_id":0,
        "institution_id":0, 
        "status_id":0,
        "bill_frequency":30,
        "term":"",
        "is_autobill":0,
        "is_active":0,
        "is_invoice_per_po":0
        ,"cur_inv_id":0,
        "po_required":0,
        "po":"",
        "is_prepaid":0,
        "review_required":0,
        "email_to":"",
        "email_cc":"",
        "inv_email_subject":"",
        "inv_email_body":"",
        "review_email_to":"",
        "billto_address_id":0,
        "billing_attn":"",
        "billto_room":"",
        "quote_id":0,
        "notes":"",
        "shipto_address_id":0,
        "shipping_attn":"",
        "shipto_room":"",
        "delivery_method":0,
        "prepaid_payment_id":0,
        "cc_onfile":0}}

    const [detail_data,SetDetail_data] = useState(tmp_data);
    const [dd_access, setDd_access] = useState({});
	useEffect(() => {
        let input_data = '[{"request_row_count":0},{"offset_row":0},{"request_rows":9999},{"gui_id":30102}]'
        let tmp_data = retrieveApiData(1,30102,57,"usp_react_sql_retrieve2", input_data);
        Promise.resolve(tmp_data).then((value)=>{
            try {
                console.log("BillGroupManageDetailDataGrid-useEffect[],tmp_data3-value",value)
                let return_data = JSON.parse(value)
                let tmp_data = {}
                for(let i = 0; i < return_data.length;i++)
                    tmp_data[return_data[i].field_id] = return_data[i].access_mode
                setDd_access(tmp_data)
            } catch (e) {
                console.log('Not JSON');
            }
        })
    },[]);

	useEffect(() => {
//        SetInstitution_id(institution_id);

        if(update_mode===0) {
            console.log("BillGroupManageDetailDataGrid-	props.current_data_from_list.data.id",props.current_data_from_list.data.id)
            console.log("BillGroupManageDetailDataGrid-	props.current_data_from_list.data.name",props.current_data_from_list.data.name)
    //        console.log("BillGroupManageDetailDataGrid-	institution_id",institution_id)
            console.log("BillGroupManageDetailDataGrid-	props.current_data_from_list.data.institution_id",props.current_data_from_list.data.institution_id)
                SetDetail_data({new_record_inserted_or_updated:false,i:props.current_data_from_list.i,data:{...props.current_data_from_list.data}});
        }
        console.log("BillGroupManageDetailDataGrid-useEffect[],dd_access",dd_access)
        
    },[props,dd_access]);

    /*
    useEffect(() => {
        if(response===1) {
            props.set_update_mode(0);
            SetUpdate_mode(0);
        }
    },[update_mode,response]);    
    */
    useEffect(() => {
        if(editData!="") {
            console.log("EditInvoiceStatusDataPage-useEffect,editData",editData)
            let tmp_data = detail_data;
            tmp_data.data.name = editData;
            SetDetail_data({new_record_inserted_or_updated:tmp_data.new_record_inserted_or_updated,i:tmp_data.i,data:{...tmp_data.data}});
            insertNewRecord();
        }
    },[editData]);    

    const onChangeTextHandler =(text,field_name,data_type) => {
        if(update_mode===1) {
            let tmp_data = detail_data;
            if(data_type===type_string)
                tmp_data.data[field_name] = text;
            else
                tmp_data.data[field_name] = parseInt(text);
            SetDetail_data({new_record_inserted_or_updated:tmp_data.new_record_inserted_or_updated,i:tmp_data.i,data:{...tmp_data.data}});
        }
        console.log("EditInvoiceStatusDataPage-onChangeTextHandler,field_name,text",field_name, text)
	}
    const onClickCheckbox =(field_name)=> {
        if(field_name==="update_mode") {
        //    if(update_mode===1) {
        //        setShowModal(true)
        //    } else {
                props.set_update_mode(update_mode===0?1:0);
                SetUpdate_mode(update_mode===0?1:0);
        //    }
        }
        let key_name = '"' + field_name + '"';

        if(update_mode===1) {
            let tmp_data = detail_data;
            let the_value = detail_data.data[field_name]===0?1:0;
            tmp_data.data[field_name] = the_value;
            SetDetail_data({new_record_inserted_or_updated:tmp_data.new_record_inserted_or_updated,i:tmp_data.i,data:{...tmp_data.data}});
            console.log("EditInvoiceStatusDataPage-onClickCheckbox,detail_data,tmp_data",detail_data,tmp_data)
        }
    }
    
    const popuateData = (a_data)=> {
//        SetDetail_data({new_record_inserted:detail_data.new_record_inserted,i:detail_data.i,data:a_data})
        SetDetail_data({new_record_inserted_or_updated:detail_data.new_record_inserted_or_updated,i:detail_data.i,data:{...a_data}});
    }
    const insertNewRecord = ()=> {
        let app_id = 1
        let gui_id = 30102
        let new_name = detail_data.data.name===undefined ? '' : detail_data.data.name;
        let the_input = [[{"name":new_name}],[]];
        console.log('BillGroupManageDetailDataGrid-onButtonClickNew the_input', the_input);
        let tmp_data = zoftApiForUspField(app_id,gui_id,31,39,1,0,"usp_react_sql_insert2_with_return", JSON.stringify(the_input));
        Promise.resolve(tmp_data).then((value)=>{
            console.log('BillGroupManageDetailDataGrid-onButtonClickNew value', value);
            if(value.return_status===0) { //normal return
                console.log('BillGroupManageDetailDataGrid-onButtonClickNew value.return_data', value.return_data);
                if(value.return_data.success) {
                    SetEditData("");
                    console.log('BillGroupManageDetailDataGrid-onButtonClickNew value.return_data.output.data',value.return_data.output);
                    let the_data = JSON.parse(value.return_data.output);
                    popuateData(the_data.data[0]);
                    props.getCurrentDataFromDetail({new_record_inserted_or_updated:true,i:-1, data:the_data.data[0]});    
                    SetEditData("");
                } else {
                    SetModalTitle("Insert Error:" + value.return_data.message + ", Please enter new name!");
                    SetShowModalEdit(true);
                };
            } else {
                SetModalTitle("Unspecified error, please report it to tech support!");
                SetShowModalOk(true);
                console.log('BillGroupManageDetailDataGrid-insertNewRecord value.return_data', value.return_data);
            }
        }) 
   }
    const onButtonClickNew = ()=> {
        SetUpdate_mode(1)
        insertNewRecord()
    }
    const onBlurTextHandler = (field_name, field_id,data_type) => {
        let the_id = detail_data.data.id;
        let the_input_data = "";
        if(the_id===undefined)
            return;
        console.log('onBlurTextHandler field_name, data_type',field_name, data_type);
        if(update_mode===1) {
            if(data_type===type_string) { //string
                the_input_data = '{"' + field_name + '":"' + detail_data.data[field_name] + '","id":' + the_id.toString() + '}'
            } else {
                let tmp_data = detail_data.data[field_name]===undefined ? 0:detail_data.data[field_name];
                the_input_data = '{"' + field_name + '":' + tmp_data.toString() + ',"id":' + the_id.toString() + '}'
            }
            console.log('BillGroupManageDetailDataGrid-onBlurTextHandler the_input_data', the_input_data);

            let api_return_data = zoftApiForUspField(1,30102,field_id, 39,0,"usp_react_sql_onchange_update", the_input_data);
//            let api_return_data = zoftApiForUsp(1,30102,39,0,0,"usp_react_sql_onchange_update", the_input_data);
            Promise.resolve(api_return_data).then((value)=>{
                console.log('BillGroupManageDetailDataGrid-onBlurTextHandler value', value);
                if(value.return_status===0) { //normal return
                    console.log('BillGroupManageDetailDataGrid-onBlurTextHandler value.return_data', value.return_data);
                    if(value.return_data.success) {
                        SetEditData("");
                        console.log('BillGroupManageDetailDataGrid-onButtonClickNew value.return_data.output.data',value.return_data.output);
                        props.getCurrentDataFromDetail({new_record_inserted_or_updated:true,i:props.current_data_from_list.i, data:detail_data.data});
                    } else {
                        SetModalTitle("Update Error:" + value.return_data.message + "!");
                        SetShowModalEdit(true);
                    };
                } else {
                    SetModalTitle("Unspecified error, please report it to tech support!");
                    SetShowModalOk(true);
                    console.log('BillGroupManageDetailDataGrid-onBlurTextHandler value.return_data', value.return_data);
                }
            }) 
        }
    }
    return  (
        <div style={{width:1450,height:310,backgroundColor:"#ffffff",border:"1px solid black"}}>
            <ZEditNumber id="030102detail01"
                x={10} y={10} width={200} height={35} whole_background_color="#ffffff"
                label="ID" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={100} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={true}
                value={detail_data.data.id}
                onchange_func = {(text) => { onChangeTextHandler(text,"id",type_number)}}
                onBlur= {() => {}}
            />
            <ZEditText id="030102detail02"
                x={10} y={60} width={200} height={35} whole_background_color="#ffffff"
                label="Name" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={100} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["1"]===undefined||dd_access["1"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.name}
                onchange_func = {(text) => { onChangeTextHandler(text,"name",type_string)}}
                onBlur= {() => {onBlurTextHandler("name", 1, type_string)}}
            />
            <ZDropdownList id="030102detail03"
                x={10} y={110} width={200} height={35} whole_background_color="#ffffff"
                label="Cost Center" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={100} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["2"]===undefined||dd_access["2"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.costcenter_id}
                onchange_func = {(text) => { onChangeTextHandler(text,"costcenter_id",type_number)}}
                onBlur= {() => {onBlurTextHandler("costcenter_id", 2, type_number)}}
                dataList={props.costcenter_list}
            />
            <ZDropdownList id="030102detail04"
                x={10} y={160} width={200} height={35} whole_background_color="#ffffff"
                label="Institution" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={100} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["3"]===undefined||dd_access["3"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.institution_id}
                onchange_func = {(text) => { onChangeTextHandler(text,"institution_id",type_number)}}
                onBlur= {() => {onBlurTextHandler("institution_id", 3, type_number)}}
                dataList={props.institution_list}
            />
            <ZDropdownList id="030102detail05"
                x={10} y={210} width={200} height={35} whole_background_color="#ffffff"
                label="Status" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={100} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["4"]===undefined||dd_access["4"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.status_id}
                onchange_func = {(text) => { onChangeTextHandler(text,"status_id",type_number)}}
                onBlur= {() => {onBlurTextHandler("status_id", 4, type_number)}}
                dataList={props.status_list}
            />
            <ZEditNumber id="030102detail06"
                x={230} y={10} width={200} height={35} whole_background_color="#ffffff"
                label="Bill Frequency" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={90} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["5"]===undefined||dd_access["5"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.bill_frequency}
                onchange_func = {(text) => { onChangeTextHandler(text,"bill_frequency",type_number)}}
                onBlur= {() => {onBlurTextHandler("bill_frequency", 5, type_number)}}
            />
            <ZEditText id="030102detail07"
                x={230} y={60} width={200} height={35} whole_background_color="#ffffff"
                label="Term" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={90} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["6"]===undefined||dd_access["6"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.term}
                onchange_func = {(text) => { onChangeTextHandler(text,"term",type_string)}}
                onBlur= {() => {onBlurTextHandler("term", 6, type_string)}}
            />
            <ZCheckbox id="030102detail08"
                x={230} y={110} width={200} height={35} whole_background_color="#ffffff"
                label="Is Autobill" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={25} button_height={25} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["7"]===undefined||dd_access["7"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.is_autobill}
                onClickCheckbox = {() => { onClickCheckbox("is_autobill")}}
                onBlur= {() => {onBlurTextHandler("is_autobill", 7, type_number)}}
            />
            <ZCheckbox id="030102detail09"
                x={230} y={160} width={200} height={35} whole_background_color="#ffffff"
                label="Is Active" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={25} button_height={25} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["8"]===undefined||dd_access["8"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.is_active}
                onClickCheckbox = {() => { onClickCheckbox("is_active")}}
                onBlur= {() => {onBlurTextHandler("is_active", 8, type_number)}}
            />
            <ZCheckbox id="030102detail10"
                x={230} y={210} width={200} height={35} whole_background_color="#ffffff"
                label="Is Invoice Per PO" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={25} button_height={25} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["9"]===undefined||dd_access["9"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.is_invoice_per_po}
                onClickCheckbox = {() => { onClickCheckbox("is_invoice_per_po")}}
                onBlur= {() => {onBlurTextHandler("is_invoice_per_po", 9, type_number)}}
            />
            <ZEditNumber id="030102detail11"
                x={440} y={10} width={200} height={35} whole_background_color="#ffffff"
                label="Current Invoice ID" label_x={0} label_y={0} label_width={80} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={90} button_y={0} button_width={90} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["10"]===undefined||dd_access["10"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.cur_inv_id}
                onchange_func = {(text) => { onChangeTextHandler(text,"cur_inv_id",type_number)}}
                onBlur= {() => {onBlurTextHandler("cur_inv_id", 10, type_number)}}
            />
            <ZEditText id="030102detail12"
                x={440} y={60} width={200} height={35} whole_background_color="#ffffff"
                label="PO" label_x={0} label_y={0} label_width={80} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={90} button_y={0} button_width={90} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["11"]===undefined||dd_access["11"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.po}
                onchange_func = {(text) => { onChangeTextHandler(text,"po",type_string)}}
                onBlur= {() => {onBlurTextHandler("po", 11, type_string)}}
            />
            <ZCheckbox id="030102detail13"
                x={420} y={110} width={160} height={35} whole_background_color="#ffffff"
                label="PO Required" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={25} button_height={25} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["12"]===undefined||dd_access["12"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.po_required}
                onClickCheckbox = {() => { onClickCheckbox("po_required")}}
                onBlur= {() => {onBlurTextHandler("po_required", 12, type_number)}}
            />
            <ZCheckbox id="030102detail14"
                x={420} y={160} width={160} height={35} whole_background_color="#ffffff"
                label="Is Prepaid" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={25} button_height={25} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["13"]===undefined||dd_access["13"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.is_prepaid}
                onClickCheckbox = {() => { onClickCheckbox("is_prepaid")}}
                onBlur= {() => {onBlurTextHandler("is_prepaid", 13, type_number)}}
            />
            <ZCheckbox id="030102detail15"
                x={420} y={210} width={160} height={35} whole_background_color="#ffffff"
                label="Review Required" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={25} button_height={25} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["14"]===undefined||dd_access["14"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.review_required}
                onClickCheckbox = {() => { onClickCheckbox("review_required")}}
                onBlur= {() => {onBlurTextHandler("review_required", 14, type_number)}}
            />

            <ZEditText id="030102detail16"
                x={630} y={10} width={280} height={35} whole_background_color="#ffffff"
                label="Email To" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={170} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["16"]===undefined||dd_access["16"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.email_to}
                onchange_func = {(text) => { onChangeTextHandler(text,"email_to",type_string)}}
                onBlur= {() => {onBlurTextHandler("email_to", 16, type_string)}}
            />
            <ZEditText id="030102detail17"
                x={630} y={60} width={280} height={35} whole_background_color="#ffffff"
                label="Email CC" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={170} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["17"]===undefined||dd_access["17"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.email_cc}
                onchange_func = {(text) => { onChangeTextHandler(text,"email_cc",type_string)}}
                onBlur= {() => {onBlurTextHandler("email_cc", 17, type_string)}}
            />
            <ZEditText id="030102detail18"
                x={630} y={110} width={280} height={35} whole_background_color="#ffffff"
                label="Invoice Email Subject" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={170} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["18"]===undefined||dd_access["18"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.inv_email_subject}
                onchange_func = {(text) => { onChangeTextHandler(text,"inv_email_subject",type_string)}}
                onBlur= {() => {onBlurTextHandler("inv_email_subject", 18, type_string)}}
            />
            <ZEditText id="030102detail19"
                x={630} y={160} width={280} height={35} whole_background_color="#ffffff"
                label="Invoice Email Body" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={170} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["19"]===undefined||dd_access["19"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.inv_email_body}
                onchange_func = {(text) => { onChangeTextHandler(text,"inv_email_body",type_string)}}
                onBlur= {() => {onBlurTextHandler("inv_email_body", 19, type_string)}}
            />
            <ZEditText id="030102detail20"
                x={630} y={210} width={280} height={35} whole_background_color="#ffffff"
                label="Review Email To" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={170} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["20"]===undefined||dd_access["20"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.review_email_to}
                onchange_func = {(text) => { onChangeTextHandler(text,"review_email_to",type_string)}}
                onBlur= {() => {onBlurTextHandler("review_email_to", 20, type_string)}}
            />

            <ZEditText id="030102detail21"
                x={920} y={60} width={200} height={35} whole_background_color="#ffffff"
                label="Bill To Address" label_x={0} label_y={0} label_width={80} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={90} button_y={0} button_width={120} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["22"]===undefined||dd_access["22"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.billto_address_id}
                onchange_func = {(text) => { onChangeTextHandler(text,"billto_address_id",type_string)}}
                onBlur= {() => {onBlurTextHandler("billto_address_id", 22, type_string)}}
            />
            <ZEditText id="030102detail22"
                x={920} y={110} width={200} height={35} whole_background_color="#ffffff"
                label="Billing Attention" label_x={0} label_y={0} label_width={80} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={90} button_y={0} button_width={120} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["23"]===undefined||dd_access["23"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.billing_attn}
                onchange_func = {(text) => { onChangeTextHandler(text,"billing_attn",type_string)}}
                onBlur= {() => {onBlurTextHandler("billing_attn", 23, type_string)}}
            />
            <ZEditText id="030102detail23"
                x={920} y={160} width={200} height={35} whole_background_color="#ffffff"
                label="Bill To Room" label_x={0} label_y={0} label_width={80} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={90} button_y={0} button_width={120} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["24"]===undefined||dd_access["24"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.billto_room}
                onchange_func = {(text) => { onChangeTextHandler(text,"billto_room",type_string)}}
                onBlur= {() => {onBlurTextHandler("billto_room", 24, type_string)}}
            />
            <ZEditNumber id="030102detail24"
                x={920} y={210} width={200} height={35} whole_background_color="#ffffff"
                label="Quote ID" label_x={0} label_y={0} label_width={80} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={90} button_y={0} button_width={120} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["25"]===undefined||dd_access["25"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.quote_id}
                onchange_func = {(text) => { onChangeTextHandler(text,"quote_id",type_number)}}
                onBlur= {() => {onBlurTextHandler("quote_id", 25, type_number)}}
            />
            <ZEditText id="030102detail25"
                x={920} y={260} width={510} height={35} whole_background_color="#ffffff"
                label="Notes" label_x={0} label_y={0} label_width={80} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={90} button_y={0} button_width={380} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["30"]===undefined||dd_access["30"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.notes}
                onchange_func = {(text) => { onChangeTextHandler(text,"notes",type_string)}}
                onBlur= {() => {onBlurTextHandler("notes", 30, type_string)}}
            />

            <ZEditText id="030102detail26"
                x={1140} y={60} width={250} height={35} whole_background_color="#ffffff"
                label="Ship To Address" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={140} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["26"]===undefined||dd_access["26"]===0?true:(update_mode===0?true:false)}
                onchange_func = {(text) => { onChangeTextHandler(text,"shipto_address_id",type_string)}}
                onBlur= {() => {onBlurTextHandler("shipto_address_id", 26, type_string)}}
            />
            <ZEditText id="030102detail27"
                x={1140} y={110} width={250} height={35} whole_background_color="#ffffff"
                label="Shipping Attention" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={140} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["27"]===undefined||dd_access["27"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.shipping_attn}
                onchange_func = {(text) => { onChangeTextHandler(text,"shipping_attn",type_string)}}
                onBlur= {() => {onBlurTextHandler("shipping_attn", 27, type_string)}}
            />
            <ZEditText id="030102detail28"
                x={1140} y={160} width={250} height={35} whole_background_color="#ffffff"
                label="Ship To Room" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={140} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["28"]===undefined||dd_access["28"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.shipto_room}
                onchange_func = {(text) => { onChangeTextHandler(text,"shipto_room",type_string)}}
                onBlur= {() => {onBlurTextHandler("shipto_room", 24, type_string)}}
            />
            <ZEditNumber id="030102detail29"
                x={1140} y={210} width={250} height={35} whole_background_color="#ffffff"
                label="Delivery Method" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={140} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["29"]===undefined||dd_access["29"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.delivery_method}
                onchange_func = {(text) => { onChangeTextHandler(text,"delivery_method",type_number)}}
                onBlur= {() => {onBlurTextHandler("delivery_method", 29, type_number)}}
            />
            <ZEditNumber id="030102detail30"
                x={440} y={260} width={200} height={35} whole_background_color="#ffffff"
                label="Prepaid Payment" label_x={0} label_y={0} label_width={80} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={90} button_y={0} button_width={90} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["15"]===undefined||dd_access["15"]===0?true:(update_mode===0?true:false)}
                value={detail_data.data.prepaid_payment_id}
                onchange_func = {(text) => { onChangeTextHandler(text,"prepaid_payment_id",type_number)}}
                onBlur= {() => {onBlurTextHandler("prepaid_payment_id", 15, type_number)}}
            />
            <ZEditNumber id="030102detail31"
                x={650} y={260} width={200} height={35} whole_background_color="#ffffff"
                label="CC Onfile" label_x={0} label_y={0} label_width={80} label_height={35}
                label_color="#000000" label_background_color="#ffffff" text_align="right"
                button_x={90} button_y={0} button_width={90} button_height={35} button_color="#000000" button_background_color="ffffff"
                disabled={dd_access["21"]===undefined||dd_access["21"]===0?true:(update_mode===0?true:false)}
                onchange_func = {(text) => { onChangeTextHandler(text,"cc_onfile",type_number)}}
                onBlur= {() => {onBlurTextHandler("cc_onfile", 21, type_number)}}
            />
            <ZTextButton id="030102detail32"
                x={1015} y={5} width={100} height={30} whole_background_color="#f0f0f0" 
                border_style="1px solid #000000"
                button_text="New" label_background_color="#f0f0f0"
                button_font_color="#ff0000" button_background_color="#ffffff"
                disabled={dd_access["31"]===undefined||dd_access["31"]===0?true:(update_mode===0?true:false)}
                onButtonClick = {() => { onButtonClickNew()}}
            />
            <ZCheckbox id="030102detail34"
                x={1180} y={5} width={100} height={30} whole_background_color="#ffffff" 
                label="Update Mode" label_x={0} label_y={0} label_width={100} label_height={35}
                label_color="#ff0000" label_background_color="#ffffff" text_align="right"
                button_x={110} button_y={0} button_width={25} button_height={25} button_color="#000000" button_background_color="ffffff"
                value={update_mode}
                disabled={dd_access["32"]===0?true:false}
                onClickCheckbox = {() => { onClickCheckbox("update_mode")}}
                onBlur= {() => {}}
            />
             {showModalOk ? <ResponseWindowOk title={modalTitle} setShowModal={SetShowModalOk} /> : null}
             {showModalEdit ? <ResponseWindowEdit title={modalTitle} setShowModal={SetShowModalEdit} setEditData={SetEditData} /> : null}
             {showModalYesNo ? <ResponseWindowYesNo title={modalTitle} setShowModal={SetShowModalYesNo} setResponse={setResponse}/> : null}
        </div>

    );
}
/*----------------------------------------------------------------
                disabled={dd_access["32"]===0?true:(update_mode===0?true:false)}
*/
export default BillGroupManageDetailDataGrid;