import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useNavigate
  }
from "react-router-dom";


import "./../../styles.css";

//import '@progress/kendo-theme-default/dist/all.css';
import './../../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Navbar from 'react-bootstrap/Navbar';
import './../../v2/css/bootstrap.min.css';
//import amplicon_sequencing from './../../v2/img/amplicon sequencing.png'


/*
import NGSred from "./v2/img/NGS-red.png"
import Sangerred from "./v2/img/Sanger-red.png"
import Cloningred from "./v2/img/Cloning-red.png"
import Oligored from "./v2/img/Oligo-red.png"
import DNAprepred from "./v2/img/DNAprep-red.png"
*/

//const components = PanelBarUtils.mapItemsToComponents(items);
const PublicPageServiceNgsServices = ()=> {
    const navigate = useNavigate();
  
    const handleClick = (path) => {
        navigate(path);
    }
return(
  <>
  <div id="header">
    <nav class="navbar navbar-expand-md navbar-light bg-light fixed-top padding">
      <div class="container-fluid d-flex align-items-center pt-3 mx-3">
        <a href="/index.html" class="logo mr-auto"><img src="/v2/img/logotransparent.png" alt="Quintara Biosciences" class="img-fluid" /></a>
        <nav class="nav-menu d-none d-lg-block">
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About</a></li>
            <li class="drop-down"><a href="/services">Services</a>
              <ul>
                <li><a href="/service/dna_sequencing">Sanger Sequencing</a></li>
                <li><a href="/service/ngs_services">NGS Services</a></li>
                <li><a href="/service/dna_maxiprep">DNAprep Services</a></li>
                <li><a href="/service/oligo">Oligo Synthesis</a></li>
                <li><a href="/service/covid">COVID-19 Sequencing</a></li>
              </ul>
            </li>
            <li class="drop-down"><a href="/support">Tools</a>
              <ul>
                <li><a href="/support#faq">FAQ</a></li>
                <li><a href="/qbtools/findPrimer">Search for QB Primers</a></li>
              </ul>
            </li>
            <li><a href="/user/myqb">Order</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </nav>
        <span><a href="/login" class="login-btn">Login</a></span>
      </div>
    </nav>
  </div>


  <div class="main container-fluid p-5">
    <div class="row">
      <div class="column col-4"align="center">
        <a href="/service/ngs_dnaseq#ampseq" title="Amplicon Sequencing" >
        <img src="/assets/images/amplicon sequencing.png" alt="amplicon seq" class="img-fluid" /></a>
          <div class="overlay">Amplicon Sequencing</div>
      </div> 
      <div class="col-4 column">
        <a href="/service/ngs_rnaseq#hybridoma" title="Hybridoma Sequencing">
        <img class="img-fluid" src="/assets/images/hybridoma.png" alt="Hybridoma Sequencing" /></a>
        <div class="overlay">Hybridoma Sequencing</div>
      </div>
      <div class="col-4 column">
        <a href="/service/ngs_dnaseq#genome" title="Small Genome Sequencing">
        <img class="img-fluid" src="/assets/images/small_genome_1.png" alt="Small Genome Sequencing" /></a>
        <div class="overlay">Small Genome Sequencing</div>
      </div>
    </div>
    <hr />
    <div class="row container-fluid">
      <div class="col-4 column">
        <a href="/service/ngs_rnaseq" title="Antibody Sequencing">
        <img src="/assets/images/antibody_1.png" alt="Antibody Sequencing" class="img-fluid" /></a>
        <div class="overlay">Antibody Sequencing</div>
      </div> 
      <div class="col-4 column">
        <a href="/service/ngs_dnaseq#ampseq" title="16S Sequencing">
        <img class="img-fluid" src="/assets/images/16s_1.png" alt="16S Sequencing" /></a>
        <div class="overlay">16S Sequencing</div>
      </div>
      <div class="col-4 column">
        <a href="/service/ngs_dnaseq#hla" title="HLA Typing">
        <img class="img-fluid" src="/assets/images/HLA_1.png" alt="HLA Typing" /></a>
        <div class="overlay">HLA Typing</div>
      </div>
    </div>
    <hr />
    <div class="row p-5">
      <div class="span15" style={{margin:"auto", position: "relative", height: 250}}>
        <img class="pipeline" src="/assets/images/ngs-pipeline_1 .jpg" />
      </div>
    </div>
    <div class="row w-90 text-center">
        <div class="col-4"><a href="">Quintara Antibody Discovery</a>  </div>
        <div class="col-4"><b>Contact: ngs@quintarabio.com for more information</b></div>
        <div class="col-4"><a href="/service/ngs_o_1">Request Quote or Order</a></div>
    </div>
  </div>

</>
)
}
  
function WebSiteServices() {return (<div>WebSiteServices</div>)}

export default PublicPageServiceNgsServices;

/*----------------------------------------------------------------
			<div class="main container-fluid p-5">
				<div class="row">
					<div class="column col-4">
						<a href="/service/ngs_dnaseq#ampseq" title="Amplicon Sequencing">
						<img src="/assets/images/ngs/ampseq_1.png" alt="amplicon seq" class="img-fluid" /></a>
						<div class="overlay">Amplicon Sequencing</div>
					</div> 
					<div class="col-4 column">
						<a href="/service/ngs_rnaseq#hybridoma" title="Hybridoma Sequencing">
						<img class="img-fluid" src="/assets/images/ngs/hybridoma_1.png" alt="Hybridoma Sequencing" /></a>
						<div class="overlay">Hybridoma Sequencing</div>
					</div>
					<div class="col-4 column">
						<a href="/service/ngs_dnaseq#genome" title="Small Genome Sequencing">
						<img class="img-fluid" src="/assets/images/ngs/small_genome_1.png" alt="Small Genome Sequencing" /></a>
						<div class="overlay">Small Genome Sequencing</div>
					</div>
				</div>
				<hr />
				<div class="row container-fluid">
					<div class="col-4 column">
						<a href="/service/ngs_rnaseq" title="Antibody Sequencing">
						<img src="/assets/images/ngs/antibody_1.png" alt="Antibody Sequencing" class="img-fluid" /></a>
						<div class="overlay">Antibody Sequencing</div>
					</div> 
					<div class="col-4 column">
						<a href="/service/ngs_dnaseq#ampseq" title="16S Sequencing">
						<img class="img-fluid" src="/assets/images/ngs/16s_1.png" alt="16S Sequencing" /></a>
						<div class="overlay">16S Sequencing</div>
					</div>
					<div class="col-4 column">
						<a href="/service/ngs_dnaseq#hla" title="HLA Typing">
						<img class="img-fluid" src="/assets/images/ngs/HLA_1.png" alt="HLA Typing" /></a>
						<div class="overlay">HLA Typing</div>
					</div>
				</div>
				<hr />
				<div class="row p-5">
					<div class="span15" style="margin:auto; position: relative; height: 250px;">
						<img class="pipeline" src="/assets/images/ngs/ngs-pipeline_1.jpg" />
					</div>
				</div>
				<div class="row w-90 text-center">
					<div class="col-4"><a href="">Quintara Antibody Discovery</a>  </div>
					<div class="col-4"><b>Contact: ngs@quintarabio.com for more information</b></div>
					<div class="col-4"><a href="/service/ngs_o_1">Request Quote or Order</a></div>
				</div>
			</div>

*/

