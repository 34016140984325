//BEGIN_IMPORT_SECTIONs
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
//import { AgGridReact,AgGridColumn } from '@ag-grid-community/react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import {retrieveApiData} from '../../../data_retrieval';
import {ResponseWindowYesNo2,ResponseWindowSelf2} from '../../../Modals';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModalRetrieve } from '../../../ModalRetrieval';

var the_this;
export default class ApplyPaymentListDataGrid extends React.Component {
	dd_select_cost_center={}	//13
	dd_select_institution={}	//4
	dd_select_payment_type={}	//14
	input_parm0=
	[
		{"request_row_count":1},
		{"offset_row":0},
		{"request_rows":1000},
		{"payment_id":0},
		{"cost_center_id":0},
		{"payment_type_id":0},
		{"greater_amount":0},
		{"less_amount":0},
		{"start_date":""},
		{"end_date":""},
	]

	state = {
		loaded:false,
		modules:[ClientSideRowModelModule],
		gridOptions:{},
		rowSelection: 'multiple',
		selected_building_id:0,
		data:[
			{"id": 0,"cc_id":0,"inst_id":0,"type_id": 0, "ref_num":0,"amount":0, "applied_amount":0,"fee":0,
			"credits":0,"balance":0,"verified":0,"notes":"","time_created":""}
		],
		input_parm:[],
		offset_row : 0,
		request_rows:50,
		showModalSelf:false,
		showModalRetrieve:false,
		retrieve_source:1, // 1=search, 2=prev, 3=next
		total_rows:0, // 1=search, 2=prev, 3=next
		from_row:1,
		rowCountInd:1,
	}
	gridApi: GridApi
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: true })
		for(let i = 0; i < this.props.cost_center_list.length;i++)
			this.dd_select_cost_center[this.props.cost_center_list[i].id] = this.props.cost_center_list[i].name;	
		for(let i = 0; i < this.props.institution_list.length;i++)
			this.dd_select_institution[this.props.institution_list[i].id] = this.props.institution_list[i].name;
		for(let i = 0; i < this.props.payment_type_list.length;i++)
			this.dd_select_payment_type[this.props.payment_type_list[i].id] = this.props.payment_type_list[i].name;
	}
	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
	//	for(let i = 0; i < this.props.invoice_status_list.length;i++)
	//		this.dd_select_status[this.props.invoice_status_list[i].id] = this.props.invoice_status_list[i].name;	

		this.input_parm0[0].request_row_count = this.props.input_parm.request_row_count
		this.input_parm0[1].offset_row = this.props.input_parm.offset_row
		this.input_parm0[2].request_rows = this.props.input_parm.request_rows
		this.input_parm0[3].payment_id = this.props.input_parm.payment_id
		this.input_parm0[4].cost_center_id = this.props.input_parm.cost_center_id
		this.input_parm0[5].payment_type_id = this.props.input_parm.payment_type_id
		this.input_parm0[6].greater_amount = this.props.input_parm.greater_amount
		this.input_parm0[7].less_amount = this.props.input_parm.less_amount
		this.input_parm0[8].start_date = this.props.input_parm.start_date
		this.input_parm0[9].end_date = this.props.input_parm.end_date

		if(this.props.search_clicked===true&&this.props.search_clicked!==prevProps.search_clicked) {
			let sql_id = 46;
			let app_id = 1;
			let gui_id = 30301;
			this.input_parm0[2].request_rows = 999999;
			//			input_parm0 = this.props.input_parm.slice(0,this.props.input_parm.length-1);
			let tmp_data = retrieveApiData(app_id,gui_id,sql_id,"usp_react_sql_retrieve2", JSON.stringify(this.input_parm0));
			Promise.resolve(tmp_data).then((value)=>{
				console.log('componentDidUpdate retrieveData value',value);
				let total_row_count = parseInt(value);
				if(total_row_count>0) {
					this.input_parm0[2].request_rows = this.props.input_parm.request_rows;
					this.SearchRetrieveData(total_row_count);
				}
			})
//			this.retrieveData();
		}
		if(this.props.prev_clicked===true&&this.props.prev_clicked!==prevProps.prev_clicked) {
			this.PrevRetrieveData();
		}
		if(this.props.next_clicked===true&&this.props.next_clicked!==prevProps.next_clicked) {
			console.log('ApplyPaymentListDataGrid componentDidUpdate next_clicked this.props.invoice_retrieved',this.props.invoice_retrieved);
			this.NextRetrieveData();
		}
	  }	
	setTitle = (completion) => {
		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.progressTitle = "Data Is Loading . . . " + completion.toString() + "%";
		this.setState(statusCopy0);
	}
	setResponse = (data) => {
		let statusCopy = Object.assign({}, this.state)
		statusCopy.data = data;
		this.gridApi.setRowData(this.state.data)
		statusCopy.showModalRetrieve = false;
		if(this.state.retrieve_source===1) {
			this.props.getRetrievedSearch(data.length,statusCopy.total_rows);
		} else if(this.state.retrieve_source===2) {
			this.props.getRetrievedPrev(data.length);
		} else {
			this.props.getRetrievedNext(data.length);
		}
		this.setState(statusCopy);

		/*
		let statusCopy = Object.assign({}, this.state)
		statusCopy.showModalRetrieve = false;
		statusCopy.data = data;
		this.setState(statusCopy);
		this.gridApi.setRowData(this.state.data)
		this.props.retrieved(data.length);
		*/
	}	
	
	onGridReady = params => {
    	this.gridApi = params.api;
 	};

	 SearchRetrieveData = (total_rows) => {
		console.log('ApplyPaymentListDataGrid SearchRetrieveData 0 this.props.input_parm',this.props.input_parm);
		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.rowCountInd = 1;
		let sql_id = 46;
		let app_id = 1;
		console.log('ApplyPaymentListDataGrid SearchRetrieveData this.props.input_parm',this.props.input_parm);
		statusCopy0.sql_id = sql_id;
		statusCopy0.showModalRetrieve = true;
		statusCopy0.data = [];
		statusCopy0.input_parm = this.input_parm0;
		if(total_rows<1000)
			statusCopy0.input_parm[2].request_rows = total_rows;
		statusCopy0.retrieve_source = 1
		statusCopy0.total_rows = total_rows;
		statusCopy0.from_row = 1;
		this.setState(statusCopy0);
	}
	PrevRetrieveData = () => {
		let input_parm2 = [
			{"request_row_count":0},
			{"offset_row":this.props.payment_retrieved.from_row - 1000},
			{"request_rows":1000},
			{"payment_id":this.props.input_parm.payment_id},
			{"cost_center_id":this.props.input_parm.cost_center_id},
			{"payment_type_id":this.props.input_parm.payment_type_id},
			{"greater_amount":this.props.input_parm.greater_amount},
			{"less_amount":this.props.input_parm.less_amount},
			{"start_date":this.props.input_parm.start_date},
			{"end_date":this.props.input_parm.end_date},
		]
		let statusCopy0 = Object.assign({}, this.state)
		statusCopy0.rowCountInd = 0;
		statusCopy0.input_parm = input_parm2;
		console.log('ApplyPaymentListDataGrid PrevRetrieveData 0 statusCopy0.input_parm',statusCopy0.input_parm);
		statusCopy0.sql_id = this.props.input_parm.invoice_update_list===0?51:52;;
		statusCopy0.showModalRetrieve = true;
//		statusCopy0.data = [];
		statusCopy0.from_row = this.props.payment_retrieved.from_row - 1000;
		statusCopy0.retrieve_source = 2
		this.setState(statusCopy0);
	}
	NextRetrieveData = () => {
		let input_parm3 = [
			{"request_row_count":0},
			{"offset_row":this.props.payment_retrieved.to_row + 1},
			{"request_rows":1000},
			{"payment_id":this.props.input_parm.payment_id},
			{"cost_center_id":this.props.input_parm.cost_center_id},
			{"payment_type_id":this.props.input_parm.payment_type_id},
			{"greater_amount":this.props.input_parm.greater_amount},
			{"less_amount":this.props.input_parm.less_amount},
			{"start_date":this.props.input_parm.start_date},
			{"end_date":this.props.input_parm.end_date},
		]
		console.log('ApplyPaymentListDataGrid NextRetrieveData 0 this.props.input_parm',this.props.input_parm);
		let statusCopy0 = Object.assign({}, this.state)

		statusCopy0.rowCountInd = 0;
		statusCopy0.input_parm = input_parm3;
		console.log('ApplyPaymentListDataGrid PrevRetrieveData 0 statusCopy0.input_parm',statusCopy0.input_parm);
		statusCopy0.sql_id = 46;
		statusCopy0.showModalRetrieve = true;
		statusCopy0.from_row = this.props.payment_retrieved.to_row + 1;
		statusCopy0.retrieve_source = 3
		this.setState(statusCopy0);
	}

	onSelectionChanged = (event)=>{
//		const selectedRows =  event.api.getSelectedRows();

		const selectedNodes =  event.api.getSelectedNodes();
		console.log('ApplyPaymentGrid the_this.gridApi', the_this.gridApi);
		if(the_this.props.update_mode===0) { // if update_mode==1 the selected row will not be populated to detailed datagrid
			this.setState({updated_from_detail_allowed:true})
			the_this.props.getCurrentDataFromList({i:selectedNodes[0].id, data:selectedNodes[0].data});
			the_this.props.getCurrentPaymentIdFromList(selectedNodes[0].data.id)
			console.log('ApplyPaymentGrid selectedNodes[0].data', selectedNodes.rowIndex, selectedNodes[0].data);
		}
	}
	onCellClicked(event){
		console.log("InstitutionGrid onCellClicked event", event);
	}
	onCellDoubleClicked(obj){
		console.log('InstitutionGrid onCellDoubleClicked(obj)', obj);
	}	
	onCellValueChanged(event) {
		console.log('InstitutionGrid onCellValueChanged(event)', event);
	}
//rowSelection:'multiple',
//rowMultiSelectWithClick:true
//rowSelection='multiple'
	createGridOptions() {
		const options: GridOptions = {      
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true,
				rowMultiSelectWithClick: true,
       			headerCheckboxSelection: true,
				rowDeselection: true,
			},
			/*
				{headerName:"",headerClass:"header-checked",field:"checked",hide:false,width:30,sortable:true,headerCheckboxSelection:true,checkboxSelection:true,editable:true,valueSetter:function (params) {params.data.checked= params.newValue;return true;},suppressNavigable:true,
				headerCheckboxSelection: true,
				headerCheckboxSelectionFilteredOnly: true,
				checkboxSelection: true,
				},
			*/
			columnDefs: [
				{
					headerName: "Row",
					valueGetter: function (params) { return params.node.rowIndex + the_this.state.from_row;},
					width:50,
				},
				{headerName:"Payment ID",headerClass:"header-id",field:"id",hide:false,width:100,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.inv_num= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Cost Center",headerClass:"header-cc_id",field:"cc_id",hide:false,width:160,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.inv_name= params.newValue;return true;},suppressNavigable:false,
				valueFormatter: function (params) {return the_this.dd_select_cost_center[params.value]},},
				{headerName:"Institution",headerClass:"header-inst_id",field:"inst_id",hide:false,sortable:false,width:160,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.quote_id= params.newValue;return true;},suppressNavigable:true,
				valueFormatter: function (params) {return the_this.dd_select_institution[params.value]},},
				{headerName:"Payment Method",headerClass:"header-type_id",field:"type_id",hide:false,sortable:false,width:160,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.quote_id= params.newValue;return true;},suppressNavigable:true,
				valueFormatter: function (params) {return the_this.dd_select_payment_type[params.value]},},
				{headerName:"Payment Reference Number",headerClass:"header-ref_num",field:"ref_num",hide:false,sortable:false,width:160,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.billgroup= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Amount",headerClass:"header-amount",field:"amount",hide:false,sortable:false,width:100,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.total_due= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Applied Amount",headerClass:"header-applied_amount",field:"applied_amount",hide:false,sortable:false,width:100,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.total_due= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Fee",headerClass:"header-fee",field:"fee",hide:false,sortable:false,width:100,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.total_due= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Credits",headerClass:"header-credits",field:"credits",hide:false,sortable:false,width:100,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.total_due= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Balance",headerClass:"header-balance",field:"balance",hide:false,sortable:false,width:100,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.prepaid= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Verified",headerClass:"header-verified",field:"verified",hide:false,width:100,sortable:true,headerCheckboxSelection:false,checkboxSelection:true,editable:false,valueSetter:function (params) {params.data.checked= params.newValue;return true;},suppressNavigable:true,
				},
				{headerName:"Notes",headerClass:"header-notes",field:"notes",hide:false,sortable:false,width:150,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.invoice_date= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Time Created",headerClass:"header-time_created",field:"time_created",hide:false,sortable:false,width:160,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.status_id= params.newValue;return true;},suppressNavigable:true,},
			],
		}
		return options;
	}
	render() {
		return  (
			<div id="pid_0630_cell_001" style={{position:"absolute",top:0,width:1350,height:300,backgroundColor:"#FFFFFF"}}>
				<div id="pid_0630_cell_001_001" className="ag-theme-balham" 
                    style={{position:"absolute",top:0,left:0,width:1350,height:300,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={this.state.rowSelection} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
				{this.state.showModalSelf?
					<ResponseWindowSelf2
						title={"Data Is Loading . . ."} 
					/> :null}
				{this.state.showModalYesNo?
					<ResponseWindowYesNo2
						title={"Current updated record will be overridden, continue?"} 
						setShowModal={(showModel)=>this.setShowModal(showModel)} 
						setResponse={(response)=>this.setResponse(response)}
					/> :null}
				{this.state.showModalRetrieve?
					<ModalRetrieve
						rowCountInd={this.state.rowCountInd}
						appId={1}	
						guiId={30301}
						sqlId={46}
						inputParm={this.state.input_parm}
						setResponse={(response)=>this.setResponse(response)}
					/> :null}			
			</div>
		)
	}
}

