//Design Tool--
import React, {Component} from 'react';
//import SangerSeqAccessCtrlUserGrid from 'api/layout/547.js'
import UserAccountGrid from './useraccountgrid';

export default class UserAccountDataPage extends React.Component {  
	state = {
		user_id:0,
		role_id:0,
		}
	componentDidMount() {
	}


//-----Render Script-----
    render(){
    return  (
        <>
        <div style={{width:1300,height:700,backgroundColor:"#FFFFFF"}}>
			<div  id = "01010101UserAccountGrid" 
                style={{left:0,top:0, width:650,height:200,backgroundColor:"#FFFFFF"}}>
            	<UserAccountGrid 
                    id = "01010101UserAccountGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"				
				/>
            </div>			
        </div>
        </>
		);
	}
}

