//BEGIN_IMPORT_SECTION
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import getToken, {setToken} from '../../GetSetToken.js';
import getApiUrl, {getPassthroughUrl} from '../../GetApiUrl';

import BussLookupColumnGrid from './busslookupcolumngrid';
import BussLookupDescriptionGrid from './busslookupdescriptiongrid';
import {ResponseWindow} from '../../Modals.js';

var the_this
export default class BussLookupTableGrid extends React.Component {
	state = {
		loaded:false,
		gridOptions:{},
		selected_table_id:0,
		data:[{"id": 0, "name": null, "note": null, "create_by": 0, "create_dt": "2022-06-18 19:39:03", "update_by": 0, "update_dt": "2022-06-18 19:39:03"}]
	}
	gridApi: GridApi
		constructor(props) {
		super(props);
	}
	componentDidMount() {
		the_this = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: true })
		this.retrieveData()
	}
	onGridReady = params => {
    	this.gridApi = params.api;
 	 };

	  onBussTabRetrieve_DW1 = (p_showModal)=>{
		console.log('onBussTabRetrieve_DW1');
		var dataDiv = <div >
		<ResponseWindow title={'Retrieved'} setShowModal={true} /> 
		</div>
			//ReactDOM.unmountComponentAtNode(document.getElementById('01010101RoleGrid'))
			ReactDOM.render(dataDiv, document.getElementById('01010101TableGrid'))
			return (
				dataDiv
			)
		//{showModal ? <ResponseWindow title={'Retrieved'} setShowModal={true} /> : null}	
	}	

	retrieveData = () => {
//		let input_data = '{"id":1}'
		let input_data = '{}'
        const api_url = getPassthroughUrl();
		console.log("BussTableGrid api_url",api_url)
        let the_pin = 0;
		let token = getToken();
		let the_input = '{"usp_name":"usp_react_sql_retrieve","sql_id":11, "input_data":' + input_data + '}';
        fetch(api_url, {
			method: 'POST',headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
        })
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			console.log('BussTableGrid responseJson',responseJson)
			this.setState({retrieved:true});
			if(responseJson.success===true) {
				console.log('BussTableGrid responseJson.output', responseJson.output);
				if(responseJson.output==='NO DATA')
					console.log('BussTableGrid NO DATA')
				else {
					let statusCopy = Object.assign({}, this.state)
					let the_data = JSON.parse(responseJson.output);
					statusCopy.data = the_data;
					statusCopy.loaded = true;
					
					//the_this.onAppRetrieve_DW1(true);
					this.setState(statusCopy);
					console.log('BussTableGrid-this.state.data', this.state.data);
					this.gridApi.setRowData(this.state.data)
					//console.log("AccessAppGrid ResponseWindow ",response)
					//setModalTitle("Retrieved!");
					//setShowModal(true);					
				}
			} else {
				console.log('BussTableGrid responseJson.error', responseJson.error);
				console.log('BussTableGrid responseJson.message', responseJson.message);
			}
		})
		.catch((error) => {
			console.log("error",error)
			this.setState({retrieved:false});
//			alert('Please wait, as this may take few seconds to load...Error Encountered')
			//this.insertOrder(1);
		});
	}

	onBussTabSelection_DW1 = (p_table_id)=>{
		console.log('onBussTabSelection_DW1');
		var dataDiv = <div >
		<BussLookupColumnGrid
			id = "01010101ColumnGrid_instance"
			value = ""
			font_color = "#FFFFFF"
			background_color = "#ffffff"
			font_family = "Arial"
			font_size = "10"
			table_id = {p_table_id}
			getTableId = {(table_id) => { this.getTableId(table_id)}}               />
		</div>
			ReactDOM.unmountComponentAtNode(document.getElementById('01010101ColumnGrid'))
			ReactDOM.render(dataDiv, document.getElementById('01010101ColumnGrid'))
			return (
					dataDiv
			)
	}	

	onBussTabSelection_DW2 = (p_table_id)=>{
		console.log('onBussTabSelection_DW2');
		var dataDiv = <div >
		<BussLookupDescriptionGrid
			id = "01010101DescriptionGrid_instance"
			value = ""
			font_color = "#FFFFFF"
			background_color = "#ffffff"
			font_family = "Arial"
			font_size = "10"
			table_id = {p_table_id}
            col_id = {0}
            getTableId = {(table_id) => { this.getTableId(table_id)}}    />
		</div>
			ReactDOM.unmountComponentAtNode(document.getElementById('01010101DescriptionGrid'))
			ReactDOM.render(dataDiv, document.getElementById('01010101DescriptionGrid'))
			return (
					dataDiv
			)
	}	


	onSelectionChanged = (event)=>{
		const selectedRows =  event.api.getSelectedRows();
		const selectedNodes =  event.api.getSelectedNodes();
		console.log('BussTableGrid selectedNodes:', selectedNodes);
		console.log('BussTableGrid event:', event);
		
		this.gridApi.forEachNode(function(node, i){
			console.log('BussTableGrid-onSelectionChanged node', node);
			if(node.selected===true) {// selected the data
				console.log('BussTableGrid-onSelectionChanged selected===true node', node);
			//	window.selected_table_id = node.data.id
				the_this.onBussTabSelection_DW1(node.data.id);
				the_this.onBussTabSelection_DW2(node.data.id);
			}
		})		
	}

	onCellValueChanged = (obj) => {
 		  console.log('BussTableGrid onCellValueChanged obj', obj);		
		  let field_name = obj.column.colDef.field 
		  let the_id = obj.data.id
		  let the_data = obj.value
		  let token = getToken();
		  if (typeof the_data==='string')
			  the_data = '"' + the_data + '"'
		  let the_input_data = '{"' + field_name + '":' + the_data + ',"id":' + the_id.toString() + '}'
		  let the_input = '{"usp_name":"usp_react_sql_onchange_update","sql_id":11,"input_data"' + ':' + the_input_data + '}'
		  console.log('BussTableGrid-onCellValueChanged the_input', the_input);
		  const api_url = getPassthroughUrl();
		  fetch(api_url, {
			  method: 'POST',headers: { 'Content-Type': 'application/json', },
			  body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
		   })
		   .then((response) => response.json())
		   .then((responseJson) => {
			  console.log('BussTableGrid-onCellValueChanged 2 no error');
		  })	
		  .catch((error) => {
			  console.error(error-'Error in update!');
			  //this.insertOrder(1);
		  });
	}

	onCellClicked(event){
//		console.log('AccessRoleGrid event', event.columnApi.columnController.columnDefs[0]);
		console.log("BussTableGrid onCellClicked event", event);

	}
	onAddRow = () => {
		let newData = [{id:0,table_name:"",note:"",create_by:"",create_dt:"",update_by:"",update_dt:"",}]
        const d = new Date();
        let todt_str = d.getMonth().toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString();
        console.log("todate:  " + todt_str);
		//let the_input_data = '{"name":"New App"}'
		//let the_input_data = '{"table_name":"New Table","create_dt":' + todt_str + '}'
		let the_input_data = '{"table_name":"New Table"}'
		let the_input = '{"usp_name":"usp_react_sql_insert","sql_id":11,"input_data"' + ':' + the_input_data + '}'
		let token = getToken();
		console.log('BussTableGrid-onAddRow token', token);
		console.log('BussTableGrid-onAddRow the_input', the_input);
        const api_url = getPassthroughUrl();
		fetch(api_url, {method: 'POST', headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})})
	 	.then((response) => response.json())
	 	.then((responseJson) => {
			if(responseJson.output==='Insert OK')
				this.retrieveData()
		})	
		.catch((error) => {
			console.error(error-'Error in AddRow!');
			//this.insertOrder(1);
		});
	}
	onDeleteRow = () => {
		console.log("this.gridApi.rowModel",this.gridApi.rowModel)
		if (window.confirm("Confirm Delete?")){

				let selectedRow = []
				let max_sample_id = 0
				let token = getToken();
				this.gridApi.forEachNode(function(node, i){
					if(node.selected===true) {// delete the data
						let table_id = node.data.id
						let the_input_data = '{"id":' + table_id.toString() + '}'
						let the_input = '{"usp_name":"usp_react_sql_delete","sql_id":11,"input_data"' + ':' + the_input_data + '}'
						const api_url = getPassthroughUrl();
						return fetch(api_url, {method: 'POST', headers: { 'Content-Type': 'application/json', },
							body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
						})
						.then((response) => response.json())
						.then((responseJson) => {
							console.log('BussTableGrid-responseJson', responseJson);
							console.log('BussTableGrid-responseJson.success', responseJson.success);
							if(responseJson.success===true) {
							let statusCopy = Object.assign({}, the_this.state)
							statusCopy.data.splice(i, 1);
							the_this.gridApi.setRowData(statusCopy.data)

							//--------Cascading Deletes of children Roles and Role-Screens
							the_input_data = '{"lookup_id":' + table_id.toString() +'}'
							the_input = '{"usp_name":"usp_react_sql_subsql","sql_id":11,"input_data"' + ':' + the_input_data + '}'
							const api_url = getPassthroughUrl();
							return fetch(api_url, {
								method: 'POST',
								headers: { 'Content-Type': 'application/json', },
								body: JSON.stringify({"auth_token": token,"app_id":1,"gui_id":10200,"input": the_input,})
							})
							.then((response) => response.json())
							.then((responseJson) => {
								console.log('BussTableGrid-responseJson using usp_react_sql_subsql', responseJson);
								console.log('BussTableGrid-responseJson.success using usp_react_sql_subsql', responseJson.success);
								the_this.onBussTabSelection_DW1(table_id);
								the_this.onBussTabSelection_DW2(table_id);	
								//the_this.onAppSelection_DW3(window.user_id,app_id)
							})	
							.catch((error) => {
								console.log('SangerSeqOrderDetail2-error', error);
							});
						//--------End of Cascading Delete Logic
                           
						}
						})	
						.catch((error) => {
							console.log('SangerSeqOrderDetail2-error', error);
						});
					}
				})
		}	//--If Confirmed Deletion	
	}
	onRefresh = ()=>{
		console.log('onRefresh');
        the_this.retrieveData();
		//the_this.onBussTableRetrieve_DW1(true);
	}

	createGridOptions() {
		const options: GridOptions = {
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true
			},
			columnDefs: [
				{headerName:"ID",headerClass:"header-id",field:"id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"TableName",headerClass:"header-table_name",field:"table_name",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.table_name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Note",headerClass:"header-note",field:"note",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.note= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Create_by",headerClass:"header-create_by",field:"create_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Create_dt",headerClass:"header-create_dt",field:"create_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_dt= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_by",headerClass:"header-update_by",field:"update_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_dt",headerClass:"header-update_dt",field:"update_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_dt= params.newValue;return true;},suppressNavigable:true,},
			],
		}
		return options;
	}
	render() {
/*
		if(this.state.loaded===false) {
			this.retrieveData()
		}
*/
		return  (
			<div style={{position:"absolute",left:0,top:0,width:650,height:200,backgroundColor:"#FFFFFF"}}>
				<div style={{position:"absolute",left:0,top:0,width:580,height:25,backgroundColor:"#FFFFFF",textAlign:"center"}}>
					Buss Table Grid
				</div>
				<div className="ag-theme-balham" 
                    style={{position:"absolute",top:25,left:0,width:580,height:175,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={"single"} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
				<div id="pid_0630_cell_001_002"
                    style={{position:"absolute",top:25,left:580,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onRefresh}>Refresh
					</button>
				</div>
				<div id="pid_0630_cell_001_003"
                    style={{position:"absolute",top:55,left:580,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onAddRow}>Add
					</button>
				</div>
				<div id="pid_0630_cell_001_004"
                    style={{position:"absolute",top:85,left:580,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onDeleteRow}>
						Delete
					</button>
				</div>				
			</div>
		)
	}
}