//Design Tool--
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import BuildingGrid from './buildinggrid';
import CampusGrid from './campusgrid';
import CityGrid from './citygrid';
import GPSDataGrid from './gpsdatagrid';
import InstitutionGrid from './institutiongrid';
import RouteGrid from './routegrid';
import LabGrid from './labgrid';

export default class FacilityAndGpsTablesDataPage extends React.Component {  
	state = {
		user_id:0,
		role_id:0,
        app_id:0,
        gui_id:0,
	}

	componentDidMount() {
	}

    getRoleId = (role_id) => 
	{
		console.log('SangerSeqAccessCtrlScreen getRoleId', role_id)
		this.setState({ ...this.state,role_id:role_id}) ;		
	}
    getUserId = (user_id) => 
	{
		console.log('SangerSeqAccessCtrlScreen getUserId', user_id)
		this.setState({ ...this.state,user_id:user_id}) ;		
	}
    getGuiId = (gui_id) => 
	{
		console.log('SangerSeqAccessCtrlScreen getGuiId', gui_id)
		this.setState({ ...this.state,gui_id:gui_id}) ;		
	}

//-----Render Script-----
    render(){
    return  (
        <>
        <div style={{width:1300,height:700,backgroundColor:"#FFFFFF"}}>
			<div style={{position:"absolute",left:200,top:0,width:80,height:15,backgroundColor:"#FFFFFF",textAlign:"center",fontSize:10}}>  
					Building Grid
			</div>
             <div  id = "01010101BuildingGrid" 
                style={{position:"absolute",left:15,top:15, width:500,height:130,backgroundColor:"#FFFFFF"}}>
                <BuildingGrid
                    id = "01010101BuildingGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getAppId = {(app_id) => { this.AppId(app_id)}}
                />
            </div>
			<div style={{position:"absolute",left:200,top:145,width:80,height:15,backgroundColor:"#FFFFFF",textAlign:"center",fontSize:10}}>  
					Campus Grid
			</div>
            <div  id = "01010101CampusGrid" 
                style={{position:"absolute",left:15, top: 160, width:500,height:130,backgroundColor:"#FFFFFF"}}>
                <CampusGrid
                    id = "01010101CampusGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getAppId = {(app_id) => { this.AppId(app_id)}}                
				/>
            </div>
			<div style={{position:"absolute",left:200,top:290,width:80,height:15,backgroundColor:"#FFFFFF",textAlign:"center",fontSize:10}}>  
					City Grid
			</div>
            <div  id = "01010101CityGrid" 
                style={{position:"absolute",left:15, top: 305, width:500,height:130,backgroundColor:"#FFFFFF"}}>
                <CityGrid
                    id = "01010101CityGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getAppId = {(app_id) => { this.AppId(app_id)}}                
				/>
            </div>
			<div style={{position:"absolute",left:200,top:435,width:80,height:15,backgroundColor:"#FFFFFF",textAlign:"center",fontSize:10}}>  
					GPS Data Grid
			</div>
            <div  id = "01010101GPSDataGrid" 
                style={{position:"absolute",left:15, top: 450, width:500,height:130,backgroundColor:"#FFFFFF"}}>
                <GPSDataGrid
                    id = "01010101GPSDataGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getAppId = {(app_id) => { this.AppId(app_id)}}                
				/>
            </div>			
			<div style={{position:"absolute",left:850,top:0,width:80,height:15,backgroundColor:"#FFFFFF",textAlign:"center",fontSize:10}}>  
					Institution Grid
			</div>
             <div  id = "01010101BuildingGrid" 
                style={{position:"absolute",left:600,top:15, width:700,height:130,backgroundColor:"#FFFFFF"}}>
                <InstitutionGrid
                    id = "01010101BuildingGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getAppId = {(app_id) => { this.AppId(app_id)}}
                />
			</div>
			<div style={{position:"absolute",left:850,top:145,width:80,height:15,backgroundColor:"#FFFFFF",textAlign:"center",fontSize:10}}>  
					Route Grid
			</div>
			<div  id = "01010101RouteGrid" 
                style={{position:"absolute",left:600,top:160, width:700,height:130,backgroundColor:"#FFFFFF"}}>
                <RouteGrid
                    id = "01010101RouteGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getAppId = {(app_id) => { this.AppId(app_id)}}
                />
			</div>	
			<div style={{position:"absolute",left:850,top:290,width:80,height:15,backgroundColor:"#FFFFFF",textAlign:"center",fontSize:10}}>  
					Lab Grid
			</div>
			<div  id = "01010101LabGrid" 
                style={{position:"absolute",left:600,top:305, width:700,height:170,backgroundColor:"#FFFFFF"}}>
                <LabGrid
                    id = "01010101LabGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getAppId = {(app_id) => { this.AppId(app_id)}}
                />
			</div>								
        </div>
        </>
		);
	}
}

/*
            <div  id = "01010101RoleGrid" 
                style={{position:"absolute",left:0, top: 200, width:650,height:200,backgroundColor:"#FFFFFF"}}>
                <AccessRoleGrid
                    id = "01010101RoleGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getAppId = {(app_id) => { this.AppId(app_id)}}                
				/>
            </div>
            <div  id = "01010101RoleGuiGrid" 
                style={{position:"absolute",left:0, top: 400, width:650,height:200,backgroundColor:"#FFFFFF"}}>
                <AccessRoleGuiGrid
                    id = "01010101RoleGuiGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    role_id = {this.state.role_id}
                    getRoleId = {(role_id) => { this.getRoleId(role_id)}}
                />
            </div>
            <div  id = "01010101UserListGrid" 
                style={{position:"absolute",left:650, top: 0, width:650,height:200,backgroundColor:"#FFFFFF"}}>
                <AccessUserListGrid
                    id = "01010101UserListGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getUserId = {(user_id) => { this.getUserId(user_id)}}
                />
            </div>
            <div  id = "01010101UserRoleGrid" 
                style={{position:"absolute",left:650, top: 200, width:650,height:200,backgroundColor:"#FFFFFF"}}>
                <AccessUserRoleGrid
                    id = "01010101UserRoleGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getUserId = {(user_id) => { this.getUserId(user_id)}}
                    getRoleId = {(role_id) => { this.getRoleId(role_id)}}
                />
            </div>
            <div  id = "01010101UserGuiGrid" 
                style={{position:"absolute",left:650, top: 400, width:650,height:200,backgroundColor:"#FFFFFF"}}>
                <AccessUserGuiGrid
                    id = "01010101UserGuiGrid_instance"
                    value = ""
                    font_color = "#FFFFFF"
                    background_color = "#ffffff"
                    font_family = "Arial"
                    font_size = "10"
                    getUserId = {(user_id) => { this.getUserId(user_id)}}
                    getAppId = {(app_id) => { this.getRoleId(app_id)}}
                    geGuiId = {(gui_id) => { this.getGuiId(gui_id)}}
                />
            </div>
*/