
// var auth_token= "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJoaXRva2VuIjoie1widXNlcl9pZFwiOiAxLCBcInRyYW5zYWN0aW9uX3Rva2VuXCI6IFwiYXNsZGtkZmtqb2l3aWRkMjk4ODNAM0AjR2FkZjA5RURBdzBlc2RmXCIsIFwiYXBwX3R5cGVcIjogXCJEXCIsIFwiYXBwX3ZlcnNpb25cIjogXCIxXCIsIFwiYXBwX3ZlcnNpb25fbnVtXCI6IDEsIFwiYXBwX2V4cGlyZV9kdFwiOiBcIjEyLzMxLzIyOjAwOjAwOjAwXCIsIFwiYXBwX2xpc3RcIjogXCJbe1xcXCJhcHBfaWRcXFwiOiAxLCBcXFwibmFtZVxcXCI6IFxcXCJJbnRlcm5hbCBBcHBcXFwiLCBcXFwiZGVmYXVsdF9ndWlfaWRcXFwiOiAzMDEwMCwgXFxcImxhc3RfYWNjZXNzX2d1aV9pZFxcXCI6IDMwMTAwfV1cIn0iLCJhdWQiOiJodHRwczovL3pvZnQtaW5jLmNvbSIsImV4cCI6MjUzNDAyMzAwODAwLCJpc3MiOiJodHRwczovL3pvZnQtaW5jLmNvbSIsImlhdCI6MTY1Mzc0NTkyNSwibmJmIjoxNjUzNzQ1OTI1fQ.cc-eciRgbv1RcJHx5p5xvwze1mWCNeUlQP7075wV52w";
var auth_token= "0";
var g_username = "";
var g_password = "";
export const getAuthUsername = () => {return g_username};
export const getAuthPassword = () => {return g_password};
export const getToken = () => {console.log("getToken-localStorage.",localStorage.auth_token);return localStorage.auth_token};
export const setAuthUsername = (a_username) => {g_username=a_username};
export const setAuthPassword = (a_password) => {g_password=a_password};
export const setToken = (a_token) => {localStorage.auth_token = a_token;console.log("setToken-a_token",a_token);auth_token=a_token};

//export default getToken;


