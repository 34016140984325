import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useNavigate
  }
from "react-router-dom";

import "./../../styles.css";

//import '@progress/kendo-theme-default/dist/all.css';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Navbar from 'react-bootstrap/Navbar';
import './../../v2/css/bootstrap.min.css';

import WebSiteHomeDefaultNavigator from "../../WebSiteHome_default_navigator"
import WebSiteHomeDefaultFooter from "../../WebSiteHome_default_footer"

//const components = PanelBarUtils.mapItemsToComponents(items);
const PublicPageServiceNgsCellSeq= ()=> {
    const navigate = useNavigate();
  
    const handleClick = (path) => {
        navigate(path);
    }
    return (
        <>
             <WebSiteHomeDefaultNavigator />
             <div id="main">
                <div class="shadow">
                    <div class="wrapper">
                        <div class="center">
                            <div class="thirds" style={{width:"80%", minHeight:880}}>
                                <div class="qblock_container">

                                    <h2>Single CellSequencing Service  </h2>
                                    <p>
                                    Single-cell sequencing is a new technology for amplifying and sequencing the DNA/RNA at single cell level.At Quintara Bio, we are dedicated to offering 10X Chromium high-quality singlecell 3’ RNA sequencing, singlecell V(D)J sequencing, single cell CNV sequencing and singlecell ATAC sequencing.
                                    </p> <br />
                                    <p>
                                    Cell populations are rarely homogeneous and synchronized in their characteristics. Singlecell RNA sequencing aims to uncover the transcriptome diversity in heterogeneous samples. The 10X Chromiumplatform we utilize makes the transcriptional profiling of tens of thousands of individual cells cost-effective and easy to interpret.
                                    </p> <br />

                                    <div class="row">
                                        <div class="left span8">
                                            <h3><b>Features</b></h3>
                                            <ul class="qblock_list">
                                                <li>Individual cells heterogeneity resolution</li>
                                                <li>Genesexpression in subpopulations of cells </li>
                                                <li>High quality data</li>
                                                <li>Multiplexing flexibility</li>
                                                <li>Full bioinformatics support</li>
                                            </ul>
                                        </div>
                                    </div> 

                                    <div class="span8 right">
                                        <h3><b>Applications</b></h3>
                                        <ul class="qblock_list">
                                            <li> Profiling scarce clinical samples</li>
                                            <li> Intra-tumor heterogeneity study</li>
                                            <li> Immune repertoire analysis</li>
                                            <li> Cancer cells evolution analysis</li>
                                            <li> Cell-specific regulatory profiling</li>
                                            <li> Chromatin accessibility analysis</li>
                                        </ul>
                                    </div> 
                                    <br />
                                    <br />

                                    <a href="mailto:ngs@quintarabio.com">
                                        <img src="/assets/images/req3.jpg" title="mailto: ngs@quintarabio.com" style={{verticalAlign: "middle"}} />
                                    </a>

                                    <div class="span8">
                                        <h2><b>Inquery</b></h2>
                                        Email for more informatio: 
                                            <a href="mailto:ngs@quintarabio.com">ngs@quintarabio.com</a>
                        
                                    </div>
                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>
            </div>

        <hr />
			<WebSiteHomeDefaultFooter />


</>
   )
}


function WebSiteServices() {return (<div>WebSiteServices</div>)}

export default PublicPageServiceNgsCellSeq;

/*
             <div id="main">
                <div class="shadow">
                    <div class="wrapper">
                        <div class="center">
                            <div class="thirds" style={{width:"80%", minHeight:880}}>
                                <div class="qblock_container">

                                    <h2>Single CellSequencing Service  </h2>
                                    <p>
                                    Single-cell sequencing is a new technology for amplifying and sequencing the DNA/RNA at single cell level.At Quintara Bio, we are dedicated to offering 10X Chromium high-quality singlecell 3’ RNA sequencing, singlecell V(D)J sequencing, single cell CNV sequencing and singlecell ATAC sequencing.
                                    </p> <br />
                                    <p>
                                    Cell populations are rarely homogeneous and synchronized in their characteristics. Singlecell RNA sequencing aims to uncover the transcriptome diversity in heterogeneous samples. The 10X Chromiumplatform we utilize makes the transcriptional profiling of tens of thousands of individual cells cost-effective and easy to interpret.
                                    </p> <br />

                                    <div class="row">
                                        <div class="left span8">
                                            <h3><b>Features</b></h3>
                                            <ul class="qblock_list">
                                                <li>Individual cells heterogeneity resolution</li>
                                                <li>Genesexpression in subpopulations of cells </li>
                                                <li>High quality data</li>
                                                <li>Multiplexing flexibility</li>
                                                <li>Full bioinformatics support</li>
                                            </ul>
                                        </div>
                                    </div> 

                                    <div class="span8 right">
                                        <h3><b>Applications</b></h3>
                                        <ul class="qblock_list">
                                            <li> Profiling scarce clinical samples</li>
                                            <li> Intra-tumor heterogeneity study</li>
                                            <li> Immune repertoire analysis</li>
                                            <li> Cancer cells evolution analysis</li>
                                            <li> Cell-specific regulatory profiling</li>
                                            <li> Chromatin accessibility analysis</li>
                                        </ul>
                                    </div> 
                                    <br />
                                    <br />

                                    <a href="mailto:ngs@quintarabio.com">
                                        <img src="/assets/images/req3.jpg" title="mailto: ngs@quintarabio.com" style="vertical-align: middle" />
                                    </a>

                                    <div class="span8">
                                        <h2><b>Inquery</b></h2>
                                        <p>Email for more informatio: <a href="mailto:ngs@quintarabio.com">ngs@quintarabio.com</a></p>
                                    </div>
                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>
            </div>
*/



/**
             <div id="main">
                <div class="shadow"></div>
                    <div class="wrapper">
                        <div class="center">
                            <div class="thirds" style="width: 80%; min-height: 880px;">
                                <div class="qblock_container">
                                    <h2>Single CellSequencing Service  </h2>
                                    <p>
                                    Single-cell sequencing is a new technology for amplifying and sequencing the DNA/RNA at single cell level.At Quintara Bio, we are dedicated to offering 10X Chromium high-quality singlecell 3’ RNA sequencing, singlecell V(D)J sequencing, single cell CNV sequencing and singlecell ATAC sequencing.
                                    </p> <br />
                                    <p>
                                    Cell populations are rarely homogeneous and synchronized in their characteristics. Singlecell RNA sequencing aims to uncover the transcriptome diversity in heterogeneous samples. The 10X Chromiumplatform we utilize makes the transcriptional profiling of tens of thousands of individual cells cost-effective and easy to interpret.
                                    </p> <br />
                                    <div class="row">
                                        <div class="left span8">
                                        <h3><b>Features</b></h3>
                                        <ul class="qblock_list">
                                            <li>Individual cells heterogeneity resolution</li>
                                            <li>Genesexpression in subpopulations of cells </li>
                                            <li>High quality data</li>
                                            <li>Multiplexing flexibility</li>
                                            <li>Full bioinformatics support</li>
                                        </ul>
                                    </div>
                                    <div class="span8 right">
                                        <h3><b>Applications</b></h3>
                                        <ul class="qblock_list">
                                        <li> Profiling scarce clinical samples</li>
                                        <li> Intra-tumor heterogeneity study</li>
                                        <li> Immune repertoire analysis</li>
                                        <li> Cancer cells evolution analysis</li>
                                        <li> Cell-specific regulatory profiling</li>
                                        <li> Chromatin accessibility analysis</li>
                                        </ul>
                                    </div> 
                                    <br /><br /><a href="mailto:ngs@quintarabio.com"><img src="/assets/images/req3.jpg" title="mailto: ngs@quintarabio.com" style="vertical-align: middle" /></a>
                                </div> 
                                <div class="span8">
                                    <h2><b>Inquery</b></h2>
                                    <p>Email for more informatio: <a href="mailto:ngs@quintarabio.com">ngs@quintarabio.com</a></p>
                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>
                <p><font>625 Mt Auburn Street, Suite 105, Cambridge, MA 02138</font> </p>
 </div>
</div>
  
*/ 