//BEGIN_IMPORT_SECTION
import React, {Component} from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridApi, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import {ResponseWindowOk2} from '../../Modals';

import getToken, {setToken} from '../../GetSetToken';
import getApiUrl, {getPassthroughUrl} from '../../GetApiUrl';
import {retrieveApiData,retrieveData2,zoftApiForUsp} from '../../data_retrieval';

// const DataRetrieval = async(app_id,gui_id,sql_id)=> {
var the_this
export default class AccessAppGuiFieldGrid extends React.Component  {
	state = {
		loaded:false,
		gridOptions:{},
		app_id:0,
		gui_id:0,
		showModalOk:false,
		modalTitle:"",
		data:[],
	}
	//[{app_id:0,gui_id:0,field_id:0,field_name:"",description:"",enabled:0,control_status:0,note:"",create_by:0,create_dt:"",update_by:0,update_dt:"",}]
	gridApi: GridApi
	constructor(props) {
		super(props);
	}
	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		console.log('AccessAppGuiFieldGrid-componentDidUpdate,this.props,prevProps', this.props,prevProps);
		if(this.props.app_id!==prevProps.app_id||this.props.gui_id!==prevProps.gui_id) {
			this.retrieveData();
		}
	}	

	componentDidMount() {
		the_this = this;
		const options = this.createGridOptions();
		this.setState({ ...this.state, gridOptions: options, dataLoaded: false })
		console.log('AccessAppGuiFieldGrid-this.state.data', this.state.data);
	}

	onGridReady = params => {
    	this.gridApi = params.api;
//		this.gridColumnApi = params.columnApi;

 	 };

	retrieveData() {
		let input_parm = [
			{"request_row_count":1},
			{"offset_row":0},
			{"request_rows":999},
			{"app_id":this.props.app_id},
			{"gui_id":this.props.gui_id},
		]
		let sql_id = 56;
		let app_id = 1;
		console.log('AccessAppGuiFieldGrid retrieveData this.props.input_parm',this.props.app_id);
		let tmp_data = retrieveApiData(1,10200,sql_id,"usp_react_sql_retrieve2", JSON.stringify(input_parm));
        let where_clause = input_parm.slice(3,input_parm.length);

		Promise.resolve(tmp_data).then((value)=>{
			console.log('EditInvoiceStatusOrderListDataGrid retrieveData value',value);
			let total_row_count = parseInt(value);
			retrieveData2(1,10200,sql_id,0,total_row_count,where_clause)
			.then((retrieved_data) => {
				console.log('EditInvoiceStatusOrderListDataGrid retrieveData retrieved_data',retrieved_data);
				let statusCopy = Object.assign({}, this.state)
				statusCopy.data = retrieved_data;
				this.setState(statusCopy);
				this.gridApi.setRowData(this.state.data)
			})
		});
	}
	onSelectionChanged = (event)=>{
		const selectedNodes =  event.api.getSelectedNodes();
		console.log('AccessAppGuiFieldGrid the_this.gridApi', the_this.gridApi);
		the_this.props.getAppGuiFieldId(selectedNodes[0].data.app_id,selectedNodes[0].data.gui_id,selectedNodes[0].data.field_id);
	}
	onPasteStart(params) {
	  console.log('AccessAppGuiFieldGrid onPasteStart:', params);
	}
	onPasteEnd(params) {
	  console.log('AccessAppGuiFieldGrid Callback onPasteEnd:', params);
	}

	onCopySelectedRowsToClipboard(params) {
	  console.log('send to clipboard called with data:');
	  console.log(params.data);
	}
	onCellClicked(event){
//		console.log('AccessRoleGrid event', event.columnApi.columnController.columnDefs[0]);
		console.log("AccessAppGuiFieldGrid onCellClicked event", event);
	}
	onCellDoubleClicked(obj){
		console.log('AccessAppGuiFieldGrid onCellDoubleClicked(obj)', obj);
	}	
	cellClicked(params) {
	console.log('AccessAppGuiFieldGrid cellClicked(params)', params);
	  params.node.setSelected(true)
	}
	onCellValueChanged = (obj) => {
		console.log('AccessAppGuiFieldGrid onCellValueChanged obj', obj);
		let field_name = obj.column.colDef.field 
		let the_app_id = obj.data.app_id
		let the_gui_id = obj.data.gui_id
		let the_field_id = obj.data.field_id
		let the_data = obj.value
		if (typeof the_data==='string')
			the_data = '"' + the_data + '"'
		console.log('AccessAppGuiFieldGrid-onCellValueChanged field_name,the_data', field_name,the_data);
		let the_input_data = '{"' + field_name + '":' + the_data + ',"app_id":' + the_app_id.toString() + ',"gui_id":' + the_gui_id.toString()+ ',"field_id":' + the_field_id.toString() + '}'
		let the_input = '{"usp_name":"usp_react_sql_onchange_update","sql_id":56,"input_data"' + ':' + the_input_data + '}'
		console.log('AccessAppGuiFieldGrid-onCellValueChanged the_input', the_input);

		let api_return_data = zoftApiForUsp(1,10201,56,0,0,"usp_react_sql_onchange_update", the_input_data);
		Promise.resolve(api_return_data).then((value)=>{
			console.log('AccessAppGuiFieldGrid-onCellValueChanged value', value);
			if(value.return_status===0) { //normal return
				console.log('AccessAppGuiFieldGrid-onCellValueChanged value.return_data', value.return_data);
				if(value.return_data.success) {
//					SetEditData("");
					console.log('AccessAppGuiFieldGrid-onCellValueChanged value.return_data.output.data',value.return_data.output);
				} else {
					let the_title = "Update Error:" + value.return_data.message + "!"
					this.setState({ ...this.state, showModalOk: true, modalTitle:the_title})
				};
			} else {
				let the_title = "Unspecified error, please report it to tech support!"
				this.setState({ ...this.state, showModalOk: true, modalTitle:the_title})
				console.log('AccessAppGuiFieldGrid-onCellValueChanged value.return_data', value.return_data);
			}
		}) 
	}
	onAddRow = () => {
//		let newData = [{app_id:this.props.app_id,gui_id:0,description:"NEW ROW",path:"DEFINE PATH",enabled:0,control_status:"",note:"",create_by:"",create_dt:"",update_by:"",update_dt:"",}]
		console.log('AccessAppGuiFieldGrid-onAddRow,this.props',this.props);
		if(this.props.app_id===0||this.props.gui_id===0) {
			let the_title = "an App Gui row is not selected!"
			this.setState({ ...this.state, showModalOk: true, modalTitle:the_title})
			return;
		}
		let the_field_id = 0;
		for(let i=0;i<this.state.data.length;i++) {
			if(this.state.data[i].field_id>the_field_id)
				the_field_id = this.state.data[i].field_id;
		}
		the_field_id++;
		let newData = {app_id:this.props.app_id,gui_id:this.props.gui_id,field_id:the_field_id,field_name:"NEW FIELD",description:"NEW FIELD",enabled:0,control_status:0,note:"",create_by:0,create_dt:"",update_by:0,update_dt:"",}
		let the_input_data = [{"app_id":newData.app_id},{"gui_id":newData.gui_id},{"field_id":newData.field_id}, 
						{"field_name":newData.field_name},{"description":newData.description}];
		console.log('AccessAppGuiFieldGrid-onSaveRow the_input_data', the_input_data);
		let api_return_data = zoftApiForUsp(1,10100,56,0,0,"usp_react_sql_insert2", JSON.stringify(the_input_data));
		Promise.resolve(api_return_data).then((value)=>{
			console.log('AccessAppGuiFieldGrid-onSaveRow value', value);
			if(value.return_status===0) { //normal return
				console.log('AccessAppGuiFieldGrid-onSaveRow value.return_data', value.return_data);
				if(value.return_data.success) {
					let statusCopy = Object.assign({}, this.state)
					statusCopy.data.push(newData);
					this.setState(statusCopy);
					console.log('AccessAppGuiFieldGrid-this.state.data', this.state.data);
					this.gridApi.setRowData(statusCopy.data)
					this.gridApi.ensureIndexVisible(statusCopy.data.length-1);

					console.log('AccessAppGuiFieldGrid-onSaveRow value.return_data.output.data',value.return_data.output);
				} else {
					let the_title = "Update Error:" + value.return_data.message + "!"
					this.setState({ ...this.state, showModalOk: true, modalTitle:the_title})
				};
			} else {
				let the_title = "Unspecified error, please report it to tech support!"
				this.setState({ ...this.state, showModalOk: true, modalTitle:the_title})
				console.log('AccessAppGuiFieldGrid-onAddRow value.return_data', value.return_data);
			}
		}) 
	}

	onSaveRow = () => {
		let there_is_insert = false;
		let breaking = false;
		this.gridApi.forEachNode(function(node, i){
			if(node.data.create_dt===""&&breaking===false) {// to be inserted
				console.log('AccessAppGuiFieldGrid-onSaveRow node', node);
				let app_id = node.data.app_id
				let gui_id = node.data.gui_id
				let field_id = node.data.field_id
				let field_name = node.data.field_name
				let description = node.data.description
				let the_input = [{"app_id":app_id},{"gui_id":gui_id},{"field_id":field_id}, {"field_name":field_name},{"description":description}];
				console.log('AccessAppGuiFieldGrid-onSaveRow the_input', the_input);

				/*
				let api_return_data = zoftApiForUsp(1,10102,56,0,"usp_react_sql_insert2", the_input_data);
				Promise.resolve(api_return_data).then((value)=>{
					console.log('AccessAppGuiFieldGrid-onSaveRow value', value);
					if(value.return_status===0) { //normal return
						console.log('AccessAppGuiFieldGrid-onSaveRow value.return_data', value.return_data);
						if(value.return_data.success) {
							SetEditData("");
							console.log('AccessAppGuiFieldGrid-onSaveRow value.return_data.output.data',value.return_data.output);
						} else {
							let the_title = "Update Error:" + value.return_data.message + "!"
							this.setState({ ...this.state, showModalOk: true, modalTitle:the_title})
						};
					} else {
						the_title = "Unspecified error, please report it to tech support!"
						this.setState({ ...this.state, showModalOk: true, modalTitle:the_title})
						console.log('AccessAppGuiFieldGrid-onBlurTextHandler value.return_data', value.return_data);
					}
				}) 
				*/
				let tmp_data = retrieveApiData(1,10200,32,"usp_react_sql_insert2", JSON.stringify(the_input));
				Promise.resolve(tmp_data).then((value)=>{
					console.log('AccessAppGuiFieldGrid-onSaveRow value', value);
					if(value==='Insert OK') {
						there_is_insert = true;
						the_this.retrieveData()
						console.log('AccessAppGuiFieldGrid-onSaveRow inserted');
					} else {
						let the_title = "Insert Error!"
						this.setState({ ...this.state, showModalOk: true, modalTitle:the_title})
						breaking = true;
					}
				}) 
			}
		})
	}

	onDeleteRow = () => {
		console.log("this.gridApi.rowModel",this.gridApi.rowModel)
		let selectedRow = []
		let max_sample_id = 0
		let token = getToken();
		let there_is_deleting = false;
		this.gridApi.forEachNode(function(node, i){
//			console.log('AccessRoleGrid-onDeleteRow node', node);
			if(node.selected===true) {// delete the data
				let app_id = node.data.app_id
				let gui_id = node.data.gui_id
				let field_id = node.data.field_id
				let the_input = [{"app_id":app_id},{"gui_id":gui_id},{"field_id":field_id}];
				let tmp_data = retrieveApiData(1,10200,56,"usp_react_sql_delete2", JSON.stringify(the_input));
				Promise.resolve(tmp_data).then((value)=>{
					console.log('AccessAppGuiFieldGrid-onDeleteRow value', value);
					if(value==='Delete OK') {
						there_is_deleting = true;
						the_this.retrieveData()
					}
				})
			}
		})
//		if(there_is_deleting===true)
//			this.retrieveData()
	}
	createGridOptions() {
		const options: GridOptions = {
			defaultColDef: {
				editable: true,
				sortable: true,
				filter: true,
				resizable: true
			},
			
			columnDefs: [
				{headerName:"AppID",width: 60, headerClass:"header-app_id",field:"app_id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.app_id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"GuiID",width: 60, headerClass:"header-gui_id",field:"gui_id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.gui_id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"FieldID",width: 80, headerClass:"header-field_id",field:"field_id",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.field_id= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Field Name",width: 200, headerClass:"header-field_name",field:"field_name",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.field_name= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Description",width: 120, headerClass:"header-description",field:"description",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.description= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Enabled",width: 80, headerClass:"header-enabled",field:"enabled",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.enabled= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"ControlStatus",width: 100, headerClass:"header-control_status",field:"control_status",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.control_status= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Note",width: 80, headerClass:"header-note",field:"note",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:true,valueSetter:function (params) {params.data.note= params.newValue;return true;},suppressNavigable:false,},
				{headerName:"Create_by",width: 80,headerClass:"header-create_by",field:"create_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Create_dt",width: 80,headerClass:"header-create_dt",field:"create_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.create_dt= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_by",width: 100,headerClass:"header-update_by",field:"update_by",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_by= params.newValue;return true;},suppressNavigable:true,},
				{headerName:"Update_dt",width: 100,headerClass:"header-update_dt",field:"update_dt",hide:false,resizable:true,sortable:false,checkboxSelection:false,editable:false,valueSetter:function (params) {params.data.update_dt= params.newValue;return true;},suppressNavigable:true,},
			],
		}
		return options;
	}
	render() {
		return  (
			<div style={{position:"absolute",top:0,width:510,height:400,backgroundColor:"#FFFFFF"}}>
				<div style={{position:"absolute",left:20,top:0,width:510,height:25,backgroundColor:"#FFFFFF",textAlign:"left"}}>
					App GUI Field
				</div>
				<div id="pid_0630_cell_001_003"
                    style={{position:"absolute",top:0,left:140,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onAddRow}>Add
					</button>
				</div>
				<div id="pid_0630_cell_001_004"
                    style={{position:"absolute",top:0,left:240,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onDeleteRow}>
						Delete
					</button>
				</div>
				<div className="ag-theme-balham" 
                    style={{position:"absolute",top:25,left:0,width:510,height:350,backgroundColor:"#FFFFFF",fontSize:12}}>
			        <AgGridReact 
						onGridReady={this.onGridReady}
						rowData={this.state.data}
						gridOptions={this.state.gridOptions}
						columnDefs={this.state.gridOptions.columnDefs}
	 				    rowSelection={"single"} //"multiple" 
						onCellClicked={this.cellClicked}
						onSelectionChanged={this.onSelectionChanged}
						onCellValueChanged={this.onCellValueChanged}
						onRowValueChanged={this.onRowValueChanged}
						> 
					</AgGridReact>
				</div>
             {this.state.showModalOk ? 
			 	<ResponseWindowOk2 
			 		title={this.state.modalTitle} 
					setShowModal={(showModel)=>this.setState({...this.state, showModalOk:showModel})}
				/> : 
				null}
			</div>
		)
	}
}

/*
				<div id="pid_0630_cell_001_005"
                    style={{position:"absolute",top:0,left:340,width:80,height:20,backgroundColor:"#FFFFFF",fontSize:12}}>
					<button style={{border:"solid 1px #888888",textAlign:"center",width:60,height:20,fontSize:10,color:"#000000"}} onClick={this.onSaveRow}>
						Save
					</button>
				</div>
*/